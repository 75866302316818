function focusRing(color = 'transparent') {
  // If the color is transparent, we don't want to show the white ring
  const innerRing = `0px 0px 0px 2px ${color === 'transparent' ? 'transparent' : 'white'}`
  const outerRing = `0px 0px 0px 4px ${color}`
  return `${innerRing}, ${outerRing}`
}

const baseStyleControl = {
  borderWidth: '1px',
  borderColor: 'var(--vndly-color-input-border)',
  borderRadius: 'base',
  bg: 'var(--vndly-color-input-background)',
  boxShadow: focusRing('transparent'),

  // Hover Gray Circle
  '&:not([data-disabled])': {
    _after: {
      content: "''",
      boxShadow: '0 0 0 7px var(--vndly-color-input-shadow-hover)',
      position: 'absolute',
      borderRadius: '9999px',
      width: '24px',
      height: '24px',
      zIndex: '-1',
      transition: 'transform 0.15s ease-out, opacity 0.15s ease-out',
      opacity: 0,
      transform: 'scale(0.7)'
    },
    _hover: {
      _after: {
        opacity: 1,
        transform: 'scale(1)'
      }
    }
  },

  _hover: {
    '&:not([data-focus], [data-checked], [data-invalid], [data-warned])': {
      borderWidth: '1px',
      borderColor: 'var(--vndly-color-input-border-hover)',
      _disabled: {
        bg: 'var(--vndly-color-input-background-disabled)',
        borderColor: 'var(--vndly-color-input-border-disabled)',
        color: 'var(--vndly-color-input)'
      }
    }
  },

  _checked: {
    borderColor: 'var(--vndly-color-input-border-checked)',
    bg: 'var(--vndly-color-input-background-checked)',
    color: 'var(--vndly-color-input-icon-checked)',
    _disabled: {
      borderColor: 'var(--vndly-color-input-border-checked-disabled)',
      bg: 'var(--vndly-color-input-background-checked-disabled)',
      color: 'var(--vndly-color-input-icon-checked)',
      cursor: 'not-allowed',
      _hover: {
        borderColor: 'var(--vndly-color-input-border-checked-disabled)',
        bg: 'var(--vndly-color-input-background-checked-disabled)',
        color: 'var(--vndly-color-input-icon-checked)'
      },
      _active: {
        borderColor: 'var(--vndly-color-input-border-checked-disabled)',
        bg: 'var(--vndly-color-input-background-checked-disabled)',
        color: 'var(--vndly-color-input-icon-checked)'
      }
    },
    _hover: {
      borderColor: 'var(--vndly-color-input-border-checked)',
      bg: 'var(--vndly-color-input-background-checked)',
      color: 'var(--vndly-color-input-icon-checked)'
    },
    _focus: {
      boxShadow: focusRing('var(--vndly-color-input-shadow-focused)'),
      _hover: {
        boxShadow: focusRing('var(--vndly-color-input-shadow-focused)')
      }
    },
    _active: {
      borderColor: 'var(--vndly-color-input-border-checked)',
      bg: 'var(--vndly-color-input-background-checked)',
      color: 'var(--vndly-color-input-icon-checked)'
    }
  },

  _indeterminate: {
    bg: 'var(--vndly-color-input-background-indeterminate)',
    borderColor: 'var(--vndly-color-input-border-indeterminate)',
    color: 'var(--vndly-color-input-icon-indeterminate)',
    _disabled: {
      borderColor: 'var(--vndly-color-input-border-indeterminate-disabled)',
      bg: 'var(--vndly-color-input-background-indeterminate-disabled)'
    },
    _hover: {
      bg: 'var(--vndly-color-input-background-indeterminate)',
      borderColor: 'var(--vndly-color-input-border-indeterminate)',
      color: 'var(--vndly-color-input-icon-indeterminate)'
    },
    _focus: {
      boxShadow: focusRing('var(--vndly-color-input-border-focused)')
    }
  },

  _disabled: {
    bg: 'var(--vndly-color-input-background-disabled)',
    borderColor: 'var(--vndly-color-input-border-disabled)',
    color: 'var(--vndly-color-input-icon)',
    cursor: 'not-allowed',
    _hover: {
      boxShadow: focusRing('transparent')
    },
    _active: {
      bg: 'var(--vndly-color-input-background-disabled)',
      borderColor: 'var(--vndly-color-input-border-disabled)',
      color: 'var(--vndly-color-input-icon)'
    }
  },

  _focus: {
    borderWidth: '2px',
    borderColor: 'var(--vndly-color-input-border-focused)',
    boxShadow: focusRing('transparent')
  },

  _active: {
    '&:not([data-focus], [data-checked], [data-invalid], [data-warned])': {
      borderColor: 'var(--vndly-color-input-border-active)',
      borderWidth: '1px'
    }
  },

  _invalid: makeInvalidControlStyles('invalid'),

  // warned styles
  'label[data-warned]:not([data-invalid]) &': makeInvalidControlStyles('warned')
}

function makeInvalidControlStyles(cssVarVariant) {
  return {
    borderWidth: '2px',
    borderColor: `var(--vndly-color-input-border-${cssVarVariant})`,
    _disabled: {
      bg: 'var(--vndly-color-input-background-disabled)',
      borderColor: 'var(--vndly-color-input-border-disabled)',
      color: 'var(--vndly-color-input)',
      boxShadow: `0 0 0 0 transparent, 0 0 0 1px var(--vndly-color-input-border-${cssVarVariant})`
    },
    _focus: {
      boxShadow: focusRing('var(--vndly-color-input-shadow-focused)'),
      _hover: {
        boxShadow: focusRing('var(--vndly-color-input-shadow-focused)')
      }
    },
    _checked: {
      borderColor: 'blueberry400',
      _disabled: {
        borderColor: 'var(--vndly-color-input-border-checked-disabled)',
        bg: 'var(--vndly-color-input-background-checked-disabled)',
        color: 'var(--vndly-color-input-icon-checked)',
        cursor: 'not-allowed'
      },
      '&:not([data-focus])': {
        boxShadow: focusRing(`var(--vndly-color-input-shadow-${cssVarVariant})`),
        _hover: {
          boxShadow: focusRing(`var(--vndly-color-input-shadow-${cssVarVariant})`)
        }
      }
    }
  }
}

const variantIcon = props => ({
  label: {
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    transition: 'all 0.15s ease-out',
    border: '2px solid',
    borderColor: 'transparent',
    borderRadius: 'base',
    p: '2px',
    m: -1,
    _disabled: { cursor: 'not-allowed' },
    _hover: { bgColor: 'gray.200' },
    _focus: { borderColor: 'var(--vndly-color-container-border-focused)' }
  },
  customControl: {
    color: 'var(--vndly-color-container-icon)',
    transition: 'all 0.15s ease-out',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'top',
    userSelect: 'none',
    flexShrink: 0,
    _checked: { color: props.checkedColor ?? 'var(--vndly-color-container-icon)' },
    _hover: {
      color: props.state.isChecked
        ? props.checkedColor ?? 'var(--vndly-color-container-border-focused)'
        : 'var(--vndly-color-container-border-focused)'
    },
    _focus: {
      color: props.state.isChecked
        ? props.checkedColor ?? 'var(--vndly-color-container-border-focused)'
        : 'var(--vndly-color-container-border-focused)'
    }
  }
})

export const Checkbox = {
  baseStyle: {
    control: baseStyleControl
  },
  sizes: {
    lg: {
      label: { fontSize: 'md' }
    },
    xl: {
      control: { w: 6, h: 6 },
      label: { fontSize: 'md' }
    }
  },
  defaultProps: {
    size: 'xl'
  },
  variants: {
    icon: variantIcon
  }
}
