const variantOutline = props => ({
  badge: {},
  container: { bg: 'white', borderWidth: '1px', borderColor: `gray.300`, color: `${props.colorScheme}.500` }
})

export const Avatar = {
  defaultProps: {
    colorScheme: 'brand'
  },
  variants: {
    outline: variantOutline
  }
}
