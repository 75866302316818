const variantUnstyled = {
  // When doing <Button as="a">
  '&.chakra-button': {
    _hover: {
      textDecoration: 'none'
    }
  }
}

const variantHovered = {
  _hover: {
    color: 'blueberry400'
  }
}

const variantLink = {
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'var(--vndly-color-link)',
  bgColor: 'var(--vndly-color-link-background)',
  textDecoration: 'underline',
  _hover: {
    color: 'var(--vndly-color-link-hover)',
    bgColor: 'var(--vndly-color-link-background-hover)'
  }
}

const variantInlineGray = {
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'gray.500',
  textDecoration: 'underline',
  _hover: {
    color: 'gray.600',
    textDecoration: 'underline'
  }
}

/**
 * @deprecated We are no longer supporting inlineDark Link variant in the Design System.
 * Alternatively, use variant `link` (ie. blue link) or `inlineGray` (ie. subdued link)
 */
const variantInlineDark = {
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'gray.800',
  textDecoration: 'underline',
  _hover: {
    color: 'gray.900',
    textDecoration: 'underline'
  }
}

const variantTabLink = {
  textDecoration: 'none',
  color: 'licorice300',
  _hover: {
    textDecoration: 'none',
    borderBottom: '4px solid',
    borderBottomColor: 'blackPepper400'
  }
}

const variantHomeLink = {
  textDecoration: 'none',
  _hover: {
    textDecoration: 'none',
    bgColor: 'var(--vndly-color-link-background-hover)',
    boxShadow: 'sm'
  },
  _focus: {
    textDecoration: 'none',
    bgColor: 'var(--vndly-color-link-background-hover)',
    boxShadow: 'sm'
  }
}

const variants = {
  link: variantLink,
  inlineGray: variantInlineGray,
  tabLink: variantTabLink,
  homeLink: variantHomeLink,
  // deprecated unstyled, hovered and inlineDark
  inlineDark: variantInlineDark,
  unstyled: variantUnstyled,
  hovered: variantHovered
}

const sizes = {
  sm: {
    fontSize: 'sm'
  },
  md: {
    fontSize: 'md'
  }
}

export const Link = {
  variants,
  sizes,
  defaultProps: {
    variant: 'link',
    size: 'md'
  }
}
