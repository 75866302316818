import { IntlShape } from '@formatjs/intl'
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import { MessageDescriptor } from 'react-intl'
import { useIntlContext } from '../components/IntlProviders.context'
import TranslationFallbackWrapper from './TranslationFallbackWrapper'
import { ExecuteMessage } from './valeyard/reactRuntime'
import { Catalog, executeMessage, Expression } from './valeyard/runtime'

// For some reason the exported `MessageDescriptor` has the id as optional even
// though that is not valid and will cause formatMessage() to throw :(
export interface SaferMessageDescriptor extends MessageDescriptor {
  id: string
}

type MessageDescriptorWithFallback = SaferMessageDescriptor & {
  noProviderFoundMsg?: string
}

export type MsgProps<Values extends Record<string, any> = Record<string, React.ReactNode>> = Values &
  SaferMessageDescriptor & {
    tagName?: React.ElementType<any>
    children?(nodes: React.ReactNodeArray): React.ReactElement | null
    ignoreTag?: IntlMessageFormatOptions['ignoreTag']
    values?: Values
  }

/**
 * Convenient way to add international messages using a Component.
 *
 * Benefits:
 * - Supports ICU Valeyard messages, unlike Format.js FormattedMessage
 * - values can be top-level props
 *
 * @example Simple message (count defaults to 1)
 * <Msg id="term.job" count={1} /> // renders "Job"
 *
 * @example Simple message with specific count
 * <Msg id="term.job" count={99} /> // renders "Jobs"
 *
 * @example Message with nested `term` value
 * <Msg id="common.ui.end_term" term={<Msg id="term.job" count={1} />} /> // renders "End Job"
 *
 * @example Message with a noProviderFoundMsg in case the provider is missing (good for elegant fallbacks without JS errors)
 * // Don't overuse this, as it hides areas that need to be wrapped. Save this for heavily used common components.
 * <Msg id="term.job" noProviderFoundMsg="Job" />
 */
export function Msg<Values extends Record<string, any> = Record<string, React.ReactNode>>(props: MsgProps<Values>) {
  return (
    <TranslationFallbackWrapper id={props.id} message={props.noProviderFoundMsg}>
      <InnerMsg {...props} />
    </TranslationFallbackWrapper>
  )
}

// Extracted InnerMsg out so `TranslationFallbackWrapper` can catch the provider not found error from `useIntlContext`
function InnerMsg<Values extends Record<string, any> = Record<string, React.ReactNode>>({
  id,
  description,
  defaultMessage,
  noProviderFoundMsg,
  tagName,
  ignoreTag,
  children,
  values: valuesOldProp,
  ...valuesConvenientTopLevelProps
}: MsgProps<Values>) {
  // TODO what do we want to do with these unused props??
  // const passThroughProps = { id, description, defaultMessage, tagName, ignoreTag, children }

  const values = { ...valuesOldProp, ...valuesConvenientTopLevelProps }
  const { intl, messages } = useIntlContext()
  const catalogFn = (_locale: string, messageKey: string) => messages[messageKey]

  return <ExecuteMessage messageKey={id} intl={intl} catalog={catalogFn} context={values} />
}

type DefaultValues = Record<string, PrimitiveType | FormatXMLElementFn<string, string>>

/**
 * Convenient way to add international messages using a hook and function. Use the <Msg> component if possible.
 *
 * @example Simple message (count defaults to 1)
 * const msg = useMsg()
 * msg("term.job", { count: 1 }) // renders "Job"
 *
 * @example Simple message with specific count
 * const msg = useMsg()
 * msg("term.job", { count: 99 }) // renders "Jobs"
 *
 * @example Message with nested `term` value
 * const msg = useMsg()
 * msg("common.ui.end_term", { term: msg("term.job", { count: 1 }) }) // renders "End Job"
 *
 *
 */
export function useMsg<Values extends Record<string, any> = DefaultValues>() {
  const intlRef = useRef<IntlShape | undefined>()
  const messagesRef = useRef<Record<string, Expression> | undefined>()
  const msgFnRef = useRef<typeof msg | undefined>()
  const hasLoggedErrorRef = useRef(false)
  let allLayersLoaded = false

  // If the valeyard ctx is loaded, use it. Otherwise, fallback to the default msg function
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ctx = useIntlContext()
    intlRef.current = ctx.intl
    messagesRef.current = ctx.messages
    allLayersLoaded = ctx.allLayersLoaded

    if (!allLayersLoaded) {
      msgFnRef.current = msgContextLoading
    } else {
      msgFnRef.current = msg
    }
  } catch (e) {
    msgFnRef.current = msgNoContextFound
  }

  // We want the returned function to be a stable reference, so we use useCallback
  return useCallback((...args: Parameters<typeof msg>) => msgFnRef.current!(...args), [allLayersLoaded])

  function msg(id: string | SaferMessageDescriptor | React.ReactNode, values: Values = {} as Values): string {
    // Prevent this function from throwing and crashing the whole page if invalid id is supplied (i.e. a falsey)
    if (!id || (typeof id !== 'string' && !isMessageDescriptor(id) && !React.isValidElement(id))) {
      // eslint-disable-next-line no-console
      console.error(`Warning: Invalid \`id\` of ${id} supplied to useMsg().msg(). Falling back to empty string.`)
      return ''
    }

    // If it's a string or a message descriptor, execute the expression
    if (typeof id === 'string' || isMessageDescriptor(id)) {
      const msgId = isMessageDescriptor(id) ? id?.id : id
      if (!messagesRef.current || !intlRef.current) return msgNoContextFound(id)
      const catalogFn: Catalog = (_locale: string, messageKey: string) => messagesRef.current![messageKey]
      const evaluatedMsg = executeMessage(msgId, intlRef.current, catalogFn, values)
      return evaluatedMsg || msgId
    }

    // If we get here, it's a React element, so we need to recurse through it and replace any nested Msgs
    const { id: lazyId, ...props } = id.props
    return msg(
      lazyId,
      Object.fromEntries(
        Object.entries(props).map(([key, value]) => [
          key,
          React.isValidElement(value as any) ? msg(value as any) : value
        ])
      ) as any
    )
  }

  function msgContextLoading() {
    return ''
  }

  function msgNoContextFound(id: string | SaferMessageDescriptor | React.ReactNode): string {
    let noProviderFoundMsg =
      (id as MessageDescriptorWithFallback)?.noProviderFoundMsg ?? (id as MessageDescriptorWithFallback)?.id
    if (noProviderFoundMsg === undefined && typeof id === 'string') noProviderFoundMsg = id
    if (!hasLoggedErrorRef.current) {
      // eslint-disable-next-line no-console
      console.error(
        'Translation intl obj not found, fallback of',
        noProviderFoundMsg,
        'used for id:',
        (id as MessageDescriptor)?.id ?? id
      )
      hasLoggedErrorRef.current = true
    }
    return String(noProviderFoundMsg)
  }
}

export function isMessageDescriptor(id: any): id is SaferMessageDescriptor {
  return id && typeof id !== 'string' && 'id' in id && id.id !== undefined
}

export type FormatMsg = ReturnType<typeof useMsg>

/**
 * A util to define msgs outside the context of React, portable and convenient.
 *
 * @example
 * // Define a lazy msg, possibly in an external js config
 * const myConfig = {
 *   tooltip: lazyMsg('page.tooltip', { count: 1 }),
 *   text: lazyMsg('page.text', { name: lazyMsg('page.text.name') })
 * }
 *
 * // In your component, use the lazy messages in either string or JSX contexts
 * const MyCmp = () => {
 *   const msg = useMsg()
 *   return (
 *     <Box title={msg(myConfig.tooltip)}>{myConfig.text}</Box>
 *   )
 * }
 */
export function lazyMsg<Values extends Record<string, React.ReactNode> = DefaultValues>(
  id: string,
  values: Values = {} as Values,
  opts?: IntlMessageFormatOptions
) {
  return <Msg id={id} {...values} {...opts} />
}

/**
 *
 *
 *
 * EVERYTHING BELOW IS DEPRECATED
 *
 *
 *
 */

export type Values = Record<string, MessageConfig>
export type MessageConfig = { id: string; values?: Values; toLowerCase?: boolean } | string | number | null
