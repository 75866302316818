import { motion } from 'framer-motion'
import { dataAttr } from '../../../common/domUtils'
import { useMsg } from '../../../common/localizationUtils'
import { Heart, HeartO, IconProps } from '../../../icons'
import { Box, CheckboxProps, forwardRef, Tooltip, useCheckbox, useMultiStyleConfig } from '../../chakra'

type IconCheckboxProps = Omit<CheckboxProps, 'children' | 'aria-label'> & {
  /**
   * The Icon to display when the checkbox is unchecked.
   */
  iconUnchecked: React.ElementType<IconProps>
  /**
   * The Icon to display when the checkbox is checked.
   */
  iconChecked: React.ElementType<IconProps>
  /**
   * The label (a verb or verb phrase) that is shown in the tooltip and read by screen readers that describes
   * the action that will be taken when the checkbox becomes checked.
   */
  verbLabelToCheck: string
  /**
   * The label (a verb or verb phrase) that is shown in the tooltip only that describes
   * the action that will be taken when the checkbox becomes unchecked.
   */
  verbLabelToUncheck: string
  checkedColor?: IconProps['color']
}
export const IconCheckbox = forwardRef<IconCheckboxProps, 'input'>(
  (
    {
      iconChecked: IconChecked,
      iconUnchecked: IconUnchecked,
      checkedColor,
      variant = 'icon',
      verbLabelToCheck,
      verbLabelToUncheck,
      ...props
    },
    ref
  ) => {
    const { state, getInputProps, getCheckboxProps, htmlProps } = useCheckbox(props)
    const inputProps = getInputProps({}, ref)
    const checkboxProps = getCheckboxProps()
    const ActiveIcon = state.isChecked ? IconChecked : IconUnchecked
    const styles = useMultiStyleConfig('Checkbox', {
      ...props,
      ...checkboxProps,
      checkedColor,
      state,
      variant
    })

    const label = state.isChecked ? verbLabelToUncheck : verbLabelToCheck
    return (
      <Tooltip closeOnClick={false} label={`${label}`}>
        <Box
          as="label"
          data-disabled={dataAttr(state.isDisabled)}
          data-focus={dataAttr(state.isFocused)}
          data-active={dataAttr(state.isActive)}
          data-hoist-above-parent-hover
          __css={styles.label}
          {...htmlProps}>
          <input {...inputProps} aria-label={verbLabelToCheck} aria-pressed={state.isChecked} role="button" />
          <motion.div aria-hidden whileTap={{ scale: state.isDisabled ? 1 : 1.2 }}>
            <Box as={ActiveIcon} __css={styles.customControl} {...checkboxProps} />
          </motion.div>
        </Box>
      </Tooltip>
    )
  }
)

IconCheckbox.displayName = 'IconCheckbox'

export const FavoriteCheckbox = forwardRef<Omit<CheckboxProps, 'children' | 'variant'>, 'input'>((props, ref) => {
  const msg = useMsg()
  return (
    <IconCheckbox
      data-testid="favorite"
      ref={ref}
      {...props}
      iconUnchecked={Heart}
      iconChecked={HeartO}
      verbLabelToCheck={msg('joblist.job_favorite_checkbox.add_to_favorites')}
      verbLabelToUncheck={msg('joblist.job_favorite_checkbox.remove_from_favorites')}
      checkedColor="red.600"
    />
  )
})

FavoriteCheckbox.displayName = 'FavoriteCheckbox'
