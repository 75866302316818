/* eslint-disable react/no-danger */
import { chakra, forwardRef } from '../design_system'

export const EmptyFolder = forwardRef((props, ref) => {
  return (
    <chakra.svg ref={ref} viewBox="0 0 234 234" {...props}>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
      	.emptyfolder-st0{fill:#F2F3F3;}
        .emptyfolder-st1{fill:none;}
        .emptyfolder-st2{fill:#FFFFFF;}
        .emptyfolder-st3{fill:#CBCACA;}
        .emptyfolder-st4{fill:#FFFFFF;stroke:#CBCACA;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .emptyfolder-st5{fill:#F2F3F3;stroke:#CBCACA;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .emptyfolder-st6{fill:none;stroke:#CBCACA;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .emptyfolder-st7{fill:#CBCACA;stroke:#CBCACA;stroke-width:3.7991;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10.0006;}
        .emptyfolder-st8{fill:#FFFFFF;stroke:#CBCACA;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .emptyfolder-st9{fill:none;stroke:#CBCACA;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
        .emptyfolder-st10{fill:none;stroke:#CBCACA;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:2.0329,4.0658;}
        .emptyfolder-st11{fill:none;stroke:#CBCACA;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
       `
        }}
      />
      <path
        className="emptyfolder-st0"
        d="M166.3,175.2c0,0.3,0,0.6,0,1v1.1c-0.2,10.5-8.8,19.1-19.4,19.1h-0.1c-5.9,0-11.3-2.7-14.9-7.2  c-5.8,7-15.9,9.2-24.2,4.7c-2.2-1.2-4.2-2.8-5.7-4.8c-2.4,3-5.7,5.2-9.5,6.3c-4.9,1.5-10.2,0.9-14.7-1.5c-2.2-1.2-4.2-2.8-5.7-4.7  c-5.7,7.1-15.8,9.3-24.1,4.9c-6.2-3.3-10.1-9.8-10.2-16.9v-65.9c0-17.1,6.7-33.3,18.8-45.4S84.8,47.1,102,47.1l0.1,2.3v-2.3  c35.4,0,64.2,28.8,64.2,64.2L166.3,175.2L166.3,175.2z"
      />
      <g>
        <path
          className="emptyfolder-st1"
          d="M102,57.4c-14.5,0-28.1,5.6-38.3,15.8C53.6,83.4,48,96.9,48,111.2V177c0.1,3.4,1.9,6.4,4.7,7.9   c2.2,1.1,4.6,1.4,6.9,0.7c2.3-0.7,4.2-2.2,5.3-4.4c1.4-2.6,4.1-4.2,7.1-4.2l0,0c3,0,5.7,1.7,7.1,4.3c0.9,1.7,2.4,2.9,3.6,3.5   c4.4,2.4,9.8,0.8,12.2-3.6c1.4-2.6,4.1-4.2,7.1-4.2s5.7,1.7,7,4.3c0.9,1.7,2.4,2.9,3.6,3.5c2.1,1.1,4.5,1.4,6.8,0.7   s4.2-2.2,5.4-4.3c1.4-2.6,4.1-4.2,7-4.2l0,0c2.9,0,5.6,1.6,7,4.2c1.6,3,4.6,4.8,7.9,4.8c5.1,0,9.1-4,9.1-9v-65.7   C155.9,81.6,131.7,57.4,102,57.4z"
        />
        <path
          className="emptyfolder-st2"
          d="M161.6,111.4c0-32.9-26.7-59.6-59.6-59.6l0,0c-16,0-31,6.2-42.2,17.5c-11.3,11.3-17.5,26.2-17.4,42.1v65.9   c0,5.4,3,10.3,7.8,12.9c7.2,3.8,16.1,1.1,19.9-6c0.4-0.7,1.2-1.2,2-1.2s1.6,0.5,2,1.2c1.4,2.5,3.4,4.6,5.9,5.9   c7.1,3.9,16.1,1.2,20-5.9c0.4-0.7,1.2-1.2,2-1.2s1.6,0.5,2,1.2c1.4,2.5,3.4,4.6,5.9,5.9c7.2,3.9,16.1,1.2,20-5.9   c0.4-0.9,1.2-1.4,2-1.4s1.6,0.5,2,1.2c2.5,4.8,7.5,7.7,12.9,7.8h0.1c8.1,0,14.7-6.5,14.7-14.6L161.6,111.4L161.6,111.4z M155.9,177   c-0.1,5-4.1,9-9.1,9c-3.2,0-6.3-1.9-7.9-4.8c-1.4-2.6-4.1-4.2-7-4.2l0,0c-2.9,0-5.6,1.6-7,4.2c-1.1,2.1-3.1,3.7-5.4,4.3   c-2.3,0.7-4.8,0.4-6.8-0.7c-1.1-0.6-2.7-1.7-3.6-3.5c-1.4-2.6-4.1-4.3-7-4.3c-3,0-5.7,1.6-7.1,4.2c-2.4,4.4-7.8,6-12.2,3.6   c-1.1-0.6-2.7-1.7-3.6-3.5c-1.4-2.6-4.1-4.3-7.1-4.3l0,0c-3,0-5.7,1.6-7.1,4.2c-1.1,2.1-3,3.7-5.3,4.4s-4.8,0.5-6.9-0.7   c-2.9-1.5-4.7-4.5-4.7-7.9v-65.8c0-14.4,5.5-27.9,15.7-38C74,63,87.6,57.4,102.1,57.4c29.7,0,53.9,24.2,53.9,53.9l-0.1,63.3   L155.9,177z"
        />
        <g>
          <path
            className="emptyfolder-st3"
            d="M102.1,47.1v2.3l-0.1-2.3c-17.2,0-33.3,6.7-45.4,18.8s-18.8,28.3-18.8,45.4v65.9c0.1,7.1,4,13.6,10.2,16.9    c8.3,4.4,18.4,2.2,24.1-4.9c1.5,1.9,3.5,3.5,5.7,4.7c4.5,2.4,9.8,3,14.7,1.5c3.8-1.1,7.1-3.3,9.5-6.3c1.5,2,3.5,3.6,5.7,4.8    c8.3,4.5,18.4,2.3,24.2-4.7c3.6,4.5,9,7.2,14.9,7.2h0.1c10.6,0,19.2-8.6,19.4-19.1v-1.1c0-0.4,0-0.7,0-1v-63.9    C166.3,75.9,137.5,47.1,102.1,47.1z M161.6,175.3L161.6,175.3c0,0.2,0,0.4,0,0.6v0.1v1.2c0,8.1-6.6,14.6-14.7,14.6h-0.1    c-5.4-0.1-10.4-3-12.9-7.8c-0.4-0.7-1.2-1.2-2-1.2s-1.6,0.5-2,1.4c-3.9,7.1-12.8,9.8-20,5.9c-2.5-1.3-4.5-3.4-5.9-5.9    c-0.4-0.7-1.2-1.2-2-1.2s-1.6,0.5-2,1.2c-3.9,7.1-12.9,9.8-20,5.9c-2.5-1.3-4.5-3.4-5.9-5.9c-0.4-0.7-1.2-1.2-2-1.2    s-1.6,0.5-2,1.2c-3.8,7.1-12.7,9.8-19.9,6c-4.8-2.6-7.8-7.5-7.8-12.9v-65.9c-0.1-15.9,6.1-30.8,17.4-42.1    C71,58,86,51.8,101.9,51.8h0.1c32.9,0,59.6,26.7,59.6,59.6L161.6,175.3L161.6,175.3z"
          />
        </g>
      </g>
      <circle className="emptyfolder-st3" cx="78.1" cy="113.3" r="8" />
      <polygon className="emptyfolder-st4" points="214.6,165.6 158.8,183.7 145.7,143.4 182.2,131.6 201.6,125.3 " />
      <polygon className="emptyfolder-st4" points="193.4,191.8 137.6,209.9 158.3,183.5 194.8,171.6 214.1,165.4 " />
      <path className="emptyfolder-st5" d="M215.2,177.6" />
      <circle className="emptyfolder-st3" cx="126" cy="113.3" r="8" />
      <path
        className="emptyfolder-st3"
        d="M48.2,44.6h-1.8c-3,0-5.5-2.5-5.5-5.5l0,0v-1.8c0-0.9-0.7-1.8-1.6-1.9c-1-0.1-1.9,0.6-2,1.7c0,0.1,0,0.1,0,0.2  v1.8c0,3-2.5,5.5-5.5,5.5l0,0h-1.7c-1,0-1.8,0.7-1.9,1.7s0.6,1.9,1.6,2c0.1,0,0.1,0,0.2,0h1.8c3,0,5.4,2.5,5.4,5.5v1.7  c0,1,0.7,1.8,1.7,1.9s1.9-0.6,2-1.6c0-0.1,0-0.1,0-0.2v-1.8c0-3,2.4-5.5,5.5-5.5l0,0h1.8c1,0,1.8-0.8,1.8-1.9c0-0.1,0-0.1,0-0.2  C50,45.3,49.2,44.6,48.2,44.6"
      />
      <path
        className="emptyfolder-st3"
        d="M203.5,59.8h-1.7c-3,0-5.4-2.4-5.4-5.4l0,0v-1.7c0-0.9-0.7-1.7-1.6-1.9c-1-0.1-1.9,0.6-2,1.6c0,0.1,0,0.1,0,0.2  v1.8c0,3-2.4,5.4-5.4,5.4l0,0h-1.7c-0.9,0-1.8,0.7-1.9,1.6c-0.1,1,0.6,1.9,1.6,2c0.1,0,0.1,0,0.2,0h1.8c3,0,5.4,2.4,5.4,5.4v1.7  c0,0.9,0.7,1.7,1.6,1.9c1,0.1,1.9-0.6,2-1.6c0-0.1,0-0.1,0-0.2v-1.8c0-3,2.4-5.4,5.4-5.4h1.8c1,0,1.8-0.8,1.8-1.8c0-0.1,0-0.1,0-0.2  C205.2,60.5,204.4,59.8,203.5,59.8"
      />
      <path
        className="emptyfolder-st3"
        d="M30.5,199.7h-1.3c-2.2,0-3.9-1.8-3.9-3.9v-1.3c0-0.7-0.5-1.3-1.1-1.4c-0.7-0.1-1.4,0.4-1.4,1.2v0.1v1.3  c0,2.2-1.8,3.9-3.9,3.9h-1.3c-0.7,0-1.3,0.5-1.4,1.1c-0.1,0.7,0.5,1.4,1.2,1.4h0.1h1.3c2.2,0,3.9,1.7,3.9,3.9l0,0v1.3  c0,0.7,0.5,1.3,1.1,1.4c0.7,0.1,1.4-0.4,1.4-1.2v-0.1v-1.3c0-2.2,1.8-3.9,3.9-3.9l0,0h1.3c0.7,0,1.3-0.6,1.3-1.3v-0.1  C31.7,200.1,31.2,199.7,30.5,199.7"
      />
      <path
        className="emptyfolder-st3"
        d="M178.2,46H177c-2.2,0-3.9-1.8-3.9-3.9l0,0v-1.3c0-0.7-0.5-1.3-1.1-1.4c-0.7-0.1-1.4,0.5-1.4,1.2v0.1V42  c0,2.2-1.8,3.9-3.9,3.9l0,0h-1.2c-0.7,0-1.3,0.5-1.4,1.1c-0.1,0.7,0.4,1.4,1.2,1.4h0.1h1.3c2.2,0,3.9,1.8,3.9,3.9l0,0v1.3  c0,0.7,0.5,1.3,1.1,1.4c0.7,0.1,1.4-0.5,1.4-1.2v-0.1v-1.3c0-2.2,1.8-3.9,3.9-3.9l0,0h1.3c0.7,0,1.3-0.6,1.3-1.3v-0.1  C179.5,46.5,178.9,46,178.2,46"
      />
      <path
        className="emptyfolder-st3"
        d="M26.5,73.9h-1.3c-2.2,0-3.9-1.8-3.9-3.9l0,0v-1.2c0-0.7-0.5-1.3-1.1-1.4c-0.7-0.1-1.4,0.5-1.4,1.2v0.1V70  c0,2.2-1.8,3.9-3.9,3.9h-1.3c-0.7,0-1.3,0.5-1.4,1.1c-0.1,0.7,0.4,1.4,1.1,1.5c0.1,0,0.2,0,0.2,0h1.3c2.2,0,3.9,1.8,3.9,3.9v1.3  c0,0.7,0.5,1.3,1.1,1.4c0.7,0.1,1.4-0.5,1.4-1.2v-0.1v-1.3c0-2.2,1.7-3.9,3.9-3.9l0,0h1.3c0.7,0,1.3-0.6,1.3-1.3v-0.1  c-0.1-0.7-0.7-1.2-1.4-1.1"
      />
      <path
        className="emptyfolder-st0"
        d="M133,213.1c0,2.5-13.4,4.5-29.9,4.5s-30-2-30-4.5s13.4-4.6,30-4.6S133,210.6,133,213.1"
      />
      <path
        className="emptyfolder-st0"
        d="M199.8,210.7c0-2.5-8.7-4.5-19.4-4.5c-0.4,0-0.7,0-1.1,0c-3.2-0.6-7.3-0.9-11.6-0.9c-10.7,0-19.4,2-19.4,4.5  c0,1.7,4,3.2,10,4c1.4,2.2,9.4,3.8,19.2,3.8c10.7,0,19.4-2,19.4-4.5l0,0C198.7,212.4,199.8,211.5,199.8,210.7z"
      />
      <path
        className="emptyfolder-st3"
        d="M112.4,147.6c-0.3-0.1-0.6-0.4-0.8-0.7c-0.6-0.8-0.4-2.1,0.5-2.5c7.5-5,16.3-0.3,16.8-0.1  c0.9,0.4,1.2,1.6,0.8,2.5s-1.6,1.2-2.5,0.8l0,0c-0.1,0-7.4-3.7-12.9-0.1C113.6,147.8,112.9,147.9,112.4,147.6z"
      />
      <g>
        <path
          className="emptyfolder-st3"
          d="M159.8,128.1c-4.2,1.9-4.8,7.7-3.6,11.2l0.1,0.1c1.3,3.9,5.7,6.1,9.6,4.6l0.6-0.3c3.5-1.6,5.4-5.7,4-9.4   l-0.1-0.1c-1.1-3.2-5.5-8.1-10.2-6.4L159.8,128.1z"
        />
        <path
          className="emptyfolder-st2"
          d="M161.3,130.9l0.1-0.1c2.2-0.8,5.3,2.6,6.1,4.8l0.1,0.1c0.7,2.1-0.3,4.4-2.3,5.3l-0.3,0.1   c-2.2,0.8-4.6-0.4-5.3-2.6l-0.1-0.1C158.9,136.2,159.2,131.9,161.3,130.9"
        />
      </g>
      <path
        className="emptyfolder-st6"
        d="M175.8,132.4l1.7-6.2c0.4-1.6,1.6-2.9,3.2-3.4l8.2-2.8c1.9-0.7,4-0.1,5.4,1.4l4.1,4.5"
      />
      <ellipse
        transform="matrix(0.9584 -0.2853 0.2853 0.9584 -24.6225 66.9623)"
        className="emptyfolder-st7"
        cx="217.5"
        cy="118"
        rx="4.4"
        ry="7.3"
      />
      <path
        className="emptyfolder-st8"
        d="M206.2,124.1L206.2,124.1c-1-3.4,0.9-7,4.3-8l4.8-1.4l1.4,4.8c1,3.4-0.9,7-4.3,8l0,0  C209.8,128.3,207,126.7,206.2,124.1z"
      />
      <path
        className="emptyfolder-st8"
        d="M227.3,123L227.3,123c-3.4,1-7-0.9-8-4.3l-1.4-4.8l4.8-1.4c3.4-1,7,0.9,8,4.3l0,0  C231.5,119.4,230,122.2,227.3,123z"
      />
      <g>
        <path
          className="emptyfolder-st2"
          d="M221.2,132.1c1.9,4.9,3.3,21.8-12.3,17.4c-15.5-4.4-6.5-18.5,0-15.5s8.4,21.5-26.7,16.6"
        />
        <g>
          <path className="emptyfolder-st9" d="M221.2,132.1c0.1,0.3,0.2,0.6,0.3,0.9" />
          <path
            className="emptyfolder-st10"
            d="M222.2,137.1c0.6,6.7-1.4,15.8-13.3,12.5c-15.5-4.4-6.5-18.5,0-15.5c6.3,2.9,8.3,20.4-23.7,17"
          />
          <path className="emptyfolder-st9" d="M183.2,150.8c-0.3,0-0.7-0.1-1-0.1" />
        </g>
      </g>
      <line className="emptyfolder-st11" x1="212.2" y1="112.4" x2="209.1" y2="109.3" />
      <line className="emptyfolder-st11" x1="218" y1="109.8" x2="218.3" y2="106" />
    </chakra.svg>
  )
})
