import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import qs from 'qs'
import fetch from '~/common/fetch'
import { queryClient } from '~/common/react-query/queryClient'
import { PrimaryKey } from '~/common/types'
import {
  TaskTypeListResponse,
  TaskTypePageConfig,
  TaskTypeUpdateResponse,
  UserTaskCategory,
  UserTasksResponse
} from '~/user_tasks/types'

type Due = 'all' | '1m' | '2w' | '1w' | 'now'
export type PageViewType = 'all' | 'my_tasks'
export type GetTasksParams = {
  due?: Due
  offset?: number
  limit?: number
  view?: string
  search?: string
  ordering?: string
  category_name?: string[]
  status?: number[]
  is_pinned?: boolean
  location?: PrimaryKey[]
  assignee?: PrimaryKey[]
}

interface BulkActionData {
  user_task_ids: number[] | 'All'
  query_params: Pick<GetTasksParams, 'view' | 'search'>
}

export interface BulkActionResponse {
  success: boolean
  success_count: number
}

export type TaskCategoryKey =
  | 'Billing Cycle'
  | 'Contractor'
  | 'Expenses'
  | 'Interview'
  | 'Invoice'
  | 'Job'
  | 'Job Applicant'
  | 'Job Offer'
  | 'Statement Of Work'
  | 'Timesheet'
  | 'Vendor'
  | 'Worker Tracker'
  | 'Worker Provisioning'
  | 'Work Order'

export interface TaskCategory {
  key: TaskCategoryKey
  label: string
  task_types: PrimaryKey[] // UserTaskType keys
}

export interface TaskCategoryResponse {
  results: TaskCategory[]
}

export const myTasksApi = {
  getTasks: (params: GetTasksParams = {}): Promise<UserTasksResponse> => {
    const defaults = { due: 'all', offset: 0, limit: 30, view: 'my_tasks', ordering: 'important' }
    return fetch.get(`/api/v2/user_tasks/?${qs.stringify({ ...defaults, ...params }, { arrayFormat: 'comma' })}`)
  },
  closeTask: (id: number) => fetch.post(`/api/v2/user_tasks/${id}/close`, {}),
  togglePinTask: (id: number) => fetch.post(`/api/v2/user_tasks/${id}/toggle_pin`, {}),
  toggleDismissTask: (id: number) => fetch.post(`/api/v2/user_tasks/${id}/toggle_dismiss`, {}),
  archiveTask: (id: number) => fetch.post(`/api/v2/user_tasks/${id}/archive`, {}),
  getSummary: (): Promise<TaskSummaryResponse> => fetch.get('/api/v2/user_tasks/summary'),
  getCategories: (): Promise<TaskCategoryResponse> => fetch.get(`/api/v2/user_tasks/categories`),
  bulkPinTasks: (data: BulkActionData) => fetch.post('/api/v2/user_tasks/bulk_pin', data),
  bulkDismissTasks: (data: BulkActionData) => fetch.post('/api/v2/user_tasks/bulk_dismiss', data),
  bulkCompleteTasks: (data: BulkActionData): Promise<BulkActionResponse> =>
    fetch.post('/api/v2/user_tasks/bulk_complete', data)
}

interface UpdateTaskConfigData {
  task_key: string
  is_active: boolean
}

const TaskTypesApi = {
  getTaskTypeList: (queryParams: UserTaskTypeListParams): Promise<TaskTypeListResponse> =>
    fetch.get('/api/v2/user_tasks/task_types/?' + qs.stringify(queryParams)),
  updateUserTaskType: (data: UpdateTaskConfigData) => fetch.patch('/api/v2/user_tasks/task_types', data),
  getTaskTypeConfig: (): Promise<TaskTypePageConfig> => fetch.get('/api/v2/user_tasks/config')
}

const TASK_TYPE_PAGE_CONFIG_KEY = 'user_task_types_config' as const
export const useTaskTypeConfigQuery = () => {
  return useQuery({ queryKey: [TASK_TYPE_PAGE_CONFIG_KEY], queryFn: TaskTypesApi.getTaskTypeConfig })
}

export type UserTaskTypeListParams = {
  search?: string
  filter?: string
}

export const updateUserTaskType = ({
  task_key,
  is_active
}: {
  task_key: string
  is_active: boolean
}): Promise<TaskTypeUpdateResponse> => {
  return TaskTypesApi.updateUserTaskType({ task_key, is_active })
}

export const USER_TASKS_QUERY_KEY = 'user/tasks'

export const myTasksQueryOpts = (queryParams: GetTasksParams = {}) =>
  ({
    queryKey: [USER_TASKS_QUERY_KEY, queryParams],
    queryFn: () => myTasksApi.getTasks(queryParams),
    keepPreviousData: true
  }) as const

export const useMyTasksQuery = (queryParams: GetTasksParams = {}) => useQuery(myTasksQueryOpts(queryParams))

export const invalidateMyTasksQuery = async (queryParams: GetTasksParams) => {
  await queryClient.invalidateQueries([USER_TASKS_QUERY_KEY, queryParams])
}

export const useArchiveTaskMutation = () => {
  return useMutation({
    mutationFn: myTasksApi.archiveTask,
    onSuccess: () => queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
  })
}

export const useCloseTaskMutation = (opts: UseMutationOptions<any, unknown, number, any>) =>
  useMutation({
    mutationFn: (id: number) => myTasksApi.closeTask(id),
    ...opts,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      if (typeof opts?.onSuccess === 'function') opts.onSuccess(...args)
    }
  })

export type PinTaskResponse = {
  user_task_id: number
  is_pinned: boolean
}

export type TaskSummaryResponse = {
  results: { [category_key: string]: UserTaskCategory }
}

export const useTogglePinTaskMutation = (
  opts?: UseMutationOptions<PinTaskResponse, unknown, number, PinTaskResponse>
) =>
  useMutation({
    mutationFn: (id: number) => myTasksApi.togglePinTask(id),
    ...opts,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      if (typeof opts?.onSuccess === 'function') opts.onSuccess(...args)
    }
  })

export const useToggleDismissTaskMutation = (opts: UseMutationOptions<any, unknown, number, any>) =>
  useMutation({
    mutationFn: (id: number) => myTasksApi.toggleDismissTask(id),
    ...opts,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      if (typeof opts?.onSuccess === 'function') opts.onSuccess(...args)
    }
  })

export const USER_TASK_SUMMARY_QUERY_KEY = 'user_tasks_summary'
export const useFetchTaskSummaryQuery = () =>
  useQuery({ queryKey: [USER_TASK_SUMMARY_QUERY_KEY], queryFn: myTasksApi.getSummary })

export const useFetchTaskCategoriesQuery = () =>
  useQuery({ queryKey: ['user_tasks_categories'], queryFn: myTasksApi.getCategories, staleTime: Infinity })

export const useBulkPinMutation = (opts: UseMutationOptions<BulkActionResponse, unknown, BulkActionData> = {}) =>
  useMutation({
    mutationFn: myTasksApi.bulkPinTasks,
    onSettled(...args) {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      opts?.onSettled?.(...args)
    },
    ...opts
  })

export const useBulkDismissMutation = (opts: UseMutationOptions<BulkActionResponse, unknown, BulkActionData> = {}) =>
  useMutation({
    mutationFn: myTasksApi.bulkDismissTasks,
    onSettled(...args) {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      opts?.onSettled?.(...args)
    },
    ...opts
  })

export const useBulkCompleteMutation = (opts: UseMutationOptions<BulkActionResponse, unknown, BulkActionData> = {}) =>
  useMutation({
    mutationFn: myTasksApi.bulkCompleteTasks,
    onSettled(...args) {
      queryClient.invalidateQueries([USER_TASKS_QUERY_KEY])
      opts?.onSettled?.(...args)
    },
    ...opts
  })

const USER_TASK_TYPE_LIST_QUERY_KEY = 'user_tasks_types'

export const useTaskTypeListQuery = (
  queryParams: UserTaskTypeListParams,
  opts: UseQueryOptions<TaskTypeListResponse>
) => {
  return useQuery({
    queryKey: [USER_TASK_TYPE_LIST_QUERY_KEY, queryParams] as const,
    queryFn: () => TaskTypesApi.getTaskTypeList(queryParams),
    ...opts
  })
}

export const useUpdateTaskType = (task_key: string, is_active: boolean) => {
  return useMutation({
    mutationFn: () => updateUserTaskType({ task_key, is_active }),
    onSuccess: () => {
      queryClient.invalidateQueries([USER_TASK_TYPE_LIST_QUERY_KEY] as const)
    }
  })
}
