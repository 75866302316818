import React, { useState } from 'react'
import { useAccountMeQuery } from '~/api/accounts'
import { useNavigationMenuConfigQuery } from '~/api/navigationMenu'
import { Msg } from '~/common/localizationUtils'
import {
  Activity,
  ApprovedDoc,
  Briefcase,
  Building,
  Bulk,
  Chart,
  Checklists,
  ChecklistsAlt,
  Cog,
  Docs,
  Expense,
  FileTransfer,
  Filing,
  Home,
  Invoice,
  MeetingScheduled,
  More,
  Payment,
  Program,
  Qa,
  Report,
  Rfp,
  Rolodex,
  Settings,
  Sow,
  TimesheetAlt,
  UserCards,
  UserCheck,
  Users,
  Vendors,
  WorkerTrackingAlt,
  WorkOrders
} from '~/icons'
import { getDefaultSowListURL } from '~/sow/list/constants'
import { woStatus } from '~/work_order_list/types_enums'
import {
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from '~/design_system'
import { useTenantMetadata } from '~/api/tenantMetadata'

type BaseModalProps = {
  isOpen: boolean
  onClose: () => void
}

export function TenantMetadataModal({ isOpen, onClose }: BaseModalProps) {
  const { data: tenantMetadata } = useTenantMetadata()
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent data-testid="tenant metadata modal">
        <ModalHeader>Tenant Metadata</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing={6} pb={4} minH={0}>
          {typeof tenantMetadata === 'object' && (
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              {Object.keys(tenantMetadata).map(key => [
                <GridItem fontWeight="bold">{key}</GridItem>,
                <GridItem colSpan={2}>{tenantMetadata[key]}</GridItem>
              ])}
            </Grid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export const useMenuItemProps = ({ isMobile = false }: { isMobile?: boolean } = {}) => {
  const { isVendorRole, isCandidateRole } = useAccountMeQuery()
  const { data: config } = useNavigationMenuConfigQuery()

  let isHomeHidden = !config?.main_menu?.home
  if (isCandidateRole || !isMobile) {
    isHomeHidden = true
  }
  const [openMetadataModal, setOpenMetadataModal] = useState(false)
  return {
    home: {
      top: {
        isHidden: isHomeHidden,
        href: '/home/all',
        leftIcon: <Home />,
        'data-testid': 'global-nav-home-link',
        children: <Msg id="nav.home" description="Home" />
      }
    },
    sow_menu: {
      top: {
        isHidden: !config?.main_menu?.sow_menu,
        leftIcon: <Sow />,
        children: <Msg id="term.statementofwork" count={1} description="Statement of Work" />
      },
      sow: {
        isHidden: !config?.main_menu?.sow_menu?.sow,
        href: getDefaultSowListURL(isVendorRole),
        leftIcon: <Sow />,
        'data-testid': 'global-nav-sow-statement-of-work-link',
        children: <Msg id="term.statementofwork" count={1} description="Statement of Work" />
      },
      rfp: {
        isHidden: !config?.main_menu?.sow_menu?.rfp,
        href: '/rfp',
        leftIcon: <Rfp />,
        'data-testid': 'global-nav-sow-request-for-proposal-link',
        children: <Msg id="term.requestforproposal" count={99} description="Requests for Proposal" />
      }
    },
    jobs_menu: {
      top: {
        isHidden: !config?.main_menu?.jobs_menu,
        leftIcon: <Briefcase />,
        children: <Msg id="term.job" count={999} description="Jobs" />
      },
      jobs: {
        isHidden: !config?.main_menu?.jobs_menu?.jobs,
        href: '/jobs',
        leftIcon: <Chart />,
        'data-testid': 'global-nav-jobs-job-dashboard-link',
        children: <Msg id="nav.job_dashboard" job_term={<Msg id="term.job" count={1} />} description="Job Dashboard" />
      },
      interview_schedules: {
        isHidden: !config?.main_menu?.jobs_menu?.interview_schedules,
        href: '/employers/interview_schedules/',
        leftIcon: <MeetingScheduled />,
        'data-testid': 'global-nav-jobs-interview-schedules-link',
        children: <Msg id="nav.interview_schedules" description="Interview Schedules" />
      },
      interview_management: {
        isHidden: !config?.main_menu?.jobs_menu?.interview_management,
        href: '/jobs/interview_management/',
        leftIcon: <Qa />,
        'data-testid': 'global-nav-jobs-interview-management-link',
        children: <Msg id="nav.interview_management" description="Interview Management" />
      }
    },
    candidate_profile: {
      top: {
        isHidden: !config?.main_menu?.candidate_profile,
        href: `/vendor/off/candidate/${config?.main_menu?.candidate_profile?.candidate_id}/view/`,
        leftIcon: <Rolodex />,
        'data-testid': 'global-nav-candidate-profile-link',
        children: (
          <Msg
            id="common.ui.term_profile"
            term={<Msg id="term.candidate" count={1} />}
            description="Candidate Profile"
          />
        )
      }
    },
    candidates: {
      top: {
        isHidden: !config?.main_menu?.candidates,
        href: '/vendor/off/candidates/',
        leftIcon: <UserCards />,
        'data-testid': 'global-nav-candidates-link',
        children: <Msg id="term.candidate" count={99} description="Candidates" />
      }
    },
    contractors_menu: {
      top: {
        isHidden: !config?.main_menu?.contractors_menu,
        leftIcon: <UserCards />,
        children: <Msg id="term.contractor" count={999} description="Contractors" />
      },
      contractors_summary: {
        isHidden: !config?.main_menu?.contractors_menu?.contractors_summary,
        href: '/contractors/',
        leftIcon: <Rolodex />,
        'data-testid': 'global-nav-contractors-contractors-summary-link',
        children: <Msg id="nav.contractors_summary" description="Contractors Summary" />
      },
      preidentified_candidates: {
        isHidden: !config?.main_menu?.contractors_menu?.preidentified_candidates,
        href: '/preidentifiedcandidate/candidatelist/',
        leftIcon: <UserCheck />,
        'data-testid': 'global-nav-contractors-pre-identified-candidates-link',
        children: <Msg id="nav.pre_identified_candidates" description="Pre-Identified Candidates" />
      },
      workers_summary: {
        isHidden: !config?.main_menu?.contractors_menu?.workers_summary,
        href: '/worker_tracking/worker/',
        leftIcon: <Rolodex />,
        'data-testid': 'global-nav-contractors-workers-summary-link',
        children: <Msg id="nav.workers_summary" description="Workers Summary" />
      }
    },
    invoices_menu: {
      top: {
        isHidden: !config?.main_menu?.invoices_menu,
        leftIcon: <Invoice />,
        children: <Msg id="term.invoice" count={999} description="Invoices" />
      },
      invoices_summary: {
        isHidden: !config?.main_menu?.invoices_menu?.invoices_summary,
        href: '/invoices/summary/',
        leftIcon: <Invoice />,
        'data-testid': 'global-nav-invoices-invoices-summary-link',
        children: <Msg id="nav.invoices_summary" description="Invoices Summary" />
      },
      invoice_payments: {
        isHidden: !config?.main_menu?.invoices_menu?.invoice_payments,
        href: '/invoices/payments/list/',
        leftIcon: <Payment />,
        'data-testid': 'global-nav-invoices-invoice-payments-link',
        children: <Msg id="nav.invoice_payments" description="Invoice Payments" />
      },
      misc_adjustments: {
        isHidden: !config?.main_menu?.invoices_menu?.misc_adjustments,
        href: '/historical_misc_adjustment',
        leftIcon: <Activity />,
        'data-testid': 'global-nav-invoices-historical-misc-adjustments-link',
        children: <Msg id="nav.historical_misc_adjustments" description="Historical Misc. Adjustments" />
      },
      invoice_files: {
        isHidden: !config?.main_menu?.invoices_menu?.invoice_files,
        href: '/invoices/view_archived_invoice_files/',
        leftIcon: <Filing />,
        'data-testid': 'global-nav-invoices-invoice-file-archives-link',
        children: <Msg id="nav.invoice_file_archives" description="Invoice File Archives" />
      },
      invoice_job_status: {
        isHidden: !config?.main_menu?.invoices_menu?.invoice_job_status,
        href: '/scheduled_jobs/automatic_invoice/results',
        leftIcon: <ApprovedDoc />,
        'data-testid': 'global-nav-invoices-invoice-job-status-link',
        children: <Msg id="nav.invoice_job_status" description="Invoice Job Status" />
      }
    },
    timesheets_menu: {
      top: {
        isHidden: !config?.main_menu?.timesheets_menu,
        leftIcon: <TimesheetAlt />,
        children: <Msg id="term.timesheet" count={999} description="Timesheets" />
      },
      timesheet_summary: {
        isHidden: !config?.main_menu?.timesheets_menu?.timesheet_summary,
        href: '/time-entries/tsview/',
        leftIcon: <TimesheetAlt />,
        'data-testid': 'global-nav-timesheets-timesheet-summary-link',
        children: <Msg id="nav.timesheet_summary" description="Timesheets Summary" />
      },
      timesheet_archives: {
        isHidden: !config?.main_menu?.timesheets_menu?.timesheet_archives,
        href: '/time-entries/view_uploaded_timesheets/',
        leftIcon: <Filing />,
        'data-testid': 'global-nav-timesheets-timesheet-archives-link',
        children: <Msg id="nav.timesheet_archives" description="Timesheet Archives" />
      }
    },
    dashboards: {
      top: {
        isHidden: !config?.main_menu?.dashboards,
        href: isVendorRole ? '/vendor_entities/stats/' : '/dashboards/summary',
        leftIcon: <Chart />,
        'data-testid': 'global-nav-dashboards-link',
        children: <Msg id="nav.dashboard" description="Dashboards" />
      }
    },
    reports_menu: {
      top: {
        isHidden: !config?.main_menu?.reports_menu,
        leftIcon: <Report />,
        children: <Msg id="nav.report" description="Reports" />
      },
      reports: {
        isHidden: !config?.main_menu?.reports_menu?.reports,
        href: '/reporting',
        leftIcon: <Sow />,
        'data-testid': 'global-nav-reports-reports-home-link',
        children: <Msg id="nav.reports_home" description="Reports Home" />
      },
      file_transfer_reports: {
        isHidden: !config?.main_menu?.reports_menu?.file_transfer_reports,
        href: '/file_transfers',
        leftIcon: <FileTransfer />,
        'data-testid': 'global-nav-reports-file-transfer-reports-link',
        children: <Msg id="nav.file_transfer_reports" description="File Transfer Reports" />
      }
    },
    more_menu: {
      top: {
        isHidden: !config?.main_menu?.more_menu,
        leftIcon: isMobile ? <More /> : undefined,
        children: <Msg id="nav.more" description="More" />
      },
      approvals: {
        isHidden: !config?.main_menu?.more_menu?.approvals,
        href: '/approvals/',
        leftIcon: <Checklists />,
        'data-testid': 'global-nav-more-approvals-link',
        children: <Msg id="approvals.title" description="Approvals" />
      },
      documents: {
        isHidden: !config?.main_menu?.more_menu?.documents,
        href: '/docs/document/',
        leftIcon: <Docs />,
        'data-testid': 'global-nav-more-documents-link',
        children: <Msg id="nav.document" description="Documents" />
      },
      vendors: {
        isHidden: !config?.main_menu?.more_menu?.vendors,
        href: '/employers/manage-vendors/',
        leftIcon: <Vendors />,
        'data-testid': 'global-nav-more-vendors-link',
        children: <Msg id="term.vendor" count={999} description="Vendors" />
      },
      users: {
        isHidden: !config?.main_menu?.more_menu?.users,
        href: isVendorRole ? '/vendor/manage-users' : '/employers/manage-users/',
        leftIcon: <Users />,
        'data-testid': 'global-nav-more-users-link',
        children: <Msg id="nav.user" description="Users" />
      },
      company_profile: {
        isHidden: !config?.main_menu?.more_menu?.company_profile,
        href: isVendorRole ? '/vendor_entities/profile/view/' : '/entities/profile/view/',
        leftIcon: <Building />,
        'data-testid': 'global-nav-more-employer-company-profile-link',
        children: <Msg id="nav.company_profile" description="Company Profile" />
      },
      work_orders: {
        isHidden: !config?.main_menu?.more_menu?.work_orders,
        href: `/work_orders/all?status_val=${woStatus.ACTIVE}`,
        leftIcon: <WorkOrders />,
        'data-testid': 'global-nav-more-work-orders-link',
        children: <Msg id="term.workorder" count={999} description="Work Orders" />
      },
      worker_tracking: {
        isHidden: !config?.main_menu?.more_menu?.worker_tracking,
        href: '/worker_tracking/',
        leftIcon: <WorkerTrackingAlt />,
        'data-testid': 'global-nav-more-worker-tracking-link',
        children: <Msg id="term.workertracking" description="Worker Profile Management" />
      },
      expenses: {
        isHidden: !config?.main_menu?.more_menu?.expenses,
        href: '/expenses/expense-report-list/',
        leftIcon: <Expense />,
        'data-testid': 'global-nav-more-expenses-and-adjustments-link',
        children: <Msg id="nav.expenses_and_adjustments" description="Expenses & Adjustments" />
      },
      bulk_updates: {
        isHidden: !config?.main_menu?.more_menu?.bulk_updates,
        href: '/bulk_updates/',
        leftIcon: <Bulk />,
        'data-testid': 'global-nav-more-bulk-updates-link',
        children: <Msg id="nav.bulk_updates" description="Bulk Updates" />
      },
      checklists: {
        isHidden: !config?.main_menu?.more_menu?.checklists,
        href: '/checklist_actions',
        leftIcon: <ChecklistsAlt />,
        'data-testid': 'global-nav-more-manage-checklist-actions-link',
        children: <Msg id="nav.manage_checklist_actions" description="Manage Checklist Actions" />
      },
      api_docs: {
        isHidden: !config?.main_menu?.more_menu?.api_docs,
        href: isVendorRole ? '/services/vendor/docs/' : '/services/program/docs/',
        leftIcon: <Cog />,
        'data-testid': 'global-nav-more-api-documentation-link',
        children: <Msg id="nav.api_documentation" description="API Documentation" />
      },
      company_settings: {
        isHidden: !config?.main_menu?.more_menu?.company_settings,
        href: isVendorRole ? '/vendor_entities/settings/' : '/entities/settings-all/all/',
        leftIcon: <Settings />,
        'data-testid': 'global-nav-more-company-settings-link',
        children: <Msg id="company_settings" description="Company Settings" />
      },
      tenant_metadata: {
        isHidden: !config?.main_menu?.more_menu?.tenant_metadata,
        leftIcon: <Program />,
        'data-testid': 'global-nav-more-tenant-metadata',
        onClick: () => setOpenMetadataModal(true),
        children: [
          <Msg key="tenant_metadata.label" id="common.ui.tenant_metadata" description="Tenant Metadata" />,
          <TenantMetadataModal
            key="tenant_metadata.modal"
            isOpen={openMetadataModal}
            onClose={() => setOpenMetadataModal(false)}
          />
        ]
      }
    },
    candidate_timesheets: {
      top: {
        isHidden: !config?.main_menu?.candidate_timesheets,
        href: '/candidate/timesheets/',
        leftIcon: <TimesheetAlt />,
        'data-testid': 'global-nav-candidate-timesheet-link',
        children: <Msg id="term.timesheet" count={999} description="Timesheets" />
      }
    },
    candidate_expenses: {
      top: {
        isHidden: !config?.main_menu?.candidate_expenses,
        href: '/expenses/expense-report-list/',
        leftIcon: <Expense />,
        'data-testid': 'global-nav-more-expenses-and-adjustments-link',
        children: <Msg id="nav.expenses_and_adjustments" description="Expenses & Adjustments" />
      }
    }
  } as const
}
