export const styles = {
  global: {
    body: {
      fontFamily: 'body',
      color: 'var(--vndly-color-container-dark)',
      bg: 'var(--vndly-color-container-background-subdued)',
      lineHeight: 'base',
      fontFeatureSettings: `"pnum"`,
      fontVariantNumeric: 'proportional-nums'
    },
    '*::placeholder': {
      color: 'var(--vndly-color-input-placeholder)'
    },
    '*, *::before, &::after': {
      borderColor: 'var(--vndly-color-container-border)',
      wordWrap: 'break-word'
    }
  }
}
