import { OffsetLimitPaginationResponse } from '~/api/common'
import { BoxProps, ComponentWithAs } from '~/design_system'

export type TaskDataStoreType = { [x: string]: any }

type TaskSubtype =
  | 'Contractors'
  | 'Expenses'
  | 'File Transfer'
  | 'Intakes'
  | 'Interviews'
  | 'Invoices'
  | 'Invoice PDF'
  | 'Job Application'
  | 'Job workflow'
  | 'Job Workflow'
  | 'Misc. Adjustments'
  | 'Offer'
  | 'Onboarding'
  | 'Payment Workflow'
  | 'Ratings'
  | 'Settings'
  | 'Statement of Work'
  | 'Vendor'
  | 'Work Order'

export interface TaskTypePageConfig {
  can_configure_user_task_types: boolean
}

export interface UserTask {
  id: number
  description: string
  source: string
  subtype: TaskSubtype
  due_date: string
  overdue: boolean
  created_at: string
  data_store: TaskDataStoreType
  category_name: string
  status: UserTaskStatus
  component: string
  component_id: number
  updated_at: string
  is_pinned: boolean
  skeleton?: boolean
  role_tags?: string[]
}

export enum UserTaskStatus {
  Pending = 1,
  Overdue,
  Cancelled,
  Completed
}

export type UserTasksResponse = OffsetLimitPaginationResponse<UserTask>

export interface UserTaskCategory {
  label: string
  key: string
  pending_count: number
  overdue_count: number
  icon?: ComponentWithAs<'div', BoxProps>
}

export interface TaskType {
  key: string
  name: string
  description: string
  is_active: boolean
  supervisors: string[]
  assignees: string[]
  category_name: string
  task_display_template: string
  number_of_open_tasks: number
  is_wf_approval_task: boolean
}

export type TaskTypeListResponse = {
  task_types: TaskType[]
}

export type TaskTypeUpdateResponse = {
  success: boolean
}
