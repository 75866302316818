import { get } from 'lodash'
import { createContext, useContext } from 'react'
import { Box, BoxProps, StackProps, Flex, Stack, forwardRef, useTheme } from '../../chakra'
import useUuid from '../../../hooks/useUuid'
import { JumpToAnchor } from '../..'

const scrollShadowStyles = (bg: any) => ({
  background: `
    linear-gradient(${bg} 33%, rgba(255,255,255, 0)),
    linear-gradient(rgba(255,255,255, 0), ${bg} 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(125,125,125, 0.25), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(125,125,125, 0.25), rgba(0,0,0,0)) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: bg,
  backgroundSize: '100% 15px, 100% 15px, 100% 6px, 100% 6px',
  backgroundAttachment: 'local, local, scroll, scroll'
})

/**
 * A box, that when scrollable, adds automatic shadows to the top and/or bottom of the container
 * to indicate that there is more scrollable content.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ScrollShadowBox = forwardRef<BoxProps, 'div'>(({ bg = 'white', ...props }, ref) => {
  const { colors } = useTheme()
  // @ts-ignore
  const bgColor = get(colors, bg)
  return <Box ref={ref} overflowY="auto" {...scrollShadowStyles(bgColor)} {...props} />
})

ScrollShadowBox.displayName = 'ScrollShadowBox'

const SectionContext = createContext({ headingId: '' })

/**
 * Section
 *
 * A container for a SectionHeader and SectionContent that provides the correct accessibility and spacing.
 */
export const Section = forwardRef(({ children, ...props }: StackProps, ref) => {
  const headingId = 'section-header-' + useUuid()
  return (
    <SectionContext.Provider value={{ headingId }}>
      <Stack
        data-section-container
        role="region"
        as="section"
        spacing="lg"
        ref={ref}
        aria-labelledby={headingId}
        {...props}>
        {children}
      </Stack>
    </SectionContext.Provider>
  )
})

Section.displayName = 'Section'

export type SectionHeaderProps = {
  size?: 'sm' | 'md'
} & BoxProps

/**
 * Section Header
 *
 * Forms often work best when broken into sections, grouping form elements according to their purpose – if it were
 * an order page you might put items in one group, customization in another, and delivery in a third.
 *
 * This can also be used to break up data heavy read-only pages (Work Order Detail and Vendor Profile)
 */
export const SectionHeader = forwardRef(({ size = 'md', children, ...props }: SectionHeaderProps, ref) => {
  const headingId = useContext(SectionContext)?.headingId
  return (
    <Flex
      id={headingId}
      data-section-header
      px={4}
      h={size === 'sm' ? '40px' : '56px'}
      textStyle="sectionHeading"
      bg="gray.100"
      alignItems="center"
      justifyContent="space-between"
      ref={ref}
      {...props}>
      {children}
    </Flex>
  )
})

export const JumpToSectionHeader = (
  props: SectionHeaderProps & { anchorId: string; sectionTitle: string | JSX.Element }
) => {
  const { children, anchorId, sectionTitle, ...headerProps } = props

  return (
    <>
      <SectionHeader {...headerProps}>
        <JumpToAnchor anchorId={anchorId}>{sectionTitle}</JumpToAnchor>
        {children}
      </SectionHeader>
    </>
  )
}

SectionHeader.displayName = 'SectionHeader'

/**
 * Section Context
 *
 * Designed to fit under the SectionHeader and gives the appropriate amount of padding
 */
export const SectionContent = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Box px={4} ref={ref} {...props}>
    {children}
  </Box>
))

SectionContent.displayName = 'SectionContent'
