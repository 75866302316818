import { useRouter } from 'next/router'
import NProgress, { NProgressOptions } from 'nprogress'
import { ReactNode, useEffect, useRef, useState } from 'react'

export function isServer() {
  return typeof window === 'undefined'
}

export function isClient() {
  return !isServer()
}

declare global {
  interface Window {
    __NEXTJS__: boolean
  }
}

export function isNextJsPage() {
  // Next.js is the only node server we run, so
  // if we are on the server, it means it is Next.js
  return isServer() || (isClient() && window.__NEXTJS__)
}

export function useIfNextJs<T>(useMaybeSomething: () => T) {
  return isNextJsPage() ? useMaybeSomething : () => ({}) as T
}

/**
 * A hook to help protect against SSR Hydration mismatches.
 * https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution
 *
 * @returns {boolean}
 *
 * @see ClientOnly for a component-based alternative to this hook
 * @example
 * function Navigation() {
 *   // We don't want to show either the user info (dynamic) OR the login link
 *   // in our SSR or SSG render. Let's defer the logic to the client by returning
 *   // null if the component is mounting, which will be during SSR or SSG
 *   const isMounting = useIsMounting();
 *   if (isMounting) return null; // could also return a skeleton, spinner, etc
 *
 *   const isAuthed = useIsAuthenticated();
 *   if (isAuthed) return <AuthenticatedNav />
 *   return <LoginButton />
 * };
 */
export function useIsMounting({ enabled = true } = {}) {
  const [isMounting, setIsMounting] = useState(true)
  useEffect(() => {
    if (!enabled) return
    setIsMounting(false)
  }, [enabled])
  return isMounting
}

// Ref version of the above util
export function useIsMountingRef({ enabled = true } = {}) {
  const isMountingRef = useRef(true)
  useEffect(() => {
    if (!enabled) return
    isMountingRef.current = false
  }, [enabled])
  return isMountingRef
}

/**
 * A Component to help protect against SSR Hydration mismatches.
 * https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution
 *
 * Only renders the children to the DOM on the client.
 *
 * @see useIsMounting for a hook-based alternative to this Component
 * @example
 * function App() {
 *   // We don't want to show either the user info (dynamic) OR the login link
 *   // in our SSR or SSG render. Let's defer the logic to the client by returning
 *   // null if the component is mounting, which will be during SSR or SSG
 *   return (
 *    <ClientOnly>
 *      <Navigation />
 *    </ClientOnly>
 *   )
 * }
 *
 * function Navigation() {
 *   const isAuthed = useIsAuthenticated();
 *   if (isAuthed) return <AuthenticatedNav />
 *   return <LoginButton />
 * };
 */
export function ClientOnly({ children }: { children: ReactNode }) {
  if (useIsMounting()) return null
  return typeof children === 'function' ? children() : children
}

export function useBodyRef() {
  return useRef(isClient() ? document.body : null)
}

export const useNativeRouter = () => {
  const [location, setLocation] = useState(window.location)
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search))

  useEffect(() => {
    const onPopState = () => {
      setLocation(window.location)
      setSearchParams(new URLSearchParams(window.location.search))
    }

    window.addEventListener('popstate', onPopState)
    return () => window.removeEventListener('popstate', onPopState)
  }, [])

  return {
    isReady: true,
    get basePath() {
      return location.pathname.split('/')[1]
    },
    get pathname() {
      return location.pathname
    },
    get route() {
      return location.pathname
    },
    get asPath() {
      return location.pathname + location.search
    },
    get query() {
      return Object.fromEntries(searchParams.entries())
    },
    push: (path: string) => {
      window.location.href = path
      setLocation(window.location)
      setSearchParams(new URLSearchParams(window.location.search))
    },
    replace: (path: string) => {
      window.location.replace(path)
      setLocation(window.location)
      setSearchParams(new URLSearchParams(window.location.search))
    },
    reload: () => {
      window.location.reload()
    },
    back: () => {
      window.history.back()
    },
    forward: () => {
      window.history.forward()
    },
    /**
     * NOT IMPLEMENTED
     * Some Next.js events may not be possible with native non-spa navigation changes
     */
    events: undefined
  }
}

/**
 * A utility that makes it safe to use `useRouter` even on non-next.js pages.
 *
 * When useUniversalRouter is used on a Next.js page, it will use `useRouter` directly.
 *
 * When used on a non-next.js page, it will use a router with an almost identical API,
 * but that routes using only native methods and without any next.js dependencies.
 */
export const useUniversalRouter = isNextJsPage() ? useRouter : useNativeRouter

/**
 * A hook that runs a callback whenever the Next.js route starts to change
 *
 * @param onRouteChange The callback you want to call, receives the next url.
 */
export function useOnNextjsRouteChange(onRouteChange: (url: string) => void) {
  const router = useRouter()
  const onRouteChangeRef = useRef(onRouteChange)
  onRouteChangeRef.current = onRouteChange

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      onRouteChangeRef.current(url)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
}

export const NProgressInit = ({
  color = '#0875e1',
  height = 1,
  ...options
}: Partial<NProgressOptions & { color: string; height: number }> = {}) => {
  if (isClient()) {
    const nprogressTemplate = `
<style>
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${color};
    height: ${height}px;
    position: fixed;
    z-index: 1300;
    top: 0;
    left: 0;
    width: 100%;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
</style>
<div class="bar" role="bar"><div class="peg"></div></div>
`
    NProgress.configure({
      parent: 'body',
      ...options,
      minimum: 0.25,
      trickleSpeed: 150,
      showSpinner: false,
      template: nprogressTemplate
    })
  }
}
