import { ReactNode } from 'react'
import { childrenToString } from '../../../common/childrenUtils'
import { useMsg } from '../../../common/localizationUtils'
import { CloseButton, Flex, Text, useToast } from '../../chakra'
import { Info, SuccessO } from '../../../icons'

/**
 * useSuccessToast
 *
 * Success toasts are used to communicate feedbacks of the system after users complete an action. Toasts are low-priority messages that don’t require users’ further actions and are meant to be
 * temporary.
 */
export function useSuccessToast() {
  const toast = useToast()
  const msg = useMsg()
  return (description?: ReactNode, onCloseComplete?: () => void) => {
    const ariaLabel: any = childrenToString(description) || msg('common.ui.success')
    toast({
      position: 'top',
      description,
      duration: 6000,
      isClosable: true,
      onCloseComplete,
      render: ({ onClose }) => (
        <Flex
          role="alert"
          aria-label={ariaLabel}
          data-toast-type="success"
          bgColor="white"
          borderRadius="base"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
          maxW="512px"
          alignItems="center"
          p={6}>
          <SuccessO boxSize={6} color="brand.600" bgColor="brand.100" borderRadius="full" />
          <Text textStyle="body" w="100%" mx={4} my={0}>
            {description || msg('common.ui.success')}
          </Text>
          <CloseButton onClick={onClose} />
        </Flex>
      )
    })
  }
}

/**
 * useInfoToast
 *
 * Informational toasts are used to update users with the system or application status. Toasts are low-priority messages that don’t require users’ further actions and are meant to be
 * temporary.
 */
export function useInfoToast() {
  const toast = useToast()
  const msg = useMsg()
  return (description: ReactNode, onCloseComplete?: () => void) => {
    const ariaLabel: any = childrenToString(description) || msg('common.ui.informational_message')
    toast({
      position: 'top',
      description,
      duration: 6000,
      isClosable: true,
      onCloseComplete,
      render: ({ onClose }) => (
        <Flex
          role="alert"
          aria-label={ariaLabel}
          data-toast-type="info"
          bgColor="white"
          borderRadius="base"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
          maxW="512px"
          alignItems="center"
          p={6}>
          <Info boxSize={6} color="blue.500" bgColor="blue.50" borderRadius="full" />
          <Text textStyle="body" mx={4}>
            {description}
          </Text>
          <CloseButton onClick={onClose} />
        </Flex>
      )
    })
  }
}
