// eslint-disable-next-line max-classes-per-file
export class FetchBaseError extends Error {
  /**
   * @type {Response}
   */
  response = undefined

  /**
   * Network status
   * @type {number}
   */
  status = undefined

  /**
   * Response body
   * @type {any}
   */
  body = undefined

  /**
   * Error message
   * @type {string}
   */
  message = 'Unknown Error'

  /**
   * Raw Error
   * @type {Error}
   */
  error = undefined
}

export class NetworkError extends FetchBaseError {
  constructor(response, error) {
    super()
    this.response = response
    this.status = response.status
    this.error = error
    this.message = `Network ${response.status} ${response.url}`
  }
}

export class ServerError extends FetchBaseError {
  constructor(response, json) {
    super()
    this.response = response
    this.status = response.status
    this.body = json
    this.message = `ServerError ${response.status} ${response.url}`
  }
}

export class AuthenticationError extends FetchBaseError {
  constructor(response, json) {
    super()
    this.response = response
    this.status = response.status
    this.body = json
    this.message = `AuthenticationError ${response.status} ${response.url}\n\n${printBodyError(json)}`
  }
}

export class ApplicationError extends FetchBaseError {
  constructor(response, json) {
    super()
    this.response = response
    this.status = response.status
    this.body = json
    this.message = `ApplicationError ${response.status} ${response.url}\n\n${printBodyError(json)}`
  }
}

export class ParseJsonError extends FetchBaseError {
  constructor(response, body) {
    super()
    this.response = response
    this.status = response.status
    this.body = body
    this.message = `ParseJsonError ${response.status} ${response.url}\n\n${printBodyError(body)}`
  }
}

function printBodyError(body) {
  const jsonString = JSON.stringify(body, null, 4)
  const looksLikeHtml = jsonString.match(/<html>.*<\/html>/)
  if (looksLikeHtml) {
    const [, title] = jsonString.match(/<title>([^<]*)<\/title>/)
    return `HTML Page returned instead of JSON...\nTitle: ${title}\n\nRaw HTML String: ${jsonString}`
  }
  return jsonString
}
