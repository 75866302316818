/**
 * Returns the first number in the pagination range
 * @param range the range of numbers for the pagination component
 * @example
 * const rangeMin = getRangeMin([1,2,3,4,5]) //=> 1
 */
export const getRangeMin = (range: number[]): number => range[0]

/**
 * Returns the last number in the pagination range
 * @param range the range of numbers for the pagination component
 * @example
 * const rangeMax = getRangeMax([1,2,3,4,5]) //=> 5
 */
export const getRangeMax = (range: number[]): number => range[range.length - 1]

/**
 * Returns the range size array for a pagination
 * @param max determines the size of the range
 * @param min determines the values of the range with it's index
 */
export const buildRange = (max: number, min: number): number[] => {
  return [...Array(max)].map((_, index) => min + index)
}
