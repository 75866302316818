import { isMatch } from 'lodash'
import { isValidElement, JSXElementConstructor, ReactElement, ReactNode, useMemo, Children } from 'react'

type ExtractionItemConfig = {
  isComponent?: string | JSXElementConstructor<any>
  isSlot?: string
  test?: (child: ReactNode) => boolean
  min?: number
  max?: number
  errorName?: string
}

interface ExtractionSchema {
  [name: string]: ExtractionItemConfig
}

type ExtractedChildren<T> = Record<keyof T | 'rest', ReactElement | ReactElement[] | null>

/**
 * Experimental utility for grouping children passed to a component based on a predicate `test` function.
 *
 * Ripped the idea off of `seapig` but it's more powerful by allowing inversion of control via the predicate.
 *
 * Where `seapig` only supported the presence of a prop, this util can support things like checking for a
 * particular component type, e.g. check if a child is of type <Checkbox /> for example, and you can still
 * check for any prop (or multiple) prop values. Additionally you can check multiple things, like if a component
 * is of type <Checkbox /> OR if it has a prop of `data-slot="checkbox"`. So quite flexible.
 *
 * Each child component can configure:
 * - An `isComponent` property that points to a component's definition function, e.g. `isComponent: Button`
 * - An `isSlot` property set to a string, it will see if a child has a prop of `data-slot={val}` where val is the string.
 * - A `test` predicate. A function that recieves the child—inspect the type, props, etc—return true if it's a match
 * - An optional `min` and `max`. By default min is 0, and max is 1. So make a component required, simply set min to 1.
 *   To allow multiple components set max to a higher number.
 *
 * You can define one or more of the test props (isComponent, isSlot, test) and if any are true, the component will match.
 *
 * Pass in the children and a schema, like so:
 *
 * @example
 * ```js
 * import { Heading, Text, Button } from '@chakra-ui/react'
 * import groupChildren, { isComponent, hasSlotProp } from '~/common/childUtils.ts'
 *
 * function MyCardThing({ children }) {
 *   const extracted = groupChildren(
 *     children,
 *     {
 *       heading: {
 *         min: 1,                // There must be at least one heading. max defaults to 1,
 *                                //   so basically you must have exactly one in this case
 *         isComponent: Heading,  // This will match a <Heading /> component
 *         isSlot: 'heading',     // OR this will match any child with data-slot="heading"
 *         // or use a `test` predicate, this is the same as the two properties above, just more boilerplate
 *         test: child => isComponent(child, Heading) || hasSlotProp(child, 'heading'),
 *         errorName: '<Heading />'  // This just helps when we need to print an error
 *       },
 *       subtext: {
 *         isComponent: Text,
 *         isSlot: 'text',
 *         errorName: '<Text />'
 *       },
 *       button: {
 *         max: 3,
 *         isComponent: Button,
 *         isSlot: 'button',
 *         errorName: '<Button />'
 *       }
 *     },
 *     { parentName: 'MyMagicComponent' }
 *   )
 *
 *   return (
 *     <Card>
 *       {extracted.heading} // Place the extracted components wherever you please
 *       {extracted.text}
 *       {extracted.button}
 *     </Card>
 *   )
 * }
 * ```
 */
export default function useGroupChildren<T extends ExtractionSchema>(
  children: ReactNode,
  schema: T,
  { parentName = '' }: { parentName?: string } = {}
): ExtractedChildren<T> {
  return useMemo(() => {
    const extracted = { rest: [] } as ExtractedChildren<T>
    Object.keys(schema).forEach(key => {
      const supportsMultiple = schema[key].max ?? 0 > 1
      extracted[key as keyof T] = supportsMultiple ? ([] as ReactElement[]) : null
    })

    // Extract children into groups
    Children.forEach(children, child => {
      if (!child) return
      const matchedKey: keyof T | 'rest' =
        Object.keys(schema).find(key => {
          const config = schema[key]
          if (config.isComponent != null && isComponent(child, config.isComponent)) return true
          if (config.isSlot != null && hasSlotProp(child, config.isSlot)) return true
          if (config.test != null && config.test(child)) return true
          return false
        }) ?? 'rest'
      const supportsMultiple = schema[matchedKey]?.max ?? 0 > 1
      if (supportsMultiple) {
        if (extracted[matchedKey] == null) extracted[matchedKey] = [] as ReactElement[]
        ;(extracted[matchedKey] as ReactElement[]).push(child as ReactElement)
      } else {
        extracted[matchedKey] = child as ReactElement
      }
    })

    // Validate children
    Object.keys(schema).forEach(key => {
      const { min = 0, max = 1, errorName = `"${key}"` }: ExtractionItemConfig = schema[key]
      const items: ReactNode | ReactNode[] = extracted[key]

      const missingRequired = items == null && min > 0
      if (missingRequired)
        throw new Error(`Missing required child ${errorName}${parentName ? ` in <${parentName}/>` : ''}`)

      const moreThanAllowed = items != null && Array.isArray(items) && items.length > max
      if (moreThanAllowed)
        throw new Error(
          `Found more ${errorName} children${
            parentName ? ` in <${parentName}/>` : ''
          } than is allowed. A max of ${max} allowed.`
        )

      const notEnoughSingle = min === 1 && items == null
      const notEnoughMulti = min > 1 && items != null && Array.isArray(items) && items.length < min
      if (notEnoughSingle || notEnoughMulti)
        throw new Error(
          `Found not enough ${errorName} children${
            parentName ? ` in <${parentName}/>` : ''
          }. A min of ${min} is required.`
        )
    })

    return extracted
  }, [children])
}

export function isComponent(child: ReactNode, type: string | JSXElementConstructor<any>) {
  return isValidElement(child) && child.type === type
}

export function hasSlotProp(child: ReactNode, slotName: string) {
  return hasProps(child, { 'data-slot': slotName })
}

export function hasProps(child: ReactNode, shape: Record<string, any>) {
  return isValidElement(child) && isMatch(child.props ?? {}, shape)
}

/**
 * A function that converts React children to a string. A good use case is when
 * you want to have the children text also be the `title` attribute of a component.
 * If the child nodes contain non-string-safe nodes than an empty string is returned.
 *
 * @example
 * // We are truncating the text children, so we want to be able to also show the
 * // full value as a tooltip via the title attribute.
 * export function AttributeText({ children, ...props } {
 *   return (
 *     <Text isTruncated title={childrenToString(children)} {...props}>
 *       {children}
 *     </Text>
 *   )
 * }
 */
export function childrenToString(children: ReactNode): string {
  return getChildrenText(children).trim()

  function getChildrenText(node: ReactNode): string {
    if (node == null) return ''
    if (typeof node === 'string' || typeof node === 'number') return String(node)
    if (Array.isArray(node)) return node.reduce((str: string, node) => `${str}${getChildrenText(node)}`, '')
    if (isValidElement(node)) {
      const children = node.props.children
      return getChildrenText(children)
    }
    return ''
  }
}
