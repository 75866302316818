import { Alert, AlertIcon, AlertProps, Box, CloseButton, forwardRef } from '../../chakra'
import { Close, Info, SuccessO, Warning, WarningO } from '../../../icons'

const statusIcons = {
  info: Info,
  error: WarningO,
  warning: Warning,
  success: SuccessO
}

type AlertBannerProps = {
  status: NonNullable<AlertProps['status']>
  hasClose?: boolean
  onClose?: () => void
} & AlertProps

/**
 * Alert banner
 *
 * Alerts are used to communicate a state that affects a system, feature or page.  They can support
 * single-line and multi-line text blocks. This component handles all the styles of the alert container,
 * text styles of the title and description, and custom icons.
 *
 * Statuses include info, success, warning and error.
 */
export const AlertBanner = forwardRef(
  ({ children, status, hasClose = false, onClose, ...props }: AlertBannerProps, ref) => (
    <Alert status={status} variant={status} alignItems="flex-start" ref={ref} data-status={status} {...props}>
      <AlertIcon as={statusIcons[status]} boxSize={6} />
      <Box flex="1" data-alert-text>
        {children}
      </Box>
      {hasClose && (
        <CloseButton
          cursor="pointer"
          position="absolute"
          right="8px"
          top="8px"
          w="24px"
          h="24px"
          color="gray.800"
          onClick={onClose}>
          <Close />
        </CloseButton>
      )}
    </Alert>
  )
)

AlertBanner.displayName = 'AlertBanner'
