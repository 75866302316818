const legacyTextStyles = {
  body: {
    sm: {
      /**
       * @deprecated Use textStyle="subtextSmall" instead
       */
      light: {
        color: 'gray.600',
        fontWeight: 'normal',
        fontSize: 'sm'
      },
      /**
       * @deprecated Use textStyle="bodySmall" instead
       */
      default: {
        color: 'gray.800',
        fontWeight: 'normal',
        fontSize: 'sm'
      }
    },
    md: {
      /**
       * @deprecated Use textStyle="bodySemi" instead
       */
      semi: {
        color: 'gray.800',
        fontWeight: 'semibold',
        fontSize: 'md'
      },
      /**
       * @deprecated Use textStyle="subtext" instead
       */
      light: {
        color: 'gray.500',
        fontWeight: 'normal',
        fontSize: 'md'
      },
      /**
       * @deprecated Use textStyle="body" instead
       */
      default: {
        color: 'gray.800',
        fontWeight: 'normal',
        fontSize: 'md'
      }
    }
  },
  heading: {
    /**
     * @deprecated Use textStyle="sectionHeading" instead
     */
    md: {
      // subheading
      color: 'gray.500',
      fontWeight: 'bold',
      fontSize: 'md'
    },
    lg: {
      /**
       * @deprecated Use textStyle="heading3" instead
       */
      // h3
      color: 'gray.800',
      fontWeight: 'semibold',
      fontSize: 'xl'
    },
    xl: {
      /**
       * @deprecated Use textStyle="heading1" instead
       */
      // h1, h2
      color: 'gray.800',
      fontWeight: 'bold',
      fontSize: '4xl'
    }
  },
  /**
   * @deprecated Use textStyle="link" instead
   */
  link: {
    color: 'brand.500',
    fontWeight: 'normal',
    fontSize: 'md'
  }
}

export default legacyTextStyles
