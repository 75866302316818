const variantLineTab = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: 'base',
  marginBottom: 0,
  borderRadius: '4px 4px 0px 0px',
  color: 'var(--vndly-color-tab-nav-text)',
  border: 'none',
  _selected: {
    fontWeight: 'var(--vndly-font-weight-semibold)',
    color: 'var(--vndly-color-tab-nav-text-selected)',
    border: 'none',
    position: 'relative',
    _after: {
      position: 'absolute',
      height: '4px',
      borderRadius: '4px 4px 0px 0px',
      bgColor: 'var(--vndly-color-tab-nav-border-selected)',
      bottom: '0px',
      content: '""',
      left: '0px',
      width: '100%'
    }
  },
  _hover: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base',
    color: 'var(--vndly-color-tab-nav-text-hover)',
    bgColor: 'var(--vndly-color-tab-nav-background-hover)',
    border: 'none',
    _selected: {
      fontWeight: 'var(--vndly-font-weight-semibold)',
      border: 'none',
      boxShadow: 'none',
      color: 'var(--vndly-color-tab-nav-text-selected)'
    }
  },
  _focus: {
    boxShadow: 'var(--vndly-color-container-border-focused) 0px 0px 0px 2px, white 0px 0px 0px 2px',
    bgColor: 'var(--vndly-color-link-background-hover)',
    color: 'var(--vndly-color-tab-nav-text-hover)'
  }
}

export const Tabs = {
  variants: {
    line: {
      tab: variantLineTab,
      tablist: { borderBottom: '1px solid' },
      tabpanel: { p: 0 }
    }
  }
}
