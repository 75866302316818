import _ from 'lodash'
import { ClientOnly } from '~/common/nextJsUtils'
import { ItemsListItem } from '~/components/v2/ItemsList'
import { useActionBarContext } from '~/components/v2/ResultsLayout/ActionBar/ActionBar.hook'
import { Box, Card, Flex, Skeleton } from '~/design_system'

export const LoadingSkeletonState = ({ numItems = 7 }: { numItems?: number }) => {
  const actionBar = useActionBarContext()
  return (
    <ClientOnly>
      {actionBar.isListDisplayMode && <ListSkeleton numItems={numItems} />}
      {actionBar.isGridDisplayMode && <GridSkeleton numItems={numItems} />}
    </ClientOnly>
  )
}

function ListSkeleton({ numItems }: { numItems: number }) {
  return (
    <Box data-testid="skeleton-list">
      {[..._.range(1, 0.1, -0.9 / numItems)].map(opacity => (
        <ItemsListItem data-testid="skeleton-list-item" p={4} opacity={opacity} key={opacity}>
          <Flex w="full" columnGap={16} rowGap={8} direction={{ base: 'column', md: 'row' }}>
            <Flex gap={2} direction="column" flexGrow={1}>
              <Skeleton height={6} minW={100} maxW="60%" />
              <Skeleton height={6} minW={100} maxW="100%" />
              <Skeleton height={6} minW={50} maxW="30%" />
            </Flex>
            <Flex gap={2} direction="column" flexGrow={2.5}>
              <Skeleton height={8} w="full" />
              <Skeleton height={8} w="full" />
            </Flex>
          </Flex>
        </ItemsListItem>
      ))}
    </Box>
  )
}

function GridSkeleton({ numItems }: { numItems: number }) {
  const nearestThree = Math.ceil(numItems / 3) * 3
  return (
    <Box
      data-testid="skeleton-grid"
      sx={{
        // Some duplicated styles here from the ResultItems component
        // Rather duplicate than create an abstraction
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        display: 'grid',
        gap: 6,
        justifyContent: 'center',
        gridTemplateColumns: { base: '1fr', sm: 'repeat(auto-fill, minmax(375px, 1fr))' },
        '[data-results-layout-main][data--no-spacing]:has(&)': {
          '[data-results-layout-main-content]': {
            overflow: 'visible',
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            marginTop: 6,
            '[data-nprogress-bar-container]': {
              top: '-25px',
              left: '6px',
              right: '6px'
            }
          }
        }
      }}>
      {[..._.range(1, 0.1, -0.9 / nearestThree)].map(opacity => (
        <Card data-testid="skeleton-list-item" p={4} opacity={opacity} key={opacity}>
          <Flex w="full" rowGap={8} direction="column">
            <Flex gap={2} direction="column" flexGrow={1}>
              <Skeleton height={6} minW={100} maxW="60%" />
              <Skeleton height={6} minW={100} maxW="100%" />
              <Skeleton height={6} minW={50} maxW="30%" />
            </Flex>
            <Flex gap={2} direction="column" flexGrow={2.5}>
              <Skeleton height={8} w="full" />
              <Skeleton height={8} w="full" />
            </Flex>
          </Flex>
        </Card>
      ))}
    </Box>
  )
}
