import {
  Badge,
  HStack,
  Link,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  MenuList,
  Menu,
  MenuButton,
  Button
} from '~/design_system'
import { useAccountMeQuery } from '~/api/accounts'
import { formatDateTime } from '~/common/tzUtils'
import { TaskActionButtons } from '~/user_tasks/components/TasksActionButton'
import { useStatusBadge } from '~/user_tasks/components/utils'
import { UserTask } from '~/user_tasks/types'
import { PinnedIconCheckbox } from '~/user_tasks/components/PinnedIconCheckbox'
import { ChevronDown } from '~/icons'
import { Msg } from '~/common/localizationUtils'

export interface TaskDrawerListProps {
  categoryName: string
  taskItems: UserTask[]
}

export const TaskDrawerList = ({ categoryName, taskItems }: TaskDrawerListProps) => {
  const { data: { timezone, locale } = {} } = useAccountMeQuery()
  const paddingLeft = { paddingLeft: '0' }
  const paddingRight = { paddingRight: '0' }
  const getStatusBadge = useStatusBadge()

  return (
    <Table data-testid={`tasks-table-${categoryName.trim()}`} fontSize="md">
      <Tbody sx={{ 'tr:last-child td': { borderBottomWidth: 0 } }}>
        {taskItems?.map((item: UserTask) => (
          <Tr key={item.id}>
            <Td style={{ ...paddingLeft, ...paddingRight }}>
              <HStack alignItems="flex-start">
                <Stack>
                  <Text textStyle="bodySemi">
                    <Link
                      variant="unstyled"
                      target="_top"
                      data-testid={`task-description-link-${item.id}`}
                      href={item.source}>
                      {item.description}
                    </Link>
                  </Text>
                  <HStack>
                    <Badge size="sm" mr={2} variant={getStatusBadge(item.status).code}>
                      {getStatusBadge(item.status).title}
                    </Badge>
                    <Text textStyle="bodySmall" color="gray.500">
                      {item.subtype}
                    </Text>
                  </HStack>
                </Stack>
                <PinnedIconCheckbox task={item} />
              </HStack>
            </Td>
            <Td style={{ ...paddingLeft, ...paddingRight }} textAlign="right" textStyle="bodySmall">
              {item.skeleton ? '' : formatDateTime(item.due_date, timezone, locale, true)}
            </Td>
            <Td style={{ ...paddingRight }}>
              <Menu>
                <MenuButton
                  as={Button}
                  data-testid={`tasks-actions-menu-btn-${item.id}`}
                  rightIcon={<ChevronDown />}
                  isActive>
                  <Msg id="common.ui.actions" />
                </MenuButton>
                <MenuList>
                  <TaskActionButtons taskID={item.id} subType={item.subtype} isPinned={item.is_pinned} showPinAction />
                </MenuList>
              </Menu>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
