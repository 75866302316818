import { createBreakpoints } from '@chakra-ui/theme-tools'
import workdayColors from '@workday/canvas-colors-web'
import { extendTheme } from '../design_system'
import colors from './colors'
import components from './components'
import { layerStyles } from './layer-styles'
import { styles } from './styles'
import { textStyles } from './text-styles'

const breakpoints = createBreakpoints({
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1500px',
  // Why is there an extra breakpoint that is the same as xl??
  // To fix a bug that caused pages to freeze completely and crash:
  // https://github.com/chakra-ui/chakra-ui/issues/4301
  '2xl': '1500px' // 1440px
})

export const workdayTheme = extendTheme({
  styles,
  components,
  breakpoints,
  colors: Object.assign(colors, workdayColors),
  shadows: {
    base: '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.06)',
    // The default "focus" ring of all chakra elements uses this outline boxShadow
    outline: '0px 0px 0px 2px white, 0px 0px 0px 4px var(--vndly-color-container-border-focused)',
    insetOutline: 'inset 0px 0px 0px 2px white, 0px 0px 0px 2px var(--vndly-color-container-border-focused)'
  },
  fonts: {
    body: '"Roboto", "Helvetica Neue", "Helvetica", Arial, sans-serif',
    heading: '"Roboto", "Helvetica Neue", "Helvetica", Arial, sans-serif',
    mono: '"Roboto Mono", "Courier New", Courier, monospace'
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 500,
    bold: 700
  },
  space: {
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem'
  },
  layerStyles,
  textStyles
})

/**
 * @deprecated
 * These are our old VNDLY custom text styles. Instead of using these text styles we will be utilizing
 * the Chakra text styles listed above. Please do not use these styles any more.
 * */
export { default as textStyles } from './legacy_text_styles'
