/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react'
import { isClient, isServer } from '~/common/nextJsUtils'

const __pageDataCache = {}
const identity = x => x

export const getPageData = (id = 'data', transform = identity, defaultData = '{}') => {
  if (isServer()) {
    __pageDataCache[id] = {}
  }
  if (!__pageDataCache[id]) {
    const scriptEl = document.getElementById(id)
    __pageDataCache[id] = JSON.parse(scriptEl ? scriptEl.textContent : defaultData)
  }
  return transform(__pageDataCache[id])
}

if (isClient()) {
  window.getPageData = getPageData
}

export const useLegacyDjangoPageData = (id = 'data', transform = identity, defaultData) =>
  useMemo(() => getPageData(id, transform, defaultData), [id, transform, defaultData])
