const baseStyleHeader = {
  py: 6,
  pl: 6,
  pr: 12
}

const baseStyleCloseButton = {
  position: 'absolute',
  top: '1.5rem',
  right: '1.5rem',
  _focus: {
    boxShadow: 'insetOutline'
  }
}

const baseStyleBody = {
  px: 6,
  pt: 0,
  pb: 6,
  flex: 1,
  overflow: 'auto'
}

const baseStyleFooter = {
  p: 6,
  justifyContent: 'flex-start',
  borderTop: '1px solid',
  borderColor: 'gray.300'
}

const baseStyle = {
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter
}

/**
 * Used remove the pointer event from the drawer overlay so we can allow
 * the rest of the page to be clickable while the drawer is open
 */
const variantAlwaysOpen = {
  dialog: {
    pointerEvents: 'auto'
  },
  dialogContainer: {
    pointerEvents: 'none'
  }
}

export const Drawer = {
  baseStyle,
  variants: {
    alwaysOpen: variantAlwaysOpen
  },
  defaultProps: {
    size: 'sm'
  }
}
