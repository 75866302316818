import { ReactNode } from 'react'
import { CONTAINER_QUERY_BREAKPOINTS, cq } from '../../../common/containerQuery'
import { Msg } from '../../../common/localizationUtils'
import { Prettify } from '../../../common/type-helpers'
import { Flex, forwardRef, RadioGroup, RadioGroupProps, assignRef } from '../../chakra'
import { CustomRadio, CustomRadioProps } from './FormControls'

type Option = { value: string | number; label?: ReactNode; tooltip?: string } & Omit<
  CustomRadioProps,
  'value' | 'variant'
>

export type SegmentedControlProps = Omit<RadioGroupProps, 'children'> & {
  options?: Array<Option>
  /**
   * By "full width" we mean that the segmented control will take up the full available space of the defined width
   * which defaults to 100% but can be set to any width.
   *
   * @example
   * <SegmentedControl isFullWidth w="50%">
   */
  isFullWidth?: boolean
  /**
   * The container size at which the segmented control should switch to a vertical layout, if isFullWidth is true.
   * Defaults to 'xs' (320px). See the containerQuery.tsx for the possible sizes.
   */
  verticalContainerBreakpoint?: keyof typeof CONTAINER_QUERY_BREAKPOINTS
}

export const SegmentedControl = forwardRef<SegmentedControlProps, 'div'>(
  (
    {
      options,
      defaultValue = options?.[0]?.value,
      isFullWidth = false,
      verticalContainerBreakpoint: bp = 'xs',
      ...props
    },
    ref
  ) => {
    return (
      <RadioGroup
        data-segmented-control
        ref={el => {
          // Assign the ref to the first input element ancestor if it exists
          assignRef(ref, el?.querySelector('input[type="radio"]') ?? el)
        }}
        defaultValue={defaultValue}
        sx={
          isFullWidth
            ? {
                containerType: 'inline-size',
                containerName: 'segmented-control'
              }
            : {}
        }
        // You can pass in a custom width to the segmented control and
        // it will take up the full width of whatever you define
        w={isFullWidth ? '100%' : 'auto'}
        {...props}>
        <Flex
          sx={cq({
            gap: -1,
            flexDirection: isFullWidth
              ? {
                  '@segmented-control/base': 'column',
                  [`@segmented-control/${bp}`]: 'row'
                }
              : 'row'
          })}>
          {options?.map(({ value, label = value, ...props }) => (
            <CustomRadio
              variant="segmentedControl"
              value={value}
              key={value}
              {...props}
              isFullWidth={isFullWidth}
              bp={bp}>
              {label}
            </CustomRadio>
          ))}
        </Flex>
      </RadioGroup>
    )
  }
)

SegmentedControl.displayName = 'SegmentedControl'

/**
 * Segmented Control Conditional (Styled Select)
 *
 * Customized a conditional (AND/OR) segmented control using Chakra components.
 * See http://storybook.beta.vndly.com/?path=/docs/foundations-forms-selectors--radio-group for
 * examples and usage.
 *
 */
export type SegmentedControlConditionalProps = Prettify<
  Omit<SegmentedControlProps, 'options' | 'onChange'> & {
    onChange?: (nextValue: 'And' | 'Or') => void
  }
>

export const SegmentedControlConditional = forwardRef<SegmentedControlProps, 'div'>((props, ref) => {
  return (
    <SegmentedControl
      ref={ref}
      {...props}
      options={[
        {
          label: <Msg id="common.ui.and" />,
          value: 'And',
          sx: {
            minW: 14
          }
        },
        {
          label: <Msg id="common.ui.or" />,
          value: 'Or',
          sx: {
            minW: 14,
            '[data-custom-control]': {
              _checked: {
                bg: 'chilimango400',
                color: 'white',
                borderColor: 'chilimango400'
              }
            }
          }
        }
      ]}
    />
  )
})

SegmentedControlConditional.displayName = 'SegmentedControlConditional'
