export const Badge = {
  baseStyle: {
    textTransform: 'uppercase',
    borderRadius: 'full',
    fontWeight: 'bold',
    lineHeight: 'base',
    textAlign: 'center'
  },
  sizes: {
    sm: {
      fontSize: 'xs',
      px: 2
    },
    lg: {
      fontSize: 'sm',
      px: 3
    }
  },
  variants: {
    danger: {
      bgColor: 'red.100',
      color: 'red.600'
    },
    pending: {
      bgColor: 'yellow.200',
      color: 'red.700'
    },
    success: {
      bgColor: 'brand.100',
      color: 'brand.700'
    },
    incomplete: {
      bgColor: 'gray.200',
      color: 'gray.500'
    },
    complete: {
      bgColor: 'gray.500',
      color: 'white'
    },
    redlinePrevious: {
      bgColor: 'red.100',
      borderRadius: 'base',
      textDecoration: 'line-through',
      textTransform: 'capitialize',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base'
    },
    redlineUpdated: {
      bgColor: 'brand.100',
      borderRadius: 'base',
      textTransform: 'capitialize',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base'
    }
  }
  // TODO: Commenting out default state until we move all badges to the new component (shown in storybook)
  // defaultProps: {
  //   size: 'sm',
  //   variant: 'incomplete'
  // }
}
