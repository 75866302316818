import React from 'react'
import {
  Box,
  BoxProps,
  AlertTitle,
  AlertDescription,
  Stack,
  OrderedList,
  ListItem,
  Button,
  Tooltip,
  forwardRef
} from '../../chakra'
import { AlertBanner } from '../alerts/Alerts'
import { Msg } from '../../../common/localizationUtils'
import { CaretDown, CaretUp, Info } from '../../../icons'
import { useFormAlertListContext } from './FormAlertListContext'

export type FieldAlert = {
  id: string
  label: string
  type: 'error' | 'warning'
  message: string
}

type AlertBannerListProps = {
  alerts: Array<FieldAlert>
} & BoxProps

/**
 * FormAlertList
 *
 * FormAlertList are used to communicate a list of alerts that affects a system, feature or page. These include
 * errors and warnings. An array of FieldAlert objects is passed to the component to display the list of alerts.
 * Each alert displayed in the list are clickable and will scroll to the field that caused the alert.
 *
 */
export const FormAlertList = forwardRef(({ alerts, children, ...props }: AlertBannerListProps, ref) => {
  const errors = alerts.filter(alert => alert.type === 'error')
  const warnings = alerts.filter(alert => alert.type === 'warning')
  const [showMoreErrors, setShowMoreErrors] = React.useState(false)
  const [showMoreWarnings, setShowMoreWarnings] = React.useState(false)
  const [isWarningsCleared, setIsWarningsCleared] = React.useState(false)
  const ctx = useFormAlertListContext()

  const scrollToField = (error: FieldAlert) => {
    if (!ctx) return
    const node = ctx.targets[error.id]?.el as HTMLElement
    node?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Box
      border="1px solid var(--vndly-color-input-border-invalid)"
      borderRadius="base"
      py={4}
      data-testid="form-alert-list"
      display={alerts.length > 0 ? 'block' : 'none'}
      ref={ref}
      {...props}>
      <Stack spacing={6} flex="1">
        {errors.length > 0 && (
          <AlertBanner status="error" variant="list-error" alignItems="flex-start">
            <AlertTitle pb={4} tabIndex={0}>
              <Msg id="term.errors_found" count={errors.length} description="Number of errors found" />
            </AlertTitle>
            <AlertDescription>
              <OrderedList>
                {errors.map((error, index) => (
                  <ListItem display={index < 3 || showMoreErrors ? undefined : 'none'}>
                    <Button variant="link" textDecoration="underline" height={8} onClick={() => scrollToField(error)}>
                      {error.label} - {error.message}
                    </Button>
                  </ListItem>
                ))}
              </OrderedList>
              {errors.length > 3 && (
                <Button
                  variant="viewAll"
                  onClick={() => setShowMoreErrors(!showMoreErrors)}
                  data-testid="toggle-more-errors">
                  {showMoreErrors ? (
                    <>
                      <CaretUp /> <Msg id="common.ui.show_less" />
                    </>
                  ) : (
                    <>
                      <CaretDown /> <Msg id="common.ui.show_more" />
                    </>
                  )}
                </Button>
              )}
            </AlertDescription>
          </AlertBanner>
        )}
        {warnings.length > 0 && !isWarningsCleared && (
          <AlertBanner status="warning" variant="list-warning" alignItems="flex-start">
            <AlertTitle pb={4} tabIndex={0}>
              <Stack isInline>
                <Msg id="term.warnings_found" count={warnings.length} description="Number of warnings found" />
                <Tooltip label="Warnings do not stop you from proceeding" placement="top-start">
                  <Info ml={1} />
                </Tooltip>
              </Stack>
            </AlertTitle>
            <AlertDescription>
              <OrderedList>
                {warnings.map((warning, index) => (
                  <ListItem display={index < 3 || showMoreWarnings ? undefined : 'none'}>
                    <Button variant="link" textDecoration="underline" height={8} onClick={() => scrollToField(warning)}>
                      {warning.label} - {warning.message}
                    </Button>
                  </ListItem>
                ))}
              </OrderedList>
              <Button data-testid="clear-warnings" size="sm" mt={4} onClick={() => setIsWarningsCleared(true)}>
                <Msg id="term.clear_warnings" />
              </Button>
              {warnings.length > 3 && (
                <Button
                  variant="viewAll"
                  onClick={() => setShowMoreWarnings(!showMoreWarnings)}
                  data-testid="toggle-more-warnings">
                  {showMoreWarnings ? (
                    <>
                      <CaretUp /> <Msg id="common.ui.show_less" />
                    </>
                  ) : (
                    <>
                      <CaretDown /> <Msg id="common.ui.show_more" />
                    </>
                  )}
                </Button>
              )}
            </AlertDescription>
          </AlertBanner>
        )}
      </Stack>
    </Box>
  )
})

FormAlertList.displayName = 'FormAlertList'
