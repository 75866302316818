import createDebugLogger from '~/common/logger'
import { isClient } from '~/common/nextJsUtils'

const debug = createDebugLogger('TTLCache')

global.__ttl_caches = []

export class TTLCache {
  static clearAll() {
    global.__ttl_caches.forEach(cache => {
      cache.clear()
    })
  }

  /**
   * Allows to prefix keys during tests to avoid conflicts between tests within
   * the same test file that may share the same TTLCache instance.
   *
   * Set this function within your test setup file to return a unique prefix
   * based on the current test name or id.
   *
   * @example
   * TTLCache.getKeyPrefixDuringTests = () => expect.getState().currentTestName ?? ''
   */
  static getKeyPrefixDuringTests = () => ''

  constructor(ttl = 0, { debugName = '?' } = {}) {
    this.cache = new Map()
    this.ttl = ttl
    this.debugName = debugName
    this.enabled = isClient()
    global.__ttl_caches.push(this)
  }

  set(_key, value, ttl) {
    if (!this.enabled) return
    // eslint-disable-next-line no-param-reassign
    ttl = ttl ?? this.ttl
    const key = TTLCache.getKeyPrefixDuringTests() + _key
    this.cache.set(key, { value, ttl, timestamp: Date.now() })
    debug(`[${this.debugName}] TTLCache SET key: ${key}, ttl: ${ttl}`, value)
    setTimeout(() => {
      this.cache.delete(key)
      debug(`[${this.debugName}] TTLCache EXPIRE key: ${key}`)
    }, ttl)
  }

  get(_key, fallback = null) {
    if (!this.enabled) return fallback
    const key = TTLCache.getKeyPrefixDuringTests() + _key
    const cached = this.cache.get(key)
    if (cached) {
      debug(`[${this.debugName}] TTLCache GET [hit] key: ${key}`, cached.value)
      return cached.value
    }
    debug(`[${this.debugName}] TTLCache GET [miss] key: ${key}`, fallback)
    return fallback
  }

  delete(_key) {
    const key = TTLCache.getKeyPrefixDuringTests() + _key
    this.cache.delete(key)
    debug(`[${this.debugName}] TTLCache DELETE key: ${key}`)
  }

  clear() {
    this.cache.clear()
    debug(`[${this.debugName}] TTLCache CLEAR`)
  }
}

export const TEN_SECONDS = 1000 * 10
export const THIRTY_SECONDS = 1000 * 30
export const ONE_MINUTE = 1000 * 60
export const FIVE_MINUTES = 1000 * 60 * 5
export const TEN_MINUTES = 1000 * 60 * 10
export const THIRTY_MINUTES = 1000 * 60 * 30
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_DAY = 24 * ONE_HOUR
export const ONE_WEEK = 7 * ONE_DAY
export const ONE_YEAR = 365 * ONE_DAY
