const baseStyle = {
  overlay: {
    bgColor: 'rgba(0, 0, 0, 0.3)'
  },
  dialog: {
    borderRadius: 'base',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.32)',
    minH: '200px'
  },
  footer: {
    borderColor: 'gray.300'
  },
  closeButton: {
    _focus: {
      boxShadow: 'insetOutline'
    }
  }
}

/**
 * A modal that is basically "full" sized but max width is limited to the largest breakpoint,
 * just like how our Container component works. Also includes 16px margin around the outer edge
 * so modal does not touch the viewport screen edges.
 */
function sizeContainer(props) {
  return {
    dialog: {
      my: 0,
      w: 'calc(100vw - 16px * 2)',
      maxW: props.theme.breakpoints.xl,
      h: 'calc(100vh - 16px * 2)',
      position: 'fixed',
      left: 'auto',
      top: 4
    },
    footer: {
      justifyContent: 'flex-start',
      borderTopWidth: '1px',
      borderColor: 'gray.300'
    }
  }
}

const sizes = {
  container: sizeContainer
}

export const Modal = {
  baseStyle,
  sizes
}
