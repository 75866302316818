/* eslint-disable @next/next/no-img-element */
import { Center, Progress } from '~/design_system'

export const VndlySpinner = props => (
  <Center h="300px" {...props}>
    <Progress
      data-testid="vndly-spinner"
      size="xs"
      isIndeterminate
      colorScheme="brand"
      w="33%"
      minW="250px"
      bg="transparent"
      bgGradient="linear(to-r, transparent 0%, gray.100 50%, transparent 100%)"
    />
  </Center>
)

export const VndlyOverlaySpinner = ({ children }) => (
  <div className="social_overlay loader-overlay">
    {children || (
      <p>
        Processing...
        <br />
        <img src="/static/images/ajax-loader.gif" alt="tick" />
      </p>
    )}
  </div>
)
