import { addWeeks, parse, setISODay } from 'date-fns'

const getDateFormatter = (locale, timeZone, formatOptions) => {
  // The dateStyle and timeStyle options are still at proposal state (stage 3): https://tc39.es/proposal-intl-datetime-style/
  // Some browsers do not support them (for example, Firefox)
  // Nodejs does not suport them as well
  // Explictly use year, month, day, hour, minute, and second instead of dateStyle and timeStyle
  const { date, time, timeZoneName } = formatOptions
  const options = {
    timeZone,
    ...(date || { year: 'numeric', month: 'short', day: 'numeric' }),
    ...(time || { hour: 'numeric', minute: 'numeric' }),
    ...(timeZoneName ? { timeZoneName } : {})
  }

  if (!locale && typeof window !== 'undefined') {
    // eslint-disable-next-line no-param-reassign
    locale = window.navigator.language
  }

  return new Intl.DateTimeFormat(locale.replace(/_/g, '-'), options)
}

/**
 * Note: timezone should typically be passed if the dateinput has a time component (not just date)
 *
 * @param {Date|string} dateInput
 * @param {string | undefined} locale
 * @param {string | undefined} timezone
 * @param {*} formatOptions
 */
export const formatDateWithLocale = (dateInput, locale, timezone = undefined, formatOptions = {}) => {
  return formatDateTimeWithLocale(dateInput, timezone, locale, formatOptions, true)
}

// Super basic regex to check if a string is a date only string (in iso format)
const IS_DATE_ONLY_STRING_REGEX = /^\d\d\d\d-\d\d?-\d\d?$/

/**
 * @param {Date | string} dateInput
 * @param {string | undefined} timezone
 * @param {string | undefined} locale
 * @param {*} formatOptions
 * @param {boolean} showDateOnly
 */
export const formatDateTimeWithLocale = (dateInput, timezone, locale, formatOptions = {}, showDateOnly = false) => {
  if (dateInput == null) return ''

  let dt = dateInput
  if (typeof dateInput === 'string') {
    dt = parse(dateInput)
    // If a string is passed that only has date, not time, only show date
    // and ignore the tz so we get the exact date returned (no tz conversion)
    if (dateInput.trim().match(IS_DATE_ONLY_STRING_REGEX)) {
      // eslint-disable-next-line no-param-reassign
      showDateOnly = true
      // eslint-disable-next-line no-param-reassign
      timezone = undefined
    }
  }

  let formatOptionsMerged = formatOptions
  if (showDateOnly) {
    formatOptionsMerged = {
      ...formatOptions,
      time: {}
    }
  }
  return getDateFormatter(locale, timezone, formatOptionsMerged).format(dt)
}

/**
 * @deprecated use formatDateTimeWithLocale
 */
export const formatDateTime = (dateInput, timezone, locale, showDateOnly) => {
  return formatDateTimeWithLocale(dateInput, timezone, locale, undefined, showDateOnly)
}

export function getAllDatesOnDayOfWeekInRange(minDate, maxDate, dayOfWeekISO) {
  // get all dates in a date range for a certain day of week, example: Monday
  // uses ISO day of week where 1 is Monday and 7 is Sunday

  let validDay = getNextDayOfWeek(minDate, dayOfWeekISO)
  const validDates = []
  while (validDay <= maxDate) {
    validDates.push(validDay)
    validDay = addWeeks(validDay, 1)
  }
  return validDates
}

export function getNextDayOfWeek(date, dayOfWeekISO) {
  // ISO day of week: week starts on Monday
  // If you pass in a Friday and want to find 1 (the first day of that week) it will return the Monday BEFORE that day
  // We want the NEXT Monday, so we add a week
  const dayOfWeekForThisDate = setISODay(date, dayOfWeekISO)
  if (dayOfWeekForThisDate < date) {
    return addWeeks(dayOfWeekForThisDate, 1)
  }
  return dayOfWeekForThisDate
}
