import { Link } from '@reach/router'
import { ComponentPropsWithoutRef } from 'react'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbLinkProps, Text, forwardRef } from '../../chakra'
import { ToggledNextLink } from '../../../components/v2/next/ToggledNextLink'
import { ChevronRight } from '../../../icons'

export type BreadcrumbLinkAttrs = {
  /** The text to display for the link. */
  name: string | JSX.Element
  /** The location to link to, if applicable. */
  href?: string
  /** The location to link to within ReachRouter, if applicable. */
  to?: string
  as?: any
  onClick?: () => void
}

export type VndlyBreadcrumbLinkProps = BreadcrumbLinkProps & {
  /** The location to link to within ReachRouter, if applicable. */
  to?: string
}

export const VndlyBreadcrumbLink = forwardRef(({ to, ...props }: VndlyBreadcrumbLinkProps, ref) => {
  if (to) {
    return <BreadcrumbLink ref={ref} as={Link} to={to} {...props} />
  }
  return <BreadcrumbLink ref={ref} {...props} />
})

export type BreadcrumbsProps = {
  /** An array of links to be rendered as the breadcrumb. */
  links: BreadcrumbLinkAttrs[]
} & ComponentPropsWithoutRef<typeof Breadcrumb>

/**
 * Displays a list of links as breadcrumbs.
 *
 * Uses Chakra's `Breadcrumb` and related components.
 */
export const Breadcrumbs = forwardRef(({ links, ...rest }: BreadcrumbsProps, ref) => (
  <Breadcrumb spacing="1" separator={<ChevronRight color="gray.400" w="16px" h="16px" />} ref={ref} {...rest}>
    {links.map((link, i) => {
      const active = i === links.length - 1
      return (
        <BreadcrumbItem key={link.href || link.to || i} isCurrentPage={active} maxW={[320, 500]} whiteSpace="nowrap">
          {active ? (
            <Text textStyle="bodySemi" textOverflow="ellipsis" overflow="hidden" data-testid="current-page">
              {link.name}
            </Text>
          ) : (
            <ToggledNextLink href={link.href}>
              <VndlyBreadcrumbLink
                textStyle="link"
                textOverflow="ellipsis"
                overflow="hidden"
                to={link.to}
                onClick={link.onClick}>
                {link.name}
              </VndlyBreadcrumbLink>
            </ToggledNextLink>
          )}
        </BreadcrumbItem>
      )
    })}
  </Breadcrumb>
))

Breadcrumbs.displayName = 'Breadcrumbs'
