const baseStyleLink = {
  transitionProperty: 'common',
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-out',
  cursor: 'pointer',
  textDecoration: 'underline',
  outline: 'none',
  color: 'var(--vndly-color-link)',
  _hover: {
    bg: 'var(--vndly-color-link-background-hover)',
    color: 'var(--vndly-color-link-hover)',
    textDecoration: 'underline'
  },
  _focusVisible: {
    boxShadow: 'outline'
  }
}

export const Breadcrumb = {
  baseStyle: {
    link: baseStyleLink
  }
}
