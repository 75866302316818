import { HStack, VStack, Checkbox, BoxProps, Text, Badge } from '../../chakra'
import { TextBlockTwoLineDefault } from '../..'
import { SidebarSplitItem } from './SidebarList'

type SidebarSplitItemProps = {
  title?: string
  subtext?: string
  badgeText?: string
  badgeStatus?: 'danger' | 'pending' | 'success' | 'incomplete' | 'complete'
} & BoxProps & { isSelected?: boolean }

/**
 * SidebarSplitItemSimple
 *
 * This component represents a simple split list item format with inline data.
 */
export const SidebarSplitItemSimple = ({ children, ...props }: SidebarSplitItemProps) => (
  <SidebarSplitItem {...props}>
    <HStack spacing={4} w="full">
      {children}
    </HStack>
  </SidebarSplitItem>
)

/**
 * SidebarSplitItemWithCheckbox
 
 * This component represents a checkbox split list item format with inline checkbox and data.
 */
export const SidebarSplitItemWithCheckbox = ({ children, ...props }: SidebarSplitItemProps) => (
  <SidebarSplitItem {...props}>
    <HStack spacing={4} w="full">
      <Checkbox />
      {children}
    </HStack>
  </SidebarSplitItem>
)

/**
 * SidebarSplitItemApproval
 
 * This component represents an approval split list item format.
 */
export const SidebarSplitItemApproval = ({ title, children, ...props }: SidebarSplitItemProps) => (
  <SidebarSplitItem justifyContent="flex-start" {...props}>
    <Checkbox />
    <VStack spacing={4} ml={4}>
      <HStack spacing={4} w="full">
        {children}
      </HStack>
      <Text mt="8px !important">{title}</Text>
    </VStack>
  </SidebarSplitItem>
)

/**
 * SidebarSplitItemWithAttribute
 
 * This component represents a single or collection of data attributes split list item format.
 */
export const SidebarSplitItemWithAttribute = ({
  title,
  subtext,
  badgeText,
  badgeStatus,
  children,
  ...props
}: SidebarSplitItemProps) => (
  <SidebarSplitItem {...props}>
    <VStack spacing={4}>
      <TextBlockTwoLineDefault primaryText={title} secondaryText={subtext} />
      {children}
    </VStack>
    <Badge variant={badgeStatus} size="sm">
      {badgeText}
    </Badge>
  </SidebarSplitItem>
)
