import React, { useContext } from 'react'
import { Optional } from '~/common/types'
import { Msg, useMsg } from '../../../common/localizationUtils'
import { ChevronLeft, ChevronRight } from '../../../icons'
import {
  chakra,
  Button,
  ButtonProps,
  forwardRef,
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Input,
  Stack,
  StackProps,
  Text,
  TextProps
} from '../../chakra'
import { buildRange, getRangeMax, getRangeMin } from './utils'

const PaginationContext = React.createContext({} as ReturnType<typeof usePaginationContext>)

type PaginationProps = {
  totalPages: number
  resultCount: number
  totalCount: number
  currentPage?: number
  initialCurrentPage?: number
  rangeSize?: number
  onPageChange?: (pageNumber: number) => void
} & StackProps
/**
 * Pagination
 *
 * The container for a pagination. This sets up the layout for the buttons and text.
 */
export const Pagination = forwardRef(
  (
    {
      totalPages,
      totalCount,
      resultCount,
      currentPage,
      initialCurrentPage,
      rangeSize,
      onPageChange,
      children,
      ...props
    }: PaginationProps,
    ref
  ) => {
    const model = usePaginationContext({
      lastPage: totalPages,
      totalCount,
      resultCount,
      currentPage,
      initialCurrentPage,
      rangeSize,
      onPageChange
    })
    return (
      <PaginationContext.Provider value={model}>
        <Stack align="center" justify="center" spacing="sm" ref={ref} {...props}>
          {children}
        </Stack>
      </PaginationContext.Provider>
    )
  }
)

Pagination.displayName = 'Pagination'

/**
 * PaginationControls
 *
 * Wrapper for any Pagination user controlled components
 */
export const PaginationControls = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Flex gap={2} align="center" justify="center" flexWrap="wrap" ref={ref} {...props}>
    {children}
  </Flex>
))

PaginationControls.displayName = 'PaginationControls'

type PaginationButtonProps = {
  /** The test id of the button. */
  'data-testid'?: string
} & Optional<IconButtonProps, 'aria-label'> &
  Pick<IconButtonProps, 'isDisabled' | 'onClick'>

/**
 * PaginationPreviousButton
 *
 * The previous button for a pagination.
 */
export const PaginationPreviousButton = forwardRef(
  ({ 'aria-label': ariaLabel, onClick, ...props }: PaginationButtonProps, ref) => {
    const msg = useMsg()
    const model = useContext(PaginationContext)
    const isDisabled = model.state.currentPage <= 1

    return (
      <IconButton
        variant="pager"
        icon={<ChevronLeft />}
        aria-label={ariaLabel ?? msg('common.ui.pagination.previous_page.btn')}
        onClick={e => {
          onClick?.(e)
          model.events.previous()
        }}
        isDisabled={isDisabled}
        size="sm"
        ref={ref}
        {...props}
      />
    )
  }
)

PaginationPreviousButton.displayName = 'PaginationPreviousButton'

/**
 * PaginationNextButton
 *
 * The next button for a pagination.
 */
export const PaginationNextButton = forwardRef(
  ({ 'aria-label': ariaLabel, onClick, ...props }: PaginationButtonProps, ref) => {
    const msg = useMsg()
    const model = useContext(PaginationContext)
    const isDisabled = model.state.currentPage >= model.state.lastPage

    return (
      <IconButton
        variant="pager"
        icon={<ChevronRight />}
        aria-label={ariaLabel ?? msg('common.ui.pagination.next_page.btn')}
        onClick={e => {
          onClick?.(e)
          model.events.next()
        }}
        isDisabled={isDisabled}
        size="sm"
        ref={ref}
        {...props}
      />
    )
  }
)

PaginationNextButton.displayName = 'PaginationNextButton'

type PaginationPageNumbersProps = {
  children?: (model: ReturnType<typeof usePaginationContext>) => React.ReactNode[] | React.ReactNode
  'aria-label'?: string
  /** The test id of the button. */
  'data-testid'?: string
  onClick?: () => void
  isDisabled?: boolean
} & StackProps
/**
 * PaginationPageNumbers
 *
 * Wrapper for PaginationPageButton components.
 */
export const PaginationPageNumbers = forwardRef(
  (
    {
      'aria-label': ariaLabel,
      'data-testid': dataTestId = 'Test Page',
      onClick,
      children,
      isDisabled,
      ...props
    }: PaginationPageNumbersProps,
    ref
  ) => {
    const model = React.useContext(PaginationContext)
    const renderChildren = typeof children === 'function' ? children(model) : children
    const msg = useMsg()
    return (
      <HStack ref={ref} {...props}>
        {children
          ? renderChildren
          : model.state.range.map(pageNumber => (
              <PaginationPageButton
                key={pageNumber}
                aria-label={ariaLabel ?? msg('common.ui.pagination.page_num.btn', { number: pageNumber })}
                data-testid={`${dataTestId} ${pageNumber}`}
                pageNumber={pageNumber}
                onClick={onClick}
                isDisabled={isDisabled}
              />
            ))}
      </HStack>
    )
  }
)

PaginationPageNumbers.displayName = 'PaginationPageNumbers'

type PageButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  pageNumber: number
} & ButtonProps
/**
 * PaginationPageButton
 *
 * Page button represented within the list of PaginationPageNumbers.
 */
export const PaginationPageButton = forwardRef(
  ({ pageNumber, onClick, isDisabled = false, ...props }: PageButtonProps, ref) => {
    const model = React.useContext(PaginationContext)
    const isCurrentPage = pageNumber === model.state.currentPage
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e)
      model.events.goTo(pageNumber)
    }
    return (
      <Button
        variant={isCurrentPage ? 'primary' : 'pager'}
        size="sm"
        onClick={handleClick}
        isDisabled={isDisabled}
        ref={ref}
        {...props}>
        {pageNumber}
      </Button>
    )
  }
)

PaginationPageButton.displayName = 'PaginationPageButton'

type PaginationJumpToProps = {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
} & StackProps &
  React.FormHTMLAttributes<HTMLFormElement>
/**
 * PaginationJumpTo
 *
 * Jump to a page as specified in the input form.
 */
export const PaginationJumpTo = forwardRef(({ onSubmit, ...props }: PaginationJumpToProps, ref) => {
  const model = React.useContext(PaginationContext)
  const [value, setValue] = React.useState(model.state.currentPage.toString())
  const msg = useMsg()

  React.useEffect(() => {
    if (value !== undefined && model.state.currentPage !== parseInt(value, 10)) {
      setValue(model.state.currentPage.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.state.currentPage])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit?.(event)
    // Clearing the input goes back to page 1
    model.events.goTo(parseInt(value, 10) || 1)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <chakra.form onSubmit={handleSubmit} mx="auto">
      <HStack spacing="sm" ref={ref} {...props}>
        <Input
          aria-label={msg('common.ui.pagination_jumpto.input_aria_label', {
            page: value,
            totalPages: model.state.lastPage
          })}
          placeholder="1"
          w="64px"
          textAlign="right"
          value={value}
          onChange={handleChange}
        />
        <Text textStyle="subtextSmall" whiteSpace="nowrap" aria-hidden>
          <Msg id="common.ui.pagination_jumpto" totalPages={model.state.lastPage} />
        </Text>
      </HStack>
    </chakra.form>
  )
})

PaginationJumpTo.displayName = 'PaginationJumpTo'

/**
 * PaginationTotalItems
 *
 * The range of total items shown per page. When total number of items is on a single page,
 * this text will display instead of the pager controls.
 */
export const PaginationTotalItems = forwardRef((props: TextProps, ref) => {
  const model = React.useContext(PaginationContext)
  const offset = model.state.resultCount * model.state.currentPage
  const itemsMax = offset > model.state.totalCount ? model.state.totalCount : offset
  const itemsMin = offset - model.state.resultCount + 1
  return (
    <Text textStyle="subtextSmall" ref={ref} m={0} {...props}>
      {model.state.lastPage === 1 ? (
        // If there is only one page, we don't need to show the page range, just the item count
        <Msg id="common.ui.pagination_total_items.item_count_only" totalCount={model.state.totalCount} />
      ) : (
        // If there are multiple pages, we show the page range and the item count
        <Msg
          id="common.ui.pagination_total_items"
          itemsMin={itemsMin}
          itemsMax={itemsMax}
          totalCount={model.state.totalCount}
        />
      )}
    </Text>
  )
})

PaginationTotalItems.displayName = 'PaginationTotalItems'

type UsePaginationContextProps = {
  lastPage: number
  totalCount: number
  resultCount: number
  currentPage?: number
  initialCurrentPage?: number
  onPageChange?: (pageNumber: number) => void
  rangeSize?: number
}

export const DEFAULT_PAGINATION_RANGE = 5

/**
 * usePaginationContext
 *
 * Hook used to build a pagination model for Pager components.
 */
export const usePaginationContext = ({
  currentPage: controlledCurrentPage,
  initialCurrentPage = 1,
  lastPage,
  totalCount,
  resultCount,
  rangeSize = DEFAULT_PAGINATION_RANGE,
  onPageChange: controlledOnPageChange
}: UsePaginationContextProps) => {
  const [internalCurrentPage, setInternalCurrentPage] = React.useState(controlledCurrentPage ?? initialCurrentPage)
  const currentPage = controlledCurrentPage ?? internalCurrentPage

  const onPageChange = (page: number) => {
    setInternalCurrentPage(page)
    controlledOnPageChange?.(page)
  }

  const next = () => {
    onPageChange(currentPage + 1)
  }

  const previous = () => {
    onPageChange(currentPage - 1)
  }

  const goTo = (pageNumber: number) => {
    if (pageNumber < 1) {
      onPageChange(1)
    } else if (pageNumber > lastPage) {
      onPageChange(lastPage)
    } else {
      onPageChange(pageNumber)
    }
  }

  const range = buildPageRange({ currentPage, lastPage, rangeSize })
  const rangeMin = getRangeMin(range)
  const rangeMax = getRangeMax(range)

  const state = {
    currentPage,
    lastPage,
    totalCount,
    resultCount,
    range,
    rangeSize,
    rangeMin,
    rangeMax
  }

  const events = {
    setCurrentPage: onPageChange,
    next,
    previous,
    goTo
  }

  return {
    state,
    events
  }
}

const buildPageRange = ({
  currentPage,
  lastPage,
  rangeSize
}: {
  currentPage: number
  lastPage: number
  rangeSize: number
}) => {
  // prevent the range size exceeding the number of pages
  const adjustedRangeSize = lastPage < rangeSize ? lastPage : rangeSize

  // Prevent the range from going below 1
  if (currentPage <= Math.floor(rangeSize / 2)) {
    const rangeMin = 1
    return buildRange(adjustedRangeSize, rangeMin)
  }
  // Prevent the range from going above the lastPage
  if (currentPage + Math.floor(adjustedRangeSize / 2) > lastPage) {
    const rangeMin = lastPage - adjustedRangeSize + 1
    return buildRange(adjustedRangeSize, rangeMin)
  }

  const rangeMin = currentPage - Math.floor(adjustedRangeSize / 2)
  return buildRange(adjustedRangeSize, rangeMin)
}
