import fetch from '~/common/fetch'

export type AnalyticsApiResponse = {
  pendo_analytics_metadata: {
    enabled: boolean
    api_key: string
    user_uuid: string
  }

  tenant: string
  tenant_locale: string
  tenant_timezone: string
  domain: 'production' | 'qa' | 'beta' | 'local'
  environment_type: 'production' | 'test'
  rbac_role: string
  role: 'anonymous user' | 'Candidate' | 'Employer' | 'Vendor' | 'WT Worker'
  user_locale: string
  user_timezone: string
}

const ONE_HOUR = 1000 * 60
const responseCache = { ttl: ONE_HOUR }
export const analyticsApi = {
  config: (): Promise<AnalyticsApiResponse> => fetch.get(`/api/v2/analytics/config/`, { responseCache })
}
