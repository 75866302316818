import { ComponentMultiStyleConfig } from '@chakra-ui/theme'

const baseStyle = {
  container: {
    bg: 'var(--vndly-color-container-background-light)',
    boxShadow: 'base',
    border: '1px solid',
    borderColor: 'var(--vndly-color-container-border)',
    borderRadius: 'var(--vndly-shape-base)'
  },
  body: {
    p: '6'
  },
  header: {
    px: '6',
    py: '4',
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'gray.300',
    overflow: 'auto',
    display: 'flex'
  },
  footer: {
    p: '6',
    py: '4',
    alignItems: 'center',
    borderTop: '1px',
    borderColor: 'gray.300',
    overflow: 'auto',
    display: 'flex'
  }
}

const variantHomeCard = {
  container: {
    p: '2',
    color: 'blackPepper300',
    boxShadow: 'none',
    border: 'none'
  },
  body: {
    px: '0',
    py: '2',
    w: '100%',
    border: 'none',
    alignItems: 'center',
    overflow: 'auto',
    display: 'flex'
  }
}
export const Card: ComponentMultiStyleConfig = {
  parts: ['container', 'body', 'header', 'footer'],
  baseStyle,
  sizes: {},
  variants: {
    homeCard: variantHomeCard
  },
  defaultProps: {
    size: 'md'
  }
}
