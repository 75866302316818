// eslint-disable-next-line custom-config/prevent-import
import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

export type IconProps = BoxProps

type CreateIconOptions = {
  displayName: string
  d?: string
  paths?: string[]
  sx?: BoxProps['sx']
}

export const Icon = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    ref={ref}
    as="svg"
    viewBox="0 0 1024 1024"
    flexShrink={0}
    display="inline-block"
    verticalAlign="middle"
    role="img"
    w="24px"
    h="24px"
    fill="currentColor"
    {...props}
  />
))

export const createIcon = ({ displayName, d, paths, sx }: CreateIconOptions) => {
  const Comp = forwardRef<IconProps, 'div'>((props, ref) => (
    <Icon aria-hidden ref={ref} {...props} sx={{ ...sx, ...props.sx }}>
      {d && <path d={d} />}
      {paths && paths.map((path, index) => <path d={path} key={index} />)}
    </Icon>
  ))

  if (process.env.NODE_ENV !== 'production') {
    Comp.displayName = displayName
  }

  return Comp
}
