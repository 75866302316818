import { createIcon } from './create-icon'

export const Keylines = createIcon({
  displayName: 'Keylines',
  paths: [
    'M546.133 863.676c-21.845 4.779-45.056 6.827-68.267 6.827-188.416 0-341.333-152.917-341.333-341.336 0-188.416 152.917-341.333 341.333-341.333 23.211 0 46.421 2.731 68.267 6.827 38.912-39.595 85.333-72.363 136.533-96.939-62.123-29.355-131.755-46.421-204.8-46.421-264.192 0-477.867 213.674-477.867 477.866 0 264.195 213.675 477.869 477.867 477.869 73.728 0 142.677-17.067 204.8-46.421-51.2-24.576-96.939-56.661-136.533-96.939z',
    'M819.2 529.166c0 111.275-53.931 208.899-136.533 271.021 39.595 30.037 85.333 52.565 136.533 62.805 84.651-86.016 136.533-204.12 136.533-334.509s-52.565-248.491-136.533-334.507c-50.517 10.24-96.256 32.768-136.533 62.805 82.603 62.123 136.533 160.427 136.533 271.019v1.365z',
    'M887.467 51.3c-73.728 0-142.677 17.066-204.8 46.421 51.2 24.576 96.939 56.661 136.533 96.939 21.845-4.779 45.056-6.827 68.267-6.827 188.416 0 341.333 152.917 341.333 341.333 0 188.419-152.917 341.336-341.333 341.336-23.211 0-46.421-2.731-68.267-6.827-38.912 39.595-85.333 72.363-136.533 96.939 62.123 29.355 131.072 46.421 204.8 46.421 264.192 0 477.867-213.675 477.867-477.869 0-264.192-213.675-477.866-477.867-477.866z',
    'M546.133 529.167c0-111.275 53.931-208.896 136.533-271.019-39.595-30.037-85.333-52.565-136.533-62.805-84.651 86.016-136.533 204.117-136.533 334.507s52.565 248.493 136.533 334.509c50.517-10.24 96.256-32.768 136.533-62.805-82.603-62.123-136.533-160.429-136.533-271.021v-1.365z',
    'M546.133 194.66c50.517 10.24 96.256 32.769 136.533 62.806 39.595-30.037 85.333-52.566 136.533-62.806-38.912-39.594-85.333-72.362-136.533-96.938-51.2 24.576-96.939 56.661-136.533 96.938z',
    'M819.2 863.676c-50.517-10.24-96.256-32.768-136.533-62.805-39.595 30.037-85.333 52.565-136.533 62.805 38.912 39.595 85.333 72.363 136.533 96.939 51.2-24.576 96.939-56.661 136.533-96.939z'
  ]
})
