import { BoxProps, ComponentWithAs } from '~/design_system'
import * as Icons from '~/icons'

export const CATEGORIES_ICON_MAP: { [key: string]: ComponentWithAs<'div', BoxProps> } = {
  'Billing Cycle': Icons.Processing,
  Contractor: Icons.Person,
  Expenses: Icons.Expense,
  Interview: Icons.Interview,
  'Interview Request': Icons.Interview,
  Invoice: Icons.Invoice,
  Job: Icons.Briefcase,
  'Job Applicant': Icons.Applied,
  'Job Offer': Icons.ApprovedDoc,
  'Statement Of Work': Icons.Sow,
  Timesheet: Icons.TimesheetAlt,
  Vendor: Icons.Vendors,
  'Worker Tracker': Icons.WorkerTracking,
  'Worker Provisioning': Icons.Checklists,
  'Work Order': Icons.WorkOrders
}

export enum UserTaskPageTab {
  MY_TASKS = 'my_tasks',
  ALL = 'all'
}
