import { createContext, useContext, useMemo, useEffect, useState, useRef, ReactNode, ComponentType } from 'react'
import useUuid from './useUuid'

type ModalComponentType<Result, ShowProps> = ComponentType<
  {
    isOpen: boolean
    onResult: (result: Result) => void
  } & ShowProps
>

type DefaultShowProps = Record<string, unknown> | undefined

type Entries = {
  [id: string]: Entry
}
type Entry = {
  isOpen: boolean
  showProps?: DefaultShowProps
}

type ResolveRefs = {
  [id: string]: (result: any) => void
}

const ModalContext = createContext<{ registerModal: any; unregisterModal: any; showModal: any; setStateById: any }>(
  undefined as any
)

export function ModalProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<Entries>({})
  const componentRefs = useRef<Record<string, ModalComponentType<any, unknown>>>({})
  const resolveRef = useRef<ResolveRefs>({})

  function setStateById(id: string, stateForId: Entry) {
    setState(state => ({
      ...state,
      [id]: { ...state[id], ...stateForId }
    }))
  }

  const value = useMemo(
    () => ({
      registerModal: (id: string, Component: ModalComponentType<any, unknown>) => {
        componentRefs.current[id] = Component
      },
      unregisterModal: (id: string) => {
        if (resolveRef.current[id]) {
          delete resolveRef.current[id]
        }
        if (componentRefs.current[id]) {
          delete componentRefs.current[id]
        }
        setState(state => {
          if (id in state) {
            const newState = { ...state }
            delete newState[id]
            return newState
          }
          return state
        })
      },
      showModal: (id: string, showProps?: DefaultShowProps) => {
        setStateById(id, { isOpen: true, showProps })
        return new Promise(resolve => {
          resolveRef.current[id] = resolve
        })
      },
      setStateById
    }),
    [setState]
  )

  return (
    <ModalContext.Provider value={value}>
      {children}
      {Object.entries(state)
        .filter(([_, { isOpen }]) => isOpen)
        .map(([id, { showProps }]) => {
          const Component = componentRefs.current[id]
          return (
            <Component
              key={id}
              {...(showProps ?? {})}
              isOpen
              onResult={(result: any) => {
                resolveRef.current[id](result)
                setStateById(id, {
                  isOpen: false,
                  showProps: {}
                })
              }}
            />
          )
        })}
    </ModalContext.Provider>
  )
}

export function useModal<Result, ShowProps = void>(
  Component: ModalComponentType<Result, ShowProps>
): {
  showModal: (showProps?: ShowProps) => Promise<Result>
} {
  const uuid = useUuid()
  const ctx = useContext(ModalContext)
  if (ctx === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  ctx.registerModal(uuid, Component)
  useEffect(() => {
    return () => {
      ctx.unregisterModal(uuid)
    }
  }, [])

  return { showModal: ctx.showModal.bind(null, uuid) }
}
