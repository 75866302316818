const baseStylePopper = {
  zIndex: 10
}

const baseStyleContent = {
  borderRadius: 'base',
  px: 6,
  py: 4,
  border: '1px solid',
  borderColor: 'gray.300',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)'
}

const baseStyleHeader = {
  color: 'gray.800',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: 'base',
  mb: 2,
  borderBottom: 'none',
  px: 0,
  py: 0
}

const baseStyleBody = {
  color: 'gray.800',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: 'base',
  px: 0,
  py: 0
}

const baseStyleFooter = {
  borderTop: 'none',
  mt: 4,
  p: 0
}

const baseStyleCloseButton = {
  position: 'absolute',
  w: '12px',
  h: '12px',
  fontSize: '14px',
  p: '0',
  top: 5,
  right: 6
}

const baseStyle = {
  popper: baseStylePopper,
  content: baseStyleContent,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
  closeButton: baseStyleCloseButton
}

const responsive = {
  popper: { width: 'unset', maxWidth: 'unset' }
}

const sizes = {
  sm: {
    popper: {
      maxWidth: '320px',
      width: '320px'
    }
  },
  md: {
    popper: {
      maxWidth: '512px',
      width: '512px'
    }
  }
}

export const Popover = {
  baseStyle,
  variants: {
    responsive
  },
  sizes,
  defaultProps: {
    size: 'sm'
  }
}
