import { omit, pick } from 'lodash'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import * as React from 'react'
import {
  chakra,
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
  forwardRef,
  Heading as ChakraHeading,
  HeadingProps,
  Icon,
  IconButton as ChakraIconButton,
  IconButtonProps,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  Select as ChakraSelect,
  SelectProps,
  Switch as ChakraSwitch,
  SwitchProps,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  Tooltip,
  TooltipProps,
  useMultiStyleConfig
} from '@chakra-ui/react'
import { isNextJsPage } from '../common/nextJsUtils'
import { CaretDown, Check, Minus, WarningO } from '../icons'
import {
  ExtendedFormControlContextProps,
  FormControlExtendedContextProvider,
  useFormControlExtendedContext
} from './components/form_controls/FormControlExtendedContext'
import { useFormAlertListContext } from './components/form_alert_list/FormAlertListContext'
import { mergeRefs } from '../common/reactUtils'

/* Chakra Components */
export * from '@chakra-ui/react'

/* Customized Chakra Components */

/**
 * Setting custom VNDLY icons for Checkbox
 */
const CustomIcon = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isIndeterminate, isChecked, ...rest } = props

  const icon = isIndeterminate ? Minus : Check
  return <Icon as={icon} w={6} h={6} {...rest} />
}

/**
 * VNDLY Input
 *
 * Input that allows users enter single valued data.
 *
 * Extended Chakra's props with `isWarned` prop to show warning message.
 */
export const Input = forwardRef<ChakraInputProps & ExtendedFormControlContextProps, 'input'>((props, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraInput ref={ref} {...props} {...getInputProps?.(props)} />
})

Input.displayName = 'Input'

// Input.id is used internally by Chakra when InputGroup clones the child Input
// without it, the styles are not cloned properly.
Input.id = 'Input'

/**
 * VNDLY Textarea
 *
 * Textarea is used to enter an amount of text that's longer than a single line
 * @see Docs https://chakra-ui.com/textarea
 *
 * Extended Chakra's props with `isWarned` prop to show warning message.
 */
export const Textarea = forwardRef<ChakraTextareaProps & ExtendedFormControlContextProps, 'textarea'>((props, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraTextarea ref={ref} {...props} {...getInputProps?.(props)} />
})

Textarea.displayName = 'Textarea'

/**
 * Checkbox
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 *
 * Extended with custom icon and `isWarned` prop to show warning message.
 *
 * @see Docs https://chakra-ui.com/checkbox
 */
export const Checkbox = forwardRef<CheckboxProps & ExtendedFormControlContextProps, 'input'>(({ ...props }, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraCheckbox data-checkbox icon={<CustomIcon />} ref={ref} {...props} {...getInputProps?.(props)} />
})

Checkbox.displayName = 'Checkbox'

/**
 * VNDLY Radio
 *
 * Component is used in forms when a user needs to select a single value from
 * several options.
 *
 * Extended with `isWarned` prop to show warning message.
 *
 * @see Docs https://chakra-ui.com/radio
 */
export const Radio = forwardRef<ChakraRadioProps & ExtendedFormControlContextProps, 'input'>((props, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraRadio ref={ref} {...props} {...getInputProps?.(props)} />
})

Radio.displayName = 'Radio'

/**
 * VNDLY Select Dropdown
 *
 * Using the default Chakra Select with custom icon. This component should be used instead of Chakra's Select
 * for dropdown inputs.
 * See http://storybook.beta.vndly.com/?path=/docs/foundations-forms-selectors--select for
 * examples and usage.
 *
 */
export const Select = forwardRef<SelectProps, 'select'>((props, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraSelect icon={<CaretDown />} ref={ref} {...props} {...getInputProps?.(props)} />
})

Select.displayName = 'Select'

/**
 * VNDLY Switch Dropdown
 *
 * Extended Chakra's Switch with `isWarned` prop to show warning message.
 */
export const Switch = forwardRef<SwitchProps & ExtendedFormControlContextProps, 'input'>((props, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <ChakraSwitch ref={ref} {...props} {...getInputProps?.(props)} />
})

Switch.displayName = 'Switch'

/**
 * Form Error Message
 *
 * Error messages help users diagnose and fix problems with a users input.  Error states should not
 * be activated until after a user has interacted with the form control.
 *
 * Meant to be used in conjunction with the `isInvalid` prop on the `FormControl` component.
 *
 * Using the default Chakra FormErrorMessage with custom icon and styling. This component should be used instead
 * of Chakra's FormErrorMessage for inputs.
 */
export const FormErrorMessage = forwardRef(({ children, ...props }: Omit<FormErrorMessageProps, 'textStyle'>, ref) => {
  const styles = useMultiStyleConfig('FormError', props)
  return (
    <ChakraFormErrorMessage {...props} ref={ref}>
      <WarningO __css={styles?.icon} aria-label="Form error message icon" />
      {children}
    </ChakraFormErrorMessage>
  )
})

FormErrorMessage.displayName = 'FormErrorMessage'

export type ExtendedFormControlProps = ChakraFormControlProps & ExtendedFormControlContextProps

export const FormControl = forwardRef<ExtendedFormControlProps, 'div'>(({ children, ...props }, ref) => {
  // If the field has an id, it will be registered with the form alert list
  const registerAlertRef = useFormAlertRegistrationRef(props)

  return (
    <ChakraFormControl ref={mergeRefs(registerAlertRef, ref)} {...props}>
      <FormControlExtendedContextProvider {...props}>{children}</FormControlExtendedContextProvider>
    </ChakraFormControl>
  )
})

FormControl.displayName = 'FormControl'

function useFormAlertRegistrationRef(props: ExtendedFormControlProps) {
  const ctx = useFormAlertListContext()
  const { id } = props

  React.useEffect(
    () => () => {
      if (id && ctx?.unregisterItem) {
        ctx?.unregisterItem(id)
      }
    },
    []
  )
  const refCb = React.useCallback(
    (el: HTMLDivElement) => {
      if (el && id && ctx?.registerItem) {
        ctx?.registerItem(id, el)
      }
    },
    [ctx?.registerItem]
  )
  return refCb
}

type CustomIconButtonProps = {
  showTooltip?: boolean
  tooltipProps?: TooltipProps
} & IconButtonProps

/**
 * Icon Button
 *
 * Custom Icon Button based on Chakra's Icon Button with an optional tooltip display. To display a tooltip with the
 * icon button set `showTooltip` and pass in any tooltipProps needed.
 */
export const IconButton = forwardRef(({ showTooltip = false, tooltipProps, ...props }: CustomIconButtonProps, ref) => {
  const variantStyles = {
    primary: { color: 'var(--vndly-color-container-light)' },
    deletePrimary: { color: 'var(--vndly-color-container-light)' },
    deleteSecondary: { color: 'var(--vndly-color-button-delete-primary)' }
  }

  const button = <ChakraIconButton sx={variantStyles} ref={ref} {...props} />

  if (showTooltip) {
    return (
      <Tooltip label={props['aria-label']} {...tooltipProps}>
        <chakra.span display={props.display}>{button}</chakra.span>
      </Tooltip>
    )
  }

  return button
})

IconButton.displayName = 'IconButton'

// Overriding the type of `href` because Next and Chakra have slightly differing
//  opinions on what shape they should be but both agree on strings
export type LinkProps = Omit<NextLinkProps, 'href' | 'color'> &
  Omit<ChakraLinkProps, 'href'> & { href?: string; avoidNextLink?: boolean }

// These are props that are common to both next/link and Chakra's Link
const commonLinkProps = ['href']

// These are all of the props that next/link accepts
//  We list them out here so we can pick them off and only pass these to next/link
const nextLinkProps = ['as', 'replace', 'scroll', 'shallow', 'passHref', 'prefetch', 'locale', 'legacyBehavior']

export const Link = forwardRef(({ children, avoidNextLink, ...props }: LinkProps, ref) => {
  const nextProps = pick(props, nextLinkProps.concat(commonLinkProps))
  const chakraProps = omit(props, nextLinkProps)

  if (isNextJsPage() && props.href && NextLink && !avoidNextLink) {
    return (
      <NextLink legacyBehavior {...(nextProps as NextLinkProps)} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <ChakraLink {...chakraProps} ref={ref}>
          {children}
        </ChakraLink>
      </NextLink>
    )
  }

  return (
    <ChakraLink {...chakraProps} ref={ref}>
      {children}
    </ChakraLink>
  )
})
Link.displayName = 'Link'

// Exporting the actual <Link> from Chakra because there are some cases that need it
//   like when using the `as` property with Reach Router
export { ChakraLink }

export const Heading = forwardRef((props: HeadingProps, ref) => {
  const { textStyle } = props

  // When one of the heading textStyle props are set on a Heading component, use a
  // Box component instead to make sure the custom text style we have defined in our
  // theme is applied.
  if (textStyle === 'heading1') {
    return <Box as="h1" ref={ref} className="chakra-heading" {...props} />
  }
  if (textStyle === 'heading2') {
    return <Box as="h2" ref={ref} className="chakra-heading" {...props} />
  }
  if (textStyle === 'heading3' || textStyle === 'heading3Semi') {
    return <Box as="h3" ref={ref} className="chakra-heading" {...props} />
  }
  if (textStyle === 'sectionHeading') {
    return <Box as="h4" ref={ref} className="chakra-heading" {...props} />
  }

  // When any other textStyle prop is set on a Heading component, use the Box component to apply
  // that given text style, otherwise use the Chakra Heading component as it is
  return textStyle ? <Box ref={ref} className="chakra-heading" {...props} /> : <ChakraHeading ref={ref} {...props} />
})
