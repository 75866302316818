import { useIntl } from '../components/IntlProviders.context'

/**
 * useFormatCurrencyLocale
 * This hook will return a currency formatting function based on the user's locale.
 */
export const useFormatCurrencyLocale = () => {
  const intl = useIntl()

  /**
   * Formats currency based on the user's locale. By default, the currency code
   * will be displayed otherwise the currency will be formatted based on
   * the locale's ISO (4217) standard
   * @param amount (number | string) Number to format as a currency
   * @param opts { currency: string } The currency code
   * @returns {string} A string representing the formatted currency
   */
  function formatCurrencyLocale(amount, { currency }) {
    let n = Number(amount)
    if (amount === undefined || Number.isNaN(n)) {
      n = 0
    }
    return new Intl.NumberFormat(intl.locale, { style: 'currency', currency, currencyDisplay: 'code' }).format(n)
  }

  return formatCurrencyLocale
}

/**
 * Formats a currency to two decimal places.
 *
 * @deprecated Use useFormatCurrencyLocale instead of this util function. Please reference our Storybook page on currency.
 */
export const formatCurrency = x => {
  const n = Number(x)
  if (x === undefined || Number.isNaN(n)) {
    return formatCurrency(0)
  }
  return n.toFixed(2)
}

/**
 * @deprecated Only localized formats are now recommended. Use formatCurrencyLocale instead of
 * this util function. Please reference our Storybook page on currency:
 * http://storybook.beta.vndly.com/?path=/docs/components-inputs-currency--docs
 */
export const currencyCodeToSymbol = currency => {
  /*
  params: currency: a string on length 3 that represents currency code. Example: USD, INR, EUR
  returns: string of len 1 - the symbol of the given currency code. Example: $, ₹, €

  Note: We should strongly consider deprecating this method. The code is an ISO standard (4217) and is locale agnostic
   */
  if (currency)
    return new Intl.NumberFormat('en-US', { style: 'currency', currency })
      .formatToParts()
      .find(({ type }) => type === 'currency').value
  return null
}

export const getHumanReadableFloat = number => {
  /*
  params: number: any floating point value
  returns: a list of size 2.
           - 0th index has the minified value of the input number
           - 1st index will contain a string that represents units/orders of magnitude {k: 1000, M: million, B: billion}
   */
  if (number === 0 || number == null || Number.isNaN(number)) return [0]

  const i = Math.floor(Math.log(number) / Math.log(1000))
  let readableNumber = number / 1000 ** i

  // true if integer part of the readable number != fractional value of the readable number (10 != 10.12)
  // Fix fractional value to 2 because we consider 10.00123 = 10.00 = 10
  const requiresDecimal = Math.round(readableNumber) !== Number(readableNumber.toFixed(2))
  readableNumber = readableNumber.toFixed(requiresDecimal ? 2 : 0)

  return [readableNumber, ['', 'K', 'M', 'B', 'T'][i]]
}
