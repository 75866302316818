import { IntlShape } from '@formatjs/intl'
import { isEmpty, isObjectLike, mapValues } from 'lodash'
import { isValidElement, ReactElement } from 'react'
import { childrenToString } from '../childrenUtils'
import { Msg, MsgProps } from '../localizationUtils'

export type Expression = any[]
export type Locale = string
export type TimeZone = string
export type MessageKey = string
export type Catalog = (locale: Locale, messageKey: MessageKey) => Expression
export type Context = { [key: string]: any }

export interface ResultWrapper<T> {
  empty(): T
  value(value: any): T
  reduce(values: any[]): T
  element(value: ReactElement, intl: IntlShape, catalog: Catalog, wrapper: ResultWrapper<T>, depth: number): T
}

class StringWrapper implements ResultWrapper<string> {
  empty() {
    return ''
  }

  value(value: any) {
    return typeof value === 'string' ? value : value?.toString() ?? ''
  }

  reduce(values: any[]) {
    return values?.join?.('') ?? ''
  }

  element(value: ReactElement, intl: IntlShape, catalog: Catalog, wrapper: ResultWrapper<string>, depth: number) {
    if (value.type === Msg) {
      const { id: messageKey, ...context } = value.props as MsgProps
      const subExp = catalog(intl.locale, messageKey!.toString()) // fix types
      return dispatch(subExp, intl, catalog, context, wrapper, depth + 1)
    }
    return childrenToString(value)
  }
}
const stringWrapper = new StringWrapper()

export function executeMessage(messageKey: MessageKey, intl: IntlShape, catalog: Catalog, context: Context): string {
  const exp = catalog(intl.locale, messageKey)
  return executeExpression(exp, intl, catalog, context)
}

export function executeExpression(exp: Expression, intl: IntlShape, catalog: Catalog, context: Context): string {
  return dispatch(exp, intl, catalog, context, stringWrapper, 0)
}

enum FType {
  join = 'j',
  string = 's',
  var = 'v',
  number = 'n',
  datetime = 'z',
  date = 'd',
  time = 't',
  list = 'l',
  plural = 'p',
  select = 'c',
  exists = 'e',
  truthy = 'u',
  catalog = 'g',
  context_var = 'x',
  context_literal = 'y'
}

export function dispatch<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  if (depth > 200) {
    return wrapper.value('Render failure due to recursive depth limit')
  }

  switch (exp?.[0]) {
    case FType.join:
      return dispatchJoin(exp, intl, catalog, context, wrapper, depth)
    case FType.string:
      return dispatchString(exp, wrapper)
    case FType.var:
      return dispatchVar(exp, intl, catalog, context, wrapper, depth)
    case FType.number:
      return dispatchNumber(exp, intl, context, wrapper)
    case FType.datetime:
      return dispatchDatetime(exp, intl, context, wrapper)
    case FType.date:
      return dispatchDate(exp, intl, context, wrapper)
    case FType.time:
      return dispatchTime(exp, intl, context, wrapper)
    case FType.list:
      return dispatchList(exp, intl, catalog, context, wrapper, depth)
    case FType.plural:
      return dispatchPlural(exp, intl, catalog, context, wrapper, depth)
    case FType.select:
      return dispatchSelect(exp, intl, catalog, context, wrapper, depth)
    case FType.exists:
      return dispatchExists(exp, intl, catalog, context, wrapper, depth)
    case FType.truthy:
      return dispatchTruthy(exp, intl, catalog, context, wrapper, depth)
    case FType.catalog:
      return dispatchCatalog(exp, intl, catalog, context, wrapper, depth)
    default:
      return wrapper.empty()
  }
}

function dispatchJoin<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  return wrapper.reduce(exp[1].map((subExp: Expression) => dispatch(subExp, intl, catalog, context, wrapper, depth)))
}

function dispatchString<T>(exp: Expression, wrapper: ResultWrapper<T>): T {
  return wrapper.value(exp[1])
}

function dispatchVar<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const value = context[exp[1]]
  return formatByType(value, intl, catalog, wrapper, depth)
}

function dispatchNumber<T>(exp: Expression, intl: IntlShape, context: Context, wrapper: ResultWrapper<T>): T {
  const value = context[exp[1]]
  const formatName = exp[2]
  if (typeof value !== 'number') {
    return wrapper.empty()
  }
  return formatNumber(value, intl, formatName, wrapper)
}

function dispatchDatetime<T>(exp: Expression, intl: IntlShape, context: Context, wrapper: ResultWrapper<T>): T {
  const value = context[exp[1]]
  const formatName = exp[2] || 'short'
  if (!(value instanceof Date)) {
    return wrapper.empty()
  }
  return formatDatetime(value, intl, formatName, wrapper)
}

function dispatchDate<T>(exp: Expression, intl: IntlShape, context: Context, wrapper: ResultWrapper<T>): T {
  const value = context[exp[1]]
  const formatName = exp[2] || 'medium'
  if (!(value instanceof Date)) {
    return wrapper.empty()
  }
  return formatDate(value, intl, formatName, wrapper)
}

function dispatchTime<T>(exp: Expression, intl: IntlShape, context: Context, wrapper: ResultWrapper<T>): T {
  const value = context[exp[1]]
  const formatName = exp[2] || 'medium'
  if (!(value instanceof Date)) {
    return wrapper.empty()
  }
  return formatTime(value, intl, formatName, wrapper)
}

function dispatchList<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const values = context[exp[1]]
  if (!(values instanceof Array)) {
    return wrapper.empty()
  }
  const listType = exp[2] || 'and'
  const listWidth = exp[3] || 'wide'
  return formatList(values, intl, catalog, wrapper, depth, listType, listWidth)
}

function dispatchPlural<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  let value = context[exp[1]]
  const ruleMapping = exp[2]

  if (typeof value === 'boolean') {
    value = +value
  }

  const exactRule = `=${value}`
  let subExp = ruleMapping[exactRule]
  if (subExp === undefined) {
    const rule = intl.formatPlural(value)
    subExp = ruleMapping[rule]
  }
  if (subExp === undefined) {
    subExp = ruleMapping.other
  }
  if (subExp !== undefined) {
    return dispatch(subExp, intl, catalog, context, wrapper, depth)
  }
  return wrapper.empty()
}

function dispatchSelect<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const value = context[exp[1]]
  const valueMapping = exp[2]
  let subExp = valueMapping[value]
  if (subExp === undefined) {
    subExp = valueMapping.other
  }
  if (subExp !== undefined) {
    return dispatch(subExp, intl, catalog, context, wrapper, depth)
  }
  return wrapper.empty()
}

function dispatchExists<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const value = context[exp[1]]
  const valueMapping = exp[2]
  const existsValue = value === undefined || value === null ? 'f' : 't'
  const subExp = valueMapping[existsValue]
  if (subExp !== undefined) {
    return dispatch(subExp, intl, catalog, context, wrapper, depth)
  }
  return wrapper.empty()
}

function dispatchTruthy<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const value = context[exp[1]]
  const valueMapping = exp[2]
  const existsValue =
    value === undefined ||
    value === null ||
    value === false ||
    value === '' ||
    (Array.isArray(value) && isEmpty(value)) ||
    (isObjectLike(value) && isEmpty(value))
      ? 'f'
      : 't'
  const subExp = valueMapping[existsValue]
  if (subExp !== undefined) {
    return dispatch(subExp, intl, catalog, context, wrapper, depth)
  }
  return wrapper.empty()
}

function dispatchCatalog<T>(
  exp: Expression,
  intl: IntlShape,
  catalog: Catalog,
  context: Context,
  wrapper: ResultWrapper<T>,
  depth: number
): T {
  const key = exp[1]
  const contextMapping = exp[2]
  const subExp = catalog(intl.locale, key)
  if (subExp !== undefined) {
    const subContext = mapValues(contextMapping, v => {
      const lookupType = v[0]
      const lookupValue = v[1]
      switch (lookupType) {
        case 'x':
          return context[lookupValue]
        case 'y':
          return lookupValue
        default:
          return undefined
      }
    })
    return dispatch(subExp, intl, catalog, subContext, wrapper, depth + 1)
  }
  return wrapper.empty()
}

type NumberFormatName = 'integer' | 'percent' | undefined

function formatNumber<T>(
  value: number | bigint,
  intl: IntlShape,
  formatName: NumberFormatName = undefined,
  wrapper: ResultWrapper<T>
): T {
  if (formatName === 'integer') {
    if (typeof value === 'number') {
      // It's important to Math.floor here because "integer" display should cut off any decimals, not round them
      return wrapper.value(intl.formatNumber(Math.floor(value), { style: 'decimal', maximumFractionDigits: 0 }))
    }
    return wrapper.value(intl.formatNumber(value, { style: 'decimal', maximumFractionDigits: 0 }))
  }
  if (formatName === 'percent') {
    return wrapper.value(intl.formatNumber(value, { style: 'unit', unit: 'percent', maximumFractionDigits: 17 }))
  }
  return wrapper.value(intl.formatNumber(value, { style: 'decimal', maximumFractionDigits: 17 }))
}

type DateTimeLike = Date
type DateTimeFormatName = 'short' | 'long' | 'medium' | 'full' | undefined

function formatDatetime<T>(
  value: DateTimeLike,
  intl: IntlShape,
  formatName: DateTimeFormatName = 'short',
  wrapper: ResultWrapper<T>
): T {
  return wrapper.value(intl.formatDate(value, { dateStyle: formatName, timeStyle: formatName }))
}

function formatDate<T>(
  value: DateTimeLike,
  intl: IntlShape,
  formatName: DateTimeFormatName = 'medium',
  wrapper: ResultWrapper<T>
): T {
  return wrapper.value(intl.formatDate(value, { dateStyle: formatName }))
}

function formatTime<T>(
  value: DateTimeLike,
  intl: IntlShape,
  formatName: DateTimeFormatName = 'medium',
  wrapper: ResultWrapper<T>
): T {
  return wrapper.value(intl.formatDate(value, { timeStyle: formatName }))
}

type ListTypeKey = 'and' | 'or' | 'unit'
type ListType = 'conjunction' | 'disjunction' | 'unit'
type ListStyleKey = 'wide' | 'short' | 'narrow'
type ListStyle = 'short' | 'long' | 'narrow'

const listTypes: { [key in ListTypeKey]: ListType } = {
  and: 'conjunction',
  or: 'disjunction',
  unit: 'unit'
}

const listWidths: { [key in ListStyleKey]: ListStyle } = {
  wide: 'long',
  short: 'short',
  narrow: 'narrow'
}

function formatList<T>(
  values: Array<any>,
  intl: IntlShape,
  catalog: Catalog,
  wrapper: ResultWrapper<T>,
  depth: number,
  listType: ListTypeKey = 'and',
  listWidth: ListStyleKey = 'wide'
): T {
  const formattedValues = values.map(v => formatByType(v, intl, catalog, wrapper, depth))
  // After the upgrade of @formatjs/intl and react-intl it chokes on
  //   typing here on `Type 'string' is not assignable to type 'T'.` while
  //   in reality it works just fine (tested via unit tests, etc).  After hours
  //   of trying to fix it I decided to just ignore the error.  Here's the commit
  //   in their repo with the change: https://github.com/formatjs/formatjs/commit/0d03bb66123cb49fbd1c7d27908979bc4521b41f#diff-e6ad9f85cb15fd4857c8ece1705ed9e178d82c320015bdb7467c1a4980cb96f0L169
  // @ts-ignore
  return intl.formatList(formattedValues, { type: listTypes[listType], style: listWidths[listWidth] })
}

function formatByType<T>(value: any, intl: IntlShape, catalog: Catalog, wrapper: ResultWrapper<T>, depth: number): T {
  switch (typeof value) {
    case 'string':
      return wrapper.value(value)
    case 'number':
      return formatNumber(value, intl, undefined, wrapper)
    case 'object':
      if (value instanceof Date) {
        return formatDatetime(value, intl, undefined, wrapper)
      }
      if (Array.isArray(value)) {
        return formatList(value, intl, catalog, wrapper, depth)
      }
      if (isValidElement(value)) {
        return wrapper.element(value, intl, catalog, wrapper, depth)
      }
      return wrapper.value(value)
    default:
      return wrapper.value(value)
  }
}
