import { Global, css } from '@emotion/react'
import workdayColors from '@workday/canvas-colors-web'
import vndlyColors from '../colors'

/* Global CSS vars for Vndly theme used in _app.js and Chakra Provider for the ThemeSelector */
export default () => (
  <Global
    styles={css`
      /* THEMING VARIABLE DEFINITIONS */
      /**
      New VNDLY WORKDAY Theme
      These are the global design tokens used throughout the application. They are aligned with the
      tokens defined in the design system.

      Try to use these tokens where possible.
      **/

      :root {
        /* Colors */
        /* Navigation Menu */
        /* TODO: Remove this after Rebrand FF is removed */
        --vndly-color-navigation-menu-bar-background: ${workdayColors.frenchVanilla100};

        /* Feedback includes alerts (banners, toasts and modals), tooltips, badges */
        --vndly-color-feedback-icon-info: ${vndlyColors.blue[600]};
        --vndly-color-feedback-background-info: ${workdayColors.blueberry100};
        --vndly-color-feedback-success: ${vndlyColors.brand[700]};
        --vndly-color-feedback-icon-success: ${vndlyColors.brand[700]};
        --vndly-color-feedback-background-success: ${vndlyColors.brand[100]};
        --vndly-color-feedback-warning: ${vndlyColors.red[700]};
        --vndly-color-feedback-icon-warning: ${vndlyColors.red[700]};
        --vndly-color-feedback-background-warning: ${vndlyColors.yellow[200]};
        --vndly-color-feedback-danger: ${vndlyColors.red[600]};
        --vndly-color-feedback-icon-danger: ${vndlyColors.red[600]};
        --vndly-color-feedback-background-danger: ${vndlyColors.red[100]};
        --vndly-color-feedback-complete: white;
        --vndly-color-feedback-background-complete: ${vndlyColors.gray[500]};
        --vndly-color-feedback-incomplete: ${vndlyColors.gray[500]};
        --vndly-color-feedback-background-incomplete: ${vndlyColors.gray[200]};

        /* Button includes action buttons with either a primary, secondary or delete variant */
        --vndly-color-button: ${workdayColors.frenchVanilla100};
        --vndly-color-button-background-primary: ${workdayColors.blueberry400};
        --vndly-color-button-background-primary-hover: ${workdayColors.blueberry500};
        --vndly-color-button-background-primary-active: ${workdayColors.blueberry600};
        --vndly-color-button-background-primary-hover-disabled: ${workdayColors.blueberry400};
        --vndly-color-button-secondary: ${workdayColors.blackPepper400};
        --vndly-color-button-secondary-hover: ${workdayColors.frenchVanilla100};
        --vndly-color-button-tertiary: ${workdayColors.blueberry400};
        --vndly-color-button-tertiary-hover: ${workdayColors.blueberry500};
        --vndly-color-button-tertiary-background: transparent;
        --vndly-color-button-tertiary-background-hover: ${workdayColors.soap200};
        --vndly-color-button-background-secondary: ${workdayColors.frenchVanilla100};
        --vndly-color-button-background-secondary-hover: ${workdayColors.blackPepper400};
        --vndly-color-button-border-secondary: ${workdayColors.blackPepper400};
        --vndly-color-button-background-delete-primary: ${workdayColors.cinnamon500};
        --vndly-color-button-background-delete-primary-hover: ${workdayColors.cinnamon600};
        --vndly-color-button-background-delete-primary-hover-disabled: ${workdayColors.cinnamon200};
        --vndly-color-button-delete-secondary: ${workdayColors.cinnamon500};
        --vndly-color-button-delete-secondary-hover: ${workdayColors.frenchVanilla100};
        --vndly-color-button-background-delete-secondary: ${workdayColors.frenchVanilla100};
        --vndly-color-button-background-delete-secondary-hover: ${workdayColors.cinnamon600};
        --vndly-color-button-border-delete-secondary: ${workdayColors.blackPepper400};
        --vndly-color-button-border-delete-secondary-hover: ${workdayColors.cinnamon600};
        --vndly-color-button-delete-secondary-hover-disabled: ${workdayColors.blackPepper400};

        /* Link includes button or inline links */
        --vndly-color-link: ${workdayColors.blueberry400};
        --vndly-color-link-hover: ${workdayColors.blueberry500};
        --vndly-color-link-background: transparent;
        --vndly-color-link-background-hover: ${workdayColors.soap200};
        --vndly-color-link-inline-light: ${workdayColors.licorice300};
        --vndly-color-link-inline-light-hover: ${workdayColors.licorice400};
        --vndly-color-link-inline-dark: ${vndlyColors.gray[800]};
        --vndly-color-link-inline-dark-hover: ${vndlyColors.gray[900]};
        --vndly-color-link-delete: ${vndlyColors.red[500]};
        --vndly-color-link-delete-hover: ${vndlyColors.red[600]};
        --vndly-color-link-background-delete: transparent;
        --vndly-color-link-background-delete-hover: ${vndlyColors.red[50]};

        /* Tab includes nav and filter tabs */
        --vndly-color-tab-nav-text: ${workdayColors.licorice300};
        --vndly-color-tab-nav-text-hover: ${workdayColors.blackPepper400};
        --vndly-color-tab-nav-text-selected: ${workdayColors.blueberry400};
        --vndly-color-tab-nav-background: transparent;
        --vndly-color-tab-nav-background-hover: ${workdayColors.soap200};
        --vndly-color-tab-nav-border-hover: transparent;
        --vndly-color-tab-nav-border-selected: ${workdayColors.blueberry400};
        --vndly-color-tab-filter-text: ${vndlyColors.gray[500]};
        --vndly-color-tab-filter-background-hover: ${vndlyColors.gray[200]};
        --vndly-color-tab-filter-background-checked: ${vndlyColors.gray[200]};

        /* Input includes text and selector form inputs */
        --vndly-color-input-text-hint: ${vndlyColors.gray[500]};
        --vndly-color-input-text-label: ${workdayColors.blackPepper300};
        --vndly-color-input-text-required: ${vndlyColors.red[500]};
        --vndly-color-input-text-placeholder: ${vndlyColors.gray[400]};
        --vndly-color-input-icon: white;
        --vndly-color-input-icon-checked: white;
        --vndly-color-input-icon-indeterminate: white;
        --vndly-color-input-background: white;
        --vndly-color-input-background-checked: ${workdayColors.blueberry400};
        --vndly-color-input-background-checked-disabled: ${workdayColors.blueberry200};
        --vndly-color-input-background-checked-invalid: ${workdayColors.cinnamon500};
        --vndly-color-input-background-checked-warned: ${workdayColors.cantaloupe400};
        --vndly-color-input-background-disabled: ${workdayColors.soap100};
        --vndly-color-input-background-indeterminate: ${workdayColors.blueberry400};
        --vndly-color-input-background-indeterminate-disabled: ${workdayColors.blueberry200};
        --vndly-color-input-border: ${workdayColors.licorice200};
        --vndly-color-input-border-hover: ${workdayColors.licorice500};
        --vndly-color-input-border-focused: ${workdayColors.blueberry400};
        --vndly-color-input-border-active: ${workdayColors.licorice500};
        --vndly-color-input-border-checked: ${workdayColors.blueberry400};
        --vndly-color-input-border-checked-disabled: ${workdayColors.blueberry200};
        --vndly-color-input-border-invalid: ${workdayColors.cinnamon500};
        --vndly-color-input-border-warned: ${workdayColors.cantaloupe400};
        --vndly-color-input-border-disabled: ${workdayColors.soap600};
        --vndly-color-input-border-indeterminate: ${workdayColors.blueberry400};
        --vndly-color-input-border-indeterminate-disabled: ${workdayColors.blueberry200};
        --vndly-color-input-shadow-hover: ${workdayColors.soap200};
        --vndly-color-input-shadow-focused: ${workdayColors.blueberry400};
        --vndly-color-input-shadow-invalid: ${workdayColors.cinnamon500};
        --vndly-color-input-shadow-invalid-focused: ${workdayColors.cinnamon600};
        --vndly-color-input-shadow-warned: ${workdayColors.cantaloupe400};
        --vndly-color-input-shadow-warned-focused: ${workdayColors.cantaloupe500};

        /* Container includes cards, drawers, popovers, modals and any general container properties such as backgrounds and text */
        --vndly-color-container-light: white;
        --vndly-color-container-dark: ${vndlyColors.gray[800]};
        --vndly-color-container-subdued: ${vndlyColors.gray[500]};
        --vndly-color-container-background-light: white;
        --vndly-color-container-background-dark: ${vndlyColors.gray[800]};
        --vndly-color-container-background-subdued: ${vndlyColors.gray[100]};
        --vndly-color-container-background-overlay: rgba(0, 0, 0, 0.3);
        --vndly-color-container-border: ${vndlyColors.gray[300]};
        --vndly-color-container-border-focused: ${vndlyColors.blue[500]};
        --vndly-color-container-icon: ${vndlyColors.gray[600]};
        --vndly-color-container-icon-hover: ${vndlyColors.gray[900]};
        --vndly-color-container-icon-info: ${vndlyColors.blue[500]};
        --vndly-color-container-background-icon-info: ${vndlyColors.blue[50]};
        --vndly-color-container-icon-success: ${vndlyColors.brand[600]};
        --vndly-color-container-background-icon-success: ${vndlyColors.brand[100]};
        --vndly-color-container-icon-warning: ${vndlyColors.yellow[400]};
        --vndly-color-container-background-icon-warning: ${vndlyColors.yellow[50]};
        --vndly-color-container-icon-danger: ${vndlyColors.red[500]};
        --vndly-color-container-background-icon-danger: ${vndlyColors.red[50]};

        /* Typography */
        --vndly-font-family: Roboto, system-ui, sans-serif;
        --vndly-font-family-mono: 'Roboto Mono', 'Courier New', Courier, monospace;

        --vndly-font-weight-light: 300;
        --vndly-font-weight-normal: 400;
        --vndly-font-weight-semibold: 500;
        --vndly-font-weight-bold: 700;

        --vndly-font-size-sm: 0.875rem;
        --vndly-font-size-md: 1rem;
        --vndly-font-size-lg: 1.125rem;
        --vndly-font-size-xl: 1.25rem;
        --vndly-font-size-2xl: 1.5rem;
        --vndly-font-size-3xl: 1.875rem;
        --vndly-font-size-4xl: 2.25rem;

        --vndly-font-line-height-none: 1;
        --vndly-font-line-height-sm: 1.25;
        --vndly-font-line-height-md: 1.5; /* base */

        /** Note: Variables defined below do not need to be used at this time. Refer to the Chakra global theme props instead **/
        /* Depth (box-shadow) */
        --vndly-depth-flat: none;
        --vndly-depth-raised: 0 1px 2px rgba(0, 0, 0, 0.25);
        --vndly-depth-overlay: 0 4px 8px rgba(0, 0, 0, 0.25);
        --vndly-depth-popout: 0 8px 16px rgba(0, 0, 0, 0.25);

        /* Spacing */
        --vndly-space-zero: 0;
        --vndly-space-sm: 0.5rem;
        --vndly-space-md: 1rem;
        --vndly-space-lg: 1.5rem;
        --vndly-space-xl: 2rem;

        /* Shape (border-radius) */
        --vndly-shape-zero: 0;
        --vndly-shape-base: 0.25rem;
        --vndly-shape-full: 9999px;

        /* Breakpoints */
        --vndly-breakpoint-sm: 600px;
        --vndly-breakpoint-md: 900px;
        --vndly-breakpoint-lg: 1200px;
        --vndly-breakpoint-xl: 1500px;
      }
    `}
  />
)
