const baseStyle = {
  container: {
    borderRadius: 'base'
  },
  title: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base'
  },
  description: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base'
  },
  icon: {
    mr: '3'
  }
}

const variantInfo = {
  container: { bg: 'var(--vndly-color-feedback-background-info)' },
  icon: { color: 'blue.600' }
}

const variantSuccess = {
  container: { bg: 'brand.100' },
  icon: { color: 'brand.700' }
}

const variantWarning = {
  container: { bg: 'yellow.200' },
  icon: { color: 'red.700' }
}

const variantDanger = {
  container: { bg: 'red.100' },
  icon: { color: 'red.600' }
}

const variantActionBar = {
  container: {
    bg: 'var(--vndly-color-feedback-background-info)',
    color: 'blackPepper400'
  }
}

const variantSystem = props => ({
  ...Alert.variants[props.status],
  container: {
    ...Alert.variants[props.status].container,
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: -1,
    rounded: 0,
    justifyContent: 'center',
    '> div': {
      flex: 'initial'
    }
  }
})

const variantWarningList = {
  container: {
    bg: 'none',
    borderLeft: '6px solid var(--vndly-color-input-border-warned)',
    borderRadius: 0
  },
  icon: { color: 'var(--vndly-color-input-border-warned)' }
}

const variantErrorList = {
  container: {
    bg: 'none',
    borderLeft: '6px solid var(--chakra-colors-red-600)',
    borderRadius: 0
  },
  icon: { color: 'red.600' }
}

export const Alert = {
  baseStyle,
  variants: {
    info: variantInfo,
    success: variantSuccess,
    warning: variantWarning,
    error: variantDanger,
    actionBar: variantActionBar,
    system: variantSystem,
    'list-warning': variantWarningList,
    'list-error': variantErrorList
  }
}
