import { isFunction } from 'lodash'
import { omitBy } from 'lodash/fp'
import { cloneElement, isValidElement, MutableRefObject, Ref, RefObject, useMemo } from 'react'

export const componentAcceptsRef = (Component: any) => Component?.$$typeof === Symbol.for('react.forward_ref')

const PROVIDER_FOUND = true
const PROVIDER_NOT_FOUND = false

/**
 * This function take a hook that calls a context provider and
 * returns a hook that returns undefined if the provider is missing, while also
 * silencing the error.
 */
export function ignoreProviderMissingError<
  THookFn extends (...args: any) => any,
  TFallback extends ReturnType<THookFn>
>(
  hookFn: THookFn,
  {
    ignoreMessage,
    errorMessage,
    fallback,
    ignore = true
  }: { ignoreMessage?: string; errorMessage?: string; ignore?: boolean; fallback?: TFallback } = {}
) {
  return (
    ...args: Parameters<THookFn>
  ):
    | (typeof fallback extends undefined
        ? readonly [value: undefined, providerFound: false]
        : readonly [value: TFallback, providerFound: false])
    | readonly [value: ReturnType<THookFn>, providerFound: true] => {
    try {
      const hookReturn: ReturnType<THookFn> = hookFn(...args)
      return [hookReturn, PROVIDER_FOUND] as const
    } catch (e: any) {
      if (
        ignore &&
        // react-tracked has this message
        (e.toString().includes('Please use <Provider>') ||
          // Constate has this message
          e.toString().includes('Component must be wrapped with Provider'))
      ) {
        if (ignoreMessage && process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.warn(ignoreMessage)
        }
        if (fallback) {
          return [fallback, PROVIDER_NOT_FOUND] as const
        }
        // @ts-ignore
        return [undefined, PROVIDER_NOT_FOUND] as const
      }
      if (errorMessage) e.message = `${e.message} ${errorMessage}`
      if (process.env.NODE_ENV !== 'production') throw e
      // eslint-disable-next-line no-console
      else console.error(e)
      // @ts-ignore
      return [undefined, PROVIDER_NOT_FOUND] as const
    }
  }
}

/**
 * Assigns a value to a ref function or object
 *
 * @param ref the ref to assign to
 * @param value the value
 */
function assignRef<T = any>(ref: ReactRef<T> | undefined, value: T) {
  if (ref == null) return

  if (isFunction(ref)) {
    ref(value)
    return
  }

  try {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    ref.current = value
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`)
  }
}

type ReactRef<T> = Ref<T> | RefObject<T> | MutableRefObject<T>

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign to value to
 */
export function mergeRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  return (node: T | null) => {
    refs.forEach(ref => assignRef(ref, node))
  }
}

export function isWithinReactComponentOrHook() {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(() => null, [])
  } catch (e) {
    return false
  }
  return true
}

const omitUndefined = omitBy((value: any) => value === undefined)

export function cloneSafely(element: any, props: Record<string, any>, optionalProps: Record<string, any> = {}) {
  if (isValidElement(element))
    return cloneElement(element, {
      ...props,
      ...omitUndefined(optionalProps)
    })
  return element
}
