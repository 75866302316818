import React, { ReactNode, useEffect, useState } from 'react'
import constate from 'constate'
import {
  forwardRef,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalHeaderProps,
  ModalFooter,
  ModalFooterProps,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  Grid,
  Box,
  Flex,
  Heading,
  Divider,
  Button,
  ButtonProps,
  Text
} from '../../chakra'
import { Stepper, Step, ButtonGroupActions } from '../..'
import { ArrowLeft, ArrowRight } from '../../../icons'
import { Msg, useMsg } from '../../../common/localizationUtils'

/**
 * Modal dialog box
 *
 * The modal dialog box is the foreground layer that sits in front of both the main content and overlay. It is meant to contain
 * the content that needs to be attended to before the user can return to the main page content. Can be used to display important
 * messaging that requires user attention. It is also useful for situations where a short form (fewer than 5 input fields) is
 * needed without having to navigate to a separate form page.
 *
 * The ModalDialog is the wrapper component around ModalDialogHeader, ModalDialogBody and ModalDialogFooter
 */
type ModalDialogProps = ModalProps & {
  testId?: string
  contentProps?: ModalContentProps
  hideCloseButton?: boolean
}
export const ModalDialog = forwardRef(
  ({ children, testId, contentProps, hideCloseButton = false, ...props }: ModalDialogProps, ref) => (
    <Modal size="xl" scrollBehavior="inside" {...props}>
      <ModalOverlay />
      <ModalContent top={5} ref={ref} data-testid={testId} {...contentProps}>
        {!hideCloseButton && <ModalCloseButton top={6} right={6} />}
        {children}
      </ModalContent>
    </Modal>
  )
)

ModalDialog.displayName = 'ModalDialog'

/**
 * Modal dialog header
 *
 * This component will add a title to the heading of the modal dialog box.
 *
 * ModalDialogHeader must be within the ModalDialog
 */
export const ModalDialogHeader = forwardRef(({ children, ...props }: ModalHeaderProps, ref) => (
  <ModalHeader py={6} px={0} textAlign="center" mx={24} ref={ref} {...props}>
    <Text as="h3" m={0} textStyle="heading3Semi">
      {children}
    </Text>
  </ModalHeader>
))

ModalDialogHeader.displayName = 'ModalDialogHeader'

/**
 * Modal dialog back button
 *
 * This component will add a back button to the modal dialog box. Typically used when there are multi-layered modals where the
 * user needs a way to get back to the parent modal.
 *
 * ModalDialogBackButton must be within a ModalDialog
 */
export const ModalDialogBackButton = forwardRef((props: ButtonProps, ref) => (
  <Button variant="inlineLink" pos="absolute" top={6} left={6} lineHeight={8} ref={ref} {...props}>
    <ArrowLeft mr={2} /> <Msg id="common.ui.back" />
  </Button>
))

ModalDialogBackButton.displayName = 'ModalDialogBackButton'

/**
 * Modal dialog body
 *
 * This component will contain the body of the modal dialog box.
 *
 * ModalDialogBody must be within a ModalDialog
 */
export const ModalDialogBody = forwardRef((props: ModalBodyProps, ref) => (
  <ModalBody textStyle="body" pb={6} pt={0} maxHeight="100%" ref={ref} {...props} />
))

ModalDialogBody.displayName = 'ModalDialogBody'

/**
 * Modal dialog footer
 *
 * This component will contain the buttons on the footer of the modal dialog box. The modal dialog box can be built with or without
 * a footer.
 *
 * ModalDialogFooter must be within a ModalDialog.
 */
export const ModalDialogFooter = forwardRef((props: ModalFooterProps, ref) => (
  <ModalFooter p={6} justifyContent="flex-start" borderTopWidth="1px" ref={ref} {...props} />
))

ModalDialogFooter.displayName = 'ModalDialogFooter'

export interface StepLabel {
  label: ReactNode
  isRejected?: boolean
}

type ModalStepperProps = {
  children: ReactNode
  title?: string
  stepperTitle: string
  stepLabels: StepLabel[]
} & ModalProps

export function useModalStepper() {
  const [activeStep, setActiveStep] = useState(1)
  const [numSteps, setNumSteps] = useState(0)
  const [isLoadingNext, setIsLoadingNext] = useState(false)

  function previousStep() {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1)
    }
  }

  function nextStep() {
    if (activeStep < numSteps) {
      setActiveStep(activeStep + 1)
    }
  }

  return {
    activeStep,
    activeStepIndex: activeStep - 1,
    isLoadingNext,
    nextStep,
    numSteps,
    previousStep,
    setActiveStep,
    setIsLoadingNext,
    setNumSteps
  }
}

export const [ModalStepperContextProvider, useModalStepperContext] = constate(useModalStepper)

/**
 * Modal Stepper
 *
 * The stepper modal box has similar functionality to the modal dialog box, except it allows for more complex forms while retaining
 * the same page context. It can be used to break up long forms in to separate steps to show user progress through a flow.
 *
 * The ModalStepper is the wrapper component around ModalStepperBody and ModalStepperFooter.
 */
export const ModalStepper = forwardRef(
  ({ children, title = '', stepperTitle, stepLabels, ...props }: ModalStepperProps, ref) => {
    const { activeStep, activeStepIndex, numSteps, setNumSteps } = useModalStepperContext()
    useEffect(() => {
      setNumSteps(stepLabels.length)
    }, [setNumSteps, stepLabels])

    return (
      <Modal size="5xl" scrollBehavior="inside" {...props}>
        <ModalOverlay />
        <ModalContent top={5} ref={ref}>
          <Grid gridTemplateColumns="1fr 2fr" maxHeight="calc(100vh - 7.5rem)">
            <Box
              px={8}
              py={16}
              bgColor="gray.100"
              borderRight="1px solid"
              borderColor="gray.300"
              maxHeight="inherit"
              overflow="hidden">
              <Heading my={2} textStyle="sectionHeading">
                {stepperTitle}
              </Heading>
              <Divider mb={4} />
              <Stepper numSteps={numSteps} activeStep={activeStep} isVertical>
                {stepLabels.map((step, index) => (
                  <Step key={`step-${index + 1}`} stepNum={index + 1} isRejected={step.isRejected || false}>
                    {step.label}
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Flex direction="column" maxHeight="inherit">
              <ModalHeader py={6} textAlign="center" mx={24}>
                <Heading textStyle="heading3Semi">{title === '' ? stepLabels[activeStepIndex].label : title}</Heading>
              </ModalHeader>
              <ModalCloseButton top={6} right={6} />
              {children}
            </Flex>
          </Grid>
        </ModalContent>
      </Modal>
    )
  }
)

ModalStepper.displayName = 'ModalStepper'

/**
 * Modal Stepper Body
 *
 * This component will contain the body of the stepper modal.
 *
 * ModalStepperBody must be within a ModalStepper.
 */
export const ModalStepperBody = forwardRef((props: ModalBodyProps, ref) => (
  <ModalBody pb={6} pt={0} minHeight="220px" overflowY="auto" bgColor="white" ref={ref} {...props} />
))

ModalStepperBody.displayName = 'ModalStepperBody'

type ModalStepperFooterProps = {
  onCancel: () => void
  isLoading?: boolean
  isDisabled?: boolean
  submitButtonLabel?: string
  loadingText?: string
  onSubmit?: () => void
  onNext?: () => void
} & ModalFooterProps

/**
 * Modal Stepper Footer
 *
 * This component will contain the buttons on the footer of the stepper modal. Functional navigation buttons,
 * next and previous buttons, are already part of the component. A cancel link button is also included. Event
 * handlers for all buttons can be passed in.
 *
 * ModalStepperFooter must be within a ModalStepper.
 */
export const ModalStepperFooter = forwardRef(
  (
    {
      onCancel,
      isLoading = false,
      isDisabled = false,
      submitButtonLabel,
      loadingText,
      onSubmit,
      onNext,
      ...props
    }: ModalStepperFooterProps,
    ref
  ) => (
    <ModalFooter p={6} justifyContent="space-between" borderTopWidth="1px" bgColor="white" ref={ref} {...props}>
      <ModalStepperPreviousButton />
      <ButtonGroupActions>
        <Button variant="link" onClick={onCancel}>
          <Msg id="common.ui.cancel" />
        </Button>
        <ModalStepperNextButton
          isLoading={isLoading}
          isDisabled={isDisabled}
          onSubmit={onSubmit}
          onNext={onNext}
          submitButtonLabel={submitButtonLabel}
          loadingText={loadingText}
        />
      </ButtonGroupActions>
    </ModalFooter>
  )
)

ModalStepperFooter.displayName = 'ModalStepperFooter'

/**
 * Modal Stepper Previous Button
 *
 * A default Previous button behavior.
 */
const ModalStepperPreviousButton = () => {
  const { activeStep, previousStep } = useModalStepperContext()
  return (
    <>
      {activeStep !== 1 ? (
        // Only Show the previous button if there's somewhere to go
        <Button leftIcon={<ArrowLeft />} onClick={previousStep}>
          <Msg id="common.ui.previous" />
        </Button>
      ) : (
        // Otherwise use a box placeholder to preserve spacing
        <Box />
      )}
    </>
  )
}

type ModalStepperNextButtonProps = {
  isLoading?: boolean
  isDisabled?: boolean
  submitButtonLabel?: string
  loadingText?: string
  onSubmit?: () => void
  onNext?: () => void
}

/**
 * Modal Stepper Next Button
 *
 * A default Next button behavior.
 */
const ModalStepperNextButton = ({
  isLoading = false,
  isDisabled = false,
  submitButtonLabel,
  loadingText,
  onSubmit,
  onNext
}: ModalStepperNextButtonProps) => {
  const { numSteps, activeStep, nextStep } = useModalStepperContext()
  const isFinalStep = activeStep === numSteps
  const msg = useMsg()

  const submitLabel = submitButtonLabel || msg('common.ui.save')
  const loadingLabel = loadingText || msg('common.ui.loading_ellipsis')

  if (isLoading) {
    return (
      <Button isLoading loadingText={loadingLabel} variant="primary" isDisabled={isDisabled}>
        {isFinalStep ? submitButtonLabel : <Msg id="common.ui.next" />}
      </Button>
    )
  }
  if (isFinalStep) {
    return (
      <Button type="submit" variant="primary" onClick={onSubmit} isDisabled={isDisabled}>
        {submitLabel}
      </Button>
    )
  }
  return (
    <Button rightIcon={<ArrowRight />} variant="primary" onClick={onNext ?? nextStep} isDisabled={isDisabled}>
      <Msg id="common.ui.next" />
    </Button>
  )
}
