import { forwardRef, ButtonGroup, ButtonGroupProps } from '../../chakra'

/**
 * Button Group Actions
 *
 * Horizontally aligns a series of action buttons together as a group. Only one primary
 * button is expected in a button group. The spacing between buttons will default to 16px.
 */
export const ButtonGroupActions = forwardRef(({ size = 'md', children, ...props }: ButtonGroupProps, ref) => (
  <ButtonGroup spacing={size} size={size} ref={ref} {...props}>
    {children}
  </ButtonGroup>
))

ButtonGroupActions.displayName = 'ButtonGroupActions'
