import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'
import { isString } from 'lodash'
import { currencyCodeToSymbol } from '~/common/currencyUtils'

/**
 * @typedef { import("react-number-format").NumberFormatProps } NumberFormatProps
 */

/**
 * @deprecated This component has been replaced by our new InputCurrency component as part of the DS,
 * please reference https://storybook.beta.vndly.com/?path=/docs/components-inputs-currency--docs
 *
 * For currency amount input field, use VndlyCurrencyInput
 *
 * @param {{
 *  currency?: string,
 *  includeCurrencyCode?: bool,
 *  includeCurrencySymbol?: bool,
 *  'data-testid'?: string
 * } & NumberFormatProps} props
 */
export const VndlyCurrencyDisplay = ({
  value,
  currency = undefined,
  suffix = undefined,
  decimalScale = undefined,
  includeCurrencyCode = true,
  includeCurrencySymbol = false,
  'data-testid': dataTestId = undefined,
  ...props
}) => {
  const optionalCurrencyCode = includeCurrencyCode && currency ? currency : ''
  const optionalCurrencySymbol = includeCurrencySymbol && currency ? currencyCodeToSymbol(currency) : ''
  return (
    <span data-testid={dataTestId}>
      {optionalCurrencyCode}
      {optionalCurrencyCode && <span> </span>}
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        prefix={optionalCurrencySymbol}
        suffix={suffix}
        decimalScale={decimalScale}
        fixedDecimalScale
        isNumericString={isString(value)}
        {...props}
      />
    </span>
  )
}

VndlyCurrencyDisplay.defaultProps = {
  decimalScale: 2
}

VndlyCurrencyDisplay.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimalScale: PropTypes.number,
  includeCurrencyCode: PropTypes.bool,
  includeCurrencySymbol: PropTypes.bool
}
