/*
 * For Base Theme
 * See https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/heading.ts
 */

const baseStyle = {
  // Set all Headings to be semibold by default
  fontWeight: 'semibold'
}

const sizes = {
  xl: {
    // Our page title (and other xl usages) will be full bold
    fontWeight: 'bold'
  }
}

export const Heading = {
  baseStyle,
  sizes
}
