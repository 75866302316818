import React from 'react'
import { Msg } from '~/common/localizationUtils'
import { woStatus } from '~/work_order_list/types_enums'

export const links = {
  home: {
    href: '/home/all',
    name: <Msg id="nav.home" />
  },
  homeLogin: {
    href: '/home/login',
    name: <Msg id="nav.home" />
  },
  contractors: {
    href: '/contractors',
    name: <Msg id="term.contractor" count={99} />
  },
  expensesAndAdjustments: {
    href: '/expenses/expense-report-list',
    name: <Msg id="nav.expenses_and_adjustments" count={99} />
  },
  jobs: {
    href: '/jobs',
    name: <Msg id="nav.jobs" job_term={<Msg id="term.job" count={99} />} />
  },
  independentContractors: {
    href: '/employers/manage-independent-contractors',
    name: <Msg id="term.independent_contractor" count={99} />
  },
  statementOfWork: {
    href: '/sow',
    name: <Msg id="term.statementofwork" count={99} />
  },
  candidates: {
    href: '/vendor/off/candidates',
    name: <Msg id="term.candidate" count={99} />
  },
  vendors: {
    href: '/employers/manage-vendors',
    name: <Msg id="term.vendor" count={99} />
  },
  work_orders: {
    href: `/work_orders/all?status_val=${woStatus.ACTIVE}`,
    name: <Msg id="term.workorder" count={99} />
  },
  companySettings: {
    href: '/entities/settings-all/all',
    name: <Msg id="nav.settings" />
  },
  securitySettings: {
    href: '/settings/security',
    name: <Msg id="settings.security" />
  },
  securitySettingsRoles: {
    href: '/settings/security/roles',
    name: <Msg id="term.security.role" count={99} />
  },
  invoiceTransactions: {
    href: '/invoices/transactions',
    name: <Msg id="breadcrumb.invoice_transactions" />
  },
  invoices: {
    href: '/invoices/summary/',
    name: <Msg id="term.invoice" count={99} />
  },
  locations: {
    href: '/work_sites/settings/',
    name: <Msg id="term.location" count={99} />
  },
  candidateTimesheets: {
    href: '/candidate/timesheets/',
    name: <Msg id="term.timesheet" count={99} />
  },
  timesheetsList: {
    href: '/time-entries/tsviewv2',
    name: <Msg id="term.timesheet" count={99} />
  },
  bulkUpdates: {
    href: '/bulk_updates',
    name: <Msg id="term.bulk_update" count={99} />
  },
  notificationSettings: {
    href: '/notifications/settings/templates',
    name: <Msg id="nav.settings.notifications" />
  }
}
