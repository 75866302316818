import React from 'react'
import { Msg } from '../../../common/localizationUtils'
import { HappyGhost, Inbox, LockedGhost, SadBrowser, SadRefresh, SadSearch } from '../../../vnmoji'
import { Box, Flex, forwardRef, StackProps, Text, TextProps } from '../../chakra'

export const EmptyState = forwardRef(({ children, ...props }: StackProps, ref) => {
  return (
    <Box
      data-empty-state-container
      sx={{
        containerType: 'inline-size',
        containerName: 'empty-state-container',
        '@container empty-state-container (min-width: 0px)': {
          '[data-container]': { p: 6 },
          '[data-empty-image]': { display: 'none' }
        },
        '@container empty-state-container (min-width: 480px)': {
          '[data-container]': { p: 12, '&>*~*': { mt: '8px' } },
          '[data-empty-image]': { display: 'block', width: '360px', height: '180px' }
        },
        '@container empty-state-container (min-width: 1080px)': {
          '[data-empty-image]': { width: '480px', height: '240px' }
        }
      }}>
      <Flex
        data-container
        textAlign="center"
        flexDirection="column"
        alignItems="center"
        role="alert"
        ref={ref}
        {...props}>
        {children}
      </Flex>
    </Box>
  )
})

EmptyState.displayName = 'EmptyState'

type EmptyStateProps = {
  title?: React.ReactNode
} & Omit<TextProps, 'title'>

export const CaughtUp = ({ title, ...props }: EmptyStateProps) => (
  <>
    <HappyGhost data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_caught_up" />}
    </Text>
  </>
)

CaughtUp.displayName = 'CaughtUp'

export const NothingToShow = ({ title, ...props }: EmptyStateProps) => (
  <>
    <Inbox data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_nothing_to_show" />}
    </Text>
  </>
)

NothingToShow.displayName = 'NothingToShow'

export const NoResults = ({ title, ...props }: EmptyStateProps) => (
  <>
    <SadSearch data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_no_search_results" />}
    </Text>
  </>
)

NoResults.displayName = 'NoResults'

export const LockedOut = ({ title, ...props }: EmptyStateProps) => (
  <>
    <LockedGhost data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_locked_out" />}
    </Text>
  </>
)

LockedOut.displayName = 'LockedOut'

export const RefreshError = ({ title, ...props }: EmptyStateProps) => (
  <>
    <SadRefresh data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_refresh_error" />}
    </Text>
  </>
)

RefreshError.displayName = 'RefreshError'

export const BrowserError = ({ title, ...props }: EmptyStateProps) => (
  <>
    <SadBrowser data-empty-image />
    <Text textStyle="bodySemi" {...props}>
      {title || <Msg id="common.ui.empty_state_browser_error" />}
    </Text>
  </>
)

BrowserError.displayName = 'BrowserError'
