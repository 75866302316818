import { useCallback, useEffect, useRef } from 'react'

type OnChangeFn = (rect: DOMRect, el: HTMLElement) => void

export function useBoundingClientRect(onChange?: OnChangeFn) {
  const elRef = useRef<HTMLElement | null>(null)

  const update = useCallback(() => {
    const el = elRef.current
    if (el == null) return
    if (typeof onChange !== 'undefined') {
      const rect = el.getBoundingClientRect?.()
      onChange(rect, el)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', update)
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('scroll', update)
      window.removeEventListener('resize', update)
    }
  }, [])

  const refCb = useCallback((el: HTMLElement | null) => {
    elRef.current = el
    update()
  }, [])

  return refCb
}
