import { v4 as uuid } from 'uuid'
import { forceArray } from './arrayUtils'
import { isServer } from './nextJsUtils'

/**
 * Creates a logging capability to help with on-demand debugging in both prod and dev environments.
 *
 * ## To use:
 *
 * 1. Create a log instance:
 * ```
 * const debug = createDebugLogger('MyComponent')
 * ```
 *
 * 2. Place debug calls wherever it would be useful, use it just like console.log:
 * ```
 * // Log out any local vars that would be helpful to see
 * debug('Data loaded', { data, isLoading, error })
 *
 * // Pass a function to be lazily executed if you have some debug logic that is expensive
 * // The function will only execute if debug is enabled.
 * debug(() => {
 *  const data = somethingExpensive()
 *  return ['Data loaded', data] // return an array of console.log args
 * })
 * ```
 *
 * 3. To enable it in your browser (any env), you need to use the Chrome/Firefox devtool console. There will be a method
 *    called `window.debugUI.{name}.toggle()` where name is the name you provide when you created the instance.
 * ```console
 * $ window.debugUI.MyComponent.toggle()       // Enables debugging, persists across refreshes
 * $ window.debugUI.MyComponent.toggle(true)   // Same as above
 * $ window.debugUI.MyComponent.toggle(false)  // Disables debugging
 * ```
 *
 * @param {String} name The name of your logger, use something unique like the name of your component or store.
 * @param {Object | true} [options]
 * @param {Boolean} [options.isEnabled] When `true` it will always log
 * @param {Boolean} [options.isEnabledInTestEnv] When `true` it will log in a local test env (never jenkins)
 * @param {string} [options.filterInTestEnv] Provide a string to filter your debug logs by, e.g. "location" will only show
 *                                 debug logs that contain the string "location".
 * @param {Boolean} [options.isEnabledInSSR] When `true` it will log on the server (aka Next.js)
 * @returns {Function} debugFunction Use it like console.log
 */
const createDebugLogger = (name, opts = {}) => {
  const {
    isEnabled: _isEnabled = false,
    isEnabledInTestEnv = false,
    filterInTestEnv = undefined,
    isEnabledInSSR = false
  } = opts === true ? { isEnabled: true } : opts

  if (isServer()) return () => {}

  const localStorageKey = `__debugUI_enable_${name}`
  const isEnabled =
    window.localStorage.getItem(localStorageKey) === 'true' ||
    (process.env.NODE_ENV === 'test' && !process.env.JENKINS_HOME && !process.env.CI && isEnabledInTestEnv) ||
    (isServer() && isEnabledInSSR) ||
    _isEnabled
  const filterStrOrRegex =
    window.localStorage.getItem(`${localStorageKey}_filter`) ||
    (process.env.NODE_ENV === 'test' && !process.env.JENKINS_HOME && !process.env.CI && filterInTestEnv)

  window.debugUI = window.debugUI ?? {}
  const debugObj = window.debugUI[name] ?? { uuid: uuid(), isEnabled, filterStrOrRegex }
  window.debugUI[name] = debugObj

  const newDebugFn = (...args) => {
    if (debugObj.isEnabled) {
      let loggedArgs = args
      if (args.length === 1 && typeof args[0] === 'function') {
        loggedArgs = forceArray(args[0]())
      }
      const logged = [`[${name}]`, ...(process.env.NODE_ENV === 'test' ? jsonifyArr(loggedArgs) : loggedArgs)]
      try {
        if (
          debugObj.filterStrOrRegex &&
          !JSON.stringify(logged, getCircularReplacer()).match(debugObj.filterStrOrRegex)
        )
          return
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Attempted to JSON.stringify', logged)
        // eslint-disable-next-line no-console
        console.error(err)
      }
      // eslint-disable-next-line no-console
      if (console.log.name === 'disabledLog') {
        // eslint-disable-next-line no-console
        console.debug('%c[strict-mode re-render]', 'color: #aaa', ...logged)
      } else {
        // eslint-disable-next-line no-console
        console.log(...logged)
      }
    }
  }
  const debugFn = debugObj?.debugFn ?? newDebugFn
  debugObj.debug = debugFn

  const newToggleFn = enabled => {
    debugObj.isEnabled = enabled !== undefined ? enabled : !debugObj.isEnabled
    window.localStorage.setItem(localStorageKey, String(debugObj.isEnabled))
  }
  const toggleFn = debugObj.toggle ?? newToggleFn
  debugObj.toggle = toggleFn

  const newFilterFn = filterStrOrRegex => {
    if (filterStrOrRegex) {
      debugObj.filterStrOrRegex = filterStrOrRegex
      window.localStorage.setItem(`${localStorageKey}_filter`, String(debugObj.filterStrOrRegex))
    } else {
      delete debugObj.filterStrOrRegex
      window.localStorage.removeItem(`${localStorageKey}_filter`)
    }
  }
  const filterFn = debugObj.filter ?? newFilterFn
  debugObj.filter = filterFn

  return debugFn
}

function jsonifyArr(arr) {
  return arr.map(item => JSON.stringify(item, null, 2))
}

export default createDebugLogger

export const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[circular ref]'
      }
      seen.add(value)
    }
    return value
  }
}
