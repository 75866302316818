import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '~/common/react-query/queryClient'
import fetch from '~/common/fetch'
import { isClient } from '~/common/nextJsUtils'
import { useQueryWithLocalStoragePersist } from '~/common/react-query/useQueryWithLocalStoragePersist'

export interface NavMenuConfig {
  version: number
  is_impersonated: boolean
  profile_menu_button: {
    displayed_name: string
    avatar_img: string
  }
  profile_menu: {
    my_profile?: { is_profile_complete: boolean }
    notification_preferences?: true
    timesheets?: true
    expenses?: true
    manage_checklist_actions?: true
    switch_account?: true
    delegate_access?: true
    change_password?: true
    reset_mfa_token?: true
    end_delegation?: true
    sign_out?: { sign_out_url: string }
  }
  main_menu?: {
    home?: true
    sow_menu?: {
      sow?: true
      rfp?: true
    }
    jobs_menu?: {
      jobs?: true
      interview_schedules?: true
      interview_management?: true
    }
    candidate_profile?: { candidate_id: number }
    candidates?: true
    contractors_menu?: {
      contractors_summary?: true
      preidentified_candidates?: true
      workers_summary?: true
    }
    invoices_menu?: {
      invoices_summary?: true
      invoice_payments?: true
      misc_adjustments?: true
      invoice_files?: true
      invoice_job_status?: true
    }
    timesheets_menu?: {
      timesheet_summary?: true
      timesheet_archives?: true
    }
    dashboards?: true
    reports_menu?: {
      reports?: true
      file_transfer_reports?: true
    }
    more_menu?: {
      approvals?: true
      documents?: true
      vendors?: true
      users?: true
      company_profile?: true
      work_orders?: true
      worker_tracking?: true
      expenses?: true
      bulk_updates?: true
      checklists?: true
      api_docs?: true
      company_settings?: true
    }
    candidate_timesheets?: true
    candidate_expenses?: true
  }
}

export const NAVIGATION_MENU_CONFIG_LOCAL_STORAGE_KEY = 'navigation_config'

export const useNavigationMenuConfigQuery = (opts?: UseQueryOptions<NavMenuConfig>) => {
  const queryKey = ['navigationMenu', 'config'] as const
  return useQueryWithLocalStoragePersist(queryKey, (): Promise<NavMenuConfig> => fetch.get('/api/v2/nav/config'), {
    ...opts,
    staleTime: Infinity,
    localStorageKey: NAVIGATION_MENU_CONFIG_LOCAL_STORAGE_KEY,
    preventClientServerMismatches: true,
    isValidLocalStorageData: isValidNavMenuConfigData
  })
}

export const isValidNavMenuConfigData = (data: any) => {
  return data?.version === 2
}

export const invalidateNavigationMenuConfigQuery = async () => {
  if (isClient()) window.localStorage.removeItem(NAVIGATION_MENU_CONFIG_LOCAL_STORAGE_KEY)
  // Setting the refetchType to 'none' because we don't want it to refetch until the page has been reloaded.
  await queryClient.invalidateQueries(['navigationMenu', 'config'], { refetchType: 'none', type: 'all' })
}

export interface ImpersonateQuickLink {
  user_id: number
  count: number
  name: string
  email: string
}

export interface ImpersonateQuickLinksResponse {
  quick_links: ImpersonateQuickLink[]
}

export const useImpersonateQuickLinks = (opts?: UseQueryOptions<ImpersonateQuickLinksResponse>) => {
  return useQuery({
    queryKey: ['navigationMenu', 'impersonateQuickLinks'] as const,
    queryFn: (): Promise<ImpersonateQuickLinksResponse> => fetch.get('/accounts/impersonate/get_quick_links'),
    staleTime: Infinity,
    ...opts
  })
}

export interface ImpersonateBody {
  id: number
}

export const useImpersonateMutation = (opts?: UseMutationOptions<void, unknown, ImpersonateBody>) => {
  return useMutation({
    mutationFn: body => fetch.post('/accounts/impersonate/', body),
    ...opts,
    async onSuccess(...args) {
      await invalidateNavigationMenuConfigQuery()
      opts?.onSuccess?.(...args)
    }
  })
}

export const useEndImpersonationMutation = (opts?: UseMutationOptions<void, unknown, undefined>) => {
  return useMutation({
    mutationFn: () => fetch.post('/accounts/impersonate/end'),
    ...opts,
    async onSuccess(...args) {
      await invalidateNavigationMenuConfigQuery()
      opts?.onSuccess?.(...args)
    }
  })
}
