import qs from 'qs'
import { states } from '~/sow/details/constants'

export const userRoles = {
  Employer: 'employer',
  Vendor: 'vendor',
  All: 'ALL'
}

export function defaultSowParams(isVendor) {
  return {
    query: '',
    state: [
      states.STATE_ACTIVE,
      states.STATE_AWAITING_CLIENT_REVIEW,
      states.STATE_AWAITING_VENDOR_REVIEW,
      states.STATE_DRAFT_CLIENT_COUNTERED,
      !isVendor ? states.STATE_DRAFT_CLIENT : states.STATE_DRAFT_VENDOR,
      states.STATE_APPROVAL_PENDING
    ].join(',')
  }
}

export function getDefaultSowListURL(isVendor) {
  return `/sow/?${qs.stringify(defaultSowParams(isVendor))}`
}
