import { useRef } from 'react'
import {
  Button,
  VStack,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogFooter,
  forwardRef,
  AlertDialogProps,
  Flex,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  Link
} from '../../chakra'
import { ButtonGroupActions } from '../..'
import { useModal } from '../../../hooks/useModal'
import { ApplicationError } from '../../../common/fetchErrors'
import { getTraceId } from '../../../common/tracing'
import { Msg } from '../../../common/localizationUtils'
import { Optional } from '../../../common/types'
import { Info, Warning, WarningO } from '../../../icons'
import { BackgroundTaskFailureError } from '../../../common/BackgroundTaskFailureError'

type BaseModalProps = {
  message: React.ReactNode
  title?: React.ReactNode
  size?: string
}

export type ShowModalProps = {
  confirmButtonText?: React.ReactNode
  cancelButtonText?: React.ReactNode
  hideIcon?: boolean
} & BaseModalProps

export type DeleteConfirmModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
} & ShowDeleteConfirmModalProps

export type ShowDeleteConfirmModalProps = Optional<ShowModalProps, 'message'>

/**
 * useDeleteConfirmModal
 *
 * Delete confirmation modals are used to alert the user they have requested a destructive
 * action. Before they can proceed to the next step, a user must confirm their requested action.
 */
export const useDeleteConfirmModal = (confirmProps?: ShowDeleteConfirmModalProps) =>
  useModal<boolean, ShowDeleteConfirmModalProps>(({ isOpen, onResult, ...props }) => (
    <DeleteConfirmModal
      isOpen={isOpen}
      onClose={() => onResult(false)}
      onConfirm={() => onResult(true)}
      {...confirmProps}
      {...props}
    />
  ))

export const DeleteConfirmModal = forwardRef(
  (
    {
      isOpen,
      onClose,
      onConfirm,
      title,
      message,
      confirmButtonText,
      cancelButtonText,
      size = 'md',
      hideIcon = false
    }: DeleteConfirmModalProps,
    ref
  ) => {
    const cancelBtnRef = useRef(null)

    return (
      <AlertModal
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        leastDestructiveRef={cancelBtnRef}
        ref={ref}
        testId="confirm-modal">
        <AlertModalHeader>
          {!hideIcon && <WarningO boxSize={8} color="red.500" bgColor="red.50" borderRadius="full" mr={2} p={1} />}
          {title ?? <Msg id="common.ui.delete_item" />}
        </AlertModalHeader>
        <AlertModalBody px={getAlertModalBodyPX(!hideIcon)}>
          {message ?? <Msg id="common.generic.delete_confirm_message" />}
        </AlertModalBody>
        <AlertModalFooter>
          <ButtonGroupActions>
            <Button variant="deletePrimary" onClick={onConfirm} data-testid="delete-confirm-modal-delete-button">
              {confirmButtonText ?? <Msg id="common.ui.delete" />}
            </Button>
            <Button onClick={onClose} ref={cancelBtnRef}>
              {cancelButtonText ?? <Msg id="common.ui.cancel" />}
            </Button>
          </ButtonGroupActions>
        </AlertModalFooter>
      </AlertModal>
    )
  }
)

DeleteConfirmModal.displayName = 'DeleteConfirmModal'

type WarnConfirmProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
} & ShowModalProps

/**
 * useWarnConfirmModal
 *
 * Default confirmation modals are used to alert the user they have requested a change to their
 * given item. Before they can proceed to the next step, a user must confirm their requested action.
 */
export const useWarnConfirmModal = () =>
  useModal<boolean, ShowModalProps>(({ isOpen, onResult, ...props }) => (
    <WarnConfirmModal isOpen={isOpen} onClose={() => onResult(false)} onConfirm={() => onResult(true)} {...props} />
  ))

export const WarnConfirmModal = forwardRef(
  (
    {
      isOpen,
      onClose,
      onConfirm,
      title,
      message,
      confirmButtonText,
      cancelButtonText,
      size = 'md',
      hideIcon = false
    }: WarnConfirmProps,
    ref
  ) => {
    const cancelBtnRef = useRef(null)

    return (
      <AlertModal
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        leastDestructiveRef={cancelBtnRef}
        ref={ref}
        testId="confirm-modal">
        <AlertModalHeader>
          {!hideIcon && <Warning boxSize={8} color="yellow.400" bgColor="yellow.50" borderRadius="full" mr={2} p={1} />}
          {title ?? <Msg id="common.ui.are_you_sure" />}
        </AlertModalHeader>
        <AlertModalBody whiteSpace="pre-line" px={getAlertModalBodyPX(!hideIcon)}>
          {message}
        </AlertModalBody>
        <AlertModalFooter>
          <ButtonGroupActions>
            <Button variant="primary" onClick={onConfirm} data-testid="continue_btn">
              {confirmButtonText ?? <Msg id="common.ui.confirm" />}
            </Button>
            <Button onClick={onClose} ref={cancelBtnRef} data-testid="cancel_btn">
              {cancelButtonText ?? <Msg id="common.ui.cancel" />}
            </Button>
          </ButtonGroupActions>
        </AlertModalFooter>
      </AlertModal>
    )
  }
)

WarnConfirmModal.displayName = 'WarnConfirmModal'

type ErrorProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
} & ShowErrorModalProps

export type ShowErrorModalProps = Optional<BaseModalProps, 'message'> & {
  error?: unknown
  confirmButtonText?: React.ReactNode
  cancelButtonText?: React.ReactNode
}

/**
 * useErrorModal
 *
 * Error modals are used to inform users of any unexpected errors that has occurred during the operation of the application.
 */
export const useErrorModal = (errorProps?: ShowErrorModalProps) =>
  useModal<boolean, ShowErrorModalProps>(({ isOpen, onResult, ...props }) => (
    <ErrorModal
      isOpen={isOpen}
      onClose={() => onResult(false)}
      onConfirm={() => onResult(true)}
      {...errorProps}
      {...props}
    />
  ))

export const ErrorModal = forwardRef(
  (
    { isOpen, onClose, onConfirm, title, error, message, confirmButtonText, cancelButtonText, size = 'md' }: ErrorProps,
    ref
  ) => {
    const cancelBtnRef = useRef(null)
    let traceId = null
    if (error instanceof ApplicationError) {
      traceId = getTraceId(error.response)
    } else if (error instanceof BackgroundTaskFailureError) {
      traceId = error.task?.result?.trace_id
    }

    return (
      <AlertModal isOpen={isOpen} onClose={onClose} size={size} leastDestructiveRef={cancelBtnRef} ref={ref}>
        <AlertModalHeader justifyContent="center">
          <VStack spacing={6}>
            <WarningO boxSize={14} color="red.500" bgColor="red.50" borderRadius="full" p={1} />
            <Text as="span">{title || <Msg id="common.ui.unexpected_error" />}</Text>
          </VStack>
        </AlertModalHeader>
        <AlertModalBody textAlign="center">
          {message || <Msg id="common.generic.error" />}
          {traceId && (
            <Text pt={2}>
              <Msg id="common.ui.support_code" description="Support Code" />:{' '}
              <Text as="span" textStyle="subtext">
                {traceId}
              </Text>
            </Text>
          )}
        </AlertModalBody>
        <AlertModalFooter>
          <ButtonGroupActions>
            {confirmButtonText && (
              <Button variant="primary" onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            )}
            <Button onClick={onClose} ref={cancelBtnRef} data-testid="error-modal-close-btn">
              {cancelButtonText ?? <Msg id="common.ui.close" />}
            </Button>
          </ButtonGroupActions>
        </AlertModalFooter>
      </AlertModal>
    )
  }
)

ErrorModal.displayName = 'ErrorModal'

type InfoProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm?: () => void
} & ShowInfoModalProps

type ShowInfoModalProps = Optional<ShowModalProps, 'message'> & {
  linkButtonText?: React.ReactNode
  linkButtonHref?: string
}

/**
 * useInfoModal
 *
 * Informational alert modals used to tell the user a task is running in the background.
 */
export const useInfoModal = () =>
  useModal<boolean, ShowInfoModalProps>(({ isOpen, onResult, ...props }) => (
    <InfoModal isOpen={isOpen} onClose={() => onResult(false)} onConfirm={() => onResult(true)} {...props} />
  ))

export const InfoModal = forwardRef(
  (
    {
      isOpen,
      onClose,
      onConfirm,
      title,
      message,
      cancelButtonText,
      linkButtonText,
      linkButtonHref,
      size = 'md',
      hideIcon = false
    }: InfoProps,
    ref
  ) => {
    const cancelBtnRef = useRef(null)

    return (
      <AlertModal
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        leastDestructiveRef={cancelBtnRef}
        ref={ref}
        testId="info-modal">
        <AlertModalHeader>
          {!hideIcon && <Info boxSize={8} color="blue.400" bgColor="blue.50" borderRadius="full" mr={2} p={1} />}
          {title ?? <Msg id="common.ui.informational_message" />}
        </AlertModalHeader>
        <AlertModalBody px={getAlertModalBodyPX(!hideIcon)}>{message}</AlertModalBody>
        <AlertModalFooter>
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Button onClick={onConfirm} ref={cancelBtnRef}>
              {cancelButtonText ?? <Msg id="common.ui.okay" />}
            </Button>
            {linkButtonText && (
              <Link href={linkButtonHref} isExternal>
                {linkButtonText}
              </Link>
            )}
          </Flex>
        </AlertModalFooter>
      </AlertModal>
    )
  }
)

type AlertModalProps = AlertDialogProps & {
  testId?: string
}
export const AlertModal = forwardRef(({ children, testId, ...props }: AlertModalProps, ref) => (
  <AlertDialog {...props}>
    <AlertDialogOverlay />
    <AlertDialogContent minH="200px" ref={ref} data-testid={testId}>
      <AlertDialogCloseButton top={6} right={6} />
      {children}
    </AlertDialogContent>
  </AlertDialog>
))

export const AlertModalHeader = forwardRef(({ children, ...props }: ModalHeaderProps, ref) => (
  <AlertDialogHeader display="flex" alignItems="center" textStyle="heading3Semi" py={6} pb={2} ref={ref} {...props}>
    {children}
  </AlertDialogHeader>
))

export function getAlertModalBodyPX(headerHasIcon: boolean) {
  // when the header does not have an icon we need less padding so the context aligns with the header text properly
  return headerHasIcon ? 16 : 6
}

export const AlertModalBody = forwardRef(({ children, ...props }: ModalBodyProps, ref) => (
  <AlertDialogBody textStyle="body" pb={6} pt={0} px={getAlertModalBodyPX(true)} maxHeight="100%" ref={ref} {...props}>
    {children}
  </AlertDialogBody>
))

export const AlertModalFooter = forwardRef(({ children, ...props }: ModalFooterProps, ref) => (
  <AlertDialogFooter p={6} justifyContent="flex-start" borderTopWidth="1px" ref={ref} {...props}>
    {children}
  </AlertDialogFooter>
))
