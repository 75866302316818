import { noop } from 'lodash'
import { useState } from 'react'
import DOMPurify from 'dompurify'
import {
  Button,
  Box,
  Card,
  CardActionIcon,
  CardBody,
  CardHeader,
  CardHeading,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  useToast,
  Input
} from '~/design_system'
import { useContactUsConfigQuery, useContactUsMutation } from '~/api/home'
import { Close, Envelope, MessageBubble } from '~/icons'
import { useMsg, Msg } from '~/common/localizationUtils'

export const ContactUsDrawer = (props: any) => {
  const { data } = useContactUsConfigQuery()

  const [showForm, setShowForm] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const { mutateAsync: sendMessage } = useContactUsMutation()
  const toast = useToast()
  const msg = useMsg()

  const onSubmit = async () => {
    const { message: responseMessage, status } = await sendMessage({ query: message, name, email }).catch(noop)
    toast({
      position: 'bottom-right',
      title: responseMessage,
      status,
      duration: 9000,
      isClosable: true
    })

    if (status === 'success') {
      setShowForm(false)
      setName('')
      setEmail('')
      setMessage('')
    }
  }

  const sanitize = (dirty: string) => {
    // Adding a hook to open links in new window if set
    DOMPurify.addHook('afterSanitizeAttributes', node => {
      // if a link has been set to open in new window, set target=_blank
      if (node.hasAttribute('rel')) {
        node.setAttribute('target', '_blank')
        node.setAttribute('rel', 'noopener noreferrer')
      }
    })
    return {
      __html: DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } })
    }
  }
  return data?.enabled ? (
    <>
      <Button
        leftIcon={<Envelope />}
        position="fixed"
        left="100%"
        bottom={30}
        roundedBottom={0}
        transformOrigin="0% 0%"
        transform="rotate(-90deg) translateY(-100%)"
        mr="-45px"
        variant="primary"
        zIndex="docked"
        onClick={() => setShowForm(true)}>
        <Msg id="contact_us.drawer.heading" />
      </Button>
      <Card
        width="340px"
        position="fixed"
        right={showForm ? 0 : '-340px'}
        bottom={0}
        boxShadow="lg"
        transition="right 0.25s ease-in"
        zIndex="sticky"
        {...props}>
        <CardHeader bg="blueberry400" color="white" justifyContent="space-between">
          <Stack isInline alignItems="center">
            <Box as={MessageBubble} alignSelf="center" />
            <CardHeading mb={0}>
              <Msg id="contact_us.drawer.heading" />
            </CardHeading>
          </Stack>
          <CardActionIcon icon={<Close />} aria-label="Close" onClick={() => setShowForm(false)} color="white" />
        </CardHeader>
        <CardBody as={Stack} spacing={4} minH={175} justifyContent="space-between">
          {data?.custom_message && (
            <Box
              dangerouslySetInnerHTML={sanitize(data?.custom_message)}
              sx={{
                a: {
                  color: 'var(--vndly-color-link)',
                  _hover: {
                    color: 'var(--vndly-color-link-hover)',
                    bgColor: 'var(--vndly-color-link-background-hover)'
                  }
                }
              }}
            />
          )}
          {data?.hide_form ? (
            <Button w="fit-content" onClick={() => setShowForm(false)}>
              <Msg id="common.ui.close" />
            </Button>
          ) : (
            <>
              <FormControl isRequired>
                <Stack>
                  {data?.request_user_info && (
                    <>
                      <FormLabel htmlFor="name" fontWeight="semibold">
                        <Msg id="contact_us.drawer.name.label" />
                      </FormLabel>
                      <Input
                        id="name"
                        placeholder={msg('contact_us.drawer.name.placeholder')}
                        onChange={e => setName(e.target.value)}
                        value={name}
                      />
                      <FormLabel htmlFor="email" fontWeight="semibold">
                        <Msg id="contact_us.drawer.email.label" />
                      </FormLabel>
                      <Input
                        id="email"
                        placeholder={msg('contact_us.drawer.email.placeholder')}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </>
                  )}
                  <FormLabel htmlFor="message" fontWeight="semibold">
                    <Msg id="contact_us.drawer.message.label" />
                  </FormLabel>
                  <Textarea
                    id="message"
                    placeholder={msg('contact_us.drawer.message.placeholder')}
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                    height="200px"
                    resize="none"
                  />
                </Stack>
              </FormControl>
              <Button variant="primary" w="fit-content" isDisabled={message === ''} onClick={onSubmit}>
                <Msg id="contact_us.drawer.send.button" />
              </Button>
            </>
          )}
        </CardBody>
      </Card>
    </>
  ) : null
}
