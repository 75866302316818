import React from 'react'
import {
  forwardRef,
  Popover,
  PopoverProps,
  PopoverTrigger,
  PopoverContent,
  PopoverContentProps,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverBodyProps,
  PopoverCloseButton,
  PopoverFooter,
  PopoverFooterProps,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps
} from '../../chakra'
import { ButtonGroupActions } from '../..'
import { Msg } from '../../../common/localizationUtils'

/**
 * Alert popup
 *
 * Alert popups are another form of confirmation alerts used inline on action or destructive buttons. Popups are
 * recommended when there is a confirmation needed within a modal or drawer.
 *
 * AlertPopup is a wrapper component.
 */
export const AlertPopup = (props: PopoverProps) => <Popover isLazy {...props} />

AlertPopup.displayName = 'AlertPopup'

/**
 * Alert popup trigger button
 *
 * This component will display a button to trigger a popup.
 */
export const AlertPopupTriggerButton = forwardRef((props: ButtonProps, ref) => (
  <PopoverTrigger>
    <Button ref={ref} {...props} />
  </PopoverTrigger>
))

AlertPopupTriggerButton.displayName = 'AlertPopupTriggerButton'

/**
 * Alert popup trigger icon button
 *
 * This component will display an icon button to trigger a popup, such as a trash icon for delete operations.
 */
export const AlertPopupTriggerIconButton = forwardRef((props: IconButtonProps, ref) => (
  <PopoverTrigger>
    <IconButton ref={ref} {...props} />
  </PopoverTrigger>
))

AlertPopupTriggerIconButton.displayName = 'AlertPopupTriggerIconButton'

type AlertPopupContentProps = {
  title: React.ReactNode
} & PopoverContentProps

/**
 * Alert popup content
 *
 * This component will be the wrapper for the popup itself, consisting of the header, body and footer.
 */
export const AlertPopupContent = forwardRef(
  ({ title, children, transition, ...props }: AlertPopupContentProps, ref) => (
    <PopoverContent ref={ref} transition={transition} {...props}>
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton p="0" w="12px" h="12px" fontSize="14px" top={5} right={6} {...props} />
      {children}
    </PopoverContent>
  )
)

AlertPopupContent.displayName = 'AlertPopupContent'

/**
 * Alert popup body
 *
 * This component will be display the body content of the popup.
 */
export const AlertPopupBody = forwardRef((props: PopoverBodyProps, ref) => <PopoverBody ref={ref} {...props} />)

AlertPopupBody.displayName = 'AlertPopupBody'

type AlertPopupFooterProps = {
  confirmButtonText: React.ReactNode
  cancelButtonText?: React.ReactNode
  onClose: () => void
  onConfirm?: () => void
  isDestructive?: boolean
} & PopoverFooterProps

/**
 * Alert popup footer
 *
 * This component will be display the footer content of the popup. It will consist of two action buttons, a
 * confirmation button and a cancel button.
 */
export const AlertPopupFooter = forwardRef(
  (
    { confirmButtonText, cancelButtonText, onClose, onConfirm, isDestructive = false, ...props }: AlertPopupFooterProps,
    ref
  ) => (
    <PopoverFooter {...props}>
      <ButtonGroupActions size="sm" ref={ref}>
        <Button data-testid="save-shift" variant={isDestructive ? 'deletePrimary' : 'primary'} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
        <Button data-testid="go-back" onClick={onClose}>
          {cancelButtonText ?? <Msg id="common.ui.cancel" />}{' '}
        </Button>
      </ButtonGroupActions>
    </PopoverFooter>
  )
)

AlertPopupFooter.displayName = 'AlertPopupFooter'
