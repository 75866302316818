import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { StrictMode, useEffect } from 'react'
import { accountsKeys, useIsMFAVerifiedQuery } from '~/api/accounts'
import { useTermsAndConditionsAcceptedQuery } from '~/api/home'
import createDebugLogger from '~/common/logger'
import { NProgressInit } from '~/common/nextJsUtils'
import { queryClient } from '~/common/react-query/queryClient'
import { IntlProviderFromApi } from '~/components/IntlProviders'
import { ErrorPageBoundary } from '~/components/v2/ErrorPage'
import { MainLayout } from '~/components/v2/layouts/MainLayout'
import { ChakraProvider } from '~/design_system'
import { ModalProvider } from '~/hooks/useModal'
import { clearAllUrlState } from '~/hooks/useUrlState'
import { workdayTheme as theme } from '~/theme'
import GlobalCSSVariables from '~/theme/css/workdayTheme'
import addAnalyticsToPage, { extractPendoGuide } from '~/third_party/analytics'
import '~/noneditable.css'
import './_globalStyles.css'

const useClearUrlState = () => {
  const router = useRouter()

  useEffect(() => {
    function beforeHistoryChange(nextUrl) {
      // Compare nextPath with currentPath to see if we are:
      // - navigating to a new page OR
      // - just refreshing the page
      //
      // If we are going to a new page (aka path) then clear the
      // useUrlState state so that we don't have query param bleed
      // from one page to the next.
      //
      // But if we are staying on the same page (just refreshing)
      // do not clear the query params, cause the user may have
      // filters, searching, ordering in place that they want to keep
      const currentPath = window.location.pathname
      const nextPath = nextUrl.split('?')[0]
      if (nextPath !== currentPath) clearAllUrlState()
    }

    router.events.on('beforeHistoryChange', beforeHistoryChange)

    return () => {
      router.events.off('beforeHistoryChange', beforeHistoryChange)
    }
  }, [router.events])
}

NProgressInit()

const usePageNavigationProgress = ({ trackPageViews = true }) => {
  const router = useRouter()

  useEffect(() => {
    function onStart() {
      NProgressInit()
      NProgress.start()
    }
    function onComplete() {
      NProgressInit()
      NProgress.done()
      if (trackPageViews) addAnalyticsToPage()
    }
    function onError() {
      NProgressInit()
      NProgress.done()
    }

    router.events.on('routeChangeStart', onStart)
    router.events.on('routeChangeComplete', onComplete)
    router.events.on('routeChangeError', onError)

    return () => {
      router.events.off('routeChangeStart', onStart)
      router.events.off('routeChangeComplete', onComplete)
      router.events.off('routeChangeError', onError)
    }
  }, [router.events])
}

export default function MyApp(props) {
  const { Component, pageProps } = props
  // If an SSR page has already fetched the accountsMe data, prepopulate the query cache
  if (pageProps.accountsMe) queryClient.setQueryData(accountsKeys.me(), pageProps.accountsMe)
  const trackPageViews = Component.trackPageViews !== false

  useClearUrlState()
  usePageNavigationProgress({ trackPageViews })

  useEffect(() => {
    // Load pendo flag to body if you were direct to nextjs from a non-nextjs form submission
    extractPendoGuide()
    if (trackPageViews) {
      addAnalyticsToPage()
    }
  }, [])

  const Layout = Component.layout ?? MainLayout

  return (
    <StrictMode>
      <GlobalCSSVariables />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <APIChecks Component={Component} />
          <IntlProviderFromApi anonymous={Component.anonymous} {...pageProps}>
            <ModalProvider>
              <Layout>
                <ErrorPageBoundary>
                  <Component {...pageProps} />
                </ErrorPageBoundary>
              </Layout>
              <StyleReactQueryDevtools />
            </ModalProvider>
          </IntlProviderFromApi>
        </QueryClientProvider>
      </ChakraProvider>
    </StrictMode>
  )
}

const APIChecks = ({ Component }) => {
  const router = useRouter()
  const enabled = router?.isReady && Component.anonymous !== true

  useTermsAndConditionsAcceptedQuery(router.asPath, { enabled })
  useIsMFAVerifiedQuery({ enabled })
  return null
}

const vitalsLoggerLog = createDebugLogger('WebVitals')

export function reportWebVitals(metric) {
  // These metrics can be sent to an analytics service in the future
  vitalsLoggerLog(metric)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function StyleReactQueryDevtools() {
  // The styles here place the toggle icon aligned with and
  // above the nextjs static indicator, and similar shadowing.
  return (
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{
        style: {
          width: '24px',
          opacity: 0.2
        }
      }}
    />
  )
}
