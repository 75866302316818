import {
  Thead,
  Td,
  Tbody,
  Tfoot,
  TableCellProps,
  TableHeadProps,
  TableBodyProps,
  TableFooterProps,
  Box,
  BoxProps,
  forwardRef
} from '../../chakra'
import { SectionHeader } from '../..'
import { ScrollShadowBox } from '../../foundations/data_display/Separators'

/**
 * TableWrapper
 *
 * This is a wrapper component for the Table components to add a default border
 * and enable horizontal and vertical scrolling for table exceeding the parent
 * container. These props can be overriden if these default settings are not the
 * intended behaviour.
 *
 * When a Table is within a Card component, override `border="none"` to prevent
 * a double border from being displayed. See Storybook example:
 * https://storybook.beta.vndly.com/?path=/docs/components-tables--card-table
 */
export const TableWrapper = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Box
    borderWidth="1px"
    borderColor="gray.300"
    borderRadius="base"
    overflow="auto"
    display="block"
    whiteSpace="nowrap"
    WebkitOverflowScrolling="touch"
    maxWidth="100%"
    ref={ref}
    {...props}>
    {children}
  </Box>
))

/**
 * StickyThead
 *
 * When building a table with a large amount of information, a sticky header can be used to maintain the context of what
 * the columns represent. Replace the Thead in a table with the StickyThead component.
 */
export const StickyThead = forwardRef(({ bg = 'gray.100', ...props }: TableHeadProps, ref) => (
  <Thead
    {...props}
    sx={{ '& > tr > th': { pos: 'sticky', top: 0, bg }, tableLayout: 'fixed' }}
    display="table"
    w="full"
    ref={ref}
  />
))

type StickyTbodyProps = {
  shadowHeight?: string
} & TableBodyProps
/**
 * StickyTbody
 *
 * For tables with a sticky header and footer, a scroll shadow indicates that the content of the body
 * can be scrolled to expose more content.
 */
export const StickyTbody = forwardRef(({ shadowHeight = 'auto', ...props }: StickyTbodyProps, ref) => (
  <ScrollShadowBox h={shadowHeight}>
    <Tbody display="table" w="full" sx={{ tableLayout: 'fixed' }} ref={ref} {...props} />
  </ScrollShadowBox>
))
/**
 * StickyTfoot
 *
 * When building a table with a large amount of information, a sticky footer can be used to either to mimick the header
 * or display important information in the footer always. Replace the Tfoot in a table with the StickyTfoot component.
 */
export const StickyTfoot = forwardRef(({ bg = 'white', ...props }: TableFooterProps, ref) => (
  <Tfoot
    {...props}
    sx={{ '& > tr > th': { pos: 'sticky', bottom: 0, bg }, tableLayout: 'fixed' }}
    display="table"
    w="full"
    ref={ref}
  />
))

/**
 * TableSectionHeader
 *
 * Large tables can be divided into sections with our section headers. Use TableSectionHeader as table cells. Replace all Td
 * components with TableSectionHeader and specify the colSpan to be the maximumn number of columns in the table.
 */
export const TableSectionHeader = forwardRef(({ children, ...props }: TableCellProps, ref) => (
  <Td p="0" ref={ref} {...props}>
    <SectionHeader size="sm">{children}</SectionHeader>
  </Td>
))
