import { addDays, addHours, addWeeks, format, parse, areRangesOverlapping, isEqual, isValid } from 'date-fns'
import { formatISO as formatISOv2, parseISO as parseISOv2 } from 'date-fns-v2'
import { useAccountMeQuery } from '~/api/accounts'

// Date and time formats for SOW for now
// TODO: Consolidate a general date utils file for all of VNDLY using date-fns.
// As a result we need to copy over utils from dates.js
export const DATA_DATE_FORMAT = 'YYYY-MM-DD'
export const DATA_TIME_FORMAT = 'HH:mm:ss' // 24 hour time with seconds for APIs
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY'
export const DISPLAY_DATE_TIME_FORMAT = 'MMM D, YYYY, h:mm A'
export const FILTERS_API_DATE_FORMAT = 'DD-MMM-YYYY'
export const FILTERS_API_TIME_FORMAT = 'HH:mm'
export const DATE_FUN_FORMAT = 'DD MMM YYYY'

// Only used for ReactDatePicker widget, doesn't follow date-fns format rules
export const DATE_FORMAT_WIDGET = 'MMM d, yyyy'
// Only used for ReactDatePicker widget, doesn't follow date-fns format rules
export const TIME_FORMAT_WIDGET = 'h:mm a'
// Only used for ReactDatePicker widget, doesn't follow date-fns format rules
export const DATE_TIME_FORMAT_WIDGET = 'MMM d, yyyy, h:mm aa'

export const JSON_SCHEMA_DATE_FORMAT = DATA_DATE_FORMAT
export const JSON_SCHEMA_DATETIME_FORMAT = `${JSON_SCHEMA_DATE_FORMAT} HH:mm`

export const formatLocalDate = date => format(date, DISPLAY_DATE_FORMAT)
export const formatLocalDateTime = datetime => format(datetime, DISPLAY_DATE_TIME_FORMAT)
export const formatDataDate = date => format(date, DATA_DATE_FORMAT)
export const formatDataTime = date => format(date, DATA_TIME_FORMAT)
export const formatFunDate = date => format(date, DATE_FUN_FORMAT)
export const formatNullableDataDate = date => (date ? formatDataDate(date) : null)
export const formatFiltersApiDate = date => format(date, FILTERS_API_DATE_FORMAT)
export const formatFiltersApiTime = date => format(date, FILTERS_API_TIME_FORMAT)
export const formatLocalTime = datetime => format(datetime, TIME_FORMAT_WIDGET)

/**
 * @deprecated use timezone from user profile (useAccountMeQuery)
 */
export const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

// TODO Port everything below to python so reporting can have the same numbers as UI
export const SUNDAY = 0
export const MONDAY = 1
export const TUESDAY = 2
export const WEDNESDAY = 3
export const THURSDAY = 4
export const FRIDAY = 5
export const SATURDAY = 6
export const TYPICAL_WORKED_DAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY]
export const TYPICAL_WEEKEND = [SATURDAY, SUNDAY]
export const TYPICAL_DAYS_WORKED_IN_A_WEEK = TYPICAL_WORKED_DAYS.length
export const TYPICAL_HOURS_WORKED_IN_A_DAY = 8

/**
 * Add work hours to a date, taking into consideration 8 hours in a work day, 5 days in a work week.
 * @param date The starting date
 * @param addedWorkHours The amount of work hours to add
 * @returns {Date}
 */
export const addWorkHours = (date, addedWorkHours) => {
  // Figure out how many full weeks, days and hours we can break convert the hours into
  const hoursWorkedInAWeek = TYPICAL_HOURS_WORKED_IN_A_DAY * TYPICAL_DAYS_WORKED_IN_A_WEEK
  const workWeeks = Math.floor(addedWorkHours / hoursWorkedInAWeek)
  const weekHours = workWeeks * hoursWorkedInAWeek
  const workDays = Math.floor((addedWorkHours - weekHours) / TYPICAL_HOURS_WORKED_IN_A_DAY)
  const dayHours = workDays * TYPICAL_HOURS_WORKED_IN_A_DAY
  const workHours = Math.floor(addedWorkHours - weekHours - dayHours)

  // Add weeks, then days, then hours
  return addHours(addDays(addWeeks(date, workWeeks), workDays), workHours)
}

/**
 * Add work days to a date, taking into consideration 8 hours in a work day, 5 days in a work week.
 * @param date The starting date
 * @param addedWorkDays The amount of work days to add
 * @returns {Date}
 */
export const addWorkDays = (date, addedWorkDays) => {
  return addWorkHours(date, addedWorkDays * TYPICAL_HOURS_WORKED_IN_A_DAY)
}

/**
 * Null-safe wrapper on date-fns.parse
 * @param dateStr
 * @returns {null|Date}
 */
export const safeParse = dateStr => {
  if (dateStr == null) {
    return null
  }
  return parse(dateStr)
}

/**
 * Take a plain date string (2021-08-24) and a plain 24h time string (22:34) and return
 * a Date object in the local time
 *
 * @param dateStr {string | undefined | null}
 * @param timeStr {string | undefined | null}
 * @returns {Date|null}
 */
export const assembleDateFromNaiveDateAndTime = (dateStr, timeStr) =>
  dateStr && timeStr ? parse(`${dateStr}T${timeStr}`) : null

/**
 * inclusive version of date-fns's `areRangesOverlapping()`
 *
 * @param {Date | string | number} initialRangeStartDate
 * @param {Date | string | number} initialRangeEndDate
 * @param {Date | string | number} comparedRangeStartDate
 * @param {Date | string | number} comparedRangeEndDate
 */
export function areRangesOverlappingInclusive(
  initialRangeStartDate,
  initialRangeEndDate,
  comparedRangeStartDate,
  comparedRangeEndDate
) {
  return (
    areRangesOverlapping(initialRangeStartDate, initialRangeEndDate, comparedRangeStartDate, comparedRangeEndDate) ||
    isEqual(initialRangeStartDate, comparedRangeStartDate) ||
    isEqual(initialRangeEndDate, comparedRangeEndDate) ||
    isEqual(initialRangeStartDate, comparedRangeEndDate) ||
    isEqual(initialRangeEndDate, comparedRangeStartDate)
  )
}

/**
 * Returns locale as a BCP 47 formatted language tag
 * Input locale = 'en_US'
 * output locale = 'en-US'
 * If English Computer is selected for locale, the returned language tag is 'en_US_POSIX'
 * '_POSIX' makes it a non-standard lang tag and needs to be removed so parse() doesn't throw an error
 * @param { string | null } locale
 * @returns {string}
 */
export const formatLocale = locale => {
  if (locale == null) {
    return 'en-US'
  }
  return locale.replace('_', '-').replace('_POSIX', '')
}

/**
 * Returns a formatted time based on the locale of the user logged in and the options being passed in
 * Use only when there is a time represented as a string
 * @param { string | null } locale
 * @param { string | null | undefined } time
 * @param { any } options
 * @returns {string}
 */
export const localizeTimeString = (locale = 'en-US', time, options) => {
  return localizeDateTime(locale, parseTime(time), options)
}

/**
 * Returns a formatted date based on the locale of the user logged in and options being passed in
 * Use when there is only a Date represented as a string
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @param { any } options
 * @returns {string}
 */
export const localizeDateString = (locale = 'en-US', date, options) => {
  return localizeDateTime(locale, parse(date), options)
}

/**
 * Returns a formatted date based on the locale of the user logged in and the options provided
 * Use only when a Date object is being passed in
 * @param { string | null } locale
 * @param { Date | null | undefined } date
 * @param { any } options
 * @returns {string}
 */
export const localizeDateTime = (locale = 'en-US', date, options) => {
  if (date === null || date === undefined || isValid(date) === false) {
    return undefined
  }
  const labelFormat = new Intl.DateTimeFormat(formatLocale(locale), options)
  return labelFormat.format(date)
}

/**
 * @type {Intl.DateTimeFormatOptions}
 */
export const DAY_OF_WEEK_SHORT_FORMAT_OPTION = { weekday: 'short' }
/**
 * @type {Intl.DateTimeFormatOptions}
 */
export const DAY_OF_WEEK_LONG_FORMAT_OPTION = { weekday: 'long' }
/**
 * @type {Intl.DateTimeFormatOptions}
 */
export const MONTH_YEAR_FORMAT_OPTION = { month: 'short', year: 'numeric' }
/**
 * @type {Intl.DateTimeFormatOptions}
 */
export const MONTH_DAY_YEAR_FORMAT_OPTION = { month: 'short', day: 'numeric', year: 'numeric' }
/**
 * @type {Intl.DateTimeFormatOptions}
 */
export const MONTH_DAY_FORMAT_OPTION = { month: 'short', day: 'numeric' }
export const HOURS_SHORT_FORMAT_OPTION = { timeStyle: 'short' }
export const TIME_12_HOUR_FORMAT_OPTIONS = { hour: 'numeric', minute: 'numeric', hourCycle: 'h12' }
export const TIME_24_HOUR_FORMAT_OPTIONS = { hour: 'numeric', minute: 'numeric', second: 'numeric', hourCycle: 'h23' }

/**
 * Returns the day of the week formatted as short.
 * EX: If locale is English will show as Mon, if German it will show as Mo.
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @returns {string}
 */
export const localizeDayOfWeekShort = (locale = 'en-US', date) => {
  return localizeDateTime(locale, parse(date), DAY_OF_WEEK_SHORT_FORMAT_OPTION)
}

/**
 * Returns the day of the week formatted as long.
 * EX: If locale is English will show as Monday, if German it will show as Montag
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @returns {string}
 */
export const localizeDayOfWeekLong = (locale = 'en-US', date) => {
  return localizeDateTime(locale, parse(date), DAY_OF_WEEK_LONG_FORMAT_OPTION)
}

/**
 * Returns Month formatted as short and Year as numeric.
 * EX: If locale is English will show as Feb 2023 if Japanese it will show as 2023年2月
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @returns {string}
 */
export const localizeMonthYear = (locale = 'en-US', date) => {
  return localizeDateTime(locale, parse(date), MONTH_YEAR_FORMAT_OPTION)
}

/**
 * Returns Month formatted as short, Day as numeric, and Year as numeric.
 * EX: If locale is English will show as Feb 27, 2023 if German it will show as 27. Feb. 2023
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @returns {string}
 */
export const localizeMonthDayYear = (locale = 'en-US', date) => {
  return localizeDateTime(locale, parse(date), MONTH_DAY_YEAR_FORMAT_OPTION)
}

/**
 * Returns Month formatted as short and Day as numeric.
 * EX: If locale is English will show as Feb 27, if German it will show as 27. Feb.
 * @param { string | null } locale
 * @param { string | null | undefined } date
 * @returns {string}
 */
export const localizeMonthDay = (locale = 'en-US', date) => {
  return localizeDateTime(locale, parse(date), MONTH_DAY_FORMAT_OPTION)
}

/** Returns time formatted as short.
 * EX: If locale is English time will show as 2:30 PM, if German it will show as 14:30
 * @param { string | null } locale
 * @param { string | null | undefined } time
 * @returns {string}
 */
export const localizeHoursMinutes = (locale = 'en-US', time) => {
  return localizeDateTime(locale, parseTime(time), HOURS_SHORT_FORMAT_OPTION)
}

/** Returns the AM and PM equivalent based on the locale used.
 * EX1: If locale is English, which uses the 12-hour format, will return { am: ' AM', pm: ' PM' }
 * EX2: If locale is Japanese, which is a 24-hour format, will return { am: ' ', pm: ' ' }
 * @param { string | null } locale
 */
export const getAmPmForLocale = (locale = 'en-US') => {
  const amString = localizeDateTime(formatLocale(locale), parse('1990-01-01 00:00:00'), {
    timeStyle: 'short'
  }).replace(/([0-9]|:|\s)*/g, '')
  const pmString = localizeDateTime(formatLocale(locale), parse('1990-01-01 23:00:00'), {
    timeStyle: 'short'
  }).replace(/([0-9]|:|\s)*/g, '')
  return { am: ` ${amString}`, pm: ` ${pmString}` }
}

/** Returns a date-fns.parse date in epoch milliseconds when only time is provided
 * Will not be needed once date-fns is upgraded to 2.0 or beyond
 * @param { string | Date | null | undefined } time
 */
export const parseTime = time => {
  if (typeof time !== 'string') {
    return time
  }
  // returns a date object using the time provided
  // when in 24-hour format will not need to be formatted
  const testTimeNonNum = time.replace(/[0-9]/g, '')
  const testMatchArrNonNum = testTimeNonNum.match(/[A-Za-z]/g)

  if (testMatchArrNonNum === null) {
    return parse('1990-01-01 ' + time)
  }

  const testTimeOnlyNum = time.replace(/[0-9]/g, '')
  const testMatchArrOnlyNum = testTimeOnlyNum.match(/[^0-9]/g)

  if (testMatchArrOnlyNum === null) {
    return undefined
  }

  const testTimeOnlyChar = time.replace(/[A-Za-z]/g, '')
  const testMatchArrOnlyChar = testTimeOnlyChar.match(/[0-9]/g)

  if (testMatchArrOnlyChar === null) {
    return undefined
  }

  // Remove whitespace and sets am|pm to lowercase
  // Then extracts out input time, hours, minutes, and meridiem from the time
  const formattedTime = time.replace(/\s+/g, '').toLowerCase()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, hours, minutes = '0', seconds = '0', meridiem] = formattedTime.match(/(\d+)(?::(\d+))?(?::(\d+))?([\s\S]*)/)

  // checks if string contains 'pm' and is not in the 12 pm hour
  // if the time passed in meets these then it sets hourAdj to 12
  // if string contains 'am' and is in the 12 am hour
  // the hourAdj is set to -12, so it's passed in correctly as 00:00
  // otherwise hourAdj is left at 0 for no impact
  // then the hourAdj is added to the extracted hour from time
  if (meridiem.includes('pm') === false && meridiem.includes('am') === false) {
    return undefined
  }
  let hourAdj = 0
  if (meridiem.includes('pm') === true && hours !== '12') {
    hourAdj = 12
  } else if (meridiem.includes('am') === true && hours === '12') {
    hourAdj = -12
  }

  const hoursTwentyFour = Number.parseInt(hours, 10) + hourAdj
  const minInt = Number.parseInt(minutes, 10)
  const secInt = Number.parseInt(seconds, 10)

  if (hoursTwentyFour > 24 || minInt > 59 || secInt > 59 || (meridiem !== 'am' && meridiem !== 'pm')) {
    return undefined
  }

  const parsedDateTime = parse('1990-01-01')
  parsedDateTime.setHours(hoursTwentyFour, minInt, secInt, 0)

  if (localizeDateTime('en-US', parsedDateTime, { timeStyle: 'short' }) === '12:00 AM' && !time.includes('12:00')) {
    return undefined
  }

  return parsedDateTime
}

/** Returns native time independent of locale
 * If given a time of 2:34 PM, returns 14:34:00
 * @param { string | null | undefined } time
 * @returns {string}
 */
export const to24HourTime = time => {
  return localizeDateTime('en-US', parseTime(time), TIME_24_HOUR_FORMAT_OPTIONS)
}

/** Returns native time independent of locale
 * If given a time of 14:34:00, returns 2:34 PM
 * @param { string } time
 * @returns {string}
 */
export const to12HourTime = time => {
  return localizeDateTime('en-US', parseTime(time), TIME_12_HOUR_FORMAT_OPTIONS)
}

/** Returns Intl Locale object
 * @param { string | null } locale
 * @returns {Object}
 */
export const getIntlLocaleObject = locale => {
  return new Intl.Locale(formatLocale(locale))
}

/** Returns Options for jQuery Timepicker based on locale so the rendered time reflects the user's locale format
 * @param { string | null } locale
 * @param { string | null | undefined } time
 * @returns {Object}
 */
export const getJqueryOptions = (locale, time) => {
  const options = { scrollDefault: to24HourTime(time), step: 5 }
  if (localizeHoursMinutes(locale, time) === localizeDateTime(locale, parse(Date.now()), { timeStyle: 'short' })) {
    options.scrollDefault = 'now'
  }

  // check is the locale has a localized am|pm or is 24-hour format
  // if there is an am|pm for the locale it will be in getAmPm.am
  // if there isn't only a single space will be returned
  const getAmPm = getAmPmForLocale(locale)
  if (getAmPm.am !== ' ') {
    options.lang = getAmPm
  } else {
    options.timeFormat = 'H:i'
  }
  return options
}

/** Returns a formatted date only in ISO format YYYY-MM-DD or null
 * @param { Date | null } date
 * @returns { string | null }
 */
export const formatDateOnlyISO = date => {
  if (!date) return null
  return formatISOv2(date, { representation: 'date' })
}

/** Returns a date or null parsed from ISO formatted string
 * @param value string value of a date in ISO format { string | null }
 * @returns { Date | null }
 */
export const parseNullableISO = value => {
  if (!value) return null
  return parseISOv2(value)
}

/** Returns the date stored from a react-datepicker given a parent element and a selector.
 * Useful in jQuery pages that render a react-datepicker
 * @param { Element } parent
 * @param { string } selector
 * @returns { Date | null }
 */
export const getDateFromReactDatePicker = (parent, selector) => {
  const endDateElem = parent.find(selector)
  return $(endDateElem).data('props').selected
}

/** Returns a Date that was in a string ISO format from a parent element and child selector
 * @param { Element } parent
 * @param { string } selector
 * @returns { Date | null }
 */
export const extractDateFromSelector = (parent, selector) => {
  if (!parent) return null

  const element = parent.find(selector)
  if (!element) return null

  const text = element.text()
  if (!text) return null

  return parseISOv2(text)
}

/** Helper used to select a specific function to return formatted date and times based on locale. Has access to Intl Locale object, user locale, and date formatting information
 *-
 * hoursMinutes: returns 2:30 PM
 *
 * monthDay: returns Feb 27
 *
 * monthYear: Feb 2023
 *
 * monthDayYear: returns Feb 27, 2023
 *
 * dayOfWeekLong: returns Monday
 *
 * dayOfWeekShort: returns Mon
 *
 * getAmPm: returns { am: ' AM', pm: ' PM' }
 *
 * intlLocaleObject: returns Intl Locale object
 *
 * The custom format options require either a date object (dateObjectCustomFormat),
 * date represented as a string (dateStringCustomFormat), or a time represented as a string (timeStringCustomFormat).
 *
 * Options are passed in as a JSON object (EX: { month: 'short', day: 'numeric' } ), which are required to specify the format that is returned
 *
 * userLocale: returns logged-in user's locale from useAccountMeQuery()
 * EX: en_US is returned for the locale 'English (United States)'
 *
 * userLocaleBCP47: returns logged-in user's locale from useAccountMeQuery() formatted as BCP 47 Language Tag
 * EX: en-US is returned for the locale 'English (United States)'
 *
 * optionsForJqueryTimePicker: sets options for jQuery Timepicker so the values displayed match the locale format
 * @param { string | null } localeOverride
 */
export const useLocalizeHelpers = (localeOverride = null) => {
  const { data: { locale: localeFromQuery } = {} } = useAccountMeQuery()
  let locale
  if (localeFromQuery == null && localeOverride == null) {
    locale = 'en-US'
  } else if (localeOverride != null) {
    locale = localeOverride
  } else {
    locale = localeFromQuery
  }

  return {
    hoursMinutes: localizeHoursMinutes.bind(null, locale),
    monthDay: localizeMonthDay.bind(null, locale),
    monthYear: localizeMonthYear.bind(null, locale),
    monthDayYear: localizeMonthDayYear.bind(null, locale),
    dayOfWeekLong: localizeDayOfWeekLong.bind(null, locale),
    dayOfWeekShort: localizeDayOfWeekShort.bind(null, locale),
    dateObjectCustomFormat: localizeDateTime.bind(null, locale),
    dateStringCustomFormat: localizeDateString.bind(null, locale),
    timeStringCustomFormat: localizeTimeString.bind(null, locale),
    intlLocaleObject: getIntlLocaleObject.bind(null, locale),
    get24HourTime: to24HourTime,
    get12HourTime: to12HourTime,
    userLocale: locale,
    userLocaleBCP47: formatLocale(locale),
    optionsForJqueryTimePicker: getJqueryOptions.bind(null, locale)
  }
}
