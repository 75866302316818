import { workdayTheme as theme } from '../../theme'
import {
  TEAM_HOME_DEFAULT_STATUS_FILTERS,
  TEAM_WORKERS_DEFAULT_STATUS_FILTERS
} from './time_and_materials/v2/constants'
import { lazyMsg } from '~/common/localizationUtils'

export const FIXED_PRICE = 0
export const TIME_MATERIALS = 1
export const MILESTONE = 2
export const UNIT_PRICE_TYPE = 5
export const NON_BILLABLE = 100 // Used to distinguish a non-billable rendering payment tabs
export const PAYMENT_TYPES = [FIXED_PRICE, TIME_MATERIALS, MILESTONE, UNIT_PRICE_TYPE]
export const PAYMENT_TYPES_L10N = {
  [FIXED_PRICE]: 'sow.payment_types.fixed_price',
  [TIME_MATERIALS]: 'sow.payment_types.time_materials',
  [MILESTONE]: 'sow.payment_types.milestone',
  [UNIT_PRICE_TYPE]: 'sow.payment_types.unit_price'
}

export const PaymentTabNames = { 0: 'FIXED PRICE PAYMENTS', 1: 'TEAM', 2: 'MILESTONES', 5: 'UNITS', 100: 'TEAM' }
export const states = {
  STATE_NEW: 0,
  STATE_DRAFT_CLIENT: 1,
  STATE_DRAFT_VENDOR: 2,
  STATE_AWAITING_VENDOR_REVIEW: 5,
  STATE_AWAITING_VENDOR_REVIEW_FAST_PATH: 6,
  STATE_AWAITING_CLIENT_REVIEW: 10,
  STATE_DRAFT_CLIENT_COUNTERED: 15,
  STATE_DRAFT_CLIENT_COUNTERED_FAST_PATH: 16,
  STATE_DRAFT_CLIENT_REJECTED: 20,
  STATE_DRAFT_CLIENT_APPROVAL: 25,
  STATE_APPROVAL_PENDING: 30,
  STATE_DRAFT_CLIENT_ACCEPTANCE_REJECTED: 32,
  STATE_CONNECTOR_PENDING: 33,
  STATE_ACTIVE: 35,
  STATE_HOLD: 40,
  STATE_CANCELLED: 50,
  STATE_VENDOR_WITHDRAWN: 55,
  STATE_PENDING_CLOSURE_APPROVALS: 60,
  STATE_CLOSURE_INITIATED: 64,
  STATE_CLOSED: 65,
  STATE_PENDING_REOPEN_APPROVALS: 66
}

export const changeOrderStates = {
  STATE_DRAFT: 0,
  STATE_VENDOR_PENDING: 3,
  STATE_PENDING: 5,
  STATE_PENDING_APPROVAL: 9,
  STATE_ACTIVE: 10,
  STATE_CONNECTOR_PENDING: 13,
  STATE_REJECTED: 20,
  STATE_COUNTERED: 30,
  STATE_DISCARDED: 99
}

export const CHANGE_ORDER_IN_PROGRESS_STATES = [
  changeOrderStates.STATE_DRAFT,
  changeOrderStates.STATE_VENDOR_PENDING,
  changeOrderStates.STATE_PENDING,
  changeOrderStates.STATE_PENDING_APPROVAL,
  changeOrderStates.STATE_CONNECTOR_PENDING,
  changeOrderStates.STATE_COUNTERED
]

export const getChangeOrderStateListOptions = isSowConnectorEnabled => [
  {
    text: lazyMsg('common.ui.approved'),
    value: changeOrderStates.STATE_ACTIVE
  },
  {
    text: lazyMsg('common.ui.awaiting_term_review', { term: lazyMsg('term.client') }),
    value: changeOrderStates.STATE_PENDING
  },
  {
    text: lazyMsg('common.ui.awaiting_term_review', { term: lazyMsg('term.vendor', { count: 1 }) }),
    value: changeOrderStates.STATE_VENDOR_PENDING
  },
  {
    text: lazyMsg('term.draft'),
    value: changeOrderStates.STATE_DRAFT
  },
  {
    text: lazyMsg('common.ui.pending_approval', { count: 99 }),
    value: changeOrderStates.STATE_PENDING_APPROVAL
  },
  ...(isSowConnectorEnabled
    ? [
        {
          text: lazyMsg('filter_config.connector_wip'),
          value: changeOrderStates.STATE_CONNECTOR_PENDING
        }
      ]
    : []),
  {
    text: lazyMsg('common.ui.rejected'),
    value: changeOrderStates.STATE_REJECTED
  }
]

export const UNIT_BULK_UPLOAD_CATEGORY = '0eadc43a-97a1-4948-baf1-01727f3d75c3'
export const SOW_BULK_UPLOAD_CATEGORY = 'f52747b2-df00-4186-9b9b-db07959d4fe0'
export const SOW_MILESTONES_BULK_UPLOAD_CATEGORY = '6924515e-2d54-4bea-9f44-303e457dc4a0'
export const SOW_WORKSITES_BULK_UPLOAD_CATEGORY = '1209e40d-78f0-46cc-a057-e36d20107f49'
export const SOW_CHARGE_CODES_BULK_UPLOAD_CATEGORY = '2f12bf83-5ef7-4bc5-91f1-9efa35d8432f'
export const SOW_JOBS_BULK_UPLOAD_CATEGORY = 'ca5bcf31-9727-4cb6-a2e9-0c609df3d635'
export const SOW_CONTRACTORS_BULK_UPLOAD_CATEGORY = '3182d54f-1b96-4e4f-b66e-2017c829c523'
export const SOW_FIXED_PRICE_BULK_UPLOAD_CATEGORY = '4d3c07bc-83b7-40e6-aaa5-f2021429ba67'

export const MULTIPLE_PAYMENT_TYPES_CONFIG = {
  id: 'multiple_payments',
  optionsFromApi: {
    url: '/api/v2/sow-module/sow/payment_types/',
    transform: '[**.payment.{"id":id, "text": payment_type, "value": id.$string()}]'
  },
  title: '',
  description: {
    [FIXED_PRICE]: lazyMsg('sow.payment_types.fixed_price.description'),
    [TIME_MATERIALS]: lazyMsg('sow.payment_types.time_materials.description'),
    [MILESTONE]: lazyMsg('sow.payment_types.milestone.description'),
    [UNIT_PRICE_TYPE]: lazyMsg('sow.payment_types.unit_price.description')
  }
}

export const getMissingFieldMessages = msg => ({
  title: msg('common.ui.title'),
  business_unit: msg('term.business_unit', { count: 1 }),
  payment_type: msg('sow.overview.budgets.multiple_payment_type'),
  planned_start_date: msg('sow.overview.timeline.planned_start_date'),
  planned_end_date: msg('sow.overview.timeline.planned_end_date'),
  client_project_manager: msg('sow.overview.client_sow_manager'),
  vendor_project_manager: msg('sow.overview.vendor_sow_manager'),
  program_fee: msg('sow.administration.program_fee_profile'),
  component: msg('sow.overview.validation.add_clause'),
  milestone: msg('sow.overview.validation.add_milestone'),
  job: msg('sow.overview.validation.add_role_with_position'),
  payment_schedule: msg('sow.overview.validation.add_scheduled_payment'),
  payment_schedule_charge_code: msg('sow.overview.validation.scheduled_payment_needs_charge_code'),
  payment_schedule_update_payment_date: msg('sow.overview.validation.scheduled_payment_update_dates'),
  unit: msg('sow.overview.validation.add_unit')
})

export const DASHBOARD_LABEL_OPTIONS = {
  ONBOARDED: 'ONBOARDED',
  PENDING_APPROVALS: 'PENDING APPROVALS',
  OPEN: 'OPEN POSITION',
  COMPLETE: 'COMPLETE',
  IN_PROGRESS: 'IN PROGRESS',
  NOT_STARTED: 'NOT STARTED',
  COMMITTED: 'COMMITTED',
  INVOICED: 'INVOICED',
  REMAINING_BUDGET: 'REMAINING',
  ACTIVE: 'APPROVED',
  PENDING: 'PENDING',
  DRAFT: 'DRAFT'
}

export const SOW_DASHBOARD_SUMMARY_CATEGORIES_COLORS_SETUP = {
  [DASHBOARD_LABEL_OPTIONS.ONBOARDED]: theme.colors.brand[300],
  [DASHBOARD_LABEL_OPTIONS.PENDING_APPROVALS]: theme.colors.orange[200],
  [DASHBOARD_LABEL_OPTIONS.OPEN]: theme.colors.gray[200],
  [DASHBOARD_LABEL_OPTIONS.COMPLETE]: theme.colors.brand[300],
  [DASHBOARD_LABEL_OPTIONS.IN_PROGRESS]: theme.colors.orange[200],
  [DASHBOARD_LABEL_OPTIONS.NOT_STARTED]: theme.colors.gray[200],
  [DASHBOARD_LABEL_OPTIONS.COMMITTED]: theme.colors.orange[200],
  [DASHBOARD_LABEL_OPTIONS.INVOICED]: theme.colors.brand[300],
  [DASHBOARD_LABEL_OPTIONS.REMAINING_BUDGET]: theme.colors.gray[200],
  [DASHBOARD_LABEL_OPTIONS.ACTIVE]: theme.colors.brand[300],
  [DASHBOARD_LABEL_OPTIONS.PENDING]: theme.colors.orange[200],
  [DASHBOARD_LABEL_OPTIONS.DRAFT]: theme.colors.gray[200]
}

export const DETAILS_TABS_OPTIONS = {
  OVERVIEW: 'OVERVIEW',
  CLAUSES: 'CLAUSES',
  MILESTONES: 'MILESTONES',
  TEAM: 'TEAM',
  CHANGE_ORDERS: 'CHANGE ORDERS',
  CHARGE_CODES: 'CHARGE CODES',
  CHANGE_ORDER_DETAILS: 'CHANGE ORDER DETAILS',
  VERSION_HISTORY: 'VERSION HISTORY',
  SETTINGS: 'SETTINGS'
}

export function getToken(msg) {
  const tokens = new Map([
    [DETAILS_TABS_OPTIONS.OVERVIEW, msg('term.overview')],
    [DETAILS_TABS_OPTIONS.CLAUSES, msg('term.clauses')],
    [DETAILS_TABS_OPTIONS.MILESTONES, msg('term.milestone', { count: 99 })],
    [DETAILS_TABS_OPTIONS.TEAM, msg('common.ui.team')],
    [DETAILS_TABS_OPTIONS.CHANGE_ORDERS, msg('term.change_order', { count: 99 })],
    [DETAILS_TABS_OPTIONS.CHARGE_CODES, msg('term.charge_code', { count: 99 })],
    [
      DETAILS_TABS_OPTIONS.CHANGE_ORDER_DETAILS,
      msg('common.ui.term_details', { term: msg('term.change_order', { count: 1 }) })
    ],
    [DETAILS_TABS_OPTIONS.VERSION_HISTORY, msg('common.ui.version_history')],
    [DETAILS_TABS_OPTIONS.SETTINGS, msg('term.settings')],
    ['FIXED PRICE PAYMENTS', msg('term.fixed_price_payment', { count: 99 })],
    ['UNITS', msg('common.ui.unit', { count: 99 })]
  ])
  return tokens
}

export const DETAILS_TAB_NAVIGATION_MAPPING = (isSowActive, isChangeOrder) => ({
  team: {
    ...(isSowActive && !isChangeOrder && { subRouter: 'workers' }),
    query_params: isSowActive ? TEAM_WORKERS_DEFAULT_STATUS_FILTERS : TEAM_HOME_DEFAULT_STATUS_FILTERS
  }
})

export const SOW_SETTINGS = {
  MODULE: 'sow',
  COMPONENT: 'sow_configuration'
}

export const FIXED_PRICE_PAYMENTS_INVOICE_TYPE = {
  AUTO_INVOICED: 1,
  REQUIRES_APPROVALS: 2
}

export const CONNECTOR_MESSAGES = {
  coupa_not_defined: 'To Be Determined'
}
