import { Alert } from './Alert'
import { Avatar } from './Avatar'
import { Badge } from './Badge'
import { Breadcrumb } from './Breadcrumbs'
import { Button } from './Button'
import { Card } from './Card'
import { Checkbox } from './Checkbox'
import { Divider } from './Divider'
import { Drawer } from './Drawer'
import { FormLabel } from './FormLabel'
import { Form } from './Form'
import { FormError } from './FormError'
import { Heading } from './Heading'
import { Input } from './Input'
import { Link } from './Link'
import { Menu } from './Menu'
import { Modal } from './Modal'
import { NumberInput } from './NumberInput'
import { Popover } from './Popover'
import { Radio } from './Radio'
import { Select } from './Select'
import { Skeleton } from './Skeleton'
import { Stat } from './Stat'
import { Switch } from './Switch'
import { Table } from './Table'
import { Tabs } from './Tabs'
import { Tag } from './Tag'
import { Textarea } from './Textarea'
import { Tooltip } from './Tooltip'

const components = {
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  FormLabel,
  Form,
  FormError,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  NumberInput,
  Popover,
  Radio,
  Select,
  Skeleton,
  Stat,
  Switch,
  Table,
  Tabs,
  Tag,
  Textarea,
  Tooltip
}

export default components
