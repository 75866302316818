import { useState } from 'react'
import { isClient } from '~/common/nextJsUtils'

/*
 * Copied from https://usehooks.com/useLocalStorage/
 * Sync state to local storage so that it persists through a page refresh. Usage is similar to useState except we pass in a local storage key so that we can default to that value on page load instead of the specified initial value.
 */

function useBrowserStorage(storage, key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return initialValue
      if (item) return JSON.parse(item)
      return initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that
  // persists the new value to storage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to storage
      storage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  }

  return [storedValue, setValue]
}

const dummyStorage = { getItem() {}, setItem() {} }

export function useLocalStorage(key, initialValue) {
  return useBrowserStorage(isClient() ? window.localStorage : dummyStorage, key, initialValue)
}

export function useSessionStorage(key, initialValue) {
  return useBrowserStorage(isClient() ? window.sessionStorage : dummyStorage, key, initialValue)
}
