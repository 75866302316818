import { Input } from './Input'

export const Select = {
  baseStyle: {
    icon: {
      fontSize: '1.5rem',
      color: 'gray.400'
    }
  },
  sizes: Input.sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps
}
