import React, { PropsWithRef, RefObject } from 'react'
import { ScrollShadowBox } from '../..'
import { TOP_NAV_HEIGHT } from '../../../common/scrollUtils'
import { StickyHeightExpander, StickyHeightExpanderProps } from '../../../components/v2/StickyHeightExpander'
import { BoxProps, Flex, FlexProps, forwardRef, Stack, StackProps, Tab, TabList, Tabs, TabsProps } from '../../chakra'
import { ListRow } from './ListItems'

/**
 * SidebarHeader
 *
 * The header wrapper for any sidebar list.
 *
 * This component accepts FlexProps as input params.
 */
export const SidebarHeader = forwardRef((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    data-items-list-header
    as="header"
    w="420px"
    bg="white"
    align="center"
    justifyContent="space-between"
    px={6}
    py={3}
    minH="72px"
    borderBottomWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    {...props}
  />
))

SidebarHeader.displayName = 'SidebarHeader'

/**
 * SidebarFooter
 *
 * The footer wrapper for any sidebar list.
 *
 * This component accepts FlexProps as input params.
 */
export const SidebarFooter = forwardRef((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    data-items-list-footer
    as="footer"
    bg="white"
    w="420px"
    align="center"
    color="var(--vndly-color-container-subdued)"
    px={6}
    py={3}
    minH="72px"
    borderTopWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    {...props}
  />
))

SidebarFooter.displayName = 'SidebarFooter'

const BOTTOM_MARGIN = 20

type SidebarSplitListProps = StackProps & Pick<StickyHeightExpanderProps, 'offsetTopPx'>
/**
 * SidebarSplitList
 *
 * The sidebar list wrapper for a split list sidebar. This component will maintain a sticky header on
 * the split list sidebar.
 *
 * This component accepts StackProps and an offsetTopPx as input params. The offsetTopPx is used to determine
 * the offset from the top that header should stick to. By default it's set to 0.
 */
export const SidebarSplitList = forwardRef(
  ({ children, offsetTopPx = TOP_NAV_HEIGHT + BOTTOM_MARGIN, ...props }: SidebarSplitListProps, ref) => (
    <StickyHeightExpander offsetTopPx={offsetTopPx}>
      <Stack data-items-list ref={ref as RefObject<HTMLDivElement>} spacing={0} flex="1" h="full" bg="white" {...props}>
        {children}
      </Stack>
    </StickyHeightExpander>
  )
)

SidebarSplitList.displayName = 'SidebarSplitList'

type SidebarSplitListItemsProps = {
  tabsRef?: PropsWithRef<any>['ref']
  tabsListRef?: PropsWithRef<any>['ref']
} & TabsProps

/**
 * SidebarSplitListItems
 *
 * The sidebar list items wrapper for split list sidebars.
 *
 * This component accepts TabsProps and tab ref information, if neccessary, as input params.
 */
export const SidebarSplitListItems = forwardRef(
  ({ tabsRef, tabsListRef, children, onChange, ...props }: SidebarSplitListItemsProps, ref) => (
    <ScrollShadowBox data-items-list-items data-testid="items-list-items" role="list" w="fit-content" ref={ref}>
      <Tabs ref={tabsRef} variant="unstyled" orientation="vertical" w="420px" onChange={onChange} isManual {...props}>
        <TabList ref={tabsListRef} w="full">
          {children}
        </TabList>
      </Tabs>
    </ScrollShadowBox>
  )
)

SidebarSplitListItems.displayName = 'SidebarSplitListItems'

/**
 * SidebarSplitItem
 *
 * Single list item for split list sidebars. This component is already embedded in custom components to build
 * the various Split List Item formats (Reference ~/design_system/components/lists/SidebarListItems.tsx). If a
 * new format of a split list item is needed, this component can be used as the wrapper for each list item.
 *
 * This component accepts BoxProps and an isSelected boolean as input params. The isSelected param can be set
 * when a list item tab is selected.
 */
export const SidebarSplitItem = forwardRef(
  ({ children, isSelected = false, ...props }: BoxProps & { isSelected?: boolean }, ref) => {
    const tabSelectedStyles = { backgroundColor: 'gray.100' }
    const rowSelectedStyles = {
      borderLeftWidth: '4px',
      borderLeftColor: 'blue.500',
      pl: 'calc(1.5rem - 4px)',
      backgroundColor: 'blue.50'
    }
    return (
      <Tab
        p={0}
        textAlign="left"
        _focus={{ boxShadow: 'inset 0 0 0 3px rgba(66,153,225,0.6)' }}
        borderColor="gray.200"
        borderTopWidth="1px"
        _hover={{ bg: 'blackAlpha.50' }}
        _first={{ borderTopWidth: 0 }}
        _last={{ borderBottomWidth: 0 }}
        ref={ref}
        {...(isSelected && tabSelectedStyles)}>
        <ListRow
          justifyContent="space-between"
          cursor="default"
          _hover={{ bg: 'blackAlpha.50' }}
          {...(isSelected && rowSelectedStyles)}
          {...props}>
          {children}
        </ListRow>
      </Tab>
    )
  }
)

SidebarSplitItem.displayName = 'SidebarSplitItem'
