import { Input } from './Input'

function getSize(size) {
  const sizeStyle = Input.sizes[size]
  const radius = {
    lg: 'md',
    md: 'base',
    sm: 'sm',
    xs: 'xs'
  }

  return {
    field: {
      ...sizeStyle.field
    },
    stepper: {
      _first: {
        borderTopEndRadius: radius[size]
      },
      _last: {
        borderBottomEndRadius: radius[size]
      }
    }
  }
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg')
}
export const NumberInput = {
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps
}
