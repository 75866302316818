import { IntlShape } from '@formatjs/intl'
import React from 'react'
import { Catalog, Context, dispatch, MessageKey, ResultWrapper } from './runtime'

class JSXWrapper implements ResultWrapper<JSX.Element | null> {
  empty() {
    return null
  }

  value(value: any) {
    return value
  }

  reduce(values: any[]) {
    if (values?.every?.(v => typeof v === 'string')) {
      return <>{values.join('')}</>
    }

    return (
      <>
        {values.map((v, i) => (
          <React.Fragment key={i}>{v}</React.Fragment>
        ))}
      </>
    )
  }

  element(
    value: React.ReactElement,
    _intl: IntlShape,
    _catalog: Catalog,
    _wrapper: ResultWrapper<JSX.Element>,
    _depth: number
  ) {
    return value
  }
}
const jsxWrapper = new JSXWrapper()

type ExecuteMessageProps = { messageKey: MessageKey; intl: IntlShape; catalog: Catalog; context: Context }
export function ExecuteMessage({ messageKey, intl, catalog, context }: ExecuteMessageProps) {
  const exp = catalog(intl.locale, messageKey)
  return exp ? dispatch(exp, intl, catalog, context, jsxWrapper, 0) : messageKey
}
