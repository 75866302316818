import { Global } from '@emotion/react'
import Head from 'next/head'
import { workdayTheme as theme } from '~/theme'

export const BaseLayout = () => {
  return (
    <>
      <Head>
        <title>Workday VNDLY</title>
        <link rel="shortcut icon" href="/static/images/favicon.ico?v=2" />
      </Head>
      <Global
        styles={{
          body: {
            color: theme.colors.gray[800],
            background: theme.colors.gray[100],
            marginTop: '0px !important'
          }
        }}
      />
    </>
  )
}
