const baseStyleHelperText = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 'base',
  color: 'gray.500',
  mt: 0,
  mb: 2
}

export const Form = {
  baseStyle: {
    helperText: baseStyleHelperText
  }
}
