import moment from 'moment'
import { chunk, groupBy, orderBy } from 'lodash'
import { useActionBarContext } from '~/components/v2/ResultsLayout/ActionBar/ActionBar.hook'
import { VndlyCurrencyDisplay } from '~/components/VndlyCurrencyDisplay'
import { TaskDataStoreType, UserTask, UserTaskStatus } from '~/user_tasks/types'
import { useMsg } from '~/common/localizationUtils'

export const useStatusBadge = () => {
  const msg = useMsg()
  return (statusCode: number) => {
    switch (statusCode) {
      case UserTaskStatus.Pending:
        return { title: msg('tasks.badge.pending'), code: 'pending' }
      case UserTaskStatus.Overdue:
        return { title: msg('tasks.badge.overdue'), code: 'danger' }
      case UserTaskStatus.Cancelled:
        return { title: msg('tasks.badge.cancelled'), code: 'incomplete' }
      case UserTaskStatus.Completed:
        return { title: msg('tasks.badge.completed'), code: 'complete' }
      default:
        return { title: msg('tasks.badge.unknown'), code: 'incomplete' }
    }
  }
}

export const chunkArray = (items: TaskDataStoreType, chunkSize = 2) => {
  const arrayFromObject = Object.entries(items || {}).map(([key, value]) => ({ [key]: value }))
  return chunk(arrayFromObject, chunkSize)
}

const formatDataStoreValue = (valueData: { type: string; value: any; code: string; format: string }) => {
  if (valueData?.type === 'date') {
    return moment(valueData.value).format('ll')
  }
  if (valueData?.type === 'datetime') {
    const t = new Date(valueData.value * 1000)
    return moment(t).format('lll')
  }
  if (valueData?.type === 'currency') {
    return (
      <VndlyCurrencyDisplay
        currency={valueData.code}
        value={valueData.value}
        includeCurrencySymbol={!!valueData.code}
        includeCurrencyCode={!valueData.code}
      />
    )
  }
  return ''
}

export const getDataStoreValue = (dataStore: { [x: string]: any }, key: string) => {
  const value = dataStore[key]
  if (typeof value === 'object') {
    return formatDataStoreValue(value)
  }
  return value
}

export function useCategorizedTasks(rawTasksList: UserTask[]) {
  const { ordering } = useActionBarContext()
  const categorySort = ordering?.value === 'category_za' ? 'desc' : 'asc'
  const sortedTasks = orderBy(rawTasksList, ['category_name', 'is_pinned'], [categorySort, 'desc'])
  return groupBy(sortedTasks, 'category_name')
}
