import { useEffect, useMemo, useRef } from 'react'
import { isEqual } from 'lodash'

export function useDeepCompareMemoize(value) {
  const ref = useRef()
  if (!isEqual(value, ref.current)) ref.current = value
  return ref.current
}

export default function useDeepEqualEffect(callback, dependencies, refs = []) {
  const ref = useRef({ refs })
  ref.current.refs = refs
  useEffect(() => callback(...ref.current.refs), useDeepCompareMemoize(dependencies))
}

// I think the refs part of the useDeepEqualEffect above is busted... like they won't actually retain
// an up to date reference, it will still just be a frozen scoped snapshot of the ref values
// trying this new pattern...
// we can fix the above later...
export function useDeepEqualEffect2(callback, dependencies, refs) {
  const refsRef = useRef(refs)
  refsRef.current = refs
  useEffect(() => callback(refsRef), useDeepCompareMemoize(dependencies))
}

export function useDeepEqualMemo(callback, dependencies, refs) {
  const refsRef = useRef(refs)
  refsRef.current = refs
  return useMemo(() => callback(refsRef), useDeepCompareMemoize(dependencies))
}
