import React, { Fragment, createContext, useContext, ReactNode } from 'react'
import { mix } from 'polished'
import { forwardRef, useTheme, Grid, GridProps, Stack, StackProps, Box, BoxProps, Flex, FlexProps } from '../../chakra'
import { percentToHex } from '../../../utils/colorUtils'
import { Check, Close } from '../../../icons'

const DEFAULT_HORIZONTAL_CIRCLE_SIZES = { past: 'sm', present: 'lg', future: 'sm' }
const DEFAULT_VERTICAL_CIRCLE_SIZES = { past: 'sm', present: 'sm', future: 'sm' }

type StepperProps = {
  children: ReactNode
} & TimelineGridProps

/**
 * Stepper
 *
 * Steppers (or wizards) are used to break-up long forms into separate steps. Vertical steppers can be
 * used inside stepper modals or cards. Please limit the number of steps to no more than 4 steps.
 *
 * Horizontal steppers are better served for longer forms on dedicated pages. Please limit the number of steps to no more than 6 steps.
 */
export const Stepper = forwardRef(
  (
    {
      numSteps,
      activeStep = 0,
      isVertical = false,
      sizes = isVertical ? DEFAULT_VERTICAL_CIRCLE_SIZES : DEFAULT_HORIZONTAL_CIRCLE_SIZES,
      rowHeight,
      children,
      ...props
    }: StepperProps,
    ref
  ) => {
    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...child.props, activeStep })
      }
      return child
    })

    return (
      <TimelineGrid
        isVertical={isVertical}
        sizes={sizes}
        numSteps={numSteps}
        activeStep={activeStep}
        rowHeight={rowHeight}
        columnGap={isVertical ? 6 : 0}
        rowGap={6}
        {...props}
        ref={ref}>
        <TimelineGridBar />

        {childrenWithProps}
      </TimelineGrid>
    )
  }
)

type StepProps = {
  icon?: ReactNode
  children: ReactNode
} & GridStepCircleProps

/**
 * Step
 *
 * Individual step within a stepper. Step components must be wrapped within a Stepper component. Each step consists
 * of a step cicle and label content. There is the option to set a denied/rejected status by passing in the isRejected prop.
 */
export const Step = forwardRef(
  ({ stepNum, activeStep = 0, icon, children, isRejected = false, ...props }: StepProps, ref) => {
    let textStyle = 'subtext'
    if (stepNum === activeStep) textStyle = 'bodySemi'
    if (activeStep > stepNum) textStyle = 'body'
    return (
      <Fragment key={`step-${stepNum}`}>
        <TimelineGridStepCircle stepNum={stepNum} isRejected={isRejected} {...props} ref={ref}>
          {icon}
        </TimelineGridStepCircle>
        <TimelineGridStepContent stepNum={stepNum} textStyle={textStyle}>
          {children}
        </TimelineGridStepContent>
      </Fragment>
    )
  }
)

/**
 * Step Highlight
 *
 * A component that adds a light gray "highlight" on a given row of a vertical
 * stepper component.
 *
 * Only supports vertical steppers right now.
 */
export const StepHighlight = forwardRef(({ stepNum, ...props }: GridStepContentProps, ref) =>
  stepNum > -1 ? (
    <Box
      data-testid="timeline-highlight-step-bg"
      gridRow={stepNum}
      gridColumn="1 / end"
      alignSelf="stretch"
      justifySelf="stretch"
      bg="gray.100"
      ml="17px"
      ref={ref}
      {...props}
    />
  ) : null
)

/**
 * Timeline Grid Context
 */
type TimelineGridContext = {
  numSteps: number
  activeStep?: number
  isVertical?: boolean
  sizes?: {
    past?: StepCircleSize
    present?: StepCircleSize
    future?: StepCircleSize
  }
  rowHeight?: string
}

const TimelineGridContext = createContext<TimelineGridContext>({
  numSteps: 0,
  sizes: DEFAULT_HORIZONTAL_CIRCLE_SIZES
})

type TimelineGridProps = GridProps & TimelineGridContext

/**
 * Timeline Grid Container
 *
 * The main container for the timeline, it's uses a grid for positioning the bar, step circles and content.
 * It also provides contextual data to the child components.
 */
const TimelineGrid = forwardRef<TimelineGridProps, 'div'>(
  (
    {
      numSteps = 0,
      activeStep = 0,
      isVertical = false,
      sizes = DEFAULT_HORIZONTAL_CIRCLE_SIZES,
      rowHeight = '42px',
      ...props
    }: TimelineGridProps,
    ref
  ) => (
    <TimelineGridContext.Provider value={{ numSteps, activeStep, isVertical, sizes, rowHeight }}>
      <Grid
        ref={ref}
        data-testid="timeline-grid"
        templateColumns={isVertical ? '32px 1fr;' : `repeat(${numSteps}, 1fr)`}
        templateRows={isVertical ? `repeat(${numSteps}, min(${rowHeight}))` : 'repeat(2, 1fr)'}
        autoFlow={isVertical ? 'column' : 'row'}
        justifyItems="center"
        position="relative"
        {...props}
      />
    </TimelineGridContext.Provider>
  )
)

type TimelineBarProps = StackProps & {
  progress: number
  isVertical?: boolean
  thickness?: BoxProps['w']
}

/**
 * Timeline Grid Bar
 *
 * A version of TimelineBar that knows how to render within the TimelineGrid based
 * on the numSteps and activeStep context values
 */
const TimelineGridBar = (props: Omit<TimelineBarProps, 'progress'>) => {
  const { numSteps, activeStep = 0, isVertical } = useContext(TimelineGridContext)
  const progress = Math.round((100 / Math.max(0, numSteps - 1)) * Math.max(0, activeStep - 1))
  const totalWidth = `${100 * (1 - 1 / numSteps)}%`
  return (
    <TimelineBar
      data-testid="timeline-bar"
      gridRow={isVertical ? '1 / end' : '1'}
      gridColumn={isVertical ? '1' : '1 / end'}
      alignSelf="center"
      mt={isVertical ? '-0.5rem' : '0'}
      isVertical={isVertical}
      progress={progress}
      {...(isVertical ? { h: totalWidth } : { w: totalWidth })}
      {...props}
    />
  )
}

/**
 * A stand-alone timeline bar. If using within a TimelineGrid, then you should use
 * TimelineGrid.Bar instead.
 */
const TimelineBar = forwardRef(({ progress, isVertical, thickness = '2px', ...props }: TimelineBarProps, ref) => {
  const gradientPerc = `${progress}%`
  const dashedPerc = `${100 - progress}%`
  return (
    <Stack isInline={!isVertical} ref={ref} {...props}>
      <Box
        w={isVertical ? thickness : gradientPerc}
        h={isVertical ? gradientPerc : thickness}
        layerStyle="brandGradient"
      />
      <Box
        w={isVertical ? thickness : dashedPerc}
        h={isVertical ? dashedPerc : thickness}
        borderTopWidth={isVertical ? 0 : thickness}
        borderLeftWidth={isVertical ? thickness : 0}
        borderStyle="dashed"
        borderColor="gray.300"
      />
    </Stack>
  )
})

type StepCircleSize = 'sm' | 'lg' | FlexProps['w']
type StepCircleProps = BoxProps & {
  /**
  - zero indicates the present state
  - positive numbers are future steps
  - negative numbers are past steps
  */
  stepNum: number
  activeStep?: number
  isRejected?: boolean
  size?: StepCircleSize
}

type GridStepCircleProps = StepCircleProps & {
  stepNum: number
  isRejected?: boolean
}

/**
 * Timeline Grid Step Circle
 * A version of StepCircle that knows where to render within the grid and knows it's own distanceFromActiveStep
 */
const TimelineGridStepCircle = forwardRef<GridStepCircleProps, 'div'>(
  ({ stepNum, ...props }: GridStepCircleProps, ref) => {
    const { isVertical } = useContext(TimelineGridContext)
    return (
      <StepCircle
        ref={ref}
        stepNum={stepNum}
        gridRow={isVertical ? stepNum : '1'}
        gridColumn={isVertical ? '1' : stepNum}
        alignSelf="center"
        zIndex={1}
        {...props}
      />
    )
  }
)

/**
 * A stand-alone step circle. If using within a TimelineGrid, then you should use
 * TimelineGrid.StepCircle instead.
 */
const StepCircle = forwardRef<StepCircleProps, 'div'>(
  (
    {
      stepNum = 0,
      activeStep: activeStepOverride = 0,
      isRejected = false,
      size: overriddenSize,
      bg,
      children,
      ...props
    }: StepCircleProps,
    ref
  ) => {
    const { sizes, activeStep = activeStepOverride, isVertical } = useContext(TimelineGridContext)
    const { colors } = useTheme()

    // @ts-ignore
    const startColor = colors.brand[500]
    const endColor = colors.blue[500]
    function gradiatedBg(activeStep: number, stepNum: number) {
      const percent = (stepNum - 1) / Math.max(1, activeStep - 1)
      return mix(percent, endColor, startColor)
    }

    const stateStyles = {
      past: () => ({
        color: 'white',
        bg: gradiatedBg(activeStep, stepNum)
      }),
      present: () => ({
        color: 'white',
        bg: 'blue.500',
        boxShadow: `0 0 0 6px ${(bg as string) ?? colors.blue[500]}${percentToHex(30)}`
      }),
      future: () => ({
        color: 'gray.300',
        bg: 'white',
        border: '2px',
        borderColor: 'gray.300'
      })
    }

    const rejectedStyles = {
      color: 'white',
      bg: 'red.500'
    }

    const distanceFromActiveStep = stepNum - activeStep
    let state: 'past' | 'present' | 'future'
    if (distanceFromActiveStep === 0) state = 'present'
    else state = distanceFromActiveStep < 0 ? 'past' : 'future'

    type sizes = 'sm' | 'lg'
    const size = overriddenSize ?? sizes?.[state] ?? 'lg'
    let chakraSize = size
    if (size === 'lg') chakraSize = 8
    if (size === 'sm') chakraSize = 4

    const margin = {
      sm: `${(52 - 16) / 2}px`,
      lg: `${(52 - 32) / 2}px`
    }

    let defaultIcon = null
    if (state === 'past') defaultIcon = <Check />
    if (state === 'present') defaultIcon = <Box w={3} h={3} bg="white" borderRadius="full" />
    if (isRejected) defaultIcon = <Close />

    const styles = isRejected ? rejectedStyles : stateStyles[state]()

    return (
      <Flex
        data-state={state}
        data-testid={`timeline-step-circle-${state}`}
        data-rejected={isRejected}
        role="img"
        aria-label={`step ${stepNum}`}
        ref={ref}
        borderRadius="50%"
        w={chakraSize}
        h={chakraSize}
        m={margin[size as sizes] ?? 0}
        position="relative"
        alignItems="center"
        justifyContent="center"
        {...styles}
        {...(bg && { bg })}
        {...props}>
        {stepNum > 1 && isRejected && (
          <Box
            display="block"
            position="absolute"
            width={isVertical ? '2px' : '40px'}
            height={isVertical ? '20px' : '2px'}
            // eslint-disable-next-line no-nested-ternary
            top={isVertical ? '-20px' : size === 'sm' ? '7px' : '15px'}
            // eslint-disable-next-line no-nested-ternary
            left={isVertical ? (size === 'sm' ? '7px' : '15px') : '-40px'}
            background={`linear-gradient(${isVertical ? '180deg' : '90deg'}, rgba(211,9,49,0), rgba(211,9,49,1))`}
          />
        )}
        {size !== 'sm' && (children ?? defaultIcon)}
      </Flex>
    )
  }
)

type GridStepContentProps = BoxProps & {
  stepNum: number
}
/**
 * Timeline Grid Step Content
 *
 * A simple component that knows how to render arbitrary content into the TimelineGrid next to a step circle
 */
const TimelineGridStepContent = forwardRef(({ stepNum, ...props }: GridStepContentProps, ref) => {
  const { isVertical } = useContext(TimelineGridContext)
  return (
    <Box
      data-testid="timeline-step-content"
      textAlign={isVertical ? 'left' : 'center'}
      {...(isVertical
        ? { gridRow: stepNum, gridColumn: '2', justifySelf: 'start', alignSelf: 'center' }
        : { gridRow: '2', gridColumn: stepNum })}
      ref={ref}
      {...props}
    />
  )
})
