import { AnimatePresence, motion } from 'framer-motion'
import React, { cloneElement, createContext, useContext } from 'react'
import { objectHookToContext } from '~/common/hookToContext'
import { Msg } from '~/common/localizationUtils'
import { TOP_NAV_HEIGHT_PX } from '~/common/scrollUtils'
import { ToggledNextLink } from '~/components/v2/next/ToggledNextLink'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  forwardRef,
  Spacer,
  useDisclosure,
  UseDisclosureReturn,
  VisuallyHidden
} from '~/design_system'
import { ChevronLeft, ChevronRight } from '~/icons'
import { useMenuItemProps } from '~/navigation_menu/constants'

const DrawerContext = createContext<{ disclosure: UseDisclosureReturn | null }>({ disclosure: null })
export function MainMenusMobile({ disclosure }: { disclosure: UseDisclosureReturn }) {
  const menuItems = useMenuItemProps({ isMobile: true })

  return (
    <DrawerContext.Provider value={{ disclosure }}>
      <Box sx={{ '@media(min-width: 1350px)': { display: 'none' } }}>
        <Drawer size="xs" placement="left" isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
          <DrawerOverlay top={TOP_NAV_HEIGHT_PX} />
          <DrawerContent
            top={`${TOP_NAV_HEIGHT_PX} !important`}
            boxShadow={getBoxShadowThatLooksLikeUnderTopNav()}
            borderTop="1px"
            borderColor="soap500">
            <DrawerBody data-testid="mobile-menu-drawer" as="nav" aria-labelledby="main-menu-label" p={0}>
              <VisuallyHidden as="h2" id="main-menu-label">
                <Msg id="nav.main_menu" description="Main Menu (for Screen Reader)" />
              </VisuallyHidden>
              <MobileMenuButton {...menuItems.home.top} />
              {!menuItems.sow_menu.top.isHidden && (
                <MobileMenu id="mobile_sow_menu">
                  <MobileMenuButton {...menuItems.sow_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.sow_menu.sow} />
                    <MobileMenuItemLink {...menuItems.sow_menu.rfp} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              {!menuItems.jobs_menu.top.isHidden && (
                <MobileMenu id="mobile_jobs_menu">
                  <MobileMenuButton {...menuItems.jobs_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.jobs_menu.jobs} />
                    <MobileMenuItemLink {...menuItems.jobs_menu.interview_schedules} />
                    <MobileMenuItemLink {...menuItems.jobs_menu.interview_management} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              <MobileMenuButton {...menuItems.candidate_profile.top} />
              <MobileMenuButton {...menuItems.candidates.top} />
              {!menuItems.contractors_menu.top.isHidden && (
                <MobileMenu id="mobile_contractors_menu">
                  <MobileMenuButton {...menuItems.contractors_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.contractors_menu.contractors_summary} />
                    <MobileMenuItemLink {...menuItems.contractors_menu.preidentified_candidates} />
                    <MobileMenuItemLink {...menuItems.contractors_menu.workers_summary} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              {!menuItems.invoices_menu.top.isHidden && (
                <MobileMenu id="mobile_invoices_menu">
                  <MobileMenuButton {...menuItems.invoices_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.invoices_menu.invoices_summary} />
                    <MobileMenuItemLink {...menuItems.invoices_menu.invoice_payments} />
                    <MobileMenuItemLink {...menuItems.invoices_menu.misc_adjustments} />
                    <MobileMenuItemLink {...menuItems.invoices_menu.invoice_files} />
                    <MobileMenuItemLink {...menuItems.invoices_menu.invoice_job_status} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              {!menuItems.timesheets_menu.top.isHidden && (
                <MobileMenu id="mobile_timesheets_menu">
                  <MobileMenuButton {...menuItems.timesheets_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.timesheets_menu.timesheet_summary} />
                    <MobileMenuItemLink {...menuItems.timesheets_menu.timesheet_archives} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              <MobileMenuButton {...menuItems.dashboards.top} />
              {!menuItems.reports_menu.top.isHidden && (
                <MobileMenu id="mobile_reports_menu">
                  <MobileMenuButton {...menuItems.reports_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.reports_menu.reports} />
                    <MobileMenuItemLink {...menuItems.reports_menu.file_transfer_reports} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              {!menuItems.more_menu.top.isHidden && (
                <MobileMenu id="mobile_more_menu">
                  <MobileMenuButton {...menuItems.more_menu.top} />
                  <MobileMenuList>
                    <MobileMenuItemLink {...menuItems.more_menu.approvals} />
                    <MobileMenuItemLink {...menuItems.more_menu.documents} />
                    <MobileMenuItemLink {...menuItems.more_menu.vendors} />
                    <MobileMenuItemLink {...menuItems.more_menu.users} />
                    <MobileMenuItemLink {...menuItems.more_menu.company_profile} />
                    <MobileMenuItemLink {...menuItems.more_menu.work_orders} />
                    <MobileMenuItemLink {...menuItems.more_menu.worker_tracking} />
                    <MobileMenuItemLink {...menuItems.more_menu.expenses} />
                    <MobileMenuItemLink {...menuItems.more_menu.bulk_updates} />
                    <MobileMenuItemLink {...menuItems.more_menu.checklists} />
                    <MobileMenuItemLink {...menuItems.more_menu.api_docs} />
                    <MobileMenuItemLink {...menuItems.more_menu.tenant_metadata} />
                    <MobileMenuItemLink {...menuItems.more_menu.company_settings} />
                  </MobileMenuList>
                </MobileMenu>
              )}
              <MobileMenuButton {...menuItems.candidate_timesheets.top} />
              <MobileMenuButton {...menuItems.candidate_expenses.top} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </DrawerContext.Provider>
  )
}

// MENU COMPONENTS

const [MobileMenuProvider, useMobileMenu] = objectHookToContext(({ id }: { id?: string }) => {
  const disclosure = useDisclosure({ id })
  return { id, ...disclosure }
}, false)

const MobileMenu = (props: BoxProps) => {
  return (
    <MobileMenuProvider id={props.id}>
      <Box {...props} />
    </MobileMenuProvider>
  )
}

type MobileMenuButtonProps = ButtonProps & { href?: string; isHidden?: boolean }

const MobileMenuButton = forwardRef<MobileMenuButtonProps, 'button'>(
  ({ href, leftIcon, rightIcon, children, isHidden, ...props }, ref) => {
    const mobileMenu = useMobileMenu()
    const opensMenuList = mobileMenu && !href && !props.onClick
    const drawerContext = useContext(DrawerContext)

    if (isHidden) return null

    const button = (
      <Button
        size="none"
        variant="mobileMenu"
        ref={ref}
        as={href ? 'a' : undefined}
        href={href || undefined}
        role={href ? 'menuitem' : undefined}
        onClick={opensMenuList ? mobileMenu?.onToggle : drawerContext.disclosure?.onClose}
        {...(opensMenuList && {
          'aria-expanded': mobileMenu?.isOpen,
          'aria-controls': `menu-list-${mobileMenu?.id}`
        })}
        {...props}>
        {leftIcon && cloneElement(leftIcon, { className: 'menu-button__icon' })}
        {children}
        <Spacer />
        {opensMenuList && <ChevronRight className="mobile-button__chevron" />}
      </Button>
    )

    if (href)
      return (
        <ToggledNextLink href={href} passHref={false}>
          {button}
        </ToggledNextLink>
      )

    return button
  }
)

MobileMenuButton.displayName = 'MobileMenuButton'

const MobileMenuList = ({ children, ...props }: BoxProps) => {
  const mobileMenu = useMobileMenu()
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        width: 'full',
        height: 'full',
        zIndex: 'modal',
        pointerEvents: mobileMenu?.isOpen ? undefined : 'none'
      }}>
      <AnimatePresence>
        {mobileMenu?.isOpen && (
          <motion.div
            key="mobile-menu-sub-menu"
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: 25 }}
            exit={{ opacity: 0, translateX: 25 }}
            style={{ height: '100%', width: '100%' }}
            transition={{ ease: 'easeIn', duration: 0.15 }}>
            <Box
              id={mobileMenu?.id}
              role="menu"
              sx={{
                background: 'white',
                width: 'full',
                height: 'full',
                overflowY: 'auto',
                boxShadow: getBoxShadowThatLooksLikeUnderTopNav()
              }}
              {...props}>
              <MobileMenuButton leftIcon={<ChevronLeft />} onClick={mobileMenu?.onClose}>
                <Msg id="common.ui.back" />
              </MobileMenuButton>
              {children}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export const MobileMenuItemLink = MobileMenuButton

const getBoxShadowThatLooksLikeUnderTopNav = () => 'inset 0 5px 7px -7px rgba(0,0,0,0.4)'
