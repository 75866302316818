import { UseQueryOptions, QueryFunction, QueryKey, useQuery } from '@tanstack/react-query'

import { THIRTY_MINUTES } from '~/common/cache'
import createDebugLogger from '~/common/logger'
import { isClient, isServer, useIsMounting } from '~/common/nextJsUtils'

declare global {
  interface Window {
    parsedResponses: Record<string, any>
  }
}

if (isClient()) {
  window.parsedResponses = {}
}

const debug = createDebugLogger('useQueryWithLocalStoragePersist')

export type QueryWithLocalStorageOptions<TResult, TError = Error> = UseQueryOptions<TResult, TError> & {
  localStorageKey: string
  preventClientServerMismatches?: boolean
  isValidLocalStorageData?: ValidateShapePredicate
  localStorageExpiryMs?: number
}

/**
 * A useQuery hook that will store your response into local storage and then hydrate from storage on future page loads.
 *
 * Local storage data has a default expiry of thirty minutes.
 */
export function useQueryWithLocalStoragePersist<TResult, TError = Error>(
  key: QueryKey,
  queryFn: QueryFunction<TResult>,
  opts: QueryWithLocalStorageOptions<TResult, TError>
) {
  debug(() => [`[${opts.localStorageKey}]`, 'START'])
  const query = useQuery({
    queryKey: key,
    queryFn,
    ...opts,
    keepPreviousData: true,
    onSuccess(data) {
      setLocalStorageWithTTL(opts.localStorageKey, data, opts.localStorageExpiryMs ?? THIRTY_MINUTES)
      opts?.onSuccess?.(data)
    }
  })

  // Later versions of react-query actually have a placeholderData feature we could use. Til then, we home roll it.
  const isMounting = useIsMounting()
  if (query.data === undefined && isClient()) {
    const localStorageData = getLocalStorageJSON(opts.localStorageKey, opts.isValidLocalStorageData)
    const canUseLocalStorageDuringMount = isMounting && !opts.preventClientServerMismatches
    if (localStorageData && (canUseLocalStorageDuringMount || !isMounting)) {
      debug(() => [
        `[${opts.localStorageKey}]`,
        '  -- return Placeholder data',
        { localStorageData, isMounting, canUseLocalStorageDuringMount }
      ])
      return {
        ...query,
        data: localStorageData,
        isLoading: false,
        isSuccess: true,
        state: 'success'
      }
    }
  }

  debug(() => [`[${opts.localStorageKey}]`, '  -- return Query data', { isMounting, query }])
  return query
}

export type ValidateShapePredicate<TData = any> = (data: TData) => boolean

export function getLocalStorageJSON<TData = any>(
  localStorageKey: string,
  isValidData: ValidateShapePredicate<TData> = () => true
): TData | undefined {
  if (isServer()) return undefined

  debug(() => [`[${localStorageKey}]`, '  -- getLocalStorageJSON'])

  const storedParsed = window.parsedResponses[localStorageKey]
  if (storedParsed !== undefined) {
    debug(() => [`[${localStorageKey}]`, '     -- return storedParsed', storedParsed])
    return storedParsed
  }

  const storedInitialData = window.localStorage.getItem(localStorageKey)
  if (storedInitialData) {
    // attempt to parse the json and set it as the initial data
    try {
      const data: { expiry: number; value: TData } = JSON.parse(storedInitialData)
      debug(() => [`[${localStorageKey}]`, '     -- Local Storage Item', data])
      const expiry = data?.expiry
      const value = data?.value

      // Check if the local storage item has an expiry ttl
      const now = Date.now()
      const isExpired = expiry == null || now > expiry
      const isInvalid = !isValidData(value)
      debug(() => [
        `[${localStorageKey}]`,
        '     -- Check Expiry and Validity',
        `isExpired: ${isExpired}, isInvalid: ${isInvalid}`,
        {
          value,
          now,
          expiry,
          diff: now - expiry
        }
      ])
      if (isExpired || isInvalid) {
        debug(() => [`[${localStorageKey}]`, '     -- CLEAR'])
        window.localStorage.removeItem(localStorageKey)
        delete window.parsedResponses[localStorageKey]
        return undefined
      }
      window.parsedResponses[localStorageKey] = value
      debug(() => [`[${localStorageKey}]`, '     -- return validated value', value])
      return value
    } catch (e) {
      // if parsing fails do nothing
    }
  }

  return undefined
}

export function setLocalStorageWithTTL(key: string, value: any, ttlMs: number) {
  if (isServer()) return
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttlMs
  }
  window.localStorage.setItem(key, JSON.stringify(item))
}
