import { parse } from 'date-fns'
import { identity, isEqual, mapValues } from 'lodash'
import qs, { ParsedQs } from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import create from 'zustand'
import { formatFiltersApiDate } from '~/common/dateUtils'
import createDebugLogger from '~/common/logger'
import { isClient } from '~/common/nextJsUtils'
import useDeepEqualEffect from '~/hooks/useDeepEqualEffect'
import { useLatestRef } from '~/hooks/useLatestRef'
import { getWindow } from '~/hooks/useUrlState.window'

const debug = createDebugLogger('useUrlState')

// ///////////////////////////////////////////////////////////////// //
// Copied from npm package with-url-state with significant changes   //
// ///////////////////////////////////////////////////////////////// //

const listeners: Array<() => void> = []

export const history = isClient() ? historyAdapter() : noopHistoryAdapter()

export type HistoryAction = 'push' | 'replace'
export type Location = { pathname: string; search: string }
export type UnregisterCallback = () => void

function historyAdapter() {
  return {
    listen: (listener: () => void): UnregisterCallback => {
      const listenerIndex = listeners.push(listener) - 1
      return () => {
        delete listeners[listenerIndex]
      }
    },
    location: getWindow()?.location,
    push: ({ search }: Location) => {
      if (search !== getWindow().location.search) {
        getWindow().history.pushState(getWindow().history.state, document.title, search)
        listeners.forEach(listener => listener())
      }
    },
    replace: ({ search }: Location) => {
      if (search !== getWindow().location.search) {
        getWindow().history.replaceState(getWindow().history.state, document.title, search)
        listeners.forEach(listener => listener())
      }
    }
  }
}

function noopHistoryAdapter() {
  return {
    listen: () => () => {},
    location: { pathname: '', search: '' },
    push: () => {},
    replace: () => {}
  }
}

export const serialisation = {
  parse: (queryString: string) => qs.parse(queryString, { ignoreQueryPrefix: true }),
  stringify: (state: any) => qs.stringify(state, { skipNulls: true, sort: alphabeticalSort })
}
function alphabeticalSort(a: string, b: string) {
  return a.localeCompare(b)
}

const _useUrlStore = create<ParsedQs>(() => serialisation.parse(history.location.search))

// Listen for query param url changes triggered by useUrlState calling history.push or history.replace
history.listen(function onLocationChange() {
  const parsed = serialisation.parse(history.location.search)
  debug(() => ['history.listen::_setState', parsed, new Error().stack])
  _useUrlStore.setState(parsed, true)
})

// Listen for history change events (back/forward button)
if (isClient()) {
  getWindow().addEventListener('popstate', () => {
    listeners.forEach(listener => listener())
  })
}

export function clearAllUrlState() {
  const nextLocation = { ...history.location, search: '?' }
  debug('clearAllUrlState::_replace', nextLocation)
  history.replace(nextLocation)

  debug('clearAllUrlState::_setState', nextLocation)
  _useUrlStore.setState({}, true)
}

export type UrlParser<TValue = any> = {
  parse: (queryValues?: ParsedQs) => TValue
  stringify: (inputValue?: TValue) => Record<string, string | null | undefined>
}

export interface UseUrlStateOptions<TValue = any> {
  parser?: UrlParser<TValue>
  urlReadWriteEnabled?: boolean
}

export type SetUrlState<TValue = ParsedQs> = (
  setStateAction: TValue | ((prevState: TValue) => TValue),
  historyAction?: HistoryAction
) => void

type UrlStateTuple<TValue = ParsedQs> = readonly [TValue, SetUrlState<TValue>]

/**
 * A more powerful url state hook, accepts objects with one or more keys and writes them
 * to the url search params. All values are converted to strings.
 *
 * @param initialState
 * @param options
 * @param options.parser {UrlParser} Describes how to convert value to and from the url
 * @param options.urlReadWriteEnabled Whether to read/write to the url search params, if false, behaves like regular useState
 */
export function useUrlState<TValue = ParsedQs>(
  initialState?: TValue,
  { parser = identityUrlParser, urlReadWriteEnabled = true }: UseUrlStateOptions<TValue> = {}
): UrlStateTuple<TValue> {
  const parserRef = useLatestRef(parser)

  useMemo(() => {
    const initialMergedState = {
      ...parserRef.current.stringify(initialState),
      ...serialisation.parse(history.location.search)
    }
    const stringified = serialisation.stringify(initialMergedState)
    // Parse the stringified to make sure everything is strings
    const initialParsedState = serialisation.parse(stringified)
    debug('useUrlState::useMemo::useUrlStore.setState', initialParsedState)
    _useUrlStore.setState(initialParsedState)
  }, [])

  const currentState: TValue = _useUrlStore(s => {
    const parsed = parserRef.current.parse(s)
    debug('useUrlState::useUrlStore::selector', s, parsed)
    return parsed
  }, isEqual)

  const setUrlState = useCallback(
    (value: TValue | ((prevState: TValue) => TValue) | null, historyAction?: HistoryAction): void => {
      debug('setUrlState', value, historyAction)
      const previousState = _useUrlStore.getState()
      const newValue =
        value === null
          ? {}
          : parserRef.current.stringify(
              typeof value === 'function'
                ? // @ts-ignore
                  value(previousState)
                : value
            )
      const newMergedState = {
        ...previousState,
        ...newValue
      }
      const stringified = serialisation.stringify(newMergedState)
      debug('setUrlState::newMergedState', { newValue, newMergedState, stringified })
      const nextLocation = { ...history.location, search: `?${stringified}` }

      if (historyAction === 'replace') {
        history.replace(nextLocation)
      } else {
        history.push(nextLocation)
      }

      // Shouldn't need to do this since the listener will update the state for us in history.listen()
      // const newParsedState = serialisation.parse(stringified)
      // debug('setUrlState::_setState', newParsedState)
      // _useUrlStore.setState(newParsedState, true)
    },
    []
  )

  useEffect(() => {
    if (urlReadWriteEnabled) {
      setUrlState(null, 'replace')
    }
  }, [])

  const [normalState, setNormalState] = useState<TValue>(initialState as TValue)
  if (!urlReadWriteEnabled) {
    return [normalState, setNormalState]
  }

  return [currentState, setUrlState]
}

export function useStringUrlState(queryKey: string, initialState?: string, urlReadWriteEnabled = true) {
  return useUrlState(initialState, {
    parser: stringUrlParser(queryKey),
    urlReadWriteEnabled
  })
}

export function useNumberUrlState(queryKey: string, initialState?: number, urlReadWriteEnabled = true) {
  return useUrlState(initialState, {
    parser: numberUrlParser(queryKey),
    urlReadWriteEnabled
  })
}

export function useBooleanUrlState(queryKey: string, initialState?: boolean, urlReadWriteEnabled = true) {
  return useUrlState(initialState, {
    parser: booleanUrlParser(queryKey),
    urlReadWriteEnabled
  })
}

export const stringUrlParser = (queryKey: string): UrlParser<string | undefined> => ({
  parse: queryValues => {
    const value = queryValues?.[queryKey]
    if (typeof value === 'string') return value || undefined
    return undefined
  },
  stringify: inputValue => {
    return { [queryKey]: inputValue || undefined }
  }
})

export const numberUrlParser = (queryKey: string): UrlParser<number | undefined> => ({
  parse: queryValues => {
    const value = queryValues?.[queryKey]
    if (typeof value === 'string') {
      const number = parseFloat(value)
      if (Number.isNaN(number)) return undefined
      return number
    }
    return undefined
  },
  stringify: inputValue => ({ [queryKey]: inputValue != null ? String(inputValue) : undefined })
})

export const booleanUrlParser = (queryKey: string): UrlParser<boolean> => ({
  parse: queryValues => {
    const value = queryValues?.[queryKey]
    if (typeof value === 'string') return value === 'true'
    return false
  },
  stringify: inputValue => ({ [queryKey]: inputValue === true ? 'true' : undefined })
})

export const dateUrlParser = (queryKey: string): UrlParser<Date | undefined> => ({
  parse: queryValues => {
    const value = queryValues?.[queryKey]
    if (typeof value === 'string') {
      const date = parse(value)
      if (Number.isNaN(date.getTime())) return undefined
      return date
    }
    return undefined
  },
  stringify: inputValue => ({ [queryKey]: inputValue ? formatFiltersApiDate(inputValue) : undefined })
})

/**
 * A string parser that validates the shape of HH:mm
 */
export const timeUrlParser = (queryKey: string): UrlParser<string | undefined> => ({
  parse: queryValues => {
    const value = queryValues?.[queryKey]
    if (typeof value === 'string' && value.match(/^[0-9]{2}:[0-9]{2}$/)) {
      return value
    }
    return undefined
  },
  stringify: inputValue => {
    if (typeof inputValue === 'string' && inputValue.match(/^[0-9]{2}:[0-9]{2}$/)) {
      return { [queryKey]: inputValue }
    }
    return { [queryKey]: undefined }
  }
})

// A url parser that does nothing
const identityUrlParser = { parse: identity, stringify: identity }

export function arrayUrlParser<TElement = ParsedQs>(
  elementParser: (queryKey: string) => UrlParser<TElement>,
  delimiter: string = ','
): (queryKey: string) => UrlParser<Exclude<TElement, undefined>[]> {
  return (queryKey: string) => ({
    parse: queryValues => {
      const value = queryValues?.[queryKey]
      debug('arrayUrlParser::parse', { value, queryKey, queryValues })
      if (typeof value === 'string' && value.length > 0) {
        return value
          .split(delimiter)
          .map(v => {
            return elementParser(queryKey).parse({ [queryKey]: v })
          })
          .filter(v => v !== undefined) as Exclude<TElement, undefined>[]
      }
      return []
    },
    stringify: inputValue => ({
      [queryKey]: inputValue?.map(el => elementParser(queryKey).stringify(el)[queryKey])?.join(',') || undefined
    })
  })
}

export type UrlParserFactoryFn = (queryKey: string) => UrlParser<any>
export type ObjectUrlParserRecord = Record<string, UrlParserFactoryFn | UrlParser>
export type UrlParserReturnType<Parsers extends ObjectUrlParserRecord> = {
  [Key in keyof Parsers]: Parsers[Key] extends (queryKey: string) => UrlParser<infer T>
    ? T
    : Parsers[Key] extends UrlParser<infer T>
    ? T
    : never
}

export function objectUrlParser<Parsers extends ObjectUrlParserRecord>(
  objectParser: Parsers
): UrlParser<UrlParserReturnType<Parsers>> {
  return {
    parse: queryValues =>
      mapValues(objectParser, (valueParser, key) => {
        const parser = isUrlParser(valueParser) ? valueParser : valueParser(key)
        return parser.parse(queryValues)
      }),
    stringify: inputValue => {
      return mapValues(objectParser, (valueParser, key) => {
        const parser = isUrlParser(valueParser) ? valueParser : valueParser(key)
        const result = parser.stringify(inputValue?.[key])
        return result[key]
      })
    }
  }
}

function isUrlParser(obj: any): obj is UrlParser {
  return obj && typeof obj.parse === 'function' && typeof obj.stringify === 'function'
}

/**
 * Similar to useUrlState, but the object passed in will be written to the url
 * search params whenever it changes.
 *
 * Then you get back the current parsed url search params.
 *
 * @param obj
 * @param urlReadWriteEnabled
 */
export function useSyncToUrlState<T>(obj: T, urlReadWriteEnabled = true) {
  const [state, setState] = useUrlState(obj, { urlReadWriteEnabled })
  useDeepEqualEffect(() => {
    setState(obj)
  }, [obj])
  return state
}
