import React, { useMemo } from 'react'
import { useL10nMessagesQuery } from '~/api/l10n'
import { isClient, useIsMounting } from '~/common/nextJsUtils'
import { createIntl, useIntl as useCtxIntl, ValeyardIntlProvider } from './IntlProviders.context'
import { Expression } from '../common/valeyard/runtime'
import { useLegacyDjangoPageData } from '../hooks/useLegacyDjangoPageData'
import { VndlySpinner } from './VndlySpinner'

/*
 useIntl but without formatMessage, to format a message please use our Msg or useMsg utils
 */
export function useIntl() {
  const intl = useCtxIntl()
  return {
    locale: intl.locale,
    timezone: intl.timeZone,
    formatDateTimeRange: intl.formatDateTimeRange,
    formatDate: intl.formatDate,
    formatTime: intl.formatTime,
    formatDateToParts: intl.formatDateToParts,
    formatTimeToParts: intl.formatTimeToParts,
    formatRelativeTime: intl.formatRelativeTime,
    formatNumber: intl.formatNumber,
    formatNumberToParts: intl.formatNumberToParts,
    formatPlural: intl.formatPlural,
    formatList: intl.formatList,
    formatListToParts: intl.formatListToParts,
    formatDisplayName: intl.formatDisplayName
  }
}

export function IntlProviderFromApi({
  anonymous,
  children
}: {
  anonymous: boolean
  children: React.ReactNode | undefined
}) {
  const { messages, locale_tag, timezone, allLayeredLoaded } = useL10nMessagesQuery({ anonymous })
  const isMounting = useIsMounting()
  const hideSpinner = Boolean(isMounting || (allLayeredLoaded && locale_tag))
  const hideContent = Boolean(isMounting || !allLayeredLoaded || !locale_tag)

  const intl = useCreateIntl(locale_tag, timezone)
  useSetHtmlLangAttr(locale_tag)

  // If there is already an IntlProvider higher up in the React tree, then we don't need another one
  if (useAlreadyWithinIntlProvider()) return children

  // console.log('IntlProviderFromApi', {
  //   showContent: (!hideContent && hideSpinner && '✅') || '❌',
  //   allLayeredLoaded: (allLayeredLoaded && '✅') || '❌',
  //   meLoaded: (locale_tag && timezone && '✅') || '❌'
  // })

  /*
  We want to show and hide the spinner and contents using native `hidden` attribute,
  so that all the rendered dom content is present in the statically rendered page.
  As soon as the l10n messages are loaded it's a simple attribute change to show the
  contents, instead of needing to have React render it all from scratch at runtime.
  */
  // @ts-ignore
  return (
    <>
      <VndlySpinner hidden={hideSpinner} />
      <div hidden={hideContent}>
        <ValeyardIntlProvider intl={intl} messages={messages} allLayersLoaded={allLayeredLoaded}>
          {children}
        </ValeyardIntlProvider>
      </div>
    </>
  )
}

export interface PreBundledCatalog {
  catalog: Record<string, Expression>
  locale: string
  locale_tag: string
  timezone: string
}

/**
 * @deprecated Only use on legacy django pages
 */
export const IntlProviderFromLegacyPageData = ({ children }: { children: React.ReactNode }) => {
  const l10n = useLegacyDjangoPageData('l10n_vndly') as PreBundledCatalog
  const intl = useCreateIntl(l10n.locale_tag, l10n.timezone)
  useSetHtmlLangAttr(l10n.locale_tag)

  // If there is already an IntlProvider higher up in the React tree, then we don't need another one
  if (useAlreadyWithinIntlProvider()) {
    return <>{children}</>
  }

  return (
    <ValeyardIntlProvider intl={intl} messages={l10n.catalog} allLayersLoaded>
      {children}
    </ValeyardIntlProvider>
  )
}

function useAlreadyWithinIntlProvider() {
  let alreadyWithinIntlProvider = false
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    alreadyWithinIntlProvider = Boolean(useCtxIntl())
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return alreadyWithinIntlProvider
}

function useCreateIntl(locale_tag: string | undefined, timezone: string | undefined) {
  return useMemo(() => createIntl({ locale_tag, timezone }), [locale_tag, timezone])
}

function useSetHtmlLangAttr(locale: string | undefined) {
  React.useEffect(() => {
    if (locale && isClient() && document?.documentElement?.lang !== locale) {
      document.documentElement.lang = locale
    }
  }, [locale])
}
