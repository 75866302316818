// eslint-disable-next-line import/no-extraneous-dependencies
import { useStyles } from '@chakra-ui/system'
// eslint-disable-next-line import/no-extraneous-dependencies
import { getNextIndex, getPrevIndex, normalizeEventKey } from '@chakra-ui/utils'
import { omit } from 'lodash'
import React, { createContext, KeyboardEvent, MouseEvent, useCallback, useContext, useState } from 'react'
import {
  useMenuDescendantsContext,
  Box,
  BoxProps,
  forwardRef,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuProps,
  useMenuButton,
  useMenuContext,
  UsePopperProps
} from '~/design_system'
import { useInputModality } from '~/common/domUtils'
import { ToggledNextLink } from '~/components/v2/next/ToggledNextLink'

type ParentMenuContext = ReturnType<typeof useMenuContext> & {
  blurAndSaveFocusedIndex: () => void
  restoreLastFocusedIndex: () => void
}
const ParentMenuContext = createContext<undefined | ParentMenuContext>(undefined)
export const useParentMenuContext = () => useContext(ParentMenuContext)

export const HoverMenu = ({ children, ...props }: MenuProps & { isDisabled?: boolean }) => {
  const parentMenu = useMenuContext()
  const [lastFocusedIndex, setLastFocusedIndex] = useState(-1)
  function blurAndSaveFocusedIndex() {
    setLastFocusedIndex(parentMenu.focusedIndex)
    parentMenu?.setFocusedIndex(-1)
  }
  function restoreLastFocusedIndex() {
    parentMenu?.setFocusedIndex(lastFocusedIndex)
  }

  const subMenuPosition: UsePopperProps = { offset: [-8, -4], placement: 'right-start' }
  const parentMenuPosition: UsePopperProps = { offset: [0, 0], placement: 'bottom-start' }
  const menuPosition = parentMenu ? subMenuPosition : parentMenuPosition

  return (
    <ParentMenuContext.Provider
      value={{
        ...parentMenu,
        blurAndSaveFocusedIndex,
        restoreLastFocusedIndex
      }}>
      <Menu preventOverflow {...menuPosition} {...props}>
        {(...args) => (
          <HoverMenuInner isDisabled={props.isDisabled}>
            {typeof children === 'function' ? children(...args) : children}
          </HoverMenuInner>
        )}
      </Menu>
    </ParentMenuContext.Provider>
  )
}

function HoverMenuInner({ isDisabled, ...props }: BoxProps & { isDisabled?: boolean }) {
  const menu = useMenuContext()
  const { isUsingKeyboard } = useInputModality()
  return (
    <Box
      {...props}
      {...(!isDisabled && {
        onMouseEnter: menu.openAndFocusMenu,
        onMouseLeave: menu.onClose
      })}
      className={isUsingKeyboard ? '--is-keyboard-usage' : undefined}
    />
  )
}

export const SubMenuButton = forwardRef<MenuButtonProps, 'button'>((props, ref) => {
  const menu = useMenuContext()
  const parentMenu = useParentMenuContext()
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      const isSupportedOpeningKey = ['Enter', 'ArrowLeft', ' '].includes(normalizeEventKey(event))
      if (isSupportedOpeningKey) {
        event.preventDefault()
        event.stopPropagation()
        parentMenu?.blurAndSaveFocusedIndex()
        menu.onOpen()
        setTimeout(() => {
          menu.setFocusedIndex(1)
        }, 10)
      }
    },
    [parentMenu, menu]
  )

  function onClick(event: MouseEvent) {
    event.stopPropagation()
  }

  const buttonProps = useMenuButton(props, ref)
  const styles = useStyles()

  return <MenuItem sx={styles.button} {...props} {...buttonProps} onClick={onClick} onKeyDownCapture={onKeyDown} />
})

SubMenuButton.displayName = 'SubMenuButton'

export const SubMenuList = forwardRef<MenuListProps, 'button'>((props, ref) => {
  const subMenu = useMenuContext()
  const descendants = useMenuDescendantsContext()
  const parentMenu = useParentMenuContext()
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const key = normalizeEventKey(event)
      const isSupportedClosingKey = ['Escape', 'ArrowRight'].includes(key)
      if (isSupportedClosingKey) {
        event.preventDefault()
        event.stopPropagation()
        subMenu.onClose()
        setTimeout(() => {
          parentMenu?.restoreLastFocusedIndex()
        }, 100)
        return
      }

      if (key === 'ArrowDown') {
        event.preventDefault()
        event.stopPropagation()
        let nextIndex = getNextIndex(subMenu.focusedIndex, descendants.count())
        if (nextIndex === 0) nextIndex = 1
        subMenu.setFocusedIndex(nextIndex)
      }

      if (key === 'ArrowUp') {
        event.preventDefault()
        event.stopPropagation()
        let prevIndex = getPrevIndex(subMenu.focusedIndex, descendants.count())
        if (prevIndex === 0) prevIndex = descendants.count() - 1
        subMenu.setFocusedIndex(prevIndex)
      }
    },
    [parentMenu, subMenu, descendants]
  )

  return <MenuList ref={ref} {...props} onKeyDown={onKeyDown} />
})

SubMenuList.displayName = 'SubMenuList'

export type MenuItemLinkProps = MenuItemProps & { isHidden?: boolean; href?: string }
export const MenuItemLink = forwardRef<MenuItemLinkProps, 'a'>(({ isHidden, ...props }, ref) => {
  if (isHidden) return null

  let itemProps
  if (props.isDisabled) {
    itemProps = omit(props, ['href'])
  } else {
    itemProps = { as: 'a' as const, ...props }
  }

  return (
    <ToggledNextLink href={props.href} passHref={false}>
      <MenuItem ref={ref} {...itemProps} />
    </ToggledNextLink>
  )
})

MenuItemLink.displayName = 'MenuItemLink'
