import { identity } from 'lodash'
import React, { ComponentProps, ComponentType, useCallback } from 'react'
import { createContainer } from 'react-tracked'

type AnyFunction = (...args: any[]) => any

// These types here just help to define the overloading of the objectHookToContext function
// Basically if throwErrorIfProviderIsMissing is false, then State can also be undefined, that's the only difference
type Select<Props, State = Props> = <Selected>(selector: (state: State) => Selected) => Selected
type ProviderAndHook<Props, State = Props> = [React.FC<Props>, (() => State) & { select: Select<Props, State> }]
export function objectHookToContext<Props, State = Props>(
  useHook?: (props: Props) => State,
  throwErrorIfProviderIsMissing?: true
): ProviderAndHook<Props, State>
export function objectHookToContext<Props, State = Props>(
  useHook?: (props: Props) => State,
  throwErrorIfProviderIsMissing?: false
): ProviderAndHook<Props, State | undefined>
/**
 * A util that mimics constate but uses react-tracked for a nicer selector API
 *
 * # Pass a hook that returns an object and the object will be shared to all descendants that use the hook
 *
 * @example
 * const [UserProvider, useUser] = objectHookToContext(() => {
 *   // Any hooks can be used here but I'm just showing a very simple return object
 *   return {
 *     name: 'Tim',
 *     age: 39
 *   }
 * })
 *
 * const MyApp = () => {
 *   return (
 *     <UserProvider>
 *       <UserName />
 *       <UserAge />
 *       <UserAgeDoubled />
 *     </UserProvider>
 *   )
 * }
 *
 * const UserName = () => {
 *   // Updates only when name changes, make sure you don't destructure though
 *   // or the proxy won't be able to detect what fields you've accessed.
 *   const user = useUser()
 *   return <div>{user.name}</div>
 * }
 *
 * const UserAge = () => {
 *   // Updates only when age changes
 *   const user = useUser()
 *   return <div>{user.age}</div>
 * }
 *
 * const UserAgeDoubled = () => {
 *   // Also supports selectors via .select
 *   // Updates only when the selector's computation changes
 *   const ageDoubled = useUser.select(s => s.age * 2)
 *   return <div>{ageDoubled}</div>
 * }
 *
 * # Without a passed hook, it will share all props passed to the provider for quick and easy contexts
 *
 * @example
 * const [Provider, useContext] = objectHookToContext<{ foo: number; bar: string }>()
 *
 * const MyApp = () => {
 *   return (
 *     <Provider foo={1} bar="hello">
 *       <Child />
 *     </Provider>
 *   )
 * }
 *
 * const Child = () => {
 *   const ctx = useContext()
 *   return (
 *     <div>
 *       {ctx.foo} {ctx.bar}
 *     </div>
 *   )
 * }
 */
export function objectHookToContext<Props, State = Props>(
  useHook: (props: Props) => State = identity,
  throwErrorIfProviderIsMissing = true
) {
  const { Provider, useTrackedState, useSelector } = createContainer<State, () => void, Props>((props: Props) => {
    const result = useHook(props)
    return [result, useCallback(() => {}, [])] as const
  })
  let _useTrackedState = useTrackedState
  if (!throwErrorIfProviderIsMissing) {
    // @ts-ignore
    _useTrackedState = () => {
      try {
        return useTrackedState()
      } catch (e: any) {
        if (e.toString().includes('Please use <Provider>')) {
          return undefined
        }
        throw e
      }
    }
  }
  return [Provider, Object.assign(_useTrackedState, { select: useSelector })] as const
}

/**
 * Same as above but has additional hooks to help with updating via a state tuple.
 *
 * Note: the hook MUST return a state tuple e.g. `return [state, setState]`
 *
 * @example
 * const [NameProvider, useName, useUpdateName] = objectHookToContext(() => useState(''))
 *
 * const MyApp = () => {
 *   return (
 *     <UserProvider>
 *       <UserName />
 *       <Button />
 *     </UserProvider>
 *   )
 * }
 *
 * const UserName = () => {
 *   // Updates only when name changes, make sure you don't destructure though
 *   // or the proxy won't be able to detect what fields you've accessed.
 *   const name = useName()
 *   return <div>{name}</div>
 * }
 *
 * const Button = () => {
 *   const name = useName()
 *   const updateName = useUpdateName()
 *   return <input value={name} onChange={(e) => updateName(e.target.value)} />
 * }
 */
export function stateHookToContext<State, Update extends AnyFunction, Props>(
  useHook: (props: Props) => readonly [State, Update]
) {
  const { Provider, useTrackedState, useSelector, useUpdate } = createContainer<State, Update, Props>(useHook)
  return [Provider, Object.assign(useTrackedState, { select: useSelector }), useUpdate] as const
}

/**
 * A util to wrap a component in some other components, such as Providers, easily.
 * @example
 * const [PageStateProvider, usePageState] = contextHook(() => { ...do some context stuff... })
 *
 * const MyPageComponent = wrapWith(PageStateProvider)(() => {
 *   // Can access the context here, instead of having to make a wrapper component manually.
 *   const pageState = usePageState()
 *   return <div>Welcome to my Page</div>
 * })
 *
 * // can also pass multiple components
 * wrapWith(ProviderA, ProviderB, ProviderC)(MyWrappedComponent)
 *
 * // Is basically equivalent to
 * <ProviderA>
 *   <ProviderB>
 *     <ProviderC>
 *       <MyWrappedComponent />
 *     </ProviderC>
 *   </ProviderB>
 * </ProviderA>
 *
 * // If you want to pass props to your wrapper components, do so like this:
 * wrapWith([PageStateProvider, { foo: true }])
 *
 * // Is basically equivalent to
 * <PageStateProvider foo={true}>
 *   <WrappedComponent />
 * </PageStateProvider>
 */
export function wrapWith(...components: ComponentType[]) {
  return wrap

  function wrap<Wrapped extends ComponentType<ComponentProps<Wrapped>>>(WrappedComponent: Wrapped): Wrapped {
    function WrappedUpComponent(props: ComponentProps<Wrapped>) {
      return components.reduceRight(
        (acc, cmp) => {
          let Component = cmp
          if (Array.isArray(cmp)) {
            Component = cmp[0]
            return <Component {...cmp[1]}>{acc}</Component>
          }
          return <Component>{acc}</Component>
        },
        // @ts-ignore
        <WrappedComponent {...props} />
      )
    }
    WrappedUpComponent.displayName = `Wrapped(${WrappedComponent.displayName})`

    return WrappedUpComponent as Wrapped
  }
}
