import { analyticsApi, AnalyticsApiResponse } from '~/api/analytics'

// we want to add wrapping html comments to the script tag so we can manually search the dom for the script easier.
// I have been extensively using this when doing testing and debugging both locally and in prod
const addWrappingCommentsToScript = (
  beginningComment: string,
  endingComment: string,
  scriptToWrap: HTMLScriptElement
) => {
  const startCommentElement = document.createComment(beginningComment)
  const endCommentElement = document.createComment(endingComment)
  const scriptsParentNode = scriptToWrap.parentNode
  scriptsParentNode!.insertBefore(startCommentElement, scriptToWrap)
  scriptsParentNode!.insertBefore(endCommentElement, scriptToWrap.nextSibling)
}

const addPendoAnalytics = (response: AnalyticsApiResponse) => {
  const script_id = 'pendo_script'

  if (document.querySelector(`#${script_id}`)) return

  const pendoScript = document.createElement('script')
  pendoScript.id = script_id
  pendoScript.type = 'text/javascript'
  pendoScript.async = true

  pendoScript.innerHTML = `
                          (function(apiKey){
                              (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                              v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                                  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                                  y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                                  z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
                                  pendo.initialize({
                                      sanitizeUrl: function (url) {
                                          // remove all url parameters because they could have personally identifiable information
                                          // and just return the base url
                                          var baseUrl = url.split("?")[0];
                                          return baseUrl;
                                      },

                                      visitor: {
                                        id: '${response.pendo_analytics_metadata.user_uuid}',
                                        rbac_role: '${response.rbac_role}',
                                        role: '${response.role}',
                                        domain: '${response.domain}',
                                        environment_type: '${response.environment_type}',
                                        user_locale: '${response.user_locale}',
                                        user_timezone: '${response.user_timezone}',
                                      },

                                      account: {
                                          id: '${response.tenant}',
                                          name: '${response.tenant}',
                                          domain: '${response.domain}',
                                          environment_type: '${response.environment_type}',
                                          tenant_timezone: '${response.tenant_timezone}',
                                          tenant_locale: '${response.tenant_locale}',
                                      }
                                  });
                          })("${response.pendo_analytics_metadata.api_key}");
                          `
  document.body.appendChild(pendoScript)

  addWrappingCommentsToScript('Pendo Analytics (set by front end)', '/Pendo Analytics (set by front end)', pendoScript)
}

export default function addAnalyticsToPage() {
  analyticsApi.config().then((response: AnalyticsApiResponse) => {
    const pa_enabled = response.pendo_analytics_metadata.enabled

    if (pa_enabled) {
      addPendoAnalytics(response)
    }
  })
}

type PendoGuideOpts = {
  store?: boolean
  delay?: number
}
/**
 * Sets a flag on the document body to indicate that a pendo guide should be shown.
 * @param flag string indicator, should be useful like what you just finished doing
 * @param store set to true if you want it in localstorage for page transition.  Nextjs routing does not need this
 * @param delay time in ms to wait before setting the flag on the body
 */
export function storePendoGuide(flag: string, { store = false, delay = 5000 }: PendoGuideOpts = {}) {
  // Delay setting on body because there may be a redirect or callback that reloads
  // and we don't want to pop the guide too early
  setTimeout(() => {
    document.body.dataset.pendoGuide = flag
  }, delay)
  if (store) {
    localStorage.setItem('pendoGuide', flag)
  }
}

/**
 * Checks local storage for a stashed pendoGuide flag and sets it ont he document body.
 * Used when transitioning between nextjs/non-nextjs pages (or vice versa)
 */
export function extractPendoGuide() {
  const pendoGuide = localStorage.getItem('pendoGuide')
  if (pendoGuide) {
    document.body.dataset.pendoGuide = pendoGuide
    // Pop off local storage after setting on body
    localStorage.removeItem('pendoGuide')
  }
}
