const baseStyle = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full'
  },
  th: {
    fontWeight: 'bold',
    fontSize: 'md',
    textTransform: 'none',
    letterSpacing: 'normal',
    textAlign: 'start',
    bgColor: 'var(--vndly-color-container-background-subdued)'
  },
  td: {
    fontSize: 'md',
    fontWeight: 'normal',
    textAlign: 'start'
  }
}

const variantSimple = {
  th: {
    color: 'gray.500',
    borderColor: 'gray.300',
    borderBottomWidth: '3px'
  },
  td: {
    border: 'none'
  },
  tr: {
    borderBottom: '1px',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  caption: {
    color: 'gray.500'
  },
  tfoot: {
    tr: {
      borderTopWidth: '1px',
      '&:last-of-type': {
        th: {
          color: 'gray.800',
          fontSize: 'md',
          borderBottomWidth: 0
        }
      }
    }
  }
}

const variantSimpleRuled = {
  ...variantSimple,
  th: {
    color: 'gray.500',
    borderRight: '1px',
    borderBottomWidth: '3px',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderRight: 'none'
    }
  },
  td: {
    borderBottom: 'none',
    borderRight: '1px',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderRight: 'none'
    }
  }
}

const variantStriped = {
  th: {
    color: 'gray.500',
    borderColor: 'gray.300',
    borderBottomWidth: '3px'
  },
  td: {
    border: 'none'
  },
  tr: {
    borderBottom: '1px',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  caption: {
    color: 'gray.500'
  },
  tfoot: {
    tr: {
      borderTopWidth: '1px',
      '&:last-of-type': {
        th: {
          color: 'gray.800',
          fontSize: 'md',
          borderBottomWidth: 0
        }
      }
    }
  }
}

const variantStripedRuled = {
  ...variantStriped,
  th: {
    color: 'gray.500',
    borderRight: '1px',
    borderBottomWidth: '3px',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderRight: 'none'
    }
  },
  td: {
    borderRight: '1px',
    borderBottom: 'none',
    borderColor: 'gray.300',
    '&:last-of-type': {
      borderRight: 'none'
    }
  },
  tbody: {
    'tr:nth-of-type(odd) td': {
      background: 'gray.100'
    }
  }
}

const sizes = {
  sm: {
    th: {
      lineHeight: 'short',
      fontSize: 'md',
      px: '4',
      py: '0'
    },
    td: {
      lineHeight: 'base',
      px: '4',
      py: '2'
    }
  },
  md: {
    th: {
      lineHeight: 'base',
      fontSize: 'md',
      px: '4',
      py: '0.4375rem'
    },
    td: {
      lineHeight: 'base',
      px: '4',
      py: '3'
    }
  },
  lg: {
    th: {
      lineHeight: 'base',
      fontSize: 'md',
      px: '4',
      py: '0.6875rem'
    },
    td: {
      lineHeight: 'base',
      px: '4',
      py: '4'
    }
  }
}

export const Table = {
  baseStyle,
  variants: {
    simple: variantSimple,
    simpleRuled: variantSimpleRuled,
    striped: variantStriped,
    stripedRuled: variantStripedRuled
  },
  sizes,
  default: {
    variant: 'simple',
    size: 'md'
  }
}
