import { useAccountMeQuery } from '~/api/accounts'
import { formatDateTimeWithLocale } from '~/common/tzUtils'

// passed timezone and locale will override those of user profile
export const Timestamp = ({
  datetime,
  showTimezone = false,
  showDateOnly = false,
  timezone = null,
  locale = null,
  formatOptions = {}
}) => {
  const { data = {} } = useAccountMeQuery()
  const simpleTzNameOptions = showTimezone ? { timeZoneName: 'short' } : {}
  const options = showDateOnly ? formatOptions : { ...simpleTzNameOptions, ...formatOptions }
  return (
    <>{formatDateTimeWithLocale(datetime, timezone || data.timezone, locale || data.locale, options, showDateOnly)}</>
  )
}
