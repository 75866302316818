// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Circle,
  HStack,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
  ModalDialog,
  ModalDialogBody,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalProps,
  SidebarSplitItem,
  SidebarSplitListItems,
  Skeleton,
  Stack,
  Text,
  TextBlockMultiLine,
  Tooltip,
  useDisclosure,
  useMenuButton,
  useMenuContext
} from '~/design_system'

import { queryClient } from '~/common/react-query/queryClient'
import { useAccountMeQuery } from '~/api/accounts'
import {
  invalidateNavigationMenuConfigQuery,
  isValidNavMenuConfigData,
  NAVIGATION_MENU_CONFIG_LOCAL_STORAGE_KEY,
  NavMenuConfig,
  useEndImpersonationMutation,
  useImpersonateMutation,
  useImpersonateQuickLinks,
  useNavigationMenuConfigQuery
} from '~/api/navigationMenu'
import { preloadImage } from '~/common/domUtils'
import { Msg, useMsg } from '~/common/localizationUtils'
import { getLocalStorageJSON } from '~/common/react-query/useQueryWithLocalStoragePersist'

import {
  Bell,
  Checklists,
  ChevronDown,
  Cog,
  Expense,
  Lock,
  Person,
  SignOut,
  Timesheet,
  UserAdd,
  Users,
  WarningO
} from '~/icons'
import { HoverMenu, MenuItemLink } from '~/navigation_menu/SubMenu'

// Attempt to preload the avatar image
const navMenuConfig = getLocalStorageJSON<NavMenuConfig>(
  NAVIGATION_MENU_CONFIG_LOCAL_STORAGE_KEY,
  isValidNavMenuConfigData
)
preloadImage(navMenuConfig?.profile_menu_button?.avatar_img)

export function ProfileMenu() {
  return (
    <Box mr={2}>
      <HoverMenu variant="profileMenu" closeOnSelect={false} placement="bottom-end">
        <ProfileMenuButton />
        <ProfileMenuList />
      </HoverMenu>
    </Box>
  )
}

function ProfileMenuButton(props: MenuButtonProps) {
  const menuButtonProps = useMenuButton(props)
  const { data: config, isSuccess } = useNavigationMenuConfigQuery()
  const name = config?.is_impersonated ? (
    <Msg id="nav.impersonated_username" name={config?.profile_menu_button.displayed_name} />
  ) : (
    config?.profile_menu_button.displayed_name
  )
  const msg = useMsg()
  // The text is truncated at 17 characters
  // See /theme/components/Menu.js::variantProfileMenu
  const TRUNCATION_LENGTH = 17
  const text = (
    <Text className="profile-button__label" isTruncated aria-label={name + ' ' + msg('nav.profile_menu')}>
      {isSuccess ? name : skeleton}
    </Text>
  )

  return (
    <Button
      variant="profileMenu"
      data-is-impersonated={config?.is_impersonated}
      leftIcon={<MenuAvatar />}
      rightIcon={<ChevronDown className="chevron-icon" />}
      {...props}
      {...menuButtonProps}>
      {name?.length > TRUNCATION_LENGTH ? (
        <Tooltip label={name} openDelay={500}>
          {text}
        </Tooltip>
      ) : (
        text
      )}
    </Button>
  )
}

const skeleton = (
  <HStack as="span">
    <Skeleton as="span" w={8}>
      _
    </Skeleton>
    <Skeleton as="span" w={16}>
      _
    </Skeleton>
  </HStack>
)

function ProfileMenuList() {
  const { role, isLoading: isAccountsMeLoading } = useAccountMeQuery()
  const { data: config, isInitialLoading: isNavConfigLoading } = useNavigationMenuConfigQuery()
  const { mutateAsync: endImpersonation } = useEndImpersonationMutation()
  const switchAccountModal = useDisclosure()

  if (isAccountsMeLoading || isNavConfigLoading) {
    return (
      <MenuList>
        <SkeletonMenuItems />
      </MenuList>
    )
  }

  const profileHref = {
    employer: '/employers/profile/?type=view',
    vendor: '/vendor/profile/?type=view',
    candidate: ''
  }[role!]

  return (
    <MenuList data-testid="profile-menu-list">
      {config?.profile_menu.my_profile && (
        <MenuItemLink
          icon={
            config?.profile_menu.my_profile.is_profile_complete ? (
              <Person />
            ) : (
              <WarningO color="var(--vndly-color-container-icon-danger)" />
            )
          }
          href={profileHref}
          data-testid="global-nav-profile-my-profile-link">
          {config?.profile_menu.my_profile.is_profile_complete ? (
            <Msg id="nav.my_profile" />
          ) : (
            <Msg id="nav.my_profile.incomplete" />
          )}
        </MenuItemLink>
      )}
      {config?.profile_menu.notification_preferences && (
        <MenuItemLink
          icon={<Bell />}
          href="/notifications/user_preferences"
          data-testid="global-nav-notification-preferences-link">
          <Msg id="nav.notification_preference" />
        </MenuItemLink>
      )}
      {config?.profile_menu.timesheets && (
        <MenuItemLink
          icon={<Timesheet />}
          href="/candidate/timesheets"
          data-testid="global-nav-candidate-timesheet-link">
          <Msg id="term.timesheet" count={999} />
        </MenuItemLink>
      )}
      {config?.profile_menu.expenses && (
        <MenuItemLink
          icon={<Expense />}
          href="/expenses/expense-report-list/"
          data-testid="global-nav-more-expenses-and-adjustments-link">
          <Msg id="nav.expenses_and_adjustments" />
        </MenuItemLink>
      )}
      {config?.profile_menu.manage_checklist_actions && (
        <MenuItemLink
          icon={<Checklists />}
          href="/checklist_actions"
          data-testid="global-nav-more-manage-checklist-actions-link">
          <Msg id="nav.manage_checklist_actions" />
        </MenuItemLink>
      )}
      {config?.profile_menu.switch_account && (
        <MenuItem icon={<Users />} onClick={() => switchAccountModal.onToggle()}>
          <Msg id="nav.switch_account" />
          <SwitchAccountModal isOpen={switchAccountModal.isOpen} onClose={switchAccountModal.onClose} />
        </MenuItem>
      )}
      {config?.profile_menu.delegate_access && (
        <MenuItemLink icon={<UserAdd />} href="/accounts/delegates/" data-testid="global-nav-delegate-access-link">
          <Msg id="nav.delegate_access" />
        </MenuItemLink>
      )}
      {config?.profile_menu.change_password && (
        <>
          <MenuDivider />
          <MenuItemLink icon={<Cog />} href="/home/change_password/" data-testid="global-nav-change-password-link">
            <Msg id="nav.change_password" />
          </MenuItemLink>
        </>
      )}
      {config?.profile_menu.reset_mfa_token && (
        <MenuItemLink icon={<Lock />} href="/accounts/reset_mfa_token/" data-testid="global-nav-reset-mfa-link">
          <Msg id="nav.reset_mfa_token" />
        </MenuItemLink>
      )}
      <MenuDivider />
      {config?.profile_menu.end_delegation && (
        <MenuItem
          icon={<SignOut />}
          onClick={async () => {
            // End Delegation needs to clear api cache, then sign out using a fetch call, so we can control
            // whether we refresh or redirect manually.
            await endImpersonation(undefined)
            navigateToRoot()
          }}
          data-testid="global-nav-profile-end-delegation-link">
          <Msg id="nav.end_delegation" />
        </MenuItem>
      )}
      {config?.profile_menu.sign_out && (
        <MenuItem
          icon={<SignOut />}
          onClick={async () => {
            // Signing out needs to clear the api cache, then redirect to the sign out url and let
            // it handle any relevant redirects back to the login page.
            await invalidateNavigationMenuConfigQuery()
            if (window.top?.window.location) window.top.location.href = config!.profile_menu.sign_out!.sign_out_url
          }}
          data-testid="global-nav-profile-sign-out-link">
          <Msg id="common.ui.sign_out" />
        </MenuItem>
      )}
    </MenuList>
  )
}

const MenuAvatar = () => {
  const { data: config, isSuccess } = useNavigationMenuConfigQuery()
  const [imageError, setImageError] = useState(false)
  return (
    <Circle className="profile-avatar-container" size={14}>
      {isSuccess && (
        <Avatar
          className="profile-avatar"
          name={imageError ? config?.profile_menu_button.displayed_name : undefined}
          bg="none"
          size="md"
          src={config?.profile_menu_button.avatar_img}
          onError={() => setImageError(true)}
        />
      )}
    </Circle>
  )
}

function SwitchAccountModal({ isOpen, onClose }: Pick<ModalProps, 'isOpen' | 'onClose'>) {
  const menu = useMenuContext()
  const { data, isLoading, isSuccess } = useImpersonateQuickLinks({ enabled: menu.isOpen })
  const { mutateAsync: impersonate } = useImpersonateMutation({
    onSuccess() {
      onClose()
      navigateToRoot()
    }
  })

  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} size="md">
      <ModalDialogHeader>Switch Account</ModalDialogHeader>
      <ModalDialogBody as={Stack} spacing={6} pb={4} minH={0}>
        <SidebarSplitListItems w="400px" textAlign="center">
          {/* @ts-ignore */}
          {isSuccess && data?.quick_links.length > 0
            ? data?.quick_links.map(link => (
                <SidebarSplitItem p={4} key={link.user_id} onClick={() => impersonate({ id: link.user_id })}>
                  <TextBlockMultiLine>
                    <Text textStyle="bodySemi" m={0}>
                      {link.name}
                    </Text>
                    <Text textStyle="body">{link.email}</Text>
                  </TextBlockMultiLine>
                </SidebarSplitItem>
              ))
            : null}
          {/* Zero State */}
          {isSuccess && data?.quick_links?.length === 0 ? 'No users to impersonate' : null}
          {/* Loading State */}
          {isLoading ? (
            <>
              <SidebarSplitItem p={4}>
                <TextBlockMultiLine>
                  <Skeleton mb={1} w={90} h={6} />
                  <Skeleton mb={1} w={150} h={6} />
                </TextBlockMultiLine>
              </SidebarSplitItem>
              <SidebarSplitItem p={4}>
                <TextBlockMultiLine>
                  <Skeleton mb={1} w={100} h={6} />
                  <Skeleton mb={1} w={200} h={6} />
                </TextBlockMultiLine>
              </SidebarSplitItem>
            </>
          ) : null}
        </SidebarSplitListItems>
      </ModalDialogBody>
      <ModalDialogFooter justifyContent="flex-end" p={3}>
        <Button
          variant="link"
          as="a"
          href="/accounts/impersonate/users"
          data-testid="global-nav-all-managed-accounts-link">
          <Msg id="nav.all_managed_accounts" />
        </Button>
      </ModalDialogFooter>
    </ModalDialog>
  )
}

function navigateToRoot() {
  queryClient.clear()
  if (window.top?.window.location.href) window.top.window.location.href = '/'
}

function SkeletonMenuItems() {
  return (
    <>
      <MenuItem>
        <Skeleton w={40}>_</Skeleton>
      </MenuItem>
      <MenuItem>
        <Skeleton w={36}>_</Skeleton>
      </MenuItem>
      <MenuItem>
        <Skeleton w={44}>_</Skeleton>
      </MenuItem>
    </>
  )
}
