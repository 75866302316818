import NextLink from 'next/link'
import { Children, cloneElement, ComponentProps } from 'react'
import { isNextJsPage } from '../../../common/nextJsUtils'

export interface ToggledNextLinkProps extends Omit<ComponentProps<typeof NextLink>, 'href'> {
  children: JSX.Element
  href?: string
}
/*
 While Next.js is no longer feature controlled these are still some common components that use ToggledNextLink like
 Breadcrumbs.tsx and PageTabs.tsx which could be found on Next and non-Next pages.

 If you are creating a new Next.js page use <NextLink> directly
*/
export const ToggledNextLink = ({ href, children, ...props }: ToggledNextLinkProps) => {
  if (isNextJsPage() && NextLink && href) {
    return (
      <NextLink href={href} passHref {...props}>
        {children}
      </NextLink>
    )
  }
  return cloneElement(Children.only(children), { href })
}
