import 'isomorphic-fetch'
import Cookie from 'js-cookie'
import { isObjectLike } from 'lodash'
import { TTLCache } from './cache'
import {
  FetchBaseError,
  NetworkError,
  ApplicationError,
  AuthenticationError,
  ParseJsonError,
  ServerError
} from './fetchErrors'

// Start of a thin wrapper around fetch for things we need like auth and xsrf

export const csrfHeader = () => ({
  'X-CSRFToken': Cookie.get('csrftoken')
})

const contentType = ct => ({
  'Content-Type': ct
})

export const jsonContentType = () => contentType('application/json;charset=UTF-8')

/**
 * VNDLY-tuned fetch call that populates calls with auth/csrf data
 * @param url
 * @param options
 * @returns {Promise<Response>}
 */
const fetch = (url, options = {}) => {
  const baseHeaders = options.noContentType ? { ...csrfHeader() } : { ...csrfHeader(), ...jsonContentType() }
  const optionheaders = options.headers ? { ...options.headers } : {}

  const headers = { ...baseHeaders, ...optionheaders }
  let baseUrl = options?.baseUrl ?? ''

  // If this request is from a Next.js server side rendering, add headers for the cookies/forwarded host
  // and update the baseURL to use the internal host/port
  const nextRequest = options.nextRequest
  if (nextRequest && process.env.NODE_ENV !== 'test') {
    const internalHost = process.env.INTERNAL_WEBAPP_HOST
    const internalPort = process.env.INTERNAL_WEBAPP_PORT
    // Traffic from Next.js --> app server is always HTTP and goes over the exposed port (not 80)
    baseUrl = `http://${internalHost}:${internalPort}`
    headers.cookie = getHeader(nextRequest, 'cookie')
    headers.host = getHeader(nextRequest, 'host')
    headers['x-forwarded-host'] = getHeader(nextRequest, 'x-forwarded-host')
  }

  return global.fetch(`${baseUrl}${url}`, { credentials: 'include', ...options, headers })
}

// The request headers is a plain object when coming from getServerSideProps
// but is a special Headers class instance with .get() method if coming from middleware or api.
function getHeader(req, headerName) {
  return req.headers[headerName] ?? req.headers.get?.(headerName)
}

/**
 * A mandatory short-lived cache that holds all the fetch promises. Allows sharing of identical fetch urls
 * within a short time frame; 100ms.
 * @type {TTLCache<Promise>}
 */
export const fetchCache = new TTLCache(100, { debugName: 'fetch' })

/**
 * A opt-in cache, also of fetch promises; held for a configured, usually longer, time frame; e.g. 5 minutes.
 * Used for dev-curated ttls on an endpoint-by-endpoint basis.
 *
 * Note: ttl is undefined because devs will define it as needed for each fetch call
 *
 * @example
 * const FIVE_MINUTES = 1000 * 60 * 5
 * const responseCache = { ttl: FIVE_MINUTES }
 * fetch.get('/api/myapi', { responseCache })
 * @type {TTLCache<Promise>}
 */
export const optInFetchCache = new TTLCache(undefined, { debugName: 'fetch2' })

function cacheableFetch(url, options, responseHandler) {
  const cachedPromise = fetchCache.get(url) ?? optInFetchCache.get(url)
  if (cachedPromise) {
    return cachedPromise
  }

  const start = new Date().getTime()
  const fetchPromise = fetch(url, options).then(async res => {
    return responseHandler(res, { url, start, options })
  })

  fetchPromise.catch(e => {
    // Remove promise from cache if TypeError: fetch failed
    if (e instanceof TypeError) {
      fetchCache.delete(url)
      optInFetchCache.delete(url)
    }
  })

  fetchCache.set(url, fetchPromise)
  if (options.responseCache) {
    optInFetchCache.set(url, fetchPromise, options?.responseCache?.ttl)
  }

  return fetchPromise
}

function decorateResponseWithTimingInfo({ url, start, res, response }) {
  if (isObjectLike(res)) {
    const end = new Date().getTime()
    const duration = end - start
    // eslint-disable-next-line no-param-reassign
    res.__timing__ = {
      url,
      start: Number(start.toFixed(2)),
      end: Number(end.toFixed(2)),
      duration: Number(duration.toFixed(2)),
      response
    }
  }
}

export const pdfResponseHandler = response =>
  response.headers.get('Content-Type') === 'application/pdf' ? response : null

export const csvResponseHandler = response => (response.headers.get('Content-Type') === 'text/csv' ? response : null)

function isRedirectedToSignInHTMLPage(response) {
  return (
    response.redirected &&
    (response.url.includes('vndly.com/home/login/') || response.url.includes('vndly.com/sign_in'))
  )
}

export const responseHandler = (response, { url, start, options = {} } = {}) =>
  Promise.resolve(response.text())
    .catch(() => '') // If there was an issue parsing the body, just make it empty
    // eslint-disable-next-line consistent-return
    .then(responseBody => {
      try {
        // TODO Migrate APIs away from @login_required decorator
        // Deal with @login_required returning HTML response
        if (isRedirectedToSignInHTMLPage(response)) {
          redirectToLoginIfLoggedOut()
          return Promise.reject(new AuthenticationError(response, 'Login Redirect'))
        }

        // Attempt to parse JSON
        const parsedJSON = responseBody ? JSON.parse(responseBody) : responseBody
        if (response.ok) {
          // we can't use performance for server-side rendering due to issues with importing perf_hooks
          // when we use fetch in storybook
          if (/* options.nextRequest || */ options.logTiming)
            decorateResponseWithTimingInfo({ url, start, res: parsedJSON, response })
          return parsedJSON
        }
        if (response.status >= 500) {
          return Promise.reject(new ServerError(response, parsedJSON))
        }
        // Handle browser location redirection if API returns a 400 code and header.next url
        const nextUrl = response.headers.get('next')
        if (nextUrl && response.status >= 400 && response.status < 500) {
          document.location.href = nextUrl
          return Promise.reject(new AuthenticationError(response, parsedJSON))
        }
        if (response.status === 401) {
          redirectToLogin()
          return Promise.reject(new AuthenticationError(response, parsedJSON))
        }
        if (response.status === 403) {
          redirectToLoginIfLoggedOut()
          return Promise.reject(new AuthenticationError(response, parsedJSON))
        }
        if (response.status < 500) {
          return Promise.reject(new ApplicationError(response, parsedJSON))
        }
      } catch (e) {
        // We should never get these unless response is mangled
        // Or API is not properly implemented
        return Promise.reject(new ParseJsonError(response, responseBody))
      }
    })

fetch.getPdf = (url, options) => fetch(url, { ...options, method: 'GET' }).then(pdfResponseHandler)
fetch.get = (url, options) => cacheableFetch(url, { ...options, method: 'GET' }, responseHandler)
fetch.postExpectingCsv = (url, body, options) =>
  fetch(url, { ...options, method: 'POST', body: JSON.stringify(body) }).then(csvResponseHandler)
fetch.post = (url, body, options) =>
  fetch(url, { ...options, method: 'POST', body: JSON.stringify(body) }).then(responseHandler)
// TODO Since contentType can be overridden in options, seems like we shouldn't automatically call
//  JSON.stringify() on the body.  Not refactoring this now, but it probably needs to be done.
fetch.postFile = (url, formData, options) =>
  fetch(url, { ...options, method: 'POST', body: formData }).then(responseHandler)
fetch.putFile = (url, formData, options) =>
  fetch(url, { ...options, method: 'PUT', body: formData }).then(responseHandler)
fetch.put = (url, body, options) =>
  fetch(url, { ...options, method: 'PUT', body: JSON.stringify(body) }).then(responseHandler)
fetch.patch = (url, body, options) =>
  fetch(url, { ...options, method: 'PATCH', body: JSON.stringify(body) }).then(responseHandler)
fetch.delete = (url, options) => fetch(url, { ...options, method: 'DELETE' }).then(responseHandler)

export default fetch

/**
 * @deprecated Use errors associated with responseHandler
 */
export class APIError extends Error {
  constructor(statusText, json) {
    super()
    this.message = `APIError [statusText=${statusText}]`
    this.json = json
  }
}

/**
 * Extract json from response
 * @param resp Promise from a fetch call
 * @returns Promise containing response content
 * @throws A rich error with .json property if the status is not ok
 * @deprecated Please use responseHandler instead
 */
export const jsonHandler = resp => {
  if (resp.ok) {
    if (resp.status === 204) {
      return resp.text()
    }
    return resp.json()
  }

  return resp.json().then(json => {
    throw new APIError(resp.statusText, json)
  })
}

export function redirectToLogin() {
  document.location.href = `/home/login/?next=${document.location.href}`
}

export function redirectToLoginIfLoggedOut() {
  // We want to batch any calls to is_authenticated for reasonable period (5 seconds)
  // There may be many endpoints resolving to 403s all at once and they all will want
  // to check for auth status, caching helps so only one call is made.
  const FIVE_SECONDS = 1000 * 5
  const responseCache = { ttl: FIVE_SECONDS }
  fetch.get('/api/v2/accounts/is_authenticated', { responseCache }).then(response => {
    if (!response.is_authenticated) {
      redirectToLogin()
    }
  })
}

export { FetchBaseError, NetworkError, ApplicationError, AuthenticationError, ParseJsonError, ServerError }
