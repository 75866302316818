import { useEffect, useMemo, useRef } from 'react'
import NumberFormat, { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format'
import { useStateRef } from '../../../hooks/useStateRef'
import { InputProps } from '../..'
import { useFormatCurrencyLocale } from '../../../common/currencyUtils'
import createDebugLogger from '../../../common/logger'
import { useIntl } from '../../../components/IntlProviders.context'
import { forwardRef, Input, InputGroup, InputLeftElement, InputRightElement, useFormControlContext } from '../../chakra'

const debug = createDebugLogger('InputCurrency')

type onChangeFloat = (value: number | null) => void
type onChangeString = (value: string) => void
export type InputCurrencyProps<TEmitFloat extends boolean | undefined> = {
  currency: string
  min?: number
  max?: number
  'data-testid'?: string
} & Omit<NumberFormatPropsBase<InputProps>, 'onChange' | 'customInput'> &
  // This code makes it so that if valueAsNumber is true,
  // then onChange must be a function that takes a number or null
  // and if valueAsNumber is false, then onChange must be a function that takes a string
  (TEmitFloat extends true
    ? { valueAsNumber: true; onChange?: onChangeFloat }
    : { valueAsNumber?: false; onChange?: onChangeString })

/**
 * InputCurrency
 *
 * Form control input to enter currency amounts. Values are formatted based on the user's locale
 * and currency code. The currency code will be displayed with the value and positioned based on
 * the ISO standard.
 *
 * All currency is displayed using the react-number-format component.
 */
export const InputCurrency = forwardRef<InputCurrencyProps<boolean | undefined>, 'input'>(
  (
    {
      currency,
      value,
      defaultValue,
      displayType,
      suffix,
      prefix,
      min = 0,
      max = 1e20,
      'data-testid': dataTestId,
      onChange,
      onBlur,
      valueAsNumber,
      ...rest
    },
    ref
  ) => {
    const isControlled = value !== undefined

    debug('---- render ----')
    const { id, isDisabled } = useFormControlContext() ?? {}
    // We will store all three value types from react-number-format's onChange in state as they are all handy to have around
    const [values, setValues] = useStateRef<NumberFormatValues>(() => {
      return {
        value: '',
        floatValue: undefined,
        formattedValue: ''
      }
    })
    const resolvedOpts = useResolveCurrencyFormat({ currency })

    const prevValuesRef = useRef<NumberFormatValues>(values.current)
    if (prevValuesRef.current.formattedValue !== values.current.formattedValue) {
      debug('values changed', { values: values.current, prevValues: prevValuesRef.current })
      prevValuesRef.current = values.current
    }

    // We'll keep track of the previous value, so we can compare it to the current value
    // and update the internal state if the value has changed. Using the usePrevious hook
    // didn't work here because I need to update the previous value sooner than the next render.
    const prevValueRef = useRef<string | number | null | undefined>()

    debug('Render', id, {
      value,
      prevValue: prevValueRef.current,
      values: values.current,
      resolvedOpts: {
        locale: resolvedOpts.locale,
        currency: resolvedOpts.currency,
        minimumFractionDigits: resolvedOpts.minimumFractionDigits,
        maximumFractionDigits: resolvedOpts.maximumFractionDigits,
        groupDelimiter: resolvedOpts.groupDelimiter,
        decimalDelimiter: resolvedOpts.decimalDelimiter,
        currencyPosition: resolvedOpts.currencyPosition
      }
    })

    // Two functions for formatting a value to a currency string
    // 1. formatCurrency: includes the currency code
    //    Used for the input's value when displayType is 'text'
    // 2. formatCurrencyWithoutCode: excludes the currency code
    //    Used for the input's value when displayType is 'input', the code isn't needed here
    //    because it's displayed as a prefix or suffix using InputLeftElement or InputRightElement
    const formatCurrencyLocale = useFormatCurrencyLocale()
    function formatCurrency(v: string | number | null | undefined) {
      if (v === '' || v == null) return ''
      return formatCurrencyLocale(v, { currency })
    }
    function formatCurrencyWithoutCode(v: string | number | null | undefined) {
      return formatCurrency(v).replace(currency, '').trim()
    }
    function makeValuesFromValue(v: string | number | null | undefined, clamp = false) {
      if (v === '' || v == null) {
        return {
          value: '',
          floatValue: undefined,
          formattedValue: ''
        }
      }
      // Restrict the value to the min/max props
      let num = Number(v)
      if (clamp) {
        num = Math.min(Math.max(num, min), max)
      }
      return {
        value: String(num),
        floatValue: num,
        formattedValue: formatCurrencyWithoutCode(num)
      }
    }

    // If the value prop (aka externally controlled value) has changed, we need to update the internal state
    const controlledValueChanged = value !== prevValueRef.current
    const valueIsDifferentFromStoredValue = valueAsNumber
      ? value !== values.current.floatValue
      : value !== values.current.value
    if (isControlled && controlledValueChanged && valueIsDifferentFromStoredValue) {
      // We'll have to construct the new values manually in a shape similar to the NumberFormatValues from react-number-format
      const newValues = makeValuesFromValue(value)
      debug('value HasChanged', id, {
        value,
        prevValue: prevValueRef.current,
        outOfSync: valueIsDifferentFromStoredValue,
        prevValues: values.current,
        newValues
      })
      setValues(newValues)
      prevValueRef.current = value
    }

    useEffect(() => {
      if (defaultValue !== undefined) {
        const values = makeValuesFromValue(defaultValue)
        debug('defaultValue set into values', id, values)
        setValues(values)
      }
    }, [])

    function internalOnChange(newValues: NumberFormatValues) {
      debug('internalOnChange', id, newValues)

      // If uncontrolled, update the internal state
      if (!isControlled) setValues(newValues)

      // Either way, call the onChange callback with the valueß
      if (valueAsNumber) {
        ;(onChange as onChangeFloat)?.(newValues.floatValue ?? null)
      } else {
        ;(onChange as onChangeString)?.(newValues.value)
      }
    }

    if (displayType === 'text') {
      return (
        <NumberFormat
          id={id}
          displayType="text"
          format={formatCurrency(values.current.value) + `${suffix || ''}`}
          value={values.current.floatValue}
          ref={ref}
          data-testid={dataTestId}
          {...rest}
        />
      )
    }

    return (
      <InputGroup>
        {resolvedOpts.currencyPosition === 'prefix' && (
          <InputLeftElement pointerEvents="none" pl={4} opacity={isDisabled ? '0.4' : '1'}>
            {currency}
          </InputLeftElement>
        )}
        <NumberFormat
          id={id}
          value={values.current.formattedValue}
          customInput={Input}
          getInputRef={ref}
          data-testid={dataTestId}
          decimalSeparator={resolvedOpts.decimalDelimiter}
          thousandSeparator={resolvedOpts.groupDelimiter}
          decimalScale={resolvedOpts.maximumFractionDigits}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            // onBlur if you've only typed, e.g. 55.1 then the value is changed to 55.10
            // We do leave empty string as empty string though, we don't want to format to 0.00 for empty.
            const newValues = makeValuesFromValue(values.current.value, true)
            debug('onBlur', id, values.current.value, '->', newValues)
            internalOnChange(newValues)
            onBlur?.(e)
          }}
          onValueChange={(values, sourceInfo) => {
            debug('onValueChange', id, values, sourceInfo.source)
            // While actively typing we allow invalid partial entries such as 55.1
            // which is missing another decimal place. But the user is still typing.
            // We also do not clamp, aka we don't use makeValuesFromValue, during
            // onValueChange, we wait until blur to clamp if needed.
            internalOnChange(values)
          }}
          sx={resolvedOpts.currencyPosition === 'prefix' && { paddingLeft: '3rem' }}
          {...rest}
        />
        {resolvedOpts.currencyPosition === 'suffix' && (
          <InputRightElement pointerEvents="none" pr={4} opacity={isDisabled ? '0.4' : '1'}>
            {currency}
          </InputRightElement>
        )}
      </InputGroup>
    )
  }
)

InputCurrency.displayName = 'InputCurrency'

/**
 * Hook to return all the parts of the currency formats needed to display in an input
 *
 * {
 *   'locale': 'en-US',
 *   'numberingSystem': 'latn',
 *   'style': 'currency',
 *   'currency': 'USD',
 *   'currencyDisplay': 'symbol',
 *   'minimumIntegerDigits': 1,
 *   'minimumFractionDigits': 2,
 *   'maximumFractionDigits': 2,
 *   'useGrouping': 'auto',
 *   'groupDelimiter': '.',
 *   'decimalDelimiter': ',',
 *   'currencySymbol': "USD"
 *   'currencyPosition': 'prefix',
 *   'notation': "standard",
 *   'roundingIncrement': 1
 *   'roundingMode': "halfExpand"
 *   'roundingPriority': "auto"
 *   'signDisplay': "auto"
 *   'trailingZeroDisplay':"auto"
 * }
 */
export const useResolveCurrencyFormat = ({ currency }: { currency: string }) => {
  const intl = useIntl()
  return useMemo(() => {
    const numberFormat = new Intl.NumberFormat(intl.locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'code'
    })

    // Just give it a number that needs grouping and decimal formatting, so we can find out which
    // characters are used for grouping and decimal.
    const parts = numberFormat.formatToParts(Number(11111111.11111))
    const resolvedOptions = numberFormat.resolvedOptions()

    const groupDelimiter = getPart(parts, 'group')
    let decimalDelimiter = getPart(parts, 'decimal')

    // if the decimal places allowed for given currency is zero, the decimal delimiter
    // must not be set to the same value as the group delimiter or undefined. Otherwise,
    // the react-number-format library will throw an error. The code below is ensuring
    // that it is set to a different value.
    if (resolvedOptions.maximumFractionDigits === 0) {
      if (groupDelimiter === ',') {
        decimalDelimiter = '.'
      } else {
        decimalDelimiter = ','
      }
    }

    const currencySymbol = getPart(parts, 'currency')
    const currencyIndex = parts.findIndex(part => part.type === 'currency')
    const currencyPosition = currencyIndex <= 1 ? 'prefix' : 'suffix'

    return {
      ...resolvedOptions,
      groupDelimiter,
      decimalDelimiter,
      currencySymbol,
      currencyPosition
    }
  }, [currency])
}

function getPart(parts: Intl.NumberFormatPart[], name: Intl.NumberFormatPart['type']): string | undefined {
  return parts.find(part => part.type === name)?.value
}
