const layerStyles = {
  none: {},
  card: {
    // @deprecated: We now have a custom Card component that can
    // be used instead of layer style for cards.
    bg: 'var(--vndly-color-container-background-light)',
    boxShadow: 'base',
    border: '1px',
    borderColor: 'var(--vndly-color-container-border)',
    borderRadius: 'var(--vndly-shape-base)'
  },
  light: {
    color: 'var(--vndly-color-container-light)',
    bg: 'var(--vndly-color-container-background-dark)'
  },
  dark: {
    color: 'var(--vndly-color-container-dark)'
  },
  // @deprecated: Moved inlineGray and inlineDark to Link global vairants instead.
  inlineGray: {
    color: 'var(--vndly-color-container-subdued) !important'
  },
  // @deprecated: Moved inlineGray and inlineDark to Link global vairants instead.
  inlineDark: {
    color: 'var(--vndly-color-container-dark) !important'
  },
  successGradient: {
    bgGradient: 'linear(to-r, brand.100, brand.500)'
  },
  warningGradient: {
    bgGradient: 'linear(to-r, yellow.100, yellow.500)'
  },
  dangerGradient: {
    bgGradient: 'linear(to-r, red.100, red.500)'
  },
  completeGradient: {
    bgGradient: 'linear(to-r, gray.400, gray.600)'
  },
  incompleteGradient: {
    bgGradient: 'linear(to-r, gray.200, gray.300)'
  },
  brandGradient: {
    bgGradient: 'linear(to-r, brand.500, blue.500)'
  }
}

export { layerStyles }
