import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const SadRefresh = forwardRef<IconProps, 'svg'>((props, ref) => (
  // @ts-expect-error
  <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
    <g className="wd-icon-container">
      <path
        fill="#FFDA61"
        d="M193.024 94.89c-.14-4.534.642-9.09 2.347-13.39 3.208-8.089 9.438-14.649 17.401-18.323a34.609 34.609 0 0 1 25.381-1.417c8.331 2.764 15.268 8.59 19.377 16.27a26.425 26.425 0 0 1 12.162-4.814 26.545 26.545 0 0 1 10.002.627C277.083 51.973 258.069 35 235 35c-24.853 0-45 19.7-45 44 0 5.603 1.071 10.96 3.024 15.89z"
      />
      <path
        fill="#B9C0C7"
        d="M320 121.173A27.597 27.597 0 0 1 313.471 139H180.305a32.702 32.702 0 0 1 2.815-18.393 33.168 33.168 0 0 1 12.418-13.975 33.501 33.501 0 0 1-.167-25.132c3.208-8.089 9.438-14.649 17.401-18.323a34.609 34.609 0 0 1 25.381-1.417c8.331 2.764 15.268 8.59 19.377 16.27a26.425 26.425 0 0 1 12.162-4.814c4.4-.563 8.873-.014 13.001 1.595a26.246 26.246 0 0 1 10.602 7.608 25.833 25.833 0 0 1 5.565 11.725 28.27 28.27 0 0 1 15.24 9.944 27.72 27.72 0 0 1 5.9 17.085zM108 41.81a8.007 8.007 0 0 0 1.912 5.19h38.999a9.478 9.478 0 0 0-.825-5.355 9.674 9.674 0 0 0-3.637-4.069 9.702 9.702 0 0 0 .05-7.317 9.932 9.932 0 0 0-5.096-5.334 10.193 10.193 0 0 0-7.434-.413 9.986 9.986 0 0 0-5.674 4.737 7.766 7.766 0 0 0-3.562-1.401 7.811 7.811 0 0 0-3.807.464 7.684 7.684 0 0 0-3.105 2.215 7.501 7.501 0 0 0-1.63 3.414 8.279 8.279 0 0 0-4.463 2.895A8.039 8.039 0 0 0 108 41.81zM299 47a5 5 0 1 0 0-10 5 5 0 0 0 0 10zM68 75c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
        opacity=".23"
      />
      <path
        fill="#40B4E5"
        d="M243 91c0 34.794-28.206 63-63 63-14.423 0-27.714-4.847-38.333-13H153a11.167 11.167 0 0 0-3.579-7.134 11.138 11.138 0 0 0-7.423-2.915 19.54 19.54 0 0 0-.706-9.228 19.51 19.51 0 0 0-21.822-13.195A63.046 63.046 0 0 1 117 91c0-34.794 28.205-63 63-63 34.794 0 63 28.206 63 63z"
      />
      <path
        fill="#B9C0C7"
        d="M88.538 129.782A19.562 19.562 0 0 0 78 141h75a11.167 11.167 0 0 0-3.579-7.134 11.138 11.138 0 0 0-7.423-2.915 19.54 19.54 0 0 0-.706-9.228 19.51 19.51 0 0 0-12.808-12.608 19.474 19.474 0 0 0-9.227-.549 19.515 19.515 0 0 0-12.277-12.522 19.469 19.469 0 0 0-17.359 2.408 19.511 19.511 0 0 0-6.031 6.696 19.54 19.54 0 0 0 4.948 24.634z"
        opacity=".23"
      />
      <g fill="#fff">
        <path d="M213.652 69.702h-9.437c5.99 6.08 9.681 14.362 9.681 23.493 0 18.67-15.429 33.806-34.461 33.806v-6.876c15.161 0 27.452-12.057 27.452-26.93 0-8.065-3.613-15.305-9.346-20.243v12.625a1 1 0 0 1-1 1h-5.009a1 1 0 0 1-1-1v-22.75h23.12a1 1 0 0 1 1 1v4.875a1 1 0 0 1-1 1zM146 112.298h9.438c-5.991-6.08-9.681-14.362-9.681-23.492 0-18.67 15.428-33.806 34.461-33.806v6.876c-15.162 0-27.452 12.057-27.452 26.93 0 8.065 3.612 15.304 9.345 20.243V96.424a1 1 0 0 1 1-1h5.009a1 1 0 0 1 1 1v22.75H146a1 1 0 0 1-1-1v-4.876a1 1 0 0 1 1-1z" />
        <path d="M204.215 69.702v-1h-2.389l1.676 1.702.713-.702zm-24.78 57.299h-1v1h1v-1zm0-6.876v-1h-1v1h1zm18.106-47.173l.653-.758-1.653-1.424v2.182h1zm-7.009-10.126v-1h-1v1h1zm-35.094 49.472v1h2.389l-1.677-1.702-.712.702zM180.218 55h1v-1h-1v1zm0 6.876v1h1v-1h-1zm-18.107 47.173l-.653.757 1.653 1.424v-2.181h-1zm7.009 10.125v1h1v-1h-1zm35.095-48.472h9.437v-2h-9.437v2zm10.681 22.493c0-9.406-3.803-17.937-9.969-24.195l-1.425 1.404c5.816 5.903 9.394 13.936 9.394 22.79h2zM179.435 128c19.566 0 35.461-15.566 35.461-34.806h-2c0 18.1-14.963 32.806-33.461 32.806v2zm-1-7.876v6.876h2v-6.876h-2zm27.452-26.93c0 14.302-11.825 25.93-26.452 25.93v2c15.695 0 28.452-12.487 28.452-27.93h-2zm-8.998-19.486c5.524 4.76 8.998 11.727 8.998 19.486h2c0-8.372-3.752-15.883-9.693-21.001l-1.305 1.515zm1.652 11.868V72.952h-2v12.625h2zm15.111-23.75h-23.12v2h23.12v-2zm-24.12 1v22.75h2v-22.75h-2zm26.12 5.875v-4.876h-2v4.876h2zm-19.111 16.875h-5.009v2h5.009v-2zm17.111-21.75h2a2 2 0 0 0-2-2v2zm0 6.875a2 2 0 0 0 2-2h-2v2zm-17.111 14.875v2a2 2 0 0 0 2-2h-2zm-7.009 0a2 2 0 0 0 2 2v-2h-2zm-34.094 25.721H146v2h9.438v-2zm-10.681-22.492c0 9.406 3.803 17.937 9.968 24.194l1.425-1.404c-5.816-5.902-9.393-13.936-9.393-22.79h-2zM180.218 54c-19.567 0-35.461 15.565-35.461 34.806h2c0-18.1 14.962-32.806 33.461-32.806v-2zm1 7.876V55h-2v6.876h2zm-27.452 26.93c0-14.303 11.824-25.93 26.452-25.93v-2c-15.696 0-28.452 12.487-28.452 27.93h2zm8.998 19.485c-5.525-4.759-8.998-11.727-8.998-19.485h-2c0 8.371 3.751 15.882 9.692 21l1.306-1.515zm-1.653-11.867v12.625h2V96.424h-2zM146 120.174h23.12v-2H146v2zm24.12-1v-22.75h-2v22.75h2zM144 113.298v4.876h2v-4.876h-2zm19.111-16.874h5.009v-2h-5.009v2zM146 118.174h-2a2 2 0 0 0 2 2v-2zm0-6.876a2 2 0 0 0-2 2h2v-2zm17.111-14.874v-2a2 2 0 0 0-2 2h2zm7.009 0a2 2 0 0 0-2-2v2h2z" />
      </g>
    </g>
  </chakra.svg>
))
