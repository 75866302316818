import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '~/common/react-query/queryClient'
import fetch from '~/common/fetch'

export const homeApi = {
  config: () => fetch.get(`/api/home/config/`),
  quickActions: () => fetch.get(`/api/home/quick_actions/`),
  termsAccepted: next_url => fetch.post(`/api/home/terms_of_conditions/`, { next_url }),
  stats: () => fetch.get(`/api/home/program_health_stats/`),
  events: () => fetch.get('/api/home/events/'),
  messages: () => fetch.get('/api/home/messages/'),
  contactUs: request => fetch.post(`/api/user-query/`, request),
  eventRespond: ({ id, status }) => fetch.post(`/api/respond_interview/${id}/`, { status }),
  contactUsConfig: () => fetch.get(`/api/contact-us-config/`)
}

export const HOME_STATS_QUERY_KEY = ['home', 'stats']
export const HOME_MESSAGES_QUERY_KEY = ['home', 'messages']
export const HOME_EVENTS_QUERY_KEY = ['home', 'events']
export const HOME_QUICK_ACTIONS_QUERY_KEY = ['home', 'quick_actions']

export const useHomeConfigQuery = () => useQuery({ queryKey: ['home', 'config'], queryFn: homeApi.config })
export const useHomeQuickActionsQuery = () =>
  useQuery({ queryKey: HOME_QUICK_ACTIONS_QUERY_KEY, queryFn: homeApi.quickActions })

export const useTermsAndConditionsAcceptedQuery = (next_url, options) => {
  useQuery({
    queryKey: ['home', 'terms'],
    queryFn: () => homeApi.termsAccepted(encodeURIComponent(next_url)),
    staleTime: 1000 * 60 * 60,
    ...options
  })
}

export const useHomeStatsQuery = () => useQuery({ queryKey: HOME_STATS_QUERY_KEY, queryFn: homeApi.stats })
export const useHomeEventsQuery = () => useQuery({ queryKey: HOME_EVENTS_QUERY_KEY, queryFn: homeApi.events })
export const useHomeMessagesQuery = () => useQuery({ queryKey: HOME_MESSAGES_QUERY_KEY, queryFn: homeApi.messages })

export const useContactUsMutation = () => useMutation({ mutationFn: homeApi.contactUs })
export const useEventRespondMutation = () =>
  useMutation({
    mutationFn: homeApi.eventRespond,
    onSuccess: () => {
      queryClient.invalidateQueries(HOME_EVENTS_QUERY_KEY)
    }
  })
export const useContactUsConfigQuery = () =>
  useQuery({ queryKey: ['contact-us-config'], queryFn: homeApi.contactUsConfig })
