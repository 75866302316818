import { Box, BoxProps, forwardRef } from '../..'

/**
 * ListRow
 *
 * A list item that displays information with no attached interactivity.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ListRow = forwardRef<BoxProps, 'div'>((props: BoxProps, ref) => (
  <Box
    data-testid="items-list-item"
    data-items-list-item
    role="listitem"
    ref={ref}
    d="flex"
    position="relative"
    alignItems="center"
    w="full"
    p={6}
    borderColor="var(--vndly-color-container-border)"
    borderTopWidth="1px"
    _first={{ borderTopWidth: 0 }}
    _last={{ borderBottomWidth: 0 }}
    outline="0"
    _focus={{ boxShadow: 'outline' }}
    bg="var(--vndly-color-container-light)"
    transitionDuration=".1s"
    transitionProperty="background"
    {...props}
  />
))

ListRow.displayName = 'ListRow'

/**
 * ListRowButton
 *
 * A list item, represented as a button.
 *
 * Provide `onClick` to each item to manage when it is clicked.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ListRowButton = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-items-list-button
    as="button"
    type="button"
    w="full"
    h="auto"
    ref={ref}
    flexGrow={1}
    size="full"
    d="flex"
    alignItems="center"
    textAlign="left"
    p={6}
    _hover={{ bg: 'blackAlpha.50' }}
    {...props}
  />
))

ListRowButton.displayName = 'ListRowButton'

/**
 * ListRowLink
 *
 * A list item, represented as a link.
 *
 * Provide `href` to control the link.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ListRowLink = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-items-list-link
    as="a"
    variant="unstyled"
    ref={ref}
    flexGrow={1}
    d="flex"
    alignItems="center"
    p={6}
    _hover={{ textDecoration: 'none', color: 'inherit', bg: 'blackAlpha.50' }}
    {...props}
  />
))

ListRowLink.displayName = 'ListRowLink'
