import React, { useState, useContext, useCallback } from 'react'
import { omit } from 'lodash'

type ItemWithRef = {
  id: string
  el: HTMLElement
}

const FormAlertListContext = React.createContext<
  | {
      targets: Record<string, ItemWithRef>
      registerItem: (id: string, el: HTMLElement) => void
      unregisterItem: (id: string) => void
    }
  | undefined
>(undefined)

export const FormAlertListContextProvider: React.FC = ({ children }) => {
  const [targets, setTargets] = useState<Record<string, ItemWithRef>>({})

  const registerItem = useCallback((id: string, el: HTMLElement) => {
    const item = {
      id,
      el
    }

    setTargets(m => {
      if (m[item.id]) {
        return m
      }
      return { ...m, [id]: item }
    })
  }, [])

  const unregisterItem = useCallback(
    (id: string) => {
      setTargets(m => omit(m, [id]))
    },
    [setTargets]
  )

  return (
    <FormAlertListContext.Provider value={{ targets, registerItem, unregisterItem }}>
      {children}
    </FormAlertListContext.Provider>
  )
}

export const useFormAlertListContext = () => {
  return useContext(FormAlertListContext)
}
