/**
 * Useful for getting a referentially stable default value for a prop or param that won't trigger
 * useEffects or other hook dependencies.
 *
 * @example
 * function MyComponent({ someProp = frozenArr }) {
 *   useEffect(() => {
 *     // Presuming someProp is not provided and the default is used,
 *     // this effect will not run on every render because someProp is stable
 *   }, [someProp])
 * }
 *
 * @see frozenObj
 */
export const frozenArr = Object.freeze([])

export const arrayToObject = (array, keyFunc = o => o.id, valueFunc = o => o.value) =>
  array.reduce((acc, item) => ({ ...acc, [keyFunc(item)]: valueFunc(item) }), {})

export const forceArray = maybeArr => {
  if (Array.isArray(maybeArr)) return maybeArr
  if (maybeArr == null) return []
  return [maybeArr]
}

/**
 Filters by fields provided.  If fields list is empty, returns original array, unchanged
 */
export function filterOrAll(array, fields) {
  return array?.length > 0 ? array.filter(i => fields.includes(i)) : array
}

/**
 * Return the first element of array or null if array does not exist or there are no elements
 *
 * @param array
 * @returns {*|null}
 */
export function firstOrNull(array) {
  return array?.length > 0 ? array[0] : null
}

export function unique(array) {
  return Array.from(new Set(array))
}
