/* eslint-disable react/sort-comp */
import * as React from 'react'
import { MessageFormatElement } from 'react-intl'

class TranslationFallbackWrapper extends React.Component<
  { id?: string | number | undefined; message?: string | number | MessageFormatElement[] },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props)
    this.hasLoggedError = false
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  hasLoggedError: boolean

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.hasLoggedError === false) {
        // eslint-disable-next-line no-console
        console.error('Translation intl obj not found, fallback of', this.props.message, 'used for id:', this.props.id)
        this.hasLoggedError = true
      }
      return this.props.message ?? this.props.id
    }
    return this.props.children
  }
}

export default TranslationFallbackWrapper
