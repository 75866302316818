import { QueryKey, UseQueryOptions } from '@tanstack/react-query'

/**
 * A small function to help with typing completion while constructing a QueryObject
 */
export function queryObj<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(obj: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  return obj
}
