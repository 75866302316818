import { forwardRef, Box, BoxProps } from '../../chakra'

/**
 * IconWithNotification
 *
 * A utility component designed specifically for showing the DotIndicator with Icons since that is
 * the dominant use case for the `DotIndicator`:
 * https://storybook.beta.vndly.com/?path=/docs/foundations-data-display-indicator-badges--icon-with-dot-notification
 */
export const IconWithNotification = forwardRef(({ children, ...props }: BoxProps, ref) => {
  return (
    <Box d="flex" pos="relative" w="fit-content" ref={ref} {...props}>
      {children}
    </Box>
  )
})
