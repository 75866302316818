import { NavigationMenu } from '~/navigation_menu/NavigationMenu'
import { BaseLayout } from './BaseLayout'

export const MainLayout = ({ children }) => {
  return (
    <>
      <BaseLayout />
      {/* eslint-disable-next-line no-nested-ternary */}
      <NavigationMenu />
      {children}
    </>
  )
}
