import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const SadGhost = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    // @ts-expect-error IconProps isn't from Chakra so types don't line up
    <chakra.svg ref={ref} viewBox="0 0 194 183" {...props}>
      <g transform="translate(.387258 .390525)" fill="none" fillRule="evenodd">
        <path
          d="M154.112742 139.809475v2.1c-.2 10.5-8.8 19.1-19.4 19.1h-.1c-5.9 0-11.3-2.7-14.9-7.2-5.8 7-15.9 9.2-24.2000003 4.7-2.2-1.2-4.2-2.8-5.7-4.8-2.4 3-5.7 5.2-9.5 6.3-4.9 1.5-10.2.9-14.7-1.5-2.2-1.2-4.2-2.8-5.7-4.7-5.7 7.1-15.8 9.3-24.1 4.9-6.2-3.3-10.1-9.8-10.2-16.9v-65.9c0-17.1 6.7-33.3 18.8-45.4 12.1-12.1 28.2-18.8 45.4-18.8l.1 2.3v-2.3c35.4000003 0 64.2000003 28.8 64.2000003 64.2v63.9z"
          fill="#F2F3F3"
          fillRule="nonzero"
        />
        <path d="M89.8127417 22.009475c-14.5 0-28.1 5.6-38.3 15.8-10.1 10.2-15.7 23.7-15.7 38v65.8c.1 3.4 1.9 6.4 4.7 7.9 2.2 1.1 4.6 1.4 6.9.7 2.3-.7 4.2-2.2 5.3-4.4 1.4-2.6 4.1-4.2 7.1-4.2s5.7 1.7 7.1 4.3c.9 1.7 2.4 2.9 3.6 3.5 4.4 2.4 9.8.8 12.2-3.6 1.4-2.6 4.1-4.2 7.1-4.2s5.7 1.7 7 4.3c.9 1.7 2.4 2.9 3.6000003 3.5 2.1 1.1 4.5 1.4 6.8.7s4.2-2.2 5.4-4.3c1.4-2.6 4.1-4.2 7-4.2 2.9 0 5.6 1.6 7 4.2 1.6 3 4.6 4.8 7.9 4.8 5.1 0 9.100309-4 9.100309-9v-65.7c.099691-29.7-24.100309-53.9-53.8003093-53.9z" />
        <path
          d="M89.8127417 16.409475c-16 0-31 6.2-42.2 17.5-11.3 11.3-17.5 26.2-17.4011911 42.1v65.9c0 5.4 3.0011911 10.3 7.8011911 12.9 7.2 3.8 16.1 1.1 19.9-6 .4-.7 1.2-1.2 2-1.2s1.6.5 2 1.2c1.4 2.5 3.4 4.6 5.9 5.9 7.1 3.9 16.1 1.2 20-5.9.4-.7 1.2-1.2 2-1.2s1.6.5 2 1.2c1.4 2.5 3.4 4.6 5.9 5.9 7.2000003 3.9 16.1000003 1.2 20.0000003-5.9.4-.9 1.2-1.4 2-1.4s1.6.5 2 1.2c2.5 4.8 7.5 7.7 12.9 7.8h.1c8.1 0 14.7-6.5 14.7-14.6v-65.8c0-32.9-26.7-59.6-59.6000003-59.6zm53.9000003 125.2c-.1 5-4.1 9-9.1 9-3.2 0-6.3-1.9-7.9-4.8-1.4-2.6-4.1-4.2-7-4.2-2.9 0-5.6 1.6-7 4.2-1.1 2.1-3.1 3.7-5.4 4.3-2.3.7-4.8.4-6.8-.7-1.1000003-.6-2.7000003-1.7-3.6000003-3.5-1.4-2.6-4.1-4.3-7-4.3-3 0-5.7 1.6-7.1 4.2-2.4 4.4-7.8 6-12.2 3.6-1.1-.6-2.7-1.7-3.6-3.5-1.4-2.6-4.1-4.3-7.1-4.3s-5.7 1.6-7.1 4.2c-1.1 2.1-3 3.7-5.3 4.4-2.3.7-4.8.5-6.9-.7-2.9-1.5-4.7-4.5-4.7-7.9v-65.8c0-14.4 5.5-27.9 15.7-38 10.2-10.2 23.8-15.8 38.3-15.8 29.7000003 0 53.9000003 24.2 53.9000003 53.9v65.7h-.1z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M89.9127417 11.709475v2.3l-.1-2.3c-17.2 0-33.3 6.7-45.4 18.8-12.1 12.1-18.8 28.3-18.8 45.4v65.9c.1 7.1 4 13.6 10.2 16.9 8.3 4.4 18.4 2.2 24.1-4.9 1.5 1.9 3.5 3.5 5.7 4.7 4.5 2.4 9.8 3 14.7 1.5 3.8-1.1 7.1-3.3 9.5-6.3 1.5 2 3.5 3.6 5.7 4.8 8.3000003 4.5 18.4000003 2.3 24.2000003-4.7 3.6 4.5 9 7.2 14.9 7.2h.1c10.6 0 19.2-8.6 19.4-19.1v-66c0-35.4-28.8-64.2-64.2000003-64.2zm59.5000003 128.2v1.9c0 8.1-6.6 14.6-14.7 14.6h-.1c-5.4-.1-10.4-3-12.9-7.8-.4-.7-1.2-1.2-2-1.2s-1.6.5-2 1.4c-3.9 7.1-12.8 9.8-20.0000003 5.9-2.5-1.3-4.5-3.4-5.9-5.9-.4-.7-1.2-1.2-2-1.2s-1.6.5-2 1.2c-3.9 7.1-12.9 9.8-20 5.9-2.5-1.3-4.5-3.4-5.9-5.9-.4-.7-1.2-1.2-2-1.2s-1.6.5-2 1.2c-3.8 7.1-12.7 9.8-19.9 6-4.8-2.6-7.8011911-7.5-7.8011911-12.9v-65.9c-.0988089-15.9 6.1011911-30.8 17.4011911-42.1 11.2-11.3 26.2-17.5 42.1-17.5h.1c32.9000003 0 59.6000003 26.7 59.6000003 59.6v63.9z"
          fill="#CBCACA"
          fillRule="nonzero"
        />
        <path d="M89.8127417 14.009475c34.2000003 0 61.9000003 27.7 61.9000003 61.9v65.8c-.1 9.4-7.7 16.9-17.1 16.9-6.2 0-11.9-3.5-14.9-9-4.5 8.3-14.9 11.3-23.1000003 6.8-2.9-1.6-5.3-3.9-6.8-6.8-4.5 8.3-14.8 11.3-23.1 6.8-2.9-1.6-5.3-3.9-6.8-6.8-4.4 8.3-14.7 11.4-23 7-5.5-2.9-8.9-8.6-9.0002699-14.9v-65.8c-.0997301-34.2 27.6002699-61.9 61.9002699-61.9-.1 0 0 0 0 0z" />
        <path d="M89.8127417 14.009475c34.2000003 0 61.9000003 27.7 61.9000003 61.9v65.8c-.1 9.4-7.7 16.9-17.1 16.9-6.2 0-11.9-3.5-14.9-9-4.5 8.3-14.9 11.3-23.1000003 6.8-2.9-1.6-5.3-3.9-6.8-6.8-4.5 8.3-14.8 11.3-23.1 6.8-2.9-1.6-5.3-3.9-6.8-6.8-4.4 8.3-14.7 11.4-23 7-5.5-2.9-8.9-8.6-9.0002699-14.9v-65.8c-.0997301-34.2 27.6002699-61.9 61.9002699-61.9-.1 0 0 0 0 0z" />
        <circle fill="#CBCACA" fillRule="nonzero" cx="65.9127417" cy="77.909475" r="8" />
        <circle fill="#CBCACA" fillRule="nonzero" cx="113.812742" cy="77.909475" r="8" />
        <path
          d="M36.0127417 9.20947502h-1.8c-3 0-5.5-2.5-5.5-5.5v-1.8c0-.9-.7-1.8-1.6-1.9-1-.1-1.9.6-2 1.7v2c0 3-2.5 5.5-5.5 5.5h-1.7c-1 0-1.8.7-1.9 1.69999998-.1 1 .6 1.9 1.6 2h2c3 0 5.4 2.5 5.4 5.5v1.7c0 1 .7 1.8 1.7 1.9 1 .1 1.9-.6 2-1.6v-2c0-3 2.4-5.5 5.5-5.5h1.8c1 0 1.8-.8 1.8-1.9v-.2c0-.89999998-.8-1.59999998-1.8-1.59999998M191.312742 24.409475h-1.7c-3 0-5.4-2.4-5.4-5.4v-1.7c0-.9-.7-1.7-1.6-1.9-1-.1-1.9.6-2 1.6v2c0 3-2.4 5.4-5.4 5.4h-1.7c-.9 0-1.8.7-1.9 1.6-.1 1 .6 1.9 1.6 2h2c3 0 5.4 2.4 5.4 5.4v1.7c0 .9.7 1.7 1.6 1.9 1 .1 1.9-.6 2-1.6v-2c0-3 2.4-5.4 5.4-5.4h1.8c1 0 1.8-.8 1.8-1.8v-.2c-.2-.9-1-1.6-1.9-1.6m-173.0000003 139.9h-1.3c-2.2 0-3.9-1.8-3.9-3.9v-1.3c0-.7-.5-1.3-1.1-1.4-.7-.1-1.4.4-1.4 1.2v1.4c0 2.2-1.8 3.9-3.9 3.9h-1.3c-.7 0-1.3.5-1.4 1.1-.1.7.5 1.4 1.2 1.4h1.4c2.2 0 3.9 1.7 3.9 3.9v1.3c0 .7.5 1.3 1.1 1.4.7.1 1.4-.4 1.4-1.2v-1.4c0-2.2 1.8-3.9 3.9-3.9h1.3c.7 0 1.3-.6 1.3-1.3v-.1c0-.7-.5-1.1-1.2-1.1m147.7000003-153.7h-1.2c-2.2 0-3.9-1.79999998-3.9-3.89999998v-1.3c0-.7-.5-1.3-1.1-1.4-.7-.1-1.4.5-1.4 1.2v1.4c0 2.2-1.8 3.89999998-3.9 3.89999998h-1.2c-.7 0-1.3.5-1.4 1.1-.1.7.4 1.4 1.2 1.4h1.4c2.2 0 3.9 1.8 3.9 3.9v1.3c0 .7.5 1.3 1.1 1.4.7.1 1.4-.5 1.4-1.2v-1.4c0-2.2 1.8-3.9 3.9-3.9h1.3c.7 0 1.3-.6 1.3-1.3v-.1c-.1-.6-.7-1.1-1.4-1.1m-151.7000003 27.9h-1.3c-2.2 0-3.9-1.8-3.9-3.9v-1.2c0-.7-.5-1.3-1.1-1.4-.7-.1-1.4.5-1.4 1.2v1.4c0 2.2-1.8 3.9-3.9 3.9h-1.3c-.7 0-1.3.5-1.4 1.1-.1.7.4 1.4 1.1 1.5h1.5c2.2 0 3.9 1.8 3.9 3.9v1.3c0 .7.5 1.3 1.1 1.4.7.1 1.4-.5 1.4-1.2v-1.4c0-2.2 1.7-3.9 3.9-3.9h1.3c.7 0 1.3-.6 1.3-1.3v-.1c-.1-.7-.7-1.2-1.4-1.1"
          fill="#CBCACA"
          fillRule="nonzero"
        />
        <path
          d="M120.812742 177.709475c0 2.5-13.4 4.5-29.9000003 4.5-16.5 0-30-2-30-4.5s13.4-4.6 30-4.6c16.6000003 0 29.9000003 2.1 29.9000003 4.6m66.8-2.4c0-2.5-8.7-4.5-19.4-4.5h-1.1c-3.2-.6-7.3-.9-11.6-.9-10.7 0-19.4 2-19.4 4.5 0 1.7 4 3.2 10 4 1.4 2.2 9.4 3.8 19.2 3.8 10.7 0 19.4-2 19.4-4.5 1.8-.7 2.9-1.6 2.9-2.4z"
          fill="#F2F3F3"
          fillRule="nonzero"
        />
        <path fill="#FFF" fillRule="nonzero" d="M158.212742 79.209475l18.7 6.4-19.2 25.8-19.3 25.8.5-32.2.6-32.2z" />
        <path
          d="M138.412742 139.209475c-.2 0-.4 0-.6-.1-.8-.3-1.4-1.1-1.4-1.9l.5-32.2.6-32.2c0-.6.3-1.2.9-1.6.5-.4 1.2-.5 1.8-.3l18.7 6.4 18.7 6.4c.6.2 1.1.7 1.3 1.3.2.6.1 1.3-.3 1.8l-19.3 25.8-19.3 25.8c-.4.5-1 .8-1.6.8zm3-63.6l-.5 29.5-.4 26 15.6-20.9 17.6-23.6-32.3-11z"
          fill="#CBCACA"
          fillRule="nonzero"
        />
        <path
          d="M178.312742 64.709475l-.8-.5-.1-1c-.5-4.8-3.5-10.8-8.8-12.5-5.3-1.8-10.9 1-14.2 4.6l-1 1.1-1.3-.7c-.3-.2-.6-.3-.9-.3l-.2-.1c-3.1-1-6.4-.8-9.3.7-2.9 1.5-5.1 4-6.1 7-1.6 4.9 0 10.3 4.1 13.5l1.1.9-3.3 10.1c-.4 1.1.2 2.3 1.3 2.7 1.1.4 2.3-.2 2.7-1.3l3.3-9.8h4.2l.6.8c3.1 3.9 8.2 5.6 13.1 4.4l.9-.2.8.6c1.1.9 2.4 1.6 3.7 2l2.8.9-1.7 5.5c-.4 1.1.2 2.4 1.3 2.7 1.1.3 2.3-.2 2.7-1.3l2.6-7.7.8-.4c3.3-1.4 5.8-4.1 6.9-7.5 1.5-5.4-.5-11.2-5.2-14.2z"
          fill="#F2F3F3"
          fillRule="nonzero"
        />
        <path
          d="M185.012742 59.709475c-1.5-6.8-6.1-14.1-13.8-16.6-6.4-2.1-13.8-.5-19.6 4.1-4.5-1-9.3-.4-13.5 1.7-4.8 2.4-8.4 6.6-10.1 11.7-2.2 6.7-.7 13.9 3.6 19.2l-1.7 5.2c-.8 2.6-.6 5.3.6 7.7 1.2 2.4 3.3 4.2 5.9 5.1 2.6.8 5.3.6 7.7-.6 2.4-1.2 4.2-3.3 5.1-5.9l.4-1.3c3.5 1.9 7.5 2.8 11.5 2.5-.5 4.6 2.3 9.2 6.9 10.7 1 .3 2.1.5 3.2.5 1.6 0 3.1-.4 4.6-1.1 2.4-1.2 4.2-3.3 5-5.9l1.5-4.5c4.1-2.5 7.2-6.3 8.7-11 2.5-7.7 0-16.2-6-21.5zm2.1 20.3c-1.4 4.2-4.3 7.5-8.3 9.5l-2 6.1c-.8 2.5-3.3 4.2-5.8 4.2-.6 0-1.2-.1-1.9-.3-3.2-1.1-4.9-4.5-3.9-7.7l.5-1.7c-1.1-.4-2.2-1-3.2-1.7-5.5 1-11.3-.8-15.2-4.8l-2.2 6.6c-1.1 3.2-4.5 4.9-7.7 3.9-3.2-1.1-4.9-4.5-3.9-7.7l2.5-7.5c-4.6-4.3-6.4-10.9-4.4-17 1.3-4.1 4.2-7.4 8.1-9.4 3.8-1.9 8.2-2.3 12.2-.9.1 0 .3.1.4.1 4-3.8 10.6-6.9 17.4-4.7 6.7 2.1 10.5 8.9 11.4 15 5.8 4 8.2 11.3 6 18z"
          fill="#CBCACA"
          fillRule="nonzero"
        />
        <path
          d="M181.312742 61.909475c-.9-6.1-4.7-12.8-11.4-15-6.8-2.3-13.4.9-17.4 4.7-.1 0-.2-.1-.4-.1-4-1.3-8.4-1-12.2.9-3.9 2-6.8 5.3-8.1 9.4-2 6.1-.3 12.7 4.4 17l-2.5 7.5c-1 3.2.7 6.7 3.9 7.7s6.7-.7 7.7-3.9l2.2-6.6c3.9 4 9.6 5.8 15.2 4.8 1 .7 2.1 1.3 3.2 1.7l-.5 1.7c-1 3.2.7 6.7 3.9 7.7.6.2 1.2.3 1.9.3 2.6 0 5-1.6 5.8-4.2l2-6.1c4-2 6.9-5.3 8.3-9.5 2-6.6-.4-13.9-6-18zm2 16.9c-1.1 3.4-3.6 6-6.9 7.5l-.8.4-2.6 7.7c-.4 1.1-1.6 1.7-2.7 1.3-1.1-.4-1.7-1.6-1.3-2.7l1.7-5.5-2.8-.9c-1.3-.4-2.6-1.1-3.7-2l-.8-.6-.9.2c-4.8 1.2-9.9-.5-13.1-4.4l-.6-.8h-4.2l-3.3 9.8c-.4 1.1-1.6 1.7-2.7 1.3-1.1-.4-1.7-1.6-1.3-2.7l3.3-10.1-1.1-.9c-4.1-3.2-5.8-8.6-4.1-13.5 1-3.1 3.2-5.6 6.1-7 2.9-1.5 6.2-1.7 9.3-.7l.2.1.9.3 1.3.7 1-1.1c3.3-3.5 8.9-6.3 14.2-4.6 5.4 1.7 8.3 7.7 8.8 12.5l.1 1 .8.5c4.9 3.1 6.9 8.9 5.2 14.2z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M169.312742 116.109475h.1c1.5.1 2.4 2.9 2.3 4.4v.1c-.1 1.4-1.3 2.5-2.7 2.5h-.2c-1.5-.1-2.6-1.4-2.4-2.9v-.1c0-1.6 1.4-4 2.9-4m-.1-3.5c-3.7 0-6 4.1-6.3 7v.1c-.3 3.3 2.2 6.3 5.5 6.5h.5c3.1 0 5.8-2.4 6-5.6.1-1.5-.2-3-.9-4.4-1.4-2.9-3.4-3.5-4.6-3.6h-.2z"
          fill="#CBCACA"
          fillRule="nonzero"
        />
        <path
          d="M138.212742 110.709475h-.3c-2.4-.2-4.2-2.3-4-4.7.2-2 1.9-5.6 4.6-5.6h.2c1.5.2 2.7 1.2 3.2 2.6.5 1.1.8 2.3.7 3.5-.2 2.4-2.1 4.2-4.4 4.2z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M138.512742 102.109475h.1c1.5.1 2.4 2.9 2.3 4.4v.1c-.1 1.4-1.3 2.5-2.7 2.5h-.2c-1.5-.1-2.6-1.4-2.4-2.9v-.1c.1-1.5 1.4-4 2.9-4m-.1-3.4c-3.7 0-6 4.1-6.3 7v.1c-.3 3.3 2.2 6.3 5.5 6.5h.5c3.1 0 5.8-2.4 6-5.6v-.1c.2-2.7-1.4-7.7-5.4-8l-.3.1zm-36.7 16.9c-.3 0-.7-.1-1-.3-.9000003-.5-1.2000003-1.7-.6-2.5 4.6-7.7 14.6-7.3 15.1-7.3 1 0 1.8.9 1.8 1.9 0 1-.9 1.8-1.9 1.8-.1 0-8.3-.2-11.7 5.5-.5.5-1.1.9-1.7.9z"
          fill="#CBCACA"
          fillRule="nonzero"
        />
      </g>
    </chakra.svg>
  )
})
