/* eslint-disable no-param-reassign */
import { isPlainObject, memoize, transform } from 'lodash'
import { BoxProps } from '~/design_system'
import createDebugLogger from './logger'

const debug = createDebugLogger('containerQuery')

export const NO_CONTAINER_PRESENT = -1

export const CONTAINER_QUERY_BREAKPOINTS = {
  default: NO_CONTAINER_PRESENT, // This is a special value that means no container is present
  base: 0,
  '5xs': 64,
  '4xs': 128,
  '3xs': 192,
  '2xs': 256,
  xs: 320,
  sm: 384,
  md: 448,
  lg: 512,
  xl: 576,
  '2xl': 672,
  '3xl': 768,
  '4xl': 896,
  '5xl': 1024,
  '6xl': 1152,
  '7xl': 1280,
  '8xl': 1440
} as const

export type NameToBreakpoints = Record<string, QueryLabelToWidthPx>
export type QueryLabelToWidthPx = Record<string, number>
export type QueryLabelToStyleValue = {
  [key in keyof typeof CONTAINER_QUERY_BREAKPOINTS as `@${key}`]?: any
}

const arbitraryValueRegex = /@([^/]+\/)?\[[^\]]+]/
const replaceContainerNameRegex = /@[^/]+\//
const queryKeyExtractionRegex = /@(?<sizeLabel1>[^/]+)$|@(?<containerName>[^/]+)\/(?<sizeLabel2>.+)$/
const extractArbitraryContainerWidthRegex = /\[(?<arbitraryValue>[^\]]+)\]/

// Here we merge the default breakpoints with the custom ones
// We also add the `@` symbol to the breakpoint names, it makes it easier to compare the incoming style keys later
const breakpointsWithAt = transform(CONTAINER_QUERY_BREAKPOINTS, (result: QueryLabelToWidthPx, value, key) => {
  result[`@${key}`] = value
})

// We extract all the breakpoint labels from the merged breakpoints, deduping them
// An example of how this set might look is `Set(['@base', '@sm', '@md', '@lg', '@xl', '@2xl', '@3xl', '@4xl', '@5xl', '@6xl', '@7xl', '@8xl', '@somecustomsize'])`
const allPossibleBreakpointLabels = new Set(Object.keys(breakpointsWithAt))

/**
 * A utility to style a component using container queries but with Chakra UI style semantics
 *
 * @example Basic Example
 * ```tsx
 * <Box sx={{ containerType: 'inline-size' }}>
 *   <Box
 *     sx={cq({
 *       // Write styles normally
 *       h: '200px',
 *       display: 'block',
 *       // Then when you have a css property that you want to respond to the container width
 *       // use the `@` symbol followed by the breakpoint name
 *       bg: { '@base': 'red.500', '@sm': 'green.500', '@md': 'yellow.500', '@lg': 'blue.500' }
 *       // use @default to define a style that will be applied when no container is present
 *       color: { '@default': 'white', '@base': 'black' }
 *     })}
 *   />
 * />
 * ```
 *
 * @example Using a custom container name
 * ```tsx
 * <Box sx={{ containerType: 'inline-size', containerName: 'sidebar' }}>
 *   <Box
 *     sx={cq({
 *       // Write styles normally
 *       h: '200px',
 *       display: 'block',
 *       // Then when you have a css property that you want to respond to the container width
 *       // use the `@` symbol followed by the container name, then /, then breakpoint name
 *       bg: {
 *         '@sidebar/base': 'red.500',
 *         '@sidebar/sm': 'green.500',
 *         '@sidebar/md': 'yellow.500',
 *         '@sidebar/lg': 'blue.500'
 *       }
 *     })}
 *   />
 * />
 *  ```
 *
 *  @example Using arbitrary values
 *  ```tsx
 * <Box sx={{ containerType: 'inline-size', containerName: 'sidebar' }}>
 *   <Box
 *     sx={cq({
 *       // Wrap the size in square brackets to use an arbitrary value
 *       bg: {
 *         '@base': 'red.500',
 *         '@sm': 'green.500',
 *         '@[888px]': 'yellow.500',
 *         // Or when using with a container name...
 *         '@sidebar/[45rem]': 'blue.500'
 *       }
 *     })}
 *   />
 * />
 *  ```
 *
 */
export const cq =
  // We memoize the function so that we don't have to recalculate the same styles over and over
  // The function is idempotent, so it's safe to do this. Same styles in should always mean same styles out.
  memoize(
    function cq(styles: BoxProps['sx']) {
      function modify(scope: Record<string, unknown>): BoxProps['sx'] {
        // We use lodash's transform function to iterate over the object and reduce it to a new object
        return transform(
          // scope is the current object we're iterating over, it could be the top level object or a nested object
          // since we are using transform recursively.
          scope,
          function transformCq(result: Record<string | number, any>, value: unknown, key: string | number) {
            // happy path, just leave the key/value the same
            // below this line though, we will be checking if we actually want to modify the key/value
            // or even write new key/values to the result object
            result[key] = value

            // We only need to continue if the value is an object and the key is a string
            if (!key || typeof key !== 'string' || !isPlainObject(value)) return

            const valueObj = value as Record<string, unknown>
            const valueKeys = Object.keys(valueObj)

            // If the value is an object, we need to check if it has any nested objects
            const hasNestedObjectValues = valueKeys.some(key => isPlainObject(valueObj[key]))
            // If it does, we need to recursively modify the nested object
            if (hasNestedObjectValues) {
              result[key] = modify(valueObj)
            }

            // Now we want to gather only the keys that look like container query keys:
            // e.g. `@breakpointLabel`, `@containerName/breakpointLabel`, `@containerName/[arbitraryValue]`
            const containerQueryKeys = valueKeys.filter(key => {
              debug('key', key, arbitraryValueRegex.toString(), arbitraryValueRegex.test(key))
              return (
                allPossibleBreakpointLabels.has(key.replace(replaceContainerNameRegex, '@')) ||
                arbitraryValueRegex.test(key)
              )
            })

            debug('containerQueryKeys', containerQueryKeys)

            // If there are no container query keys, we can just return early
            if (containerQueryKeys.length === 0) return

            // If there are container query keys, but there are also non-container query keys, we throw an error
            // because I don't think we want people mixing responsive and container breakpoints
            // e.g. `p: { 'base': 4, '@base': 6 }` <-- yuck
            if (containerQueryKeys.length !== valueKeys.length) {
              throw new Error(`Cannot mix responsive and container breakpoints. ${JSON.stringify(value, undefined, 2)}`)
            }

            const hasDefaultKey = containerQueryKeys.includes('@default')

            // Now we'll iterate over the keys that we need to transform
            containerQueryKeys.forEach(queryKey => {
              debug('queryKey', queryKey)
              // Extract containerName and sizeLabel from the query key
              // There are two possible formats for the query key:
              // 1. @sizeLabel -> will output to `@container (min-width: ${width}px)`
              // 2. @containerName/sizeLabel --> will output to `@container ${containerName} (min-width: ${width}px)`
              const { containerName, sizeLabel1, sizeLabel2 } = queryKeyExtractionRegex.exec(queryKey)
                ?.groups as Record<string, string>
              const sizeLabel = sizeLabel1 ?? sizeLabel2

              // Get the value from the original object, like `4` or `red.500`, etc
              const value = valueObj[queryKey]

              // If they used an arbitrary value, we need to extract it from the query key, like `@containerName/[1234px]`
              // we'd extract `1234px` from that
              const arbitraryContainerWidth =
                extractArbitraryContainerWidthRegex.exec(sizeLabel)?.groups?.arbitraryValue

              // Get the container width from the breakpoints object, either the custom one or the default one
              const breakpointContainerWidth = breakpointsWithAt['@' + sizeLabel]

              debug({ containerName, sizeLabel, breakpointContainerWidth, arbitraryContainerWidth, value })

              // Remove the original key/value from the result object, it means nothing to chakra/emotion
              // It was just a special syntax we invented to handle container queries
              delete result[queryKey]
              if (!hasDefaultKey) {
                delete result[key]
              }

              // Handle missing container queries, where the container isn't present
              // They are not wrapped in a `@container ()` query
              // So `{ color: { '@base': 'red' } }` will just output to `{ color: red }`
              if (breakpointContainerWidth === NO_CONTAINER_PRESENT) {
                result[key] = value
                return
              }

              // Add a single space after container names
              const name = containerName ? containerName + ' ' : ''
              const containerWidthString = arbitraryContainerWidth ?? `${breakpointContainerWidth}px`
              // Handle non-base queries, wrap in an @container query
              const containerQueryStr = `@container ${name}(min-width: ${containerWidthString})`

              // Finally, we add the new key/value to the result object
              result[containerQueryStr] = {
                '&': {
                  // Spread the existing styles for this selector first
                  ...result?.[containerQueryStr]?.['&'],
                  // Then add the new styles
                  [key]: value
                }
              }
            })
          }
        )
      }

      const modified = styles ? modify(styles) : styles
      debug(modified)
      return modified
    },
    (styles: BoxProps['sx']) => stableHash(styles)
  )

/**
 * Hashes the value into a stable hash.
 */
function stableHash(obj: BoxProps['sx']): string {
  return JSON.stringify(obj, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key]
            return result
          }, {} as any)
      : val
  )
}
