const text = {
  textStyle: 'errorSmall'
}

const icon = {
  mr: 1
}

const warningText = {
  textStyle: 'bodySmallSemi',
  mt: 2,
  display: 'flex',
  alignItems: 'center',
  lineHeight: 'normal'
}

const warningIcon = {
  color: 'cantaloupe400',
  mr: 1
}

export const FormError = {
  baseStyle: {
    text,
    icon,
    warningText,
    warningIcon
  }
}
