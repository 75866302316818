import { useCallback, useState } from 'react'
import useForceUpdate from './useForceUpdate'

export function useStateRef<T>(initialState: T | (() => T)) {
  const [state] = useState<{ current: T }>(() => ({
    // @ts-ignore Known bug: https://github.com/microsoft/TypeScript/issues/37663
    current: typeof initialState === 'function' ? initialState() : initialState
  }))

  const forceUpdate = useForceUpdate()

  const setStateRef = useCallback((setter, triggerRerender = true) => {
    // eslint-disable-next-line no-param-reassign
    state.current = typeof setter === 'function' ? setter(state.current) : setter
    if (triggerRerender) forceUpdate()
  }, [])

  return [state, setStateRef] as const
}
