import { keyframes } from '@emotion/react'

// Change `to` to gray.300, less stark of an animation
// Use opacity for better css perf
const mutedFade = keyframes`
  from {
    opacity: .07
  }
  to {
    opacity: .23
  }
`

export const Skeleton = {
  baseStyle: {
    borderRadius: 4,
    animation: `0.8s ease-out infinite alternate ${mutedFade}`,
    willChange: 'opacity'
  }
}
