import React, { ReactNode, useEffect, useState } from 'react'
import constate from 'constate'
import { omit } from 'lodash'
import { HTMLChakraProps } from '~/design_system'
import { Stack, StackProps, Box, forwardRef } from '../../chakra'
import { TOP_NAV_HEIGHT } from '../../../common/scrollUtils'

type JumpToListItemWithRef = {
  id: string
  name: ReactNode
  el: HTMLElement
}

/**
 * JumpToAnchor
 *
 * Put this anchor anywhere you need a jump to menu item that sends you here
 */
export function JumpToAnchor({ anchorId, children }: HTMLChakraProps<'span'> & { anchorId: string }) {
  const { registerJumpToItem, unregisterJumpToItem } = useJumpToMenuContext()

  useEffect(
    () => () => {
      if (unregisterJumpToItem) {
        unregisterJumpToItem(anchorId)
      }
    },
    []
  )

  return (
    <Box
      as="span"
      id={anchorId}
      ref={el => {
        if (el && registerJumpToItem) {
          registerJumpToItem(anchorId, children, el)
        }
      }}
      // Offset for floating menu at top
      sx={{ scrollMarginTop: `${TOP_NAV_HEIGHT + 20}px` }}>
      {children}
    </Box>
  )
}

/**
 * JumpToMenu
 *
 * A list of form sections to jump to each section more easily by clicking on the section on
 * the left hand side link. This component is good to use for very long forms.
 *
 * This is the parent wrapper component which accepts StackProps.
 */
export const JumpToMenu = forwardRef(({ children, ...props }: StackProps, ref) => (
  <JumpToMenuContextProvider>
    <Stack isInline ref={ref} {...props}>
      {children}
    </Stack>
  </JumpToMenuContextProvider>
))

/**
 * JumpToContent
 *
 * This component is a child component of JumpToMenu which represents the content area of the jump to menu. This type of layout
 * is ideal for long forms where a easy way to scroll through the content is required. The scroll position is determined by
 * the headingRefs passed into the SidebarList.
 */
export const JumpToContent = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Box flex="1">
    <Stack p={6} spacing={4} ref={ref} {...props}>
      {children}
    </Stack>
  </Box>
))

/**
 * Context for JumpToMenu that allows anchors/sections to self-register to the menu
 *
 * Items should automatically sort in the list based on their DOM ordering
 */
export const [JumpToMenuContextProvider, useJumpToMenuContext] = constate(() => {
  const [targets, setTargets] = useState<Record<string, JumpToListItemWithRef>>({})

  const registerJumpToItem = (id: string, name: ReactNode, el: HTMLElement) => {
    const item = {
      id,
      name,
      el
    }

    setTargets(m => {
      if (m[item.id]) {
        return m
      }
      return { ...m, [id]: item }
    })
  }

  const unregisterJumpToItem = (id: string) => {
    setTargets(m => omit(m, [id]))
  }

  // Sort target list by DOM order
  const sortedTargets = Object.values(targets).sort((a, b) => {
    // eslint-disable-next-line no-bitwise
    return a.el.compareDocumentPosition(b.el) & 2 ? 1 : -1
  })

  return {
    targets: sortedTargets,
    registerJumpToItem,
    unregisterJumpToItem
  }
})
