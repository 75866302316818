import React from 'react'
import {
  BoxProps,
  Heading,
  HeadingProps,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Tooltip,
  SimpleGrid,
  SimpleGridProps,
  forwardRef
} from '../../chakra'
import { Container, Card } from '../..'
import { ArrowLeft } from '../../../icons'
import { childrenToString } from '../../../common/childrenUtils'

export type PageHeaderProps = {
  cardProps?: BoxProps & { 'data-testid'?: string }
} & StackProps

/**
 * Page Header
 *
 * This is the page header wrapper component. It will layout the header within our DS Container component
 * to ensure it is centered with the main content on the page. Please ensure your content is also wrapped
 * within the same Container component for exact alignment.
 *
 * This component includes the breadcrumb path based on the names and href provided. Please check Breadcrumbs
 * documentation in VNDLY's storybook.
 * https://storybook.beta.vndly.com/?path=/docs/components-button-groups-breadcrumbs--breadcrumbs
 */
export const PageHeader = forwardRef(({ children, maxW, cardProps, ...props }: PageHeaderProps, ref) => (
  <Card
    as="header"
    bg="white"
    rounded={0}
    borderColor="gray.100"
    boxShadow="0px 2px 4px 0px rgb(0,0,0,0.08)"
    ref={ref}
    {...cardProps}>
    <Container position="relative" maxW={maxW} px={{ base: '6', md: '12' }}>
      <Stack direction="column" pos="relative" pt={4} pb={6} spacing={2} w="100%" {...props}>
        {children}
      </Stack>
    </Container>
  </Card>
))

PageHeader.displayName = 'PageHeader'

/**
 * Page Title
 *
 * This is the page title component, using Chakra's Heading component. This component is only used when
 * a title block is not needed.
 */
export const PageTitle = forwardRef(({ children, ...props }: HeadingProps, ref) => {
  const tooltipLabel = childrenToString(children)

  return (
    <Heading as="h1" textStyle="heading1" isTruncated ref={ref} data-testid="page-header-title" {...props}>
      <Tooltip placement="bottom-start" label={tooltipLabel} aria-label={tooltipLabel}>
        {children ?? ''}
      </Tooltip>
    </Heading>
  )
})

PageTitle.displayName = 'PageTitle'

/**
 * Page Back Button
 *
 * On secondary or tertiary pages (such as detail views), a back button may be needed to easily
 * allow the user to navigate back to the previous page. This component will automatically position
 * the back button in the correct place within the header. If the onClick function is not provided
 * it will use window.history.back() to go back. An aria-label tag is required to be passed when
 * using this component.
 */
export const PageBackButton = forwardRef(({ ...props }: IconButtonProps, ref) => (
  <IconButton size="md" isRound icon={<ArrowLeft />} onClick={() => window.history.back()} ref={ref} {...props} />
))

PageBackButton.displayName = 'PageBackButton'

/**
 * Page Actions Container
 *
 * Action buttons used on a page header will need to be wrapper within this component to ensure proper
 * spacing and alignment.
 */
export const PageActionsContainer = forwardRef((props: StackProps, ref) => (
  <Stack
    alignItems={{ base: 'flex-start', md: 'flex-end' }}
    spacing="sm"
    direction={{ base: 'column-reverse', md: 'column' }}
    ref={ref}
    {...props}
  />
))

PageActionsContainer.displayName = 'PageActionsContainer'

/**
 * Page Header Content Wrapper
 *
 * When there is a split view of content on both the left and right hand sides, this wrapper component can
 * be used to create a grid between the data.
 *
 * All page title info and action buttons should be wrapped with this component to achive the proper spacing
 * and alignment.
 */
export const PageHeaderWrapper = forwardRef(({ children, ...props }: SimpleGridProps, ref) => {
  return (
    <SimpleGrid
      templateColumns={{ base: '1fr', md: '2fr 1fr' }}
      spacing="sm"
      alignItems="flex-start"
      mt="auto"
      ref={ref}
      {...props}>
      {children}
    </SimpleGrid>
  )
})

PageHeaderWrapper.displayName = 'PageHeaderWrapper'
