/*
 * For Base Theme
 * See https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/button.ts
 */

import { Menu } from './Menu'

const baseStyle = {
  borderRadius: 'base',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'var(--vndly-color-button)',
  _disabled: {
    opacity: '0.4'
  },
  '> span > svg': {
    w: '16px'
  },
  '> svg': {
    w: '24px'
  }
}

function variantPrimary(props) {
  // Based on the solid variant
  return {
    ...props.theme.components.Button.variants.solid({ ...props }),
    bgColor: 'var(--vndly-color-button-background-primary)',
    color: 'var(--vndly-color-button)',
    _hover: {
      color: 'var(--vndly-color-button)',
      bgColor: 'var(--vndly-color-button-background-primary-hover)',
      _disabled: {
        bgColor: 'var(--vndly-color-button-background-primary-hover-disabled)'
      }
    },
    _focus: {
      bgColor: 'var(--vndly-color-container-border-focused)',
      boxShadow: 'insetOutline'
    },
    _active: {
      bgColor: 'var(--vndly-color-button-background-primary-active)'
    }
  }
}

function variantSecondary(props) {
  // Based on the outline variant
  return {
    ...props.theme.components.Button.variants.outline({ ...props }),
    bgColor: 'var(--vndly-color-button-background-secondary)',
    borderWidth: '1px',
    borderColor: 'var(--vndly-color-button-border-secondary)',
    color: 'var(--vndly-color-button-secondary)',
    _hover: {
      color: 'var(--vndly-color-button-secondary-hover)',
      bgColor: 'var(--vndly-color-button-background-secondary-hover)',
      borderColor: 'var(--vndly-color-button-border-secondary)',
      _disabled: {
        color: 'var(--vndly-color-button-secondary)'
      }
    },
    _focus: {
      color: 'var(--vndly-color-button-secondary)',
      bgColor: 'var(--vndly-color-button-background-secondary)',
      borderColor: 'var(--vndly-color-button-border-secondary)',
      boxShadow: 'outline'
    },
    _active: {
      color: 'var(--vndly-color-button-secondary-hover)',
      bg: 'var(--vndly-color-button-background-secondary-hover)',
      borderColor: 'var(--vndly-color-button-border-secondary)'
    }
  }
}

function variantDeletePrimary(props) {
  // Based on the solid variant
  return {
    ...props.theme.components.Button.variants.solid({ ...props }),
    bgColor: 'var(--vndly-color-button-background-delete-primary)',
    _hover: {
      bgColor: 'var(--vndly-color-button-background-delete-primary-hover)'
    },
    _focus: {
      bgColor: 'var(--vndly-color-button-background-delete-primary)',
      boxShadow: 'insetOutline'
    },
    _active: {
      bgColor: 'var(--vndly-color-button-background-delete-primary-hover)'
    },
    _disabled: {
      bgColor: 'var(--vndly-color-button-background-delete-primary-hover-disabled)',
      opacity: 1
    }
  }
}

function variantDeleteSecondary(props) {
  // Based on the outline variant
  return {
    ...props.theme.components.Button.variants.outline({ ...props }),
    bgColor: 'var(--vndly-color-button-background-delete-secondary)',
    borderWidth: '1px',
    borderColor: 'var(--vndly-color-button-border-delete-secondary)',
    color: 'var(--vndly-color-button-delete-secondary)',
    _hover: {
      color: 'var(--vndly-color-button-delete-secondary-hover)',
      bgColor: 'var(--vndly-color-button-background-delete-secondary-hover)',
      borderColor: 'var(--vndly-color-button-border-delete-secondary-hover)',
      _disabled: {
        color: 'var(--vndly-color-button-delete-secondary-hover-disabled)'
      }
    },
    _focus: {
      color: 'var(--vndly-color-button-delete-secondary)',
      bgColor: 'var(--vndly-color-button-background-delete-secondary)',
      borderColor: 'var(--vndly-color-button-border-delete-secondary)',
      boxShadow: 'outline'
    },
    _active: {
      color: 'var(--vndly-color-button-delete-secondary-hover)',
      bg: 'var(--vndly-color-button-background-delete-secondary-hover)',
      borderColor: 'var(--vndly-color-button-border-delete-secondary)'
    }
  }
}

/**
 * Also referred as the Tertiary Button
 */
function variantLink(props) {
  return {
    bgColor: 'var(--vndly-color-button-tertiary-background)',
    fontWeight: 'var(--vndly-font-weight-semibold)',
    lineHeight: 'base',
    color: 'var(--vndly-color-button-tertiary)',
    height: props.size === 'md' ? '10' : '8',
    p: '0 4',
    _hover: {
      color: 'var(--vndly-color-button-tertiary-hover)',
      bgColor: 'var(--vndly-color-button-tertiary-background-hover)',
      textDecoration: 'underline'
    },
    _focus: {
      color: 'var(--vndly-color-container-border-focused)',
      boxShadow: 'insetOutline',
      textDecoration: 'underline'
    },
    _active: {
      color: 'var(--vndly-color-button-tertiary-hover)',
      bgColor: 'var(--vndly-color-button-tertiary-background-hover)',
      textDecoration: 'underline'
    }
  }
}

/**
 * @deprecated We are no longer using button variant `linkDelete`. Instead we have
 * introduced a primary (deletePrimary) and secondary (deleteSecondary) destructive
 * button variants respectively.
 */
function variantLinkDelete(props) {
  return {
    bgColor: 'var(--vndly-color-button-tertiary-background)',
    fontWeight: 'var(--vndly-font-weight-semibold)',
    lineHeight: 'base',
    color: 'var(--vndly-color-link-delete)',
    height: props.size === 'md' ? '10' : '8',
    p: '0 4',
    _hover: {
      color: 'var(--vndly-color-link-delete-hover)',
      bgColor: 'var(--vndly-color-button-tertiary-background-hover)',
      textDecoration: 'underline'
    },
    _focus: {
      color: 'var(--vndly-color-link-delete)',
      boxShadow: 'insetOutline',
      textDecoration: 'underline'
    },
    _active: {
      color: 'var(--vndly-color-link-delete-hover)',
      bgColor: 'var(--vndly-color-button-tertiary-background-hover)',
      textDecoration: 'underline'
    }
  }
}

const variantLinkInlineBlue = {
  bgColor: 'var(--vndly-color-link-background)',
  fontSize: 'var(--vndly-font-size-md)',
  fontWeight: 'var(--vndly-font-weight-semibold)',
  lineHeight: 'base',
  height: 'auto',
  color: 'var(--vndly-color-link)',
  padding: 0,
  textDecoration: 'underline',
  _hover: {
    color: 'var(--vndly-color-link-hover)',
    bgColor: 'var(--vndly-color-link-background-hover)',
    textDecoration: 'underline'
  },
  _focus: {
    boxShadow: 'insetOutline'
  },
  _active: {
    color: 'var(--vndly-color-link-hover)'
  }
}

const variantLinkInlineGray = {
  padding: 0,
  height: 'auto',
  bgColor: 'transparent',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'gray.500',
  textDecoration: 'underline',
  _hover: {
    color: 'gray.600',
    textDecoration: 'none'
  }
}

/**
 * @deprecated We are no longer supporting inlineDark Button variant in the Design System.
 * Alternatively, use variant `inlineBlue` (ie. blue link) or `inlineGray` (ie. subdued link)
 */
const variantLinkInlineDark = {
  padding: 0,
  height: 'auto',
  bgColor: 'transparent',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: 'base',
  color: 'gray.800',
  textDecoration: 'underline',
  _hover: {
    color: 'gray.900',
    textDecoration: 'none'
  }
}

const variantGhost = {
  _hover: {
    bgColor: 'gray.200'
  }
}

const variantQuickAction = {
  cursor: 'pointer',
  color: 'inherit',
  bgColor: 'gray.50',
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
  w: '100%',
  minHeight: '88px',
  whiteSpace: 'normal',
  justifyContent: 'flex-start'
}

const variantPager = {
  color: 'gray.800',
  fontWeight: 'normal',
  _hover: {
    bgColor: 'gray.200'
  }
}

const variantList = {
  cursor: 'pointer',
  borderRadius: '0',
  borderLeft: '4px solid',
  borderLeftColor: 'transparent',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: 'base',
  color: 'gray.800',
  justifyContent: 'flex-start',
  pl: '4',
  _hover: {
    bgColor: 'gray.50'
  },
  _active: {
    bgColor: 'gray.50',
    fontWeight: 'semibold',
    borderLeftColor: 'blue.500'
  },
  _checked: {
    bgColor: 'gray.50',
    fontWeight: 'semibold',
    borderLeftColor: 'blue.500'
  }
}

const variantMobileMenu = {
  ...Menu.variants.mainMenu.button,
  w: 'full',
  rounded: 0,
  justifyContent: 'flex-start',
  h: '3rem',
  '.mobile-button__chevron': {
    color: 'licorice200'
  },
  _hover: {
    ...Menu.variants.mainMenu.button._hover,
    '.mobile-button__chevron': {
      color: 'licorice500'
    }
  },
  '.menu-button__icon': {
    ...Menu.variants.mainMenu.button['.menu-button__icon'],
    mr: 2
  },
  // Blend mode makes it so that the hover bg still shows the box shadow through it
  mixBlendMode: 'multiply'
}

const variantViewAll = {
  w: 'full',
  height: '24px',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 'base',
  color: 'gray.500',
  bgColor: 'gray.100',
  borderRadius: 'none',
  mt: 4
}

const sizes = {
  sm: {
    height: '8',
    fontSize: 'sm'
  },
  md: {
    height: '10',
    fontSize: 'md'
  }
}

export const Button = {
  baseStyle,
  variants: {
    primary: variantPrimary,
    secondary: variantSecondary,
    deletePrimary: variantDeletePrimary,
    deleteSecondary: variantDeleteSecondary,
    link: variantLink, // Deprecating: Use variant tertiary instead of variant link for Link Buttons. The naming will align with Canvas' button types.
    tertiary: variantLink,
    linkDelete: variantLinkDelete,
    inlineLink: variantLinkInlineBlue,
    inlineGray: variantLinkInlineGray,
    inlineDark: variantLinkInlineDark,
    ghost: variantGhost,
    quickAction: variantQuickAction,
    pager: variantPager,
    list: variantList,
    mainMenu: Menu.variants.mainMenu.button,
    mobileMenu: variantMobileMenu,
    profileMenu: Menu.variants.profileMenu.button,
    viewAll: variantViewAll
  },
  sizes,
  defaultProps: {
    variant: 'secondary',
    size: 'md'
  }
}
