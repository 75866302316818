import { ReactNode } from 'react'
import { forwardRef, Text, Stack, HStack, StackProps } from '../../chakra'

/**
 * Text Block Inline
 *
 * Used for any inline text blocks. More commonly used inline text blocks are available below.
 */
export const TextBlockInline = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Stack isInline alignItems="center" ref={ref} {...props}>
    {children}
  </Stack>
))

TextBlockInline.displayName = 'TextBlockInline'

/**
 * Text Block Multi Line
 *
 * Used for 2 or 3 line text blocks. More commonly used multi-line text block components are available below.
 */
export const TextBlockMultiLine = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Stack spacing={0} ref={ref} {...props}>
    {children}
  </Stack>
))

TextBlockMultiLine.displayName = 'TextBlockMultiLine'

/**
 * Commonly Used Text Block Components
 */
export type TextBlockProps = {
  primaryText: ReactNode
  secondaryText?: ReactNode
  tertiaryText?: ReactNode
  icon?: ReactNode
} & StackProps

export const TextBlockInlineDefault = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <TextBlockInline alignItems="center" ref={ref} {...props}>
      {icon}
      <Text textStyle="bodySemi" mb={0}>
        {primaryText}
      </Text>
      <Text textStyle="subtextSmall">{secondaryText}</Text>
    </TextBlockInline>
  )
)

TextBlockInlineDefault.displayName = 'TextBlockInlineDefault'

export const TextBlockInlineMuted = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <TextBlockInline
      alignItems="center"
      ref={ref}
      sx={{ '& > svg': { color: 'var(--vndly-color-container-subdued)', width: '18px' } }}
      {...props}>
      {icon}
      <Text textStyle="subtextSmallSemi">{primaryText}</Text>
      <Text textStyle="subtextSmall">{secondaryText}</Text>
    </TextBlockInline>
  )
)

TextBlockInlineMuted.displayName = 'TextBlockInlineMuted'

export const TextBlockTwoLineDefault = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <HStack alignItems="flex-start">
      {icon}
      <TextBlockMultiLine ref={ref} {...props}>
        <Text textStyle="bodySemi">{primaryText}</Text>
        <Text textStyle="body">{secondaryText}</Text>
      </TextBlockMultiLine>
    </HStack>
  )
)

TextBlockTwoLineDefault.displayName = 'TextBlockTwoLineDefault'

export const TextBlockTwoLineDefaultAlt = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <HStack alignItems="flex-start">
      {icon}
      <TextBlockMultiLine ref={ref} {...props}>
        <Text textStyle="bodySemi">{primaryText}</Text>
        <Text textStyle="subtextSmall">{secondaryText}</Text>
      </TextBlockMultiLine>
    </HStack>
  )
)

TextBlockTwoLineDefaultAlt.displayName = 'TextBlockTwoLineDefaultAlt'

export const TextBlockTwoLineMuted = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <HStack
      alignItems="flex-start"
      sx={{ '& > svg': { color: 'var(--vndly-color-container-subdued)', width: '18px' } }}>
      {icon}
      <TextBlockMultiLine ref={ref} {...props}>
        <Text textStyle="subtextSmallSemi">{primaryText}</Text>
        <Text textStyle="subtextSmall">{secondaryText}</Text>
      </TextBlockMultiLine>
    </HStack>
  )
)

TextBlockTwoLineMuted.displayName = 'TextBlockTwoLineMuted'

export const TextBlockTwoLineLarge = forwardRef(
  ({ primaryText, secondaryText, icon, ...props }: TextBlockProps, ref) => (
    <HStack alignItems="flex-start">
      {icon}
      <TextBlockMultiLine ref={ref} {...props}>
        <Text textStyle="heading2">{primaryText}</Text>
        <Text textStyle="body">{secondaryText}</Text>
      </TextBlockMultiLine>
    </HStack>
  )
)

TextBlockTwoLineLarge.displayName = 'TextBlockTwoLineLarge'

export const TextBlockThreeLineDefault = forwardRef(
  ({ primaryText, secondaryText, tertiaryText, icon, ...props }: TextBlockProps, ref) => (
    <HStack alignItems="flex-start">
      {icon}
      <TextBlockMultiLine ref={ref} {...props}>
        <Text textStyle="bodySemi">{primaryText}</Text>
        <Text textStyle="body">{secondaryText}</Text>
        <Text textStyle="subtextSmall">{tertiaryText}</Text>
      </TextBlockMultiLine>
    </HStack>
  )
)

TextBlockThreeLineDefault.displayName = 'TextBlockThreeLineDefault'
