import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const HappyGhost = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    // @ts-expect-error IconProps isn't from Chakra so types don't line up
    <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
      <g className="wd-icon-container">
        <path
          fill="#FFDA61"
          d="M190.847 110.181a33.43 33.43 0 0 1 4.691-3.549 33.501 33.501 0 0 1-.167-25.132c3.208-8.089 9.438-14.649 17.401-18.323a34.609 34.609 0 0 1 25.381-1.417c8.331 2.764 15.268 8.59 19.377 16.27a26.425 26.425 0 0 1 12.162-4.814 26.543 26.543 0 0 1 10.211.682C278.27 47.728 256.104 27 229 27c-28.167 0-51 22.386-51 50 0 12.732 4.854 24.353 12.847 33.181z"
        />
        <path
          fill="#B9C0C7"
          d="M320 121.173A27.597 27.597 0 0 1 313.471 139H180.305a32.702 32.702 0 0 1 2.815-18.393 33.168 33.168 0 0 1 12.418-13.975 33.501 33.501 0 0 1-.167-25.132c3.208-8.089 9.438-14.649 17.401-18.323a34.609 34.609 0 0 1 25.381-1.417c8.331 2.764 15.268 8.59 19.377 16.27a26.425 26.425 0 0 1 12.162-4.814c4.4-.563 8.873-.014 13.001 1.595a26.246 26.246 0 0 1 10.602 7.608 25.833 25.833 0 0 1 5.565 11.725 28.27 28.27 0 0 1 15.24 9.944 27.72 27.72 0 0 1 5.9 17.085zM108 41.81a8.007 8.007 0 0 0 1.912 5.19h38.999a9.478 9.478 0 0 0-.825-5.355 9.674 9.674 0 0 0-3.637-4.069 9.702 9.702 0 0 0 .05-7.317 9.932 9.932 0 0 0-5.096-5.334 10.193 10.193 0 0 0-7.434-.413 9.986 9.986 0 0 0-5.674 4.737 7.766 7.766 0 0 0-3.562-1.401 7.811 7.811 0 0 0-3.807.464 7.684 7.684 0 0 0-3.105 2.215 7.501 7.501 0 0 0-1.63 3.414 8.279 8.279 0 0 0-4.463 2.895A8.039 8.039 0 0 0 108 41.81zM299 47a5 5 0 1 0 0-10 5 5 0 0 0 0 10zM68 75c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
          opacity=".23"
        />
        <path
          fill="#12A67C"
          d="M243 91c0 34.794-28.206 63-63 63a62.819 62.819 0 0 1-24.07-4.761 18.087 18.087 0 0 0 1.07-6.15c0-8.236-5.487-15.174-12.96-17.266-2.413-9.101-10.614-15.801-20.36-15.801-1.247 0-2.468.11-3.655.32A62.953 62.953 0 0 1 117 91c0-34.794 28.205-63 63-63 34.794 0 63 28.206 63 63z"
        />
        <path
          fill="#B9C0C7"
          d="M69.316 125.502c-.024-.45-.036-.902-.036-1.358C69.28 110.258 80.392 99 94.1 99c9.212 0 17.251 5.084 21.534 12.633a20.783 20.783 0 0 1 8.046-1.611c9.746 0 17.947 6.7 20.36 15.801 7.473 2.092 12.96 9.03 12.96 17.266 0 9.892-7.916 17.911-17.68 17.911H72.68C62.916 161 55 152.981 55 143.089c0-8.727 6.16-15.996 14.316-17.587z"
          opacity=".23"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M216.51 74.302l-46.208 46.208-27.499-27.5 12.021-12.02 15.478 15.478 34.187-34.187L216.51 74.3z"
          clipRule="evenodd"
        />
      </g>
    </chakra.svg>
  )
})
