import { ReactNode, useState } from 'react'
import {
  forwardRef,
  Text,
  HStack,
  Input,
  InputProps,
  InputGroupProps,
  InputGroup,
  Menu,
  MenuButton,
  MenuProps,
  IconButton,
  InputRightElement,
  FormLabel,
  FormLabelProps,
  Stack,
  NumberInputProps,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '../../chakra'
import { CaretDown, CaretUp, Show, Hide } from '../../../icons'
import { useFormControlExtendedContext } from './FormControlExtendedContext'

/**
 * VNDLY Form Label Optional
 *
 * When a field is optional, the label will indicate as such.
 */
export const FormLabelOptional = forwardRef(({ children, ...props }: FormLabelProps, ref) => (
  <FormLabel ref={ref} {...props}>
    <Stack isInline alignItems="baseline">
      <Text textStyle="bodySemi">{children}</Text>
      <Text textStyle="subtextSmall">Optional</Text>
    </Stack>
  </FormLabel>
))

FormLabelOptional.displayName = 'FormLabelOptional'

/**
 * VNDLY Form Input Addon Menu
 *
 * A dropdown menu used inside a Chakra InputAddon.
 * See http://storybook.beta.vndly.com/?path=/docs/foundations-forms-form-elements--input-addon-dropdown for
 * examples.
 *
 */
export type InputAddonMenuProps = {
  menuButtonChildren: ReactNode
  children: ReactNode
} & MenuProps

export const InputAddonMenu = ({ menuButtonChildren, children, ...props }: InputAddonMenuProps) => (
  <Menu {...props}>
    <MenuButton>
      <HStack alignItems="center">
        {menuButtonChildren}
        <CaretDown w="16px" color="gray.500" m="2px !important" />
      </HStack>
    </MenuButton>
    {children}
  </Menu>
)

/**
 * Component to style international country codes for phone input
 */
export type InternationalCountryCodeProps = {
  flagIcon: ReactNode
  countryCode: string
}

export const InternationalCountryCode = ({ flagIcon, countryCode }: InternationalCountryCodeProps) => (
  <HStack alignItems="center">
    {flagIcon}
    <Text as="span" color="gray.500">
      {` +${countryCode}`}
    </Text>
  </HStack>
)

type InputPasswordProps = {
  inputGroupProps?: InputGroupProps
} & InputProps
/**
 * A custom password input with Show/Hide password
 */
export const InputPassword = forwardRef(({ inputGroupProps, ...props }: InputPasswordProps, ref) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup {...inputGroupProps}>
      <Input type={show ? 'text' : 'password'} {...props} ref={ref} />
      <InputRightElement mr={4}>
        <IconButton
          variant="link"
          color="var(--vndly-color-container-subdued)"
          aria-label="show/hide password"
          h="auto"
          minWidth="auto"
          p={1}
          onClick={handleClick}>
          {show ? <Hide /> : <Show />}
        </IconButton>
      </InputRightElement>
    </InputGroup>
  )
})

type InputNumberProps = {
  dataTestId?: string
} & NumberInputProps
/**
 * A custom number input
 */
export const InputNumber = forwardRef(({ dataTestId, ...props }: InputNumberProps, ref) => {
  const { getInputProps } = useFormControlExtendedContext()
  return (
    <NumberInput ref={ref} {...props}>
      <NumberInputField {...getInputProps?.(props)} />
      <NumberInputStepper>
        <NumberIncrementStepper data-testid={dataTestId ? `${dataTestId}-up` : ''}>
          <CaretUp h="19px" />
        </NumberIncrementStepper>
        <NumberDecrementStepper data-testid={dataTestId ? `${dataTestId}-down` : ''}>
          <CaretDown h="19px" />
        </NumberDecrementStepper>
      </NumberInputStepper>
    </NumberInput>
  )
})
