import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const SadSearch = forwardRef<IconProps, 'svg'>((props, ref) => (
  // @ts-expect-error
  <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
    <g className="wd-icon-container">
      <path
        fill="#B9C0C7"
        d="M309.5 136c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5zM92.5 44a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19zM233.947 64.517a20.334 20.334 0 0 1-.027-1.05C233.92 52.715 242.418 44 252.9 44c7.044 0 13.192 3.936 16.467 9.78a15.725 15.725 0 0 1 6.153-1.247c7.452 0 13.724 5.187 15.569 12.233 5.715 1.62 9.911 6.991 9.911 13.367C301 85.792 294.947 92 287.48 92h-50.96C229.053 92 223 85.792 223 78.133c0-6.756 4.711-12.383 10.947-13.616z"
        opacity=".23"
      />
      <path
        fill="#BDBDBD"
        fillRule="evenodd"
        d="M220.314 102.686a4 4 0 0 1 5.657 0l40.343 40.343a4 4 0 0 1 0 5.657l-8.486 8.486a4 4 0 0 1-5.656 0l-40.344-40.344a4 4 0 0 1 0-5.656l8.486-8.486z"
        clipRule="evenodd"
      />
      <path
        fill="#A1AAB3"
        fillRule="evenodd"
        d="M232.142 108.858l31.629 31.629-14.142 14.142L218 123l14.142-14.142z"
        clipRule="evenodd"
      />
      <path
        fill="#DFE2E6"
        d="M219.598 32.206c-21.869-21.87-57.327-21.87-79.196 0-15.507 15.507-20.018 37.846-13.534 57.328a20.74 20.74 0 0 1 7.22 1.59c3.42 1.426 6.443 3.745 8.785 6.74 2.343 2.996 3.929 6.571 4.611 10.39 5.016 1.378 9.457 4.477 12.627 8.812a25.32 25.32 0 0 1 4.02 8.454c19.031 5.604 40.452.898 55.467-14.118 21.869-21.87 21.869-57.326 0-79.196z"
      />
      <path
        fill="#B9C0C7"
        d="M165 132.204c.004 5.773-1.911 11.364-5.409 15.796H49.253a30.78 30.78 0 0 1 2.332-16.297c2.237-5.085 5.809-9.383 10.29-12.383a31.6 31.6 0 0 1-.14-22.27c2.659-7.167 7.82-12.98 14.419-16.235 6.598-3.256 14.127-3.705 21.03-1.256 6.903 2.45 12.651 7.611 16.055 14.416 2.964-2.299 6.431-3.766 10.077-4.265a20.669 20.669 0 0 1 10.772 1.414c3.42 1.426 6.443 3.745 8.785 6.74 2.343 2.996 3.929 6.571 4.611 10.39 5.016 1.378 9.457 4.477 12.627 8.812 3.171 4.334 4.89 9.659 4.889 15.138z"
        opacity=".23"
      />
      <path
        fill="#fff"
        d="M150 71.5c4.805-11.471 16.948-21.5 30.102-21.5s25.297 10.03 30.102 21.5C205.399 82.971 193.256 93 180.102 93S154.805 82.97 150 71.5z"
      />
      <circle cx="180.5" cy="71.5" r="16.5" fill="#12A67C" />
      <circle cx="180" cy="72" r="7" fill="#0C7A5B" />
      <circle cx="187" cy="67" r="4" fill="#fff" />
      <path
        fill="#40B4E5"
        fillRule="evenodd"
        d="M140.402 32.206c21.869-21.87 57.327-21.87 79.196 0s21.869 57.327 0 79.196c-15.015 15.016-36.436 19.722-55.467 14.118a25.32 25.32 0 0 0-4.02-8.454c-3.17-4.335-7.611-7.434-12.627-8.812-.682-3.819-2.268-7.394-4.611-10.39-2.342-2.995-5.365-5.314-8.785-6.74a20.74 20.74 0 0 0-7.22-1.59c-6.484-19.482-1.973-41.82 13.534-57.328zm10.607 10.607c16.011-16.012 41.971-16.012 57.982 0 16.012 16.011 16.012 41.971 0 57.983-16.011 16.011-41.971 16.011-57.982 0-16.012-16.012-16.012-41.972 0-57.983z"
        clipRule="evenodd"
      />
    </g>
  </chakra.svg>
))
