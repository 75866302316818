import { forwardRef, StackProps, Stack, BoxProps, Box, FlexProps, Flex } from '../../chakra'
import { TOP_NAV_HEIGHT } from '../../../common/scrollUtils'
import { StickyHeightExpander, StickyHeightExpanderProps } from '../../../components/v2/StickyHeightExpander'

/**
 * SplitList
 *
 * A list of form sections to jump to each section more easily by clicking on the section on
 * the sidebar menu list item. This component is good to use for very long forms.
 *
 * This is a parent wrapper component accepting StackProps.
 */
export const SplitList = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Stack isInline borderWidth="1px" borderColor="var(--vndly-color-container-border)" ref={ref} {...props}>
    {children}
  </Stack>
))

SplitList.displayName = 'SplitList'

const BOTTOM_MARGIN = 20
type SplitListContentContainerProps = FlexProps & Pick<StickyHeightExpanderProps, 'offsetTopPx'>
/**
 * SplitListContentContainer
 *
 * The content for the Split List comprises on many child components. This component is a child component of SplitList,
 * and used as a wrapper component to represent the content of the Split List.
 *
 * This component accepts BoxProps as input params.
 */
export const SplitListContentContainer = ({
  children,
  offsetTopPx = TOP_NAV_HEIGHT + BOTTOM_MARGIN,
  ...props
}: SplitListContentContainerProps) => (
  <StickyHeightExpander
    marginInlineStart="1px !important"
    borderLeftWidth="1px"
    borderLeftColor="var(--vndly-color-container-border)"
    overflow="auto"
    bg="white"
    w="full"
    offsetTopPx={offsetTopPx}
    display="flex"
    flexDir="column"
    {...props}>
    {children}
  </StickyHeightExpander>
)

SplitListContentContainer.displayName = 'SplitListContentContainer'

/**
 * SplitListHeader
 *
 * This component is a child component of SplitListContentContainer that represents the header of the content for a list item.
 *
 * This component accepts FlexProps as input params.
 */
export const SplitListHeader = forwardRef(({ children, ...props }: FlexProps, ref) => (
  <Flex
    ref={ref}
    position="sticky"
    top="0"
    justifyContent="space-between"
    bg="white"
    data-items-list-header
    as="header"
    align="center"
    px={6}
    py={3}
    minH="72px"
    borderBottomWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    zIndex={1}
    {...props}>
    {children}
  </Flex>
))

SplitListHeader.displayName = 'SplitListHeader'

/**
 * SplitListContent
 *
 * This component is a child component of SplitListContentContainer that represents the body of the content for a list item.
 *
 * This component accepts BoxProps as input params.
 */
export const SplitListContent = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Box p={8} flexGrow={1} ref={ref} {...props}>
    {children}
  </Box>
))

SplitListContent.displayName = 'SplitListContent'

/**
 * SplitListFooter
 *
 * This component is a child component of SplitList that represents the body of the content for a list item.
 *
 * This component accepts FlexProps as input params.
 */
export const SplitListFooter = forwardRef((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    data-split-list-footer
    as="footer"
    position="sticky"
    bottom="0"
    justifyContent="space-between"
    bg="white"
    align="center"
    px={6}
    py={3}
    minH="72px"
    borderTopWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    {...props}
  />
))

SplitListFooter.displayName = 'SplitListFooter'
