/* eslint-disable no-param-reassign,func-names */
import ReactDOM from 'react-dom'
import { isClient } from '~/common/nextJsUtils'
import { componentAcceptsRef } from '~/common/reactUtils'

/**
 * A way to render React components with props easily with jQuery
 *
 * ## Register the React Component
 *
 * In your React Component file, register the component with jQuery using `asJqueryPlugin`
 * ```
 * const Greeting = ({ person }) => <div>Hello {person}</div>
 * asJqueryPlugin('Greeting', Greeting, { person: "Bob" })
 * ```
 *
 * ## Rendering, Selecting and Updating Props with jQuery
 *
 * Select an element and render using the `react` function
 * ```
 * $('#greeting').react('Greeting', { person: 'Frank' })
 * ```
 *
 * ## Auto-Render the React Component in your jQuery App
 * ```
 * <div data-react="Greeting" data-react-auto></div>
 * ```
 * This will render Greeting into that div on page load. (Make sure the react bundle is loaded after the jquery.js and html)
 *
 * Use the top-level `$.react` function to select elements by their `data-react` attribute. Optionally pass some props.
 *
 * ```
 * $.react('Greeting') // Selects all <div data-react="Greeting"></div>
 * $.react('Greeting', { person: "Frank" }) // Selects and sets props
 * ```
 */

const registry = {}

// This is how React components register themselves as available within jQuery
export default function asJqueryPlugin(componentName, Component, defaults = {}, hmrDeps) {
  registry[componentName] = { Component, defaults, hmrDeps }
}

function installJqueryReactPlugin($) {
  function render($els, updateProps = false, ...args) {
    let componentName
    let props = {}

    if (args.length === 1) {
      props = args[0]
    }
    if (args.length === 2) {
      componentName = args[0]
      props = args[1]
    }
    $els.each(function render() {
      const entry = registry[componentName || $(this).data('react')]
      if (!entry) throw Error(`${componentName} component is not registered.`)
      const { Component, defaults, hmrDeps } = entry
      let finalProps = props
      if (updateProps) {
        finalProps = { ...$(this).data('props'), ...props }
      }
      $(this).data('props', finalProps)
      const renderit = () => {
        const refProp = componentAcceptsRef(Component) ? { ref: this } : {}
        ReactDOM.render(<Component {...refProp} key={finalProps.key} {...defaults} {...finalProps} />, this)
      }
      renderit()
      if (module.hot && hmrDeps && $(this).data('__hmr') !== true) {
        $(this).data('__hmr', true)
        module.hot.accept(hmrDeps, renderit)
      }
    })
    return $els
  }

  // Add the plugin function jQuery
  // Use this to render with a full props object
  $.fn.react = function renderReactIntoElements(...args) {
    return render(this, false, ...args)
  }

  // Use this to only make partial prop updates that merge with existing props
  $.fn.updateReactProps = function updateReactProps(...args) {
    return render(this, true, ...args)
  }

  $.react = function getReactElementsByName(name, props) {
    const $els = $(`[data-react=${name}]`)
    if (props) {
      $els.react(props)
    }
    return $els
  }

  $.updateReactProps = function getReactElementsByName(name, props) {
    const $els = $(`[data-react=${name}]`)
    if (props) {
      $els.updateReactProps(props)
    }
    return $els
  }

  // Auto-activate any elements with data-react attrs
  $(() => {
    $('[data-react-auto]').each(function renderReactOnLoad() {
      $(this).react()
    })
  })
}

if (isClient()) {
  const jQueryIsDefined = typeof window.$ !== 'undefined'
  if (jQueryIsDefined) installJqueryReactPlugin(window.$)
}
