import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

export default function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useHasChanged(value: any) {
  const prev = usePrevious(value)
  return prev !== value
}

export function useHasAnyChanged(values: Array<any>) {
  const prevs = usePrevious(values)
  return prevs?.length !== values.length || prevs?.some((prev, i) => !isEqual(prev, values[i]))
}
