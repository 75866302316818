import { Link as ReachLink } from '@reach/router'
import React, { ReactElement } from 'react'
import { Msg, useMsg } from '../../../common/localizationUtils'
import { ToggledNextLink } from '../../../components/v2/next/ToggledNextLink'
import { ChevronDown, ChevronUp } from '../../../icons'
import {
  Box,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuListProps,
  RadioGroup,
  RadioGroupProps,
  Tab,
  TabList,
  TabListProps,
  TabProps,
  Tabs,
  TabsProps,
  useBreakpointValue,
  RadioProps
} from '../../chakra'
import { CustomRadio } from '../form_controls/FormControls'

/**
 * Nav Tabs
 *
 * Used to navigate easily between views with different url paths within a page. This is the wrapper container for Nav tabs.
 */
export const NavTabs = forwardRef(({ children, ...props }: TabsProps, ref) => (
  <Tabs ref={ref} {...props}>
    {children}
  </Tabs>
))

NavTabs.displayName = 'NavTabs'

type NavTabListProps = {
  hideUnderline?: boolean
  overflowTabs?: boolean
} & TabListProps

/**
 * Nav Tab List
 *
 * This component will be the wrapper to form a Nav tab list. It will automatically add an overflow menu when there are more than
 * five tabs.
 */
export const NavTabList = forwardRef(
  ({ hideUnderline = false, overflowTabs = true, children, ...props }: NavTabListProps, ref) => {
    const tabArray = React.Children.toArray(children)
    const tabCount = tabArray.length
    const maxTabs = useBreakpointValue({ base: 2, sm: 3, md: 5 }, 'md') || 5

    return (
      <TabList data-testid="tab-list" borderBottom={hideUnderline ? 'none' : '1px solid'} ref={ref} {...props}>
        {overflowTabs && tabCount > maxTabs ? (
          <>
            {tabArray.slice(0, maxTabs)}
            <OverflowTabMenu>{tabArray.slice(maxTabs, tabCount)}</OverflowTabMenu>
          </>
        ) : (
          children
        )}
      </TabList>
    )
  }
)

NavTabList.displayName = 'NavTabList'

type NavTabProps = {
  href: any
} & TabProps

/**
 * Nav Tab
 *
 * Individual nav tab component. NavTab elements need to be wrapped inside the NavTabs. This component is a tab link
 * supporting NextJS links.
 *
 * Based on Chakra's Tab component.
 *
 * TODO: Move ToggledNextLink to the DS
 */
export const NavTab = forwardRef<NavTabProps, 'button'>((props, ref) => (
  /* @ts-ignore */
  <ToggledNextLink href={props.href}>
    {/* @ts-ignore */}
    <Tab as="a" ref={ref} px={4} py={3} variant="tabLink" {...props} />
  </ToggledNextLink>
))

NavTab.displayName = 'NavTab'

/**
 * Nav Tab Reach
 *
 * Individual nav tab component. NavTabReach elements need to be wrapped inside the NavTabs. This component is a tab link
 * supporting reach router links.
 *
 * Based on Chakra's Tab component.
 */
export const NavTabReach = forwardRef(({ href, ...props }: NavTabProps, ref) => (
  <Tab as={ReachLink} to={href} px={4} py={3} variant="tabLink" ref={ref} {...props} />
))

NavTabReach.displayName = 'NavTabReach'

/**
 * Overflow Tab Menu
 *
 * To maintain a responsive tab layout, this overflow menu will be used to list any tabs that exceed the max number of tabs
 * for the given breakpoint.
 *
 * This component has not been exported because it is only used internally within the design system right now.
 */
const OverflowTabMenu = forwardRef(({ children, ...props }: MenuListProps, ref) => (
  <Menu variant="overflowMenu">
    {({ isOpen }) => (
      <>
        <MenuButton>
          <Msg id="nav.more" /> {isOpen ? <ChevronUp w="16px" /> : <ChevronDown width="16px" />}
        </MenuButton>
        <MenuList ref={ref} {...props}>
          {React.Children.map(children, child => (
            <MenuItem as={Box} key={(child as ReactElement).key}>
              {child}
            </MenuItem>
          ))}
        </MenuList>
      </>
    )}
  </Menu>
))

/**
 * Page Tabs
 *
 * This component is the parent wrapper of a series of PageTab components. Page tabs would be used when
 * tab that don't need to change url paths and stay within the same context is needed.
 */
export const PageTabs = forwardRef(({ children, ...props }: TabsProps, ref) => (
  <Tabs ref={ref} {...props}>
    {children}
  </Tabs>
))

PageTabs.displayName = 'PageTabs'

/**
 * Page Tabs List
 *
 * This component will be the wrapper to form a page tab list. It will automatically add an overflow menu when there are more than
 * five tabs.
 */
export const PageTabList = forwardRef(({ children, ...props }: NavTabListProps, ref) => (
  <NavTabList ref={ref} {...props}>
    {children}
  </NavTabList>
))

PageTabList.displayName = 'PageTabList'

/**
 * Page Tab
 *
 * Individual page tab component. Used to set each page tab as a button.
 *
 * Based on Chakra's Tab component
 */
export const PageTab = forwardRef(({ children, ...props }: TabProps, ref) => (
  /* @ts-ignore */
  <Tab ref={ref} px={4} py={3} {...props}>
    {children}
  </Tab>
))

PageTab.displayName = 'PageTab'

/**
 * Filter Tabs
 *
 * This component is the parent wrapper of a series of FilterTab components. Filter tabs would be used when
 * prioritizing the same group of data. Used up to four tabs in a group.
 *
 * Just Chakra's Tabs component with a custom "button" variant and isManual set to true.
 */
export const FilterTabs = forwardRef<RadioGroupProps, 'div'>((props, ref) => {
  const msg = useMsg()
  return (
    <RadioGroup ref={ref} display="flex" gap={2} aria-label={msg('common.action_bar.filter_tabs.group')} {...props} />
  )
})

FilterTabs.displayName = 'FilterTabs'

/**
 * Filter Tab
 *
 * Use up to four filter tabs.  Typically, you will want to include an “All” option.
 *
 * If possible, it is highly recommended to include counts on each item, as these can replace result counts in a list view.
 *
 * FilterTab elements need to be wrapped inside the FilterTabs
 */
export const FilterTab = forwardRef<RadioProps, 'input'>((props, ref) => {
  return <CustomRadio ref={ref} variant="filterTab" {...props} />
})

FilterTab.displayName = 'FilterTab'
