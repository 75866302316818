import { cq } from '../../common/containerQuery'

const sizes = {
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' }
  },
  lg: {
    control: { w: 6, h: 6 },
    label: { fontSize: 'md' }
  }
}

function focusRing(color = 'transparent') {
  // If the color is transparent, we don't want to show the white ring
  const innerRing = `0px 0px 0px 2px ${color === 'transparent' ? 'transparent' : 'white'}`
  const outerRing = `0px 0px 0px 4px ${color}`
  return `${innerRing}, ${outerRing}`
}

const baseStyleControl = {
  bg: 'var(--vndly-color-input-background)',
  borderColor: 'var(--vndly-color-input-border)',
  borderWidth: '1px',
  w: '1.5rem',
  h: '1.5rem',
  boxShadow: focusRing('transparent'),

  // Hover Gray Circle
  '&:not([data-disabled])': {
    _after: {
      content: "''",
      boxShadow: '0 0 0 7px var(--vndly-color-input-shadow-hover)',
      position: 'absolute',
      borderRadius: '9999px',
      width: '24px',
      height: '24px',
      zIndex: '-1',
      transition: 'transform 0.15s ease-out, opacity 0.15s ease-out',
      opacity: 0,
      transform: 'scale(0.7)'
    },
    _hover: {
      _after: {
        opacity: 1,
        transform: 'scale(1)'
      }
    }
  },

  _focus: {
    borderWidth: '2px',
    borderColor: 'var(--vndly-color-input-border-focused)',
    boxShadow: focusRing('transparent')
  },
  _checked: {
    color: 'white',
    bg: 'var(--vndly-color-input-background-checked)',
    borderWidth: '2px',
    borderColor: 'var(--vndly-color-input-border-checked)',
    _focus: {
      boxShadow: focusRing('var(--vndly-color-input-border-checked)'),
      '&[data-hover]:not([data-disabled])': {
        boxShadow: focusRing('var(--vndly-color-input-border-checked)')
      }
    },
    '&[data-hover]:not([data-disabled], [data-invalid], [data-warned])': {
      bg: 'var(--vndly-color-input-border-checked)',
      borderColor: 'var(--vndly-color-input-border-checked)'
    }
  },
  '&[data-disabled]': {
    opacity: 0.5,
    cursor: 'not-allowed',
    boxShadow: focusRing('transparent'),
    color: 'var(--vndly-color-input-background-disabled)',
    bg: 'var(--vndly-color-input-background-disabled)',
    borderColor: 'var(--vndly-color-input-border-disabled)',
    '&[data-checked]': {
      color: 'white',
      bg: 'var(--vndly-color-input-background-checked)',
      borderColor: 'var(--vndly-color-input-border-checked)'
    }
  },
  '&[data-invalid]': makeInvalidControlStyles('invalid'),
  '&[data-warned]:not([data-invalid])': makeInvalidControlStyles('warned')
}

/**
 * @param variant {"invalid" | "warned"}
 */
function makeInvalidControlStyles(variant) {
  return {
    borderWidth: '2px',
    borderColor: `var(--vndly-color-input-border-${variant})`,
    _disabled: {
      opacity: 0.5
    },
    _hover: {
      borderWidth: '2px',
      borderColor: `var(--vndly-color-input-border-${variant})`
    },
    _focus: {
      borderWidth: '2px',
      borderColor: 'var(--vndly-color-input-border-focused)',
      boxShadow: focusRing('transparent'),
      '&[data-hover]:not([data-disabled])': {
        boxShadow: focusRing('transparent')
      }
    },
    _checked: {
      bg: `var(--vndly-color-input-background-checked-${variant})`,
      borderColor: `var(--vndly-color-input-border-${variant})`,
      '&[data-hover]:not([data-disabled])': {
        bg: `var(--vndly-color-input-background-checked-${variant})`,
        borderColor: `var(--vndly-color-input-border-${variant})`,
        boxShadow: focusRing('transparent')
      },
      _focus: {
        borderColor: `var(--vndly-color-input-border-${variant})`,
        boxShadow: focusRing(`var(--vndly-color-input-shadow-focused)`),
        '&[data-hover]:not([data-disabled])': {
          boxShadow: focusRing(`var(--vndly-color-input-shadow-focused)`)
        }
      }
    }
  }
}

const variantSegmentedControl = props => {
  return cq({
    customLabel: {
      flex: props.isFullWidth ? '1 1 0' : 'none'
    },
    customControl: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: props.isFullWidth
        ? { '@segmented-control/base': 'flex-start', [`@segmented-control/${props.bp}`]: 'center' }
        : 'center',
      textStyle: 'body',
      cursor: 'pointer',
      p: 3,
      h: 10,
      borderWidth: '1px',
      borderColor: 'gray.300',
      transitionProperty: 'var(--chakra-transition-property-common)',
      transitionDuration: 'var(--chakra-transition-duration-normal)',
      whiteSpace: props.isFullWidth
        ? { '@segmented-control/base': 'nowrap', [`@segmented-control/${props.bp}`]: 'initial' }
        : 'initial',
      _hover: {
        bgColor: 'gray.200'
      },
      _active: {
        bgColor: 'gray.200'
      },
      _checked: {
        bgColor: 'var(--vndly-color-button-background-primary)',
        color: 'var(--vndly-color-button)',
        borderColor: 'var(--vndly-color-button-background-primary)',
        zIndex: 1,
        fontWeight: 'semibold',
        textStyle: 'bodySemi'
      },
      _focus: {
        boxShadow: 'outline',
        zIndex: 1
      },
      ...(props.isFullWidth
        ? {
            'label:not(:first-of-type) > &': {
              borderLeftWidth: { '@segmented-control/base': '1px', [`@segmented-control/${props.bp}`]: 0 },
              borderTopWidth: { '@segmented-control/base': 0, [`@segmented-control/${props.bp}`]: '1px' },
              _checked: {
                borderLeftWidth: '1px',
                borderTopWidth: '1px'
              }
            },
            'label:first-of-type > &': {
              roundedTopRight: { '@segmented-control/base': 'base', [`@segmented-control/${props.bp}`]: 'none' },
              roundedTopLeft: { '@segmented-control/base': 'base', [`@segmented-control/${props.bp}`]: 'base' },
              roundedBottomLeft: { '@segmented-control/base': 'none', [`@segmented-control/${props.bp}`]: 'base' }
            },
            'label:last-of-type > &': {
              roundedTopRight: { '@segmented-control/base': 'none', [`@segmented-control/${props.bp}`]: 'base' },
              roundedBottomRight: { '@segmented-control/base': 'base', [`@segmented-control/${props.bp}`]: 'base' },
              roundedBottomLeft: { '@segmented-control/base': 'base', [`@segmented-control/${props.bp}`]: 'none' }
            },
            // If an unchecked tab is followed by a checked tab, remove the right border
            'label:has(+ label:is([data-checked])) > &': {
              borderRightWidth: { '@segmented-control/base': '1px', [`@segmented-control/${props.bp}`]: 0 },
              borderBottomWidth: { '@segmented-control/base': 0, [`@segmented-control/${props.bp}`]: '1px' }
            }
          }
        : {
            'label:not(:first-of-type) > &': {
              borderLeftWidth: 0,
              _checked: {
                borderLeftWidth: '1px'
              }
            },
            'label:first-of-type > &': {
              roundedLeft: 'base'
            },
            'label:last-of-type > &': {
              roundedRight: 'base'
            },
            // If an unchecked tab is followed by a checked tab, remove the right border
            'label:has(+ label:is([data-checked])) > &': {
              borderRightWidth: 0
            }
          })
    }
  })
}

const variantFilterTab = {
  customLabel: {
    display: 'flex',
    alignItems: 'center',
    px: 3,
    h: 8,
    cursor: 'pointer',
    border: 'none',
    borderRadius: 'base',
    fontSize: 'sm',
    lineHeight: 'base',
    color: 'var(--vndly-color-tab-filter-text)',
    transitionProperty: 'var(--chakra-transition-property-common)',
    transitionDuration: 'var(--chakra-transition-duration-normal)',
    fontWeight: 'semibold',
    _hover: {
      bgColor: 'var(--vndly-color-tab-filter-background-hover)'
    },
    _active: {
      bgColor: 'var(--vndly-color-tab-filter-background-hover)',
      fontSize: 'sm',
      fontWeight: 'semibold',
      lineHeight: 'base'
    },
    _checked: {
      bgColor: 'var(--vndly-color-tab-filter-background-checked)',
      fontSize: 'sm',
      lineHeight: 'base'
    },
    _focus: {
      boxShadow: 'outline'
    }
  },
  customControl: {
    textWrap: 'nowrap'
  }
}

export const Radio = {
  baseStyle: {
    control: baseStyleControl
  },
  sizes,
  defaultProps: {
    size: 'lg'
  },
  /**
   * Here's how you can create custom variants:
   * 1. Add a key to the variants object
   * 2. Add a customLabel and customControl key to the variant object
   * 3. Add styles to the customLabel and customControl object
   *   - customLabel styles are applied to the label container
   *   - customControl styles are applied to the custom checkbox within
   * 4. Use the variant prop to apply the custom styles
   */
  variants: {
    segmentedControl: variantSegmentedControl,
    filterTab: variantFilterTab
  }
}
