import { useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ONE_HOUR, ONE_YEAR } from '~/common/cache'
import { useHasChanged } from '~/hooks/usePrevious'
import fetch from '../common/fetch'
import { FetchOptions } from '../common/fetchHelpers'
import {
  getLocalStorageJSON,
  setLocalStorageWithTTL,
  useQueryWithLocalStoragePersist
} from '../common/react-query/useQueryWithLocalStoragePersist'
import { Expression } from '../common/valeyard/runtime'

export type Localization = {
  locale: string
  languageTag: string
  catalog: Record<string, string>
}

type L10nLayerParams = { locale: string; layerType: 'tenant' | 'base'; tag: string; hash: string }
export const l10nApi = {
  catalog: (fetchOpts?: FetchOptions): Promise<Localization> => fetch.get('/api/v2/l10n/catalog/', fetchOpts),
  layerMeta: (fetchOpts?: FetchOptions) => fetch.get('/api/v2/l10n/layers/meta', fetchOpts),
  layer: ({ locale, layerType, tag, hash }: L10nLayerParams, fetchOpts?: FetchOptions) =>
    fetch.get(`/api/v2/l10n/v/catalog/${layerType}/${locale}/${tag}?hash=${hash}`, fetchOpts)
}

export const l10nKeys = {
  all: ['l10n', 'catalog'] as const,
  layer: ['l10n', 'layer'] as const
}

export const useL10nQuery = (opts: UseQueryOptions<Localization> = {}) => {
  const key = l10nKeys.all
  return useQueryWithLocalStoragePersist(key, () => l10nApi.catalog(), {
    ...opts,
    localStorageKey: 'l10n_default',
    staleTime: 60 * 1000 * 5 // 5 minutes
  })
}

interface L10nLayerMeta {
  timezone: string
  locale: string
  locale_tag: string
  l10n_search_locales: string[]
  l10n_search_locales_tags: string[]
  l10n_base_hash: string
  l10n_tenant_hash: string
}

export const useL10nLayerMetaQuery = (opts: UseQueryOptions<L10nLayerMeta> = {}) => {
  return useQuery({
    queryKey: [...l10nKeys.layer, 'meta'],
    queryFn: () => l10nApi.layerMeta(),
    staleTime: Infinity,
    ...opts
  })
}

interface LocalizationLayer {
  catalog: Record<string, Expression>
  hash: string
  locale: string
  locale_tag: string
  layer_type: 'tenant' | 'base'
}

export const useL10nMessagesQuery = ({ anonymous }: { anonymous: boolean }) => {
  const addlInfoFromLocalStorage =
    getLocalStorageJSON<Pick<L10nLayerMeta, 'l10n_search_locales' | 'locale_tag' | 'timezone'>>('l10n-addl-info')

  const {
    data: {
      l10n_base_hash,
      l10n_tenant_hash,
      l10n_search_locales = addlInfoFromLocalStorage?.l10n_search_locales ?? [],
      locale_tag = addlInfoFromLocalStorage?.locale_tag,
      timezone = addlInfoFromLocalStorage?.timezone
    } = {},
    isSuccess: meIsSuccess
  } = useL10nLayerMetaQuery()

  useMemo(() => {
    if (meIsSuccess) setLocalStorageWithTTL('l10n-addl-info', { l10n_search_locales, locale_tag, timezone }, ONE_HOUR)
  }, [meIsSuccess, l10n_search_locales, locale_tag, timezone])

  const tag = anonymous ? ('anonymous' as const) : ('vndly' as const)
  const layerQueryOptionsArray = l10n_search_locales.flatMap<
    UseQueryOptions<
      LocalizationLayer,
      unknown,
      LocalizationLayer,
      [...typeof l10nKeys.layer, string, 'tenant' | 'base', typeof tag, string?]
    >
  >(locale => {
    const tenantLocalStorageData = getLocalStorageJSON<LocalizationLayer>(
      makeLocalStorageKey({ layer_type: 'tenant', locale, tag })
    )
    const baseLocalStorageData = getLocalStorageJSON<LocalizationLayer>(
      makeLocalStorageKey({ layer_type: 'base', locale, tag })
    )

    return [
      {
        queryKey: [...l10nKeys.layer, locale, 'tenant' as const, tag, l10n_tenant_hash],
        queryFn: (): LocalizationLayer => l10nApi.layer({ locale, layerType: 'tenant', tag, hash: l10n_tenant_hash! }),
        keepPreviousData: true,
        enabled: !!l10n_tenant_hash,
        placeholderData: tenantLocalStorageData,
        initialData:
          tenantLocalStorageData && tenantLocalStorageData.hash === l10n_tenant_hash
            ? tenantLocalStorageData
            : undefined,
        staleTime: Infinity
      },
      {
        queryKey: [...l10nKeys.layer, locale, 'base' as const, tag, l10n_base_hash],
        queryFn: (): LocalizationLayer => l10nApi.layer({ locale, layerType: 'base', tag, hash: l10n_base_hash! }),
        keepPreviousData: true,
        enabled: !!l10n_base_hash,
        placeholderData: baseLocalStorageData,
        initialData:
          baseLocalStorageData && baseLocalStorageData.hash === l10n_base_hash ? baseLocalStorageData : undefined,
        staleTime: Infinity
      }
    ]
  })

  const layerQueries = useQueries({ queries: layerQueryOptionsArray ?? [] })
  const allLayeredLoaded = layerQueries.length > 0 && layerQueries.every(layer => layer.isSuccess)
  // Store fetch statuses into a simple hash of 0s and 1s, so we can compare them to the previous render:
  // e.g. 1000 means the first layer is fetched, but the rest are not
  const layerFetchedStatusesSimpleHash = layerQueries.map(layer => Number(layer.isFetched)).join('')
  const anyFetchedStatusHasChanged = useHasChanged(layerFetchedStatusesSimpleHash)

  // Store each layer query's data in local storage only after they are fetched, so we always have the latest from the server
  if (anyFetchedStatusHasChanged) {
    layerQueries.forEach(layer => {
      if (layer.isFetched && layer.data) {
        const { layer_type, locale } = layer.data
        setLocalStorageWithTTL(makeLocalStorageKey({ layer_type, locale, tag }), layer.data, ONE_YEAR)
      }
    })
  }

  // Reduce all layers into a single catalog, once right away when we load layers from local storage, and again when all layers are fetched
  const reducedMessages = useMemo(() => {
    return allLayeredLoaded
      ? layerQueries.reduceRight((acc, layer) => {
          return {
            ...acc,
            ...layer.data?.catalog
          }
        }, {})
      : {}
  }, [allLayeredLoaded, layerFetchedStatusesSimpleHash])

  return {
    allLayeredLoaded,
    messages: reducedMessages,
    locale_tag,
    timezone
  }
}

function makeLocalStorageKey(parts: { layer_type: 'base' | 'tenant'; locale: string; tag: string }) {
  return `l10n-${parts.layer_type}-${parts.locale}-${parts.tag}`
}
