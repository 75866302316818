import React, { useEffect } from 'react'
import { useAccountMeQuery } from '~/api/accounts'
import { preloadImage, useInputModality } from '~/common/domUtils'
import { links } from '~/common/links'
import { Msg, useMsg } from '~/common/localizationUtils'
import { useUniversalRouter } from '~/common/nextJsUtils'
import { TOP_NAV_HEIGHT_PX } from '~/common/scrollUtils'
import { ContactUsDrawer } from '~/components/v2/ContactUsDrawer'
import { SystemAlertBanners } from '~/components/v2/SystemAlertBanners'
import { Box, Button, chakra, Flex, FlexProps, Link, Spacer, useDisclosure, UseDisclosureReturn } from '~/design_system'
import { Menu } from '~/icons'
import { MainMenusDesktop } from '~/navigation_menu/MainMenus'
import { MainMenusMobile } from '~/navigation_menu/MainMenus.mobile'
import { ProfileMenu } from '~/navigation_menu/ProfileMenu'
import UserTasksDrawer from '~/user_tasks/UserTasksDrawer'

export function NavigationMenu(props: FlexProps) {
  const { isLoading } = useAccountMeQuery()
  const mobileMenuDisclosure = useDisclosure()

  const bgStyle = {
    bg: 'var(--vndly-color-navigation-menu-bar-background)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
  }

  return (
    <>
      <SkipToContentLink />
      <Box position="sticky" top={0} zIndex="sticky">
        <Flex h={TOP_NAV_HEIGHT_PX} w="100%" alignItems="center" textStyle="bodySmall" {...bgStyle} {...props}>
          {!isLoading && <OpenMobileDrawerButton onToggle={mobileMenuDisclosure.onToggle} />}
          <VndlyLogo />
          {!isLoading && <MainMenusDesktop />}
          {!isLoading && <MainMenusMobile disclosure={mobileMenuDisclosure} />}
          <Spacer />
          <UserTasksDrawer />
          <ProfileMenu />
        </Flex>
        <SystemAlertBanners />
      </Box>
      <ContactUsDrawer />
      <SkipToMainNavigationLink />
    </>
  )
}

function OpenMobileDrawerButton({ onToggle }: { onToggle: UseDisclosureReturn['onToggle'] }) {
  const msg = useMsg()

  return (
    <chakra.button
      aria-label={msg('nav.open_mobile_menu')}
      onClick={onToggle}
      sx={{
        transition: 'background .2s linear',
        border: 'none',
        borderRight: '1px',
        borderColor: 'soap500',
        rounded: 'none',
        w: '4rem',
        h: 'full',
        _hover: { bg: 'soap300' },
        '@media (min-width: 1350px)': { display: 'none' }
      }}>
      <Menu w={6} h={6} color="licorice200" />
    </chakra.button>
  )
}

preloadImage('/static/images/logo-workday-vndly.svg')

function VndlyLogo() {
  const { isCandidateRole, isLoading: isAccountsMeLoading } = useAccountMeQuery()

  let href: string
  if (isAccountsMeLoading) {
    href = '/'
  } else if (isCandidateRole) {
    href = links.candidateTimesheets.href
  } else {
    href = links.home.href
  }

  return (
    <Link
      href={href}
      data-testid="global-nav-vndly-logo"
      display="flex"
      px={3}
      py={1}
      mx={1}
      w="210px"
      borderRadius={4}
      _hover={{ background: 'soap300' }}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src="/static/images/logo-workday-vndly.svg"
        alt="VNDLY Home"
        id="workday-vndly-logo"
        width="208px"
        height="40px"
      />
    </Link>
  )
}

function SkipToContentLink() {
  return (
    <Button
      as="a"
      id="skip-to-content-btn"
      href="#skip-to-main-navigation-btn"
      roundedTop={0}
      position="absolute"
      left=".5rem"
      zIndex="tooltip"
      transform="translateY(-105%)"
      transition="transform 0.15s"
      opacity={0}
      bg="white"
      _focus={{
        transform: 'translateY(0%)',
        boxShadow: 'outline',
        opacity: 1
      }}>
      <Msg id="common.ui.skip_to_content" />
    </Button>
  )
}

function SkipToMainNavigationLink() {
  // Provide a skip link that takes focus on a route change within the site,
  // with a label that indicates what the link will do when activated: e.g. "skip to main navigation".
  // More information can be found here:
  // https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/#recommendations-finding-common-ground
  const { events } = useUniversalRouter()

  useEffect(() => {
    const handler = () => {
      const skipToNavBtn = document.getElementById('skip-to-main-navigation-btn')
      if (skipToNavBtn) setTimeout(() => skipToNavBtn.focus(), 100)
    }
    events?.on('routeChangeComplete', handler)
    return () => events?.off('routeChangeComplete', handler)
  }, [events])

  const { isUsingKeyboard } = useInputModality()
  return (
    <Button
      as="a"
      id="skip-to-main-navigation-btn"
      href="#skip-to-content-btn"
      position="absolute"
      left=".5rem"
      zIndex="dropdown"
      transform="translateY(-110%)"
      transition="transform 0.15s"
      bg="white"
      opacity={0}
      _focus={
        // Only show the button if they are using their keyboard,
        // otherwise we don't want this button showing for typical mouse usage
        isUsingKeyboard
          ? {
              transform: 'translateY(0%)',
              opacity: 1,
              boxShadow: 'outline'
            }
          : {}
      }
      sx={{ scrollMarginTop: '20px' }}>
      <Msg id="common.ui.skip_to_main_navigation" />
    </Button>
  )
}
