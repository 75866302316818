import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const Inbox = forwardRef<IconProps, 'svg'>((props, ref) => (
  // @ts-expect-error
  <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
    <g className="wd-icon-container">
      <path
        fill="#B9C0C7"
        d="M71 39a5 5 0 1 0 0-10 5 5 0 0 0 0 10zM280.596 39.968c-.01-.202-.016-.407-.016-.612 0-6.272 5.12-11.356 11.437-11.356 4.244 0 7.949 2.296 9.922 5.705a9.749 9.749 0 0 1 3.708-.727c4.49 0 8.269 3.026 9.381 7.136 3.444.944 5.972 4.078 5.972 7.797 0 4.468-3.647 8.089-8.147 8.089h-30.706c-4.5 0-8.147-3.621-8.147-8.089 0-3.94 2.839-7.224 6.596-7.943zM84 121.682A9.982 9.982 0 0 1 81.762 128H36.105c-.296-2.223.039-4.485.965-6.519a11.633 11.633 0 0 1 4.258-4.953 12.246 12.246 0 0 1-.058-8.908c1.1-2.867 3.236-5.192 5.966-6.494a11.508 11.508 0 0 1 8.702-.502c2.857.98 5.235 3.044 6.644 5.766a8.885 8.885 0 0 1 4.17-1.706 8.821 8.821 0 0 1 4.457.566 9.012 9.012 0 0 1 3.635 2.696 9.288 9.288 0 0 1 1.908 4.156 9.669 9.669 0 0 1 5.225 3.524A10.039 10.039 0 0 1 84 121.682zM116.175 125.907c-7.397 3.41-14.061 8.367-19.591 14.57C91.054 146.68 86.773 154.002 84 162h229c-.836-8.811-4.728-16.985-10.928-22.952-6.201-5.968-14.273-9.308-22.665-9.379a66.15 66.15 0 0 0-2.154-29.691c-2.962-9.572-8.061-18.253-14.866-25.313-6.806-7.059-15.12-12.29-24.242-15.252a56.582 56.582 0 0 0-28.174-1.767c-2.834-9.336-7.701-17.842-14.21-24.838-6.509-6.996-14.48-12.287-23.274-15.45a56.592 56.592 0 0 0-27.373-2.764c-9.204 1.346-17.981 4.945-25.63 10.512-7.649 5.566-13.956 12.944-18.417 21.544-4.461 8.599-6.952 18.18-7.274 27.978-.321 9.797 1.535 19.538 5.422 28.443 3.886 8.904 9.695 16.725 16.96 22.836z"
        opacity=".23"
      />
      <path
        fill="#BDBDBD"
        d="M186.796 72.365l-8.76-.027v-.025l-4.583-10.656a1.283 1.283 0 0 0-1.2-.78 1.262 1.262 0 0 0-.898.399 1.312 1.312 0 0 0-.251 1.425l4.142 9.633-5.963.042a1.273 1.273 0 0 0-.864.404 1.315 1.315 0 0 0-.353.9c0 .334.126.655.353.898.226.242.536.387.864.404h4.731V75h5.104v-.017l7.709-.025c.328-.017.638-.162.864-.404.227-.243.353-.565.353-.9 0-.334-.126-.655-.353-.898a1.273 1.273 0 0 0-.864-.404l-.031.013z"
      />
      <path
        fill="#BDBDBD"
        d="M196.504 72.365l-8.76-.027v-.025l-4.58-10.656a1.291 1.291 0 0 0-.71-.664 1.265 1.265 0 0 0-.964.027 1.299 1.299 0 0 0-.674.7 1.328 1.328 0 0 0-.004.98l4.142 9.634-5.959.042a1.276 1.276 0 0 0-.865.405 1.32 1.32 0 0 0 0 1.797c.227.242.536.387.865.405h4.73l-.02.017h5.107v-.017l7.709-.025c.329-.017.638-.162.865-.404.226-.243.352-.565.352-.9 0-.334-.126-.655-.352-.898a1.276 1.276 0 0 0-.865-.404l-.017.013z"
      />
      <path
        fill="#1894C9"
        d="M177.211 59.805c.261.79 1.551 4.733 3.105 4.998 1.554.264 4.793-.922 4.793-.922l-.945-4.907-6.953.831z"
      />
      <path
        fill="#40B4E5"
        d="M202.145 33.933c-.835 10.99-7.952 25.507-16.246 27.257-8.294 1.751-20.99.348-20.99.348s-3.879.864-5.84 1.104c-.729.087-1.811.268-2.29-.592-.479-.86.366-1.629.863-1.977 7.349-5.137 10.738-13.266 15.335-20.533 2.841-4.486 6.47-8.447 9.54-12.797 2.396-3.39 4.484-6.999 6.682-10.531 3.724-5.983 7.979-6.665 13.846-2.816 1.904 1.26-.352 13.305-.9 20.537z"
      />
      <path
        fill="#FFC629"
        d="M203.107 13.25v4.138l-2.396 5.266 10.663-5.402c.209-.105.38-.273.491-.482a1.16 1.16 0 0 0-.157-1.313 1.123 1.123 0 0 0-.591-.348l-8.01-1.859z"
      />
      <path
        fill="#fff"
        d="M202.173 33.61s-11.916-5.28-14.377 11.234c-2.461 16.514-1.883 16.357-1.883 16.357s5.6-.348 11.149-8.502c2.633-3.87 4.214-11.095 4.625-13.702.258-1.786.42-3.584.486-5.388z"
      />
      <path
        fill="#1894C9"
        d="M192.574 29.652c-2.396-5.881-5.22-7.03-10.581-4.59-5.36 2.44-14.247 18.793-16.643 23.23-2.396 4.438-13.35 12.53-13.35 12.53s8.969.347 26.741-7.521c17.773-7.87 16.229-17.77 13.833-23.649z"
      />
      <path
        fill="#40B4E5"
        d="M168.024 61.142c.26.79 1.554 4.733 3.105 4.997 1.55.265 4.792-.918 4.792-.918l-.945-4.911-6.952.832z"
      />
      <path
        fill="#C06C00"
        d="M109 100c0-15.464 12.536-28 28-28h89c15.464 0 28 12.536 28 28v42a4 4 0 0 1-4 4H113a4 4 0 0 1-4-4v-42z"
      />
      <g>
        <path fill="#A1AAB3" d="M191 146h-18v25h18v-25z" />
        <path fill="#FFA126" d="M230 99h-22v30l11-4.286L230 129V99z" />
        <path fill="#663724" d="M230 95h-47.794c-1.218 0-2.206 1.007-2.206 2.25s.988 2.25 2.206 2.25H230V95z" />
        <path fill="#CB7445" d="M145 100h-19a4 4 0 0 0-4 4v3.414h27V104a4 4 0 0 0-4-4z" />
      </g>
      <path
        fill="#A33600"
        d="M109 98.5c0-14.635 11.864-26.5 26.5-26.5S162 83.865 162 98.5V142a4 4 0 0 1-4 4h-45a4 4 0 0 1-4-4V98.5z"
      />
    </g>
  </chakra.svg>
))
