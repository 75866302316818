import { InputProps as ChakraInputProps, useFormControlContext } from '@chakra-ui/react'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { dataAttr } from '../../../common/domUtils'
import { mergeRefs } from '../../../common/reactUtils'

export type ExtendedFormControlContext = Partial<ReturnType<typeof usePropsForFieldWarning>>
const FormControlExtendedContext = createContext<ExtendedFormControlContext>({})

export type ExtendedFormControlContextProps = {
  isWarned?: boolean
}
export const FormControlExtendedContextProvider = ({
  children,
  ...props
}: ExtendedFormControlContextProps & { children?: ReactNode | undefined }) => {
  return (
    <FormControlExtendedContext.Provider
      value={{
        ...usePropsForFieldWarning(props)
      }}>
      {children}
    </FormControlExtendedContext.Provider>
  )
}

export const useFormControlExtendedContext = () => {
  return useContext(FormControlExtendedContext)
}

/**
 * Adds ability to show a warning message using the `isWarned` prop on the
 * `FormControl` component in addition to the FormWarningMessage component.
 */
function usePropsForFieldWarning({ isWarned = false }: ExtendedFormControlContextProps) {
  const { id } = useFormControlContext()

  // Create a unique id for the warning message based on the field id
  const warningId = `${id}-warning`

  /**
   * Track whether the `FormWarningMessage` has been rendered.
   * We use this to append its id the `aria-describedby` of the `input`.
   */
  const [hasWarningText, setHasWarningText] = useState(false)

  // Make our own getWarningMessageProps, since it doesn't exist on the field context
  // But it's very similar to field.getErrorMessageProps()
  const getWarningMessageProps = useCallback(
    (props = {}, forwardedRef = null) => ({
      id: warningId,
      ...props,
      /**
       * Notify the field context when the error message is rendered on screen,
       * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
       */
      ref: mergeRefs(forwardedRef, node => {
        if (!node) return
        setHasWarningText(true)
      }),
      'aria-live': 'polite'
    }),
    [warningId]
  )

  const getInputProps = (props: Pick<ChakraInputProps, 'aria-describedby'> & ExtendedFormControlContextProps) => {
    let ariaDescribedBy = props['aria-describedby']
    if (hasWarningText) {
      ariaDescribedBy = [ariaDescribedBy, warningId].join(' ').trim()
    }

    return {
      ...props,
      'data-warned': dataAttr(props.isWarned ?? isWarned),
      'aria-describedby': ariaDescribedBy || undefined
    }
  }

  return {
    isWarned,
    warningId,
    hasWarningText,
    setHasWarningText,
    getWarningMessageProps,
    getInputProps
  }
}
