import { css, Global } from '@emotion/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { isNextJsPage } from '~/common/nextJsUtils'
import { queryClient } from '~/common/react-query/queryClient'
import { ErrorPageBoundary } from '~/components/v2/ErrorPage'
import { IntlProviderFromLegacyPageData } from '~/components/IntlProviders'
import { ChakraProvider } from '~/design_system'
import { ModalProvider } from '~/hooks/useModal'
import GlobalCSSVariables from '~/theme/css/workdayTheme'
import { workdayTheme as theme } from '../theme'

// Remove this wrapper once every page is converted to using Chakra and not bootstrap
export const ChakraWrapper = ({ children, isChakraOnly = true, globalShims = true, wrappingDiv = true }) => {
  // Next.js automatically wraps the whole page in chakra and react-query providers, so we can avoid it here.
  if (isNextJsPage()) return children
  return (
    <>
      <GlobalCSSVariables />
      <ChakraProvider resetCSS={isChakraOnly} theme={theme}>
        {/* TODO: Remove these globals once header is rewritten using Chakra */}
        {/* Set globalShims to false if you are using iframe Header */}
        {globalShims && (
          <Global
            styles={css`
              html {
                font-size: 16px !important;
              }

              .top-nav img,
              .menu-list img {
                display: inline;
              }

              .isChakra div[role='radiogroup'] label,
              .isChakra div[role='group'] label,
              .isChakra label {
                font-weight: normal; /* less.forms makes this bold */
              }

              .isChakra a {
                font-size: inherit;
              }

              /* modify the drawer top position to avoid overlapping with the header */
              .isChakra.drawer {
                top: 60px !important;
                height: calc(100% - 60px) !important;
              }

              .isChakra h1,
              .isChakra h2,
              .isChakra h3,
              .isChakra h4,
              .isChakra h5,
              .isChakra h6 {
                margin: 0;
              }
            `}
          />
        )}
        <QueryClientProvider client={queryClient}>
          <IntlProviderFromLegacyPageData>
            <ModalProvider>
              <ErrorPageBoundary>
                {wrappingDiv ? <div className="isChakra">{children}</div> : children}
              </ErrorPageBoundary>
            </ModalProvider>
          </IntlProviderFromLegacyPageData>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  )
}
