import React, { useEffect, useState } from 'react'

import { useTogglePinTaskMutation } from '~/api/myTasks'
import { useMsg } from '~/common/localizationUtils'
import { CheckboxProps, IconCheckbox, useErrorModal } from '~/design_system'
import { Pin, Pin1 } from '~/icons'
import { UserTask } from '../types'

type PinnedIconButtonProps = {
  task: UserTask
} & CheckboxProps

export const PinnedIconCheckbox = ({ task, ...props }: Omit<PinnedIconButtonProps, 'variant'>) => {
  const msg = useMsg()

  const [isPinned, setIsPinned] = useState(task.is_pinned)
  useEffect(() => {
    setIsPinned(task.is_pinned)
  }, [task.is_pinned])

  const { showModal: showErrorModal } = useErrorModal()
  const { mutateAsync: togglePinTask } = useTogglePinTaskMutation({
    onMutate(_id: number) {
      // optimistic update of state
      setIsPinned(prev => !prev)
      return undefined
    },
    onError() {
      // roll back if there is an error
      setIsPinned(prev => !prev)
      showErrorModal({ message: `${task.subtype} ${msg('tasks.actions.marked_failed')}` })
    }
  })

  return (
    <IconCheckbox
      isChecked={isPinned}
      iconUnchecked={Pin}
      iconChecked={Pin1}
      verbLabelToCheck={msg('tasks.actions.pin_task')}
      verbLabelToUncheck={msg('tasks.actions.unpin_task')}
      onChange={() => {
        togglePinTask(task.id)
      }}
      {...props}
    />
  )
}
