import { ReactNode } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { FetchBaseError } from '~/common/fetchErrors'
import { BrowserError, Button, EmptyState, Link, LockedOut, NoResults, Text } from '~/design_system'

/**
 * This component is using the new Empty State from the design system, however we would like to eventually
 * remove these components in favor of using the Empty State from the design system directly.
 * */
export const NotFoundPage = () => (
  <EmptyState>
    <NoResults title="Oops! Page not found." mb={2} />
    <Button as="a" variant="primary" href="/home/login/" mt="1rem !important">
      Take me to the homepage
    </Button>
  </EmptyState>
)

/**
 * This component is using the new Empty State from the design system, however we would like to eventually
 * remove these components in favor of using the Empty State from the design system directly.
 * */
export const AccessDeniedPage = () => {
  return (
    <EmptyState>
      <LockedOut title="Permission Denied" />
      <Text maxW="75ch" mb={2}>
        Please verify the account you are using is setup correctly.
      </Text>
      <Button as="a" variant="primary" href="/home/login/">
        Take me to the homepage
      </Button>
    </EmptyState>
  )
}

/**
 * This component is using the new Empty State from the design system, however we would like to eventually
 * remove these components in favor of using the Empty State from the design system directly.
 * */
export const NotLoggedInPage = () => {
  return (
    <EmptyState>
      <LockedOut title="Not Logged In" />
      <Text maxW="75ch" mb={2}>
        You are not logged in. Redirecting you to the sign in page...
      </Text>
      <Button as="a" variant="primary" href="/home/login/">
        Take me to the sign in page now
      </Button>
    </EmptyState>
  )
}

/**
 * This component is using the new Empty State from the design system, however we would like to eventually
 * remove these components in favor of using the Empty State from the design system directly.
 * */
export const ErrorPage = () => {
  const description = (
    <Text maxW="75ch" mb={2}>
      We're not quite sure what went wrong but we're taking a look. In the meantime, you can try going back or{' '}
      <Link variant="link" href="https://support.vndly.com/" target="_blank">
        visit our help center
      </Link>{' '}
      if you still need a hand
    </Text>
  )

  return (
    <EmptyState>
      <BrowserError title="Oops, our circuits got crossed." />
      {description}
      <Button as="a" variant="primary" href="/home/login/">
        Take me to the homepage
      </Button>
    </EmptyState>
  )
}

type ErrorBoundaryFallbackErrorPageProps = Omit<FallbackProps, 'error'> & {
  error: unknown
}

/**
 * Use with ErrorBoundary, wrapped around a page that may have an API FetchBaseError error
 *
 * @example
 *  import { ErrorBoundary } from 'react-error-boundary'
 *  import { ErrorBoundaryFallbackErrorPage } from '~/components/v2/ErrorPage'
 *
 *  <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackErrorPage}>
 *    <MyPage />
 *  </ErrorBoundary>
 */
export const ErrorBoundaryFallbackErrorPage = (props: ErrorBoundaryFallbackErrorPageProps) => {
  if (props.error instanceof FetchBaseError) {
    if (props.error.status === 401) return <NotLoggedInPage />
    if (props.error.status === 403) return <AccessDeniedPage />
    if (props.error.status === 404) return <NotFoundPage />
  }
  return <ErrorPage />
}

export const ErrorPageBoundary = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackErrorPage}>{children}</ErrorBoundary>
)
