import { RefObject } from 'react'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  forwardRef,
  ScrollShadowBox,
  Stack,
  StackProps
} from '~/design_system'

/**
 * @module ItemsList
 *
 * This module contains `ItemsList` and related components. The purpose of
 * these components is to provide an easy way to render a list of items, either
 * as buttons (which react to `onClick`) or links (which take `href`).
 *
 * @see `ItemsList.stories.jsx` for usage examples.
 */

/**
 * The main container for a list of items. Use the other components in this
 * module as direct children of `ItemsList`.
 *
 * The underlying component is Chakra's `Stack`.
 *
 * Optionally pass your own component width breakpoints to modify when the ResultItem layout changes occur.
 */
export const ItemsList = forwardRef<StackProps, 'div'>((props, ref) => (
  <Stack data-items-list ref={ref as RefObject<HTMLDivElement>} spacing={0} flex="1" layerStyle="card" {...props} />
))

ItemsList.displayName = 'ItemsList'

/**
 * The header for the list of items.
 *
 * The underlying component is Chakra's `Flex`.
 */
export const ItemsListHeader = forwardRef<FlexProps, 'div'>((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    data-items-list-header
    as="header"
    align="center"
    px={6}
    py={3}
    borderBottomWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    {...props}
  />
))

/**
 * The footer for the list of items.
 *
 * The underlying component is Chakra's `Flex`.
 */
export const ItemsListFooter = forwardRef<FlexProps, 'div'>((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    data-items-list-footer
    as="footer"
    align="center"
    color="var(--vndly-color-container-subdued)"
    px={6}
    py={3}
    borderTopWidth="1px"
    borderColor="var(--vndly-color-container-border)"
    {...props}
  />
))

/**
 * The container for the list of items.
 *
 * If you'd like your items to be displayed in a collapsible format rather than
 * as links or buttons, use Chakra's `Accordion` component instead.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ItemsListItems = forwardRef<BoxProps, 'div'>((props: BoxProps, ref) => (
  <ScrollShadowBox data-items-list-items data-testid="items-list-items" role="list" ref={ref} {...props} />
))

ItemsListItems.displayName = 'ItemsListItems'

// these styles have been copied from `Accordion` and `AccordionItem` so we can
// share similar looks between views. in `@chakra-ui/react@^1.0.0`, we'll be
// able to use `apply="Accordion.root"` and `apply="Accordion.Item"` to place
// those styles on this component instead

/**
 * A list item that displays information with no attached interactivity. If the
 * user should be able to interact with this item, nest the `ItemsListButton` or
 * `ItemsListLink` within the `ItemsListItem`.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ItemsListItem = forwardRef<BoxProps, 'div'>((props: BoxProps, ref) => (
  <Box
    data-testid="items-list-item"
    data-items-list-item
    role="listitem"
    ref={ref}
    d="flex"
    position="relative"
    alignItems="center"
    w="full"
    p={6}
    borderColor="var(--vndly-color-container-border)"
    borderTopWidth="1px"
    _first={{ borderTopWidth: 0 }}
    _last={{ borderBottomWidth: 0 }}
    outline="0"
    _focus={{ boxShadow: 'outline' }}
    bg="var(--vndly-color-container-light)"
    transitionDuration=".1s"
    transitionProperty="background"
    {...props}
  />
))

ItemsListItem.displayName = 'ItemsListItem'

/**
 * A list item, represented as a button.
 *
 * Provide `onClick` to each item to manage when it is clicked.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ItemsListButton = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-items-list-button
    as="button"
    type="button"
    ref={ref}
    flexGrow={1}
    d="flex"
    alignItems="center"
    textAlign="left"
    m={-6}
    p={6}
    _hover={{ bg: 'blackAlpha.50' }}
    {...props}
  />
))

ItemsListButton.displayName = 'ItemsListButton'

/**
 * A list item, represented as a link.
 *
 * Provide `href` to control the link.
 *
 * The underlying component is Chakra's `Box`.
 */
export const ItemsListLink = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-items-list-link
    as="a"
    variant="unstyled"
    ref={ref}
    flexGrow={1}
    d="flex"
    alignItems="center"
    m={-6}
    p={6}
    _hover={{ textDecoration: 'none', color: 'inherit', bg: 'blackAlpha.50' }}
    {...props}
  />
))

ItemsListLink.displayName = 'ItemsListLink'

/**
 * A full width "Load More" button.
 *
 * Place it at the bottom of an ItemsList with infinitely loading items.
 *
 * The underlying component is Chakra's Button
 */
export const ItemsListLoadMoreButton = forwardRef<ButtonProps, 'button'>(({ children, ...props }, ref) => (
  <Button ref={ref} isFullWidth h={12} variant="secondary" borderRadius={0} {...props}>
    {children ?? 'Load More'}
  </Button>
))
