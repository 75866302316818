import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react'
import { UserPreferenceNamespace, VisibleFieldData } from '~/api/userPreferences'
import { isClient } from '~/common/nextJsUtils'
import { isWithinReactComponentOrHook } from '~/common/reactUtils'
import { StateTuple } from '~/common/types'
import { useDisclosure } from '~/design_system'
import { useSessionStorage } from '~/hooks/useBrowserStorage'
import { useStringUrlState } from '~/hooks/useUrlState'
import { DisplayMode } from './ActionBar.DisplayModeButtons'

type FieldStateProps<S> = { value: S; onChange: Dispatch<SetStateAction<S>> }
function stateTupleToObj<S>(s: Readonly<StateTuple<S>>): FieldStateProps<S> {
  return { value: s[0], onChange: s[1] }
}

export type ActionBarConfig = {
  defaultFiltersOpen?: boolean
  defaultVisibleFieldsModalOpen?: boolean
  defaultSearch?: string
  defaultView?: string
  defaultOrdering?: string
  defaultDisplayMode?: DisplayMode
  urlReadWriteEnabled?: boolean
  defaultUserPreferenceNamespace?: UserPreferenceNamespace
  defaultVisibleFields?: VisibleFieldData[]
  searchPlaceholder?: string | React.ReactNode
  // Used to configure the views in the ActionBar
  views?: ReadonlyArray<{ value: string; label: string | React.ReactNode; dataTestId?: string }>
  // Used to configure the sortBy in the ActionBar
  orderings?: ReadonlyArray<{ value: string; label: string | React.ReactNode }>
  // Used to configure the display mode buttons to be shown in the ActionBar
  hasDisplayModeButtons?: boolean
  // Used to configure the visible fields button to be shown in the ActionBar
  hasVisibleFieldsButton?: boolean
}

export type ActionBarApi = ReturnType<typeof useActionBar>

/**
 * @deprecated This hook is meant to be private. Please use ActionBarProvider instead.
 */
export const useActionBar = ({
  defaultFiltersOpen = false,
  defaultVisibleFieldsModalOpen = false,
  defaultSearch,
  defaultView,
  defaultOrdering,
  defaultDisplayMode = 'list',
  urlReadWriteEnabled = true,
  defaultUserPreferenceNamespace = UserPreferenceNamespace.JOB,
  defaultVisibleFields = [],
  ...props
}: ActionBarConfig = {}) => {
  const path = isClient() ? window.location.pathname : 'server'
  const filtersDisclosure = useDisclosure({ defaultIsOpen: defaultFiltersOpen })
  const visibleFieldsDisclosure = useDisclosure({ defaultIsOpen: defaultVisibleFieldsModalOpen })
  const search = stateTupleToObj(useStringUrlState('search', defaultSearch, urlReadWriteEnabled))
  /**
   * @deprecated This has been renamed, use the `view` property instead.
   */
  const filterTab = stateTupleToObj(useStringUrlState('view', defaultView, urlReadWriteEnabled))
  const view = filterTab
  const ordering = stateTupleToObj(useStringUrlState('ordering', defaultOrdering, urlReadWriteEnabled))
  const displayMode = stateTupleToObj(
    useSessionStorage(`action-bar-display-mode ${path}`, defaultDisplayMode) as StateTuple<DisplayMode>
  )
  const isGridDisplayMode = displayMode.value === 'grid'
  const isListDisplayMode = displayMode.value === 'list'
  const userPreferenceNamespace = defaultUserPreferenceNamespace
  const visibleFields = stateTupleToObj(useState(defaultVisibleFields) as StateTuple<VisibleFieldData[]>)

  const queryValues = useMemo(
    () => ({
      search: search?.value,
      view: view?.value,
      ordering: ordering?.value
    }),
    [search?.value, view?.value, ordering?.value]
  )

  return {
    filtersDisclosure,
    visibleFieldsDisclosure,
    search,
    filterTab,
    view,
    ordering,
    displayMode,
    isListDisplayMode,
    isGridDisplayMode,
    userPreferenceNamespace,
    visibleFields,
    queryValues,
    ...props
  }
}

const InternalActionBarContext = createContext<ReturnType<typeof useActionBar>>(
  {} as unknown as ReturnType<typeof useActionBar>
)

export type ActionBarProviderProps = {
  config?: ActionBarConfig
  children: ReactNode
}

export const ActionBarProvider = ({ children, config }: ActionBarProviderProps) => {
  const actionBar = useActionBar(config)
  return <InternalActionBarContext.Provider value={actionBar}>{children}</InternalActionBarContext.Provider>
}

export const useActionBarContext = () => useContext(InternalActionBarContext) ?? {}

export function useActionBarQueryValues() {
  return useActionBarContext().queryValues
}

export function defineActionBar(config?: ActionBarConfig) {
  const isWithinReact = isWithinReactComponentOrHook()
  if (isWithinReact)
    throw new Error('defineActionBar() must be statically defined **outside** of a React component or hook.')
  return {
    Provider: (props: { children: ReactNode }) => <ActionBarProvider config={config} {...props} />,
    /**
     * Good for accessing the query values to pass from ActionBar that are relevant to the API query.
     */
    // TODO (Tim) Add clearer types for returned values based on which action bar elements were defined
    useValues: () => useActionBarQueryValues(),
    /**
     * Good for accessing state related to the action bar other than the API query values.
     */
    useContext: () => useActionBarContext()
  }
}
