import { ReactNode } from 'react'
import { Box, Link, List, ListItem, Table, TableProps, Tbody, Td, Text, Th, Thead, Tr } from './chakra'

/**
 * Storybook Component Table
 *
 * This table is used to display all the component styles, properties and usage on Storybook
 */
const twoColumnWidth = ['40%', '60%']

export type StorybookComponentTableProps = {
  headings: Array<string>
  specs: Array<{ name: string; component: ReactNode; properties: any; usage: any }>
  widths?: Array<string>
} & TableProps

export const StorybookComponentTable = ({
  headings,
  specs,
  widths = twoColumnWidth,
  ...props
}: StorybookComponentTableProps) => (
  <Table variant="simple" {...props}>
    <Thead>
      <Tr>
        {headings.map((heading, index) => (
          <Th key={heading} w={widths[index]}>
            <Text textStyle="sectionHeading">{heading}</Text>
          </Th>
        ))}
      </Tr>
    </Thead>
    <Tbody>
      {specs.map(spec => {
        return (
          <Tr key={spec.name}>
            <Td>
              <Text textStyle="bodySmall" mb={2}>
                {spec.name}:
              </Text>
              {spec.component}
            </Td>
            <Td>{spec.usage}</Td>
          </Tr>
        )
      })}
    </Tbody>
  </Table>
)

/**
 * Storybook Full Width Components Table
 */
type StorybookFullWidthComponentTableProps = {
  specs: Array<{
    name: string
    component: ReactNode
    properties: any
    usage: any
    elementsUsed: Array<{ name: string; href: string }>
  }>
}

export const StorybookFullWidthComponentTable = ({ specs }: StorybookFullWidthComponentTableProps) => (
  <>
    <Text textStyle="sectionHeading">Elements</Text>
    {specs.map(spec => {
      return (
        <Box key={spec.name} my={6} p={6} border="1px solid" borderColor="gray.300">
          <Box mb={6}>
            <Text textStyle="heading3" pb={3}>
              {spec.name}:
            </Text>
            {spec.component}
          </Box>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th w="100%">
                  <Text textStyle="sectionHeading">Usage</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{spec.usage}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      )
    })}
  </>
)

/**
 * Storybook Elements Used
 *
 * This is a list of other components used to build the given component
 */
type StorybookElementsUsedProps = {
  heading?: string
  links: Array<{ name: string; href: string }>
}

export const StorybookElementsUsed = ({ heading = 'Elements Used', links }: StorybookElementsUsedProps) => (
  <Box bg="gray.100" textStyle="bodySmall" p={4} mb={2} maxW="350px">
    <Text key="list-title" textStyle="bodySmallSemi" m={0}>
      {heading}:
    </Text>
    <List p={0} m={0}>
      {links.map(link => (
        <ListItem key={`list-${link.name}`}>
          <Link variant="inlineGray" href={link.href}>
            {link.name}
          </Link>
        </ListItem>
      ))}
    </List>
  </Box>
)
