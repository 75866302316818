import { Box, BoxProps } from '../../design_system'
import { useBoundingClientRect } from '../../hooks/useBoundingClientRect'

export type StickyHeightExpanderProps = BoxProps & {
  offsetTopPx?: number
  offsetBottomPx?: number
}

const HEADER_HEIGHT = 60
export const StickyHeightExpander = ({
  offsetTopPx = HEADER_HEIGHT,
  offsetBottomPx = 0,
  ...props
}: StickyHeightExpanderProps) => {
  const ref = useBoundingClientRect((rect, el) => {
    // eslint-disable-next-line no-param-reassign
    el.style.height = `calc(100vh - (${Math.max(offsetTopPx, rect.y)}px + ${Math.max(0, offsetBottomPx)}px))`
  })
  return (
    <Box ref={ref} pos="sticky" top={`${offsetTopPx}px`} transform="translateZ(0)" willChange="height" {...props} />
  )
}
