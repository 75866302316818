import { ReactNode } from 'react'
import { forwardRef, Button, ButtonProps, HStack, Stack, ModalBodyProps } from '../../chakra'
import { ModalDialogBody, TextBlockTwoLineDefaultAlt } from '../..'
/**
 * Modal quick action body
 *
 * This component will be the container of the quick action button body content.
 *
 * ModalQuickActionBody must be within a ModalDialog
 */
export const ModalQuickActionBody = forwardRef(({ children, ...props }: ModalBodyProps, ref) => (
  <ModalDialogBody ref={ref} {...props}>
    <Stack spacing="md">{children}</Stack>
  </ModalDialogBody>
))

type ActionButtonProps = {
  name: ReactNode
  description: ReactNode
  icon: ReactNode
} & ButtonProps
/**
 * Quick Action Modal Button
 *
 * This component is the action button inside a modal.
 *
 * ActionButton must be within a ModalDialogBody.
 */
export const ActionButton = forwardRef(({ name, description, icon, ...props }: ActionButtonProps, ref) => (
  <Button variant="quickAction" mt={1} px={6} ref={ref} {...props}>
    <HStack spacing="md">
      {icon}
      <TextBlockTwoLineDefaultAlt primaryText={name} secondaryText={description} textAlign="left" />
    </HStack>
  </Button>
))
