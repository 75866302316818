import shallow from 'zustand/shallow'
import { useEffect, createRef } from 'react'

export const scrollControl =
  useStore =>
  ({ name }) => {
    const { actions, fieldRefs } = useStore(
      store => ({
        actions: store.actions,
        fieldRefs: store.fieldRefs
      }),
      shallow
    )
    // Register this field in the ref registry for scroll-to behavior
    useEffect(() => {
      const ref = createRef()
      actions.registerField(name, ref)
    }, [])
    return <div ref={fieldRefs[name]} />
  }

export const scrollErrorIntoView = (errors, fieldRefs, isValidating = true) => {
  if (!errors) return
  const errorKeys = Object.keys(errors)
  if (errorKeys.length && isValidating && fieldRefs[errorKeys[0]]) {
    const ref = fieldRefs[errorKeys[0]].current
    const field = ref.parentNode.querySelector('input')
    if (field && field.offsetWidth > 0 && field.offsetHeight > 0) {
      field.focus()
    } else {
      ref.scrollIntoView(false)
    }
  }
}

export const TOP_NAV_HEIGHT = 60
export const TOP_NAV_HEIGHT_PX = `${60}px`

// so the -100 offset, it just looks nice to move the target element up just a tad from the middle
export function scrollElIntoView(el, { behavior = 'smooth', offset = -100, alignment = 'middle' } = {}) {
  const top = calculateTop(el, offset, alignment)
  window.scrollTo({ behavior, left: 0, top })
}

function calculateTop(el, offset, alignment) {
  if (alignment === 'top') {
    return window.pageYOffset + el.getBoundingClientRect().top - TOP_NAV_HEIGHT - offset
  }

  return window.pageYOffset + el.getBoundingClientRect().top - TOP_NAV_HEIGHT - window.innerHeight / 2 - offset
}
