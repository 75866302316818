import { Box, BoxProps, forwardRef } from '../../chakra'

/**
 * DotIndicator
 *
 * Used alongside icons to attract users attention to new or important items.
 * Shows in the top right corner of the parent container (parent container must use `position: relative`).
 * https://storybook.beta.vndly.com/?path=/docs/foundations-data-display-indicator-badges--dot-notification
 */
export const DotIndicator = forwardRef((props: BoxProps, ref) => (
  <Box
    ref={ref}
    bg="brand.500"
    w={3}
    h={3}
    borderRadius="full"
    border="2px"
    borderColor="transparent"
    backgroundClip="padding-box"
    pos="absolute"
    top="-4px"
    right="-4px"
    {...props}
  />
))

export interface NumberIndicatorProps extends BoxProps {
  number: number
  priority?: 'high' | 'low'
}

/**
 * NumberIndicator
 *
 * Use to signify that something has multiple items.  Low priority is useful when multiple items are
 * more tertiary and not the most important information on the page.  High priority is used when the items require
 * more attention.  If the `number` attribute is higher than 999 we show 999+ instead of the entire number
 * to save on horizontal space.
 *
 * https://storybook.beta.vndly.com/?path=/docs/foundations-data-display-indicator-badges--numbered-high-priority
 */
export const NumberIndicator = forwardRef((props: NumberIndicatorProps, ref) => {
  const number = props.number
  const priority = props.priority || 'low'
  const bg = priority === 'low' ? 'gray.200' : 'blue.500'
  const fontColor = priority === 'low' ? 'gray.600' : 'white'

  const numberDisplay = number > 999 ? '999+' : number

  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={bg}
      minWidth="20px"
      w="fit-content"
      h="20px"
      px={1}
      borderRadius="full"
      border="2px"
      borderColor="white"
      color={fontColor}
      fontSize="xs"
      {...props}>
      {numberDisplay}
    </Box>
  )
})
