/**
 *
 * Chakra Text Styles
 * We will be using these predefined text styles as direct props to Chakra elements.
 * See VNDLY's Storybook for examples: https://storybook.beta.vndly.com/?path=/docs/foundations-typography--text
 *
 * */

const textStyles = {
  heading1: {
    fontSize: '4xl',
    fontWeight: 'semibold',
    lineHeight: 'shorter'
  },
  heading2: {
    fontSize: '2xl',
    fontWeight: 'semibold',
    lineHeight: 'shorter'
  },
  heading3Semi: {
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: 'shorter'
  },
  heading3: {
    fontSize: 'xl',
    fontWeight: 'normal',
    lineHeight: 'shorter'
  },
  sectionHeading: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    letterSpacing: 'widest',
    textTransform: 'uppercase',
    color: 'var(--vndly-color-container-subdued)'
  },
  bodySemi: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base'
  },
  body: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base'
  },
  bodySmallSemi: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    lineHeight: 'base'
  },
  bodySmall: {
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'base'
  },
  subtextSemi: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-container-subdued)'
  },
  subtext: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: 'base',
    color: 'var(--vndly-color-container-subdued)'
  },
  subtextSmallSemi: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-container-subdued)'
  },
  subtextSmall: {
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'base',
    color: 'var(--vndly-color-container-subdued)'
  },
  link: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-link)'
  },
  linkSmall: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-link)'
  },
  tooltip: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-link)',
    textDecoration: 'underline dashed 1px',
    cursor: 'help'
  },
  error: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-link-delete)'
  },
  errorSmall: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    lineHeight: 'base',
    color: 'var(--vndly-color-link-delete)'
  }
}

export { textStyles }
