import qs from 'qs'
import { useInfiniteQuery, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { OffsetLimit, OffsetLimitPaginationResponse } from '~/api/common'
import { User } from '~/api/users'
import fetch from '~/common/fetch'
import { FetchOptions } from '~/common/fetchHelpers'
import { queryObj } from '~/common/react-query/queryObject'

export enum AuthProviderId {
  SAML = 2,
  VNDLY_GOOGLE_OAUTH2 = 3,
  NOLOGIN = 4,
  VNDLY = 1
}

export enum UserRole {
  VENDOR = 'vendor',
  CANDIDATE = 'candidate',
  EMPLOYER = 'employer'
}

export type BaseAccountMeResponse = {
  id: number
  full_name: string
  email: string
  rbac_role: string
  tenant: string
  environment: string
  timezone: string
  locale: string
  default_conf_num?: string
  default_conf_num_country_code?: string
  preferences?: any
}

export interface VendorAccountMeResponse extends BaseAccountMeResponse {
  role: UserRole.VENDOR
  employer_id: null
  vendor_entity_id: number
}

export interface CandidateAccountMeResponse extends BaseAccountMeResponse {
  role: UserRole.CANDIDATE
  employer_id: null
  vendor_entity_id: null
}

export interface EmployerAccountMeResponse extends BaseAccountMeResponse {
  role: UserRole.EMPLOYER
  employer_id: number
  vendor_entity_id: null
}

export type AccountMeResponse = (VendorAccountMeResponse | CandidateAccountMeResponse | EmployerAccountMeResponse) & {
  role: UserRole
  isVendorRole: boolean
  isCandidateRole: boolean
  isEmployerRole: boolean
}

export type IsMFAVerifiedResponse = Record<string, never>
export type ResetMFAResponse = Record<string, never>

export type DelegatedUser = Pick<User, 'id' | 'first_name' | 'last_name' | 'full_name' | 'email'> & { company: string }
export type DelegatedUserListResponse = OffsetLimitPaginationResponse<DelegatedUser>
export type DelegatedUserListFilters = Partial<OffsetLimit> & {
  query?: string
  ordering?: 'last_name' | 'company' | '-last_name' | '-company'
}

export interface CreateDelegatePayload {
  delegate_id: number | undefined
  target_id: number
  expires_date?: string
}

const THIRTY_MINUTES = 1000 * 60 * 30
const responseCache = { ttl: THIRTY_MINUTES }
export const accountsApi = {
  me: async (queryParams?: Record<string, any>, fetchOpts?: FetchOptions): Promise<AccountMeResponse> => {
    const q = queryParams ? `?${qs.stringify(queryParams)}` : ''
    const response: AccountMeResponse = await fetch.get(`/api/v2/accounts/me/${q}`, { responseCache, ...fetchOpts })
    return Object.assign(response ?? {}, {
      isVendorRole: response?.role === UserRole.VENDOR,
      isCandidateRole: response?.role === UserRole.CANDIDATE,
      isEmployerRole: response?.role === UserRole.EMPLOYER
    })
  },
  isAuthenticated(): Promise<{ is_authenticated: boolean }> {
    const FIVE_SECONDS = 1000 * 5
    const responseCache = { ttl: FIVE_SECONDS }
    return fetch.get('/api/v2/accounts/is_authenticated', { responseCache })
  },
  isMFAVerified: (): Promise<IsMFAVerifiedResponse> => fetch.get(`/api/v2/accounts/is_mfa_verified/`),
  resetMFA: (): Promise<ResetMFAResponse> => fetch.post(`/accounts/reset_mfa/`),
  delegateRequestUserList: (filters?: DelegatedUserListFilters): Promise<DelegatedUserListResponse> =>
    fetch.get(`/api/v2/accounts/delegates/users/request/${qs.stringify(filters, { addQueryPrefix: true })}`),
  delegateGrantUserList: (filters?: DelegatedUserListFilters): Promise<DelegatedUserListResponse> =>
    fetch.get(`/api/v2/accounts/delegates/users/to/${qs.stringify(filters, { addQueryPrefix: true })}`),
  createDelegate: (data: CreateDelegatePayload): Promise<void> => fetch.post(`/accounts/delegates/create/`, data)
}

export const accountsKeys = {
  all: ['accounts'] as const,
  me: (filters?: Record<string, any>) => [...accountsKeys.all, 'me', filters] as const,
  is_authenticated: () => [...accountsKeys.all, 'is_mfa_verified'] as const,
  is_mfa_verified: () => [...accountsKeys.all, 'is_mfa_verified'] as const,
  delegate_to_user_list: (filters?: DelegatedUserListFilters) =>
    [...accountsKeys.all, 'delegate_to_user_list', filters] as const,
  delegate_to_grant_user_list: (filters?: DelegatedUserListFilters) =>
    [...accountsKeys.all, 'delegate_to_user_list', filters] as const
}

export type AccountMeParams = {
  full_details?: boolean
}
const accountsMe = (queryParams?: AccountMeParams) =>
  queryObj({
    queryKey: accountsKeys.me(queryParams),
    queryFn: () => accountsApi.me(queryParams),
    staleTime: Infinity
  })

export const useAccountMeQuery = (queryParams?: AccountMeParams) => {
  const result = useQuery(accountsMe(queryParams))

  return Object.assign(result, {
    role: result.data?.role,
    isVendorRole: result.data?.role === UserRole.VENDOR,
    isCandidateRole: result.data?.role === UserRole.CANDIDATE,
    isEmployerRole: result.data?.role === UserRole.EMPLOYER
  })
}

export const useIsAuthenticatedQuery = (options?: UseQueryOptions<{ is_authenticated: boolean }>) => {
  return useQuery({
    queryKey: accountsKeys.is_authenticated(),
    queryFn: () => accountsApi.isAuthenticated(),
    ...options
  })
}

export const useIsMFAVerifiedQuery = (options: UseQueryOptions<IsMFAVerifiedResponse>) => {
  return useQuery({ queryKey: accountsKeys.is_mfa_verified(), queryFn: accountsApi.isMFAVerified, ...options })
}

export const useDelegationRequestUserListQuery = (filters?: DelegatedUserListFilters) => {
  const finalFilters = { limit: 10, ...filters }
  const key = accountsKeys.delegate_to_user_list(finalFilters)
  return useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam = 0 }) => {
      return accountsApi.delegateRequestUserList({ offset: pageParam, ...filters })
    },
    getNextPageParam: (lastPage: OffsetLimitPaginationResponse<unknown>) => lastPage.next_params?.offset ?? false
  })
}

export const useCreateDelegateMutation = (opts?: UseMutationOptions<void, unknown, CreateDelegatePayload>) => {
  return useMutation({
    mutationFn: createDelegatePayload => accountsApi.createDelegate(createDelegatePayload),
    ...opts
  })
}

export const useDelegationGrantUserListQuery = (filters?: DelegatedUserListFilters) => {
  const finalFilters = { limit: 10, ...filters }
  const key = accountsKeys.delegate_to_grant_user_list(finalFilters)
  return useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam = 0 }) => {
      return accountsApi.delegateGrantUserList({ offset: pageParam, ...filters })
    },
    getNextPageParam: (lastPage: OffsetLimitPaginationResponse<unknown>) => lastPage.next_params?.offset ?? false
  })
}

// Getting tinyMCE locale using vndly locale
// Until refactor will need to be updated as more language packs are available
// TODO Future refactor to have this auto mapped between VNDLY and tinyMCE locales
const tinyMCELocale = new Map<string, string>([
  ['', 'en'],
  ['da_DK', 'da'],
  ['de_DE', 'de'],
  ['en_US', 'en'],
  ['en', 'en'],
  ['es', 'es'],
  ['fi_FI', 'fi'],
  ['fr_FR', 'fr_FR'],
  ['fr_CA', 'fr_FR'],
  ['it_IT', 'it_IT'],
  ['ja_JP', 'ja'],
  ['ko_KR', 'ko_KR'],
  ['nb_NO', 'nb_NO'],
  ['nl_NL', 'nl'],
  ['pl_PL', 'pl'],
  ['pt_BR', 'pt_BR'],
  ['sv_SE', 'sv_SE'],
  ['zh_Hans_CN', 'zh_CN'],
  ['zh_Hans_TW', 'zh_TW']
])

export const getTinyMceLocale = (CurrentLocale = 'en') => {
  // checks if value exists exactly and defaults to en if not
  // if no exact match checks if there is anything comparable to CurrentLocale
  // returns any exact or partial match if found otherwise returns the default of en

  let locale
  if (tinyMCELocale.has(CurrentLocale)) {
    locale = tinyMCELocale.get(CurrentLocale)
  } else if (CurrentLocale && Array.from(tinyMCELocale.keys()).find(k => k.includes(CurrentLocale.split('_')[0]))) {
    locale = tinyMCELocale.get(`${Array.from(tinyMCELocale.keys()).find(k => k.includes(CurrentLocale.split('_')[0]))}`)
  } else {
    // This is the default language if nothing matches what is passed in
    locale = 'en'
  }
  return locale
}

// Getting date-fns locale using vndly locale
// Until refactor will need to be updated as more language packs are available
// TODO Future refactor to have this auto mapped between VNDLY and date-fns locales
const dateFnsLocale = new Map<string, string>([
  ['', 'en'],
  ['da_DK', 'da'],
  ['de_DE', 'de'],
  ['en_US', 'en-US'],
  ['en', 'en-US'],
  ['es', 'es'],
  ['fi_FI', 'fi'],
  ['fr_FR', 'fr'],
  ['fr_CA', 'fr-CA'],
  ['it_IT', 'it'],
  ['ja_JP', 'ja'],
  ['ko_KR', 'ko'],
  ['nb_NO', 'nb'],
  ['nl_NL', 'nl'],
  ['pl_PL', 'pl'],
  ['pt_BR', 'pt-BR'],
  ['sv_SE', 'sv'],
  ['zh_Hans_CN', 'zh-CN'],
  ['zh_Hant_TW', 'zh-TW']
])

export const getDateFnsLocale = (CurrentLocale = 'en') => {
  // checks if value exists exactly and defaults to en if not
  // if no exact match checks if there is anything comparable to CurrentLocale
  // returns any exact or partial match if found otherwise returns the default of en

  let locale
  if (dateFnsLocale.has(CurrentLocale)) {
    locale = dateFnsLocale.get(CurrentLocale)
  } else if (CurrentLocale && Array.from(dateFnsLocale.keys()).find(k => k.includes(CurrentLocale.split('_')[0]))) {
    locale = dateFnsLocale.get(`${Array.from(dateFnsLocale.keys()).find(k => k.includes(CurrentLocale.split('_')[0]))}`)
  } else {
    // This is the default language if nothing matches what is passed in
    locale = 'en-US'
  }
  return locale
}

// Returns a formatted date/time based on the locale of the user logged in.
// The format of the date/time is determined by the options argument passed in.
// Either pass in date or time if only one is needed, otherwise pass in DateTime
// If no date or time is passed in the current DateTime will be returned
export const localizeDateTime = (locale = 'en_US', options: any, date?: string, time?: string, dateTime?: string) => {
  const labelFormat = new Intl.DateTimeFormat(locale.replace('_', '-').replace('_POSIX', ''), options)
  let localizedDateTime
  if (dateTime) {
    localizedDateTime = labelFormat.format(new Date(dateTime))
  } else if (time) {
    const formattedTime = time.toLowerCase().replace('pm', ' pm').replace('am', ' am')
    localizedDateTime = labelFormat.format(new Date('2023-02-23 ' + formattedTime))
  } else if (date) {
    localizedDateTime = labelFormat.format(new Date(date + ' 00:00:00'))
  } else {
    localizedDateTime = labelFormat.format(Date.now())
  }
  return localizedDateTime
}
