export const Divider = {
  baseStyle: {
    borderWidth: '1px',
    borderColor: 'gray.300'
  },
  variants: {
    dark: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'gray.500'
    },
    progress: props =>
      props.isDashed
        ? {
            borderBottomWidth: '1px',
            borderColor: 'gray.500',
            borderStyle: 'dashed',
            marginBottom: 0,
            marginInlineStart: '0 !important'
          }
        : {
            borderBottomWidth: '1px',
            borderColor: 'brand.500',
            borderStyle: 'solid',
            marginBottom: 0,
            marginInlineStart: '0 !important'
          }
  }
}
