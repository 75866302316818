import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const LockedGhost = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    // @ts-expect-error IconProps isn't from Chakra so types don't line up
    <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
      <g className="wd-icon-container">
        <path
          fill="#B9C0C7"
          d="M125.966 94.904a27.908 27.908 0 0 1 9.154 6.498A28.129 28.129 0 0 1 141 111H34a16.052 16.052 0 0 1 5.106-10.235 15.84 15.84 0 0 1 10.59-4.183 28.215 28.215 0 0 1 1.007-13.24 28.06 28.06 0 0 1 6.946-11.289 27.797 27.797 0 0 1 11.327-6.801 27.65 27.65 0 0 1 13.164-.788 28.043 28.043 0 0 1 6.64-11.077 27.786 27.786 0 0 1 10.875-6.89 27.631 27.631 0 0 1 12.79-1.232c4.3.6 8.401 2.205 11.975 4.688a27.933 27.933 0 0 1 8.605 9.607 28.177 28.177 0 0 1 .866 25.16 27.976 27.976 0 0 1-7.925 10.184z"
          opacity=".23"
        />
        <path
          fill="#FFDA61"
          d="M226.22 120.71a15.232 15.232 0 0 1 2.941-6.712 15.118 15.118 0 0 1 8.164-5.413 14.164 14.164 0 0 1 2.981-6.382 14.066 14.066 0 0 1 5.68-4.141 14.007 14.007 0 0 1 13.48 1.751 18.371 18.371 0 0 1 10.381-8.855 18.26 18.26 0 0 1 6.207-.954A47.4 47.4 0 0 0 279 73.5c0-26.233-21.266-47.5-47.5-47.5S184 47.267 184 73.5c0 24.449 18.471 44.584 42.22 47.21z"
        />
        <path
          fill="#B9C0C7"
          d="M226 123.297a15.167 15.167 0 0 0 3.498 9.703h71.339a18.065 18.065 0 0 0-1.508-10.011 17.964 17.964 0 0 0-6.653-7.607 18.51 18.51 0 0 0 .09-13.68 18.399 18.399 0 0 0-9.322-9.973 18.271 18.271 0 0 0-13.597-.771 18.371 18.371 0 0 0-10.381 8.855 14.007 14.007 0 0 0-13.48-1.752 14.066 14.066 0 0 0-5.68 4.142 14.164 14.164 0 0 0-2.981 6.382 15.118 15.118 0 0 0-8.164 5.413 15.235 15.235 0 0 0-3.161 9.299z"
          opacity=".23"
        />
        <path
          fill="#BDBDBD"
          fillRule="evenodd"
          d="M142.629 66.871c0-20.64 16.731-37.371 37.371-37.371 20.639 0 37.371 16.732 37.371 37.371v38.306h-74.742V66.871zM180 42.5c-13.46 0-24.371 10.911-24.371 24.371v25.306h48.742V66.871c0-13.46-10.911-24.371-24.371-24.371z"
          clipRule="evenodd"
        />
        <path
          fill="#FFC629"
          d="M137 80c-6.627 0-12 5.373-12 12v37.825a7.2 7.2 0 0 1 1.111 2.683 7.874 7.874 0 0 1 4.245 2.77 7.719 7.719 0 0 1 1.644 4.758 7.682 7.682 0 0 1-1.819 4.964h-4.093c1.896 4.131 6.069 7 10.912 7h43V80h-43z"
        />
        <path
          fill="#FFA126"
          d="M223 80c6.627 0 12 5.373 12 12v17.4a15.125 15.125 0 0 0-5.839 4.598 15.235 15.235 0 0 0-3.161 9.299 15.167 15.167 0 0 0 3.498 9.703H235v7c0 6.627-5.373 12-12 12h-43V80h43z"
        />
        <path
          fill="#7B858F"
          d="M187.484 122.296a12.14 12.14 0 0 0 4.677-9.586c0-6.717-5.444-12.162-12.161-12.162-6.716 0-12.161 5.445-12.161 12.162 0 3.894 1.83 7.36 4.677 9.586v7.252a7.484 7.484 0 1 0 14.968 0v-7.252z"
        />
        <path fill="#FFA126" d="M180 93.064l-45-13.096h90l-45 13.096z" />
        <path
          fill="#B9C0C7"
          d="M132 140.036a7.682 7.682 0 0 1-1.819 4.964H93.085a9.106 9.106 0 0 1 .784-5.122 9.236 9.236 0 0 1 3.46-3.892 9.327 9.327 0 0 1-.047-6.999 9.476 9.476 0 0 1 4.847-5.102 9.646 9.646 0 0 1 7.071-.395 9.513 9.513 0 0 1 5.398 4.531 7.359 7.359 0 0 1 3.388-1.341 7.392 7.392 0 0 1 3.621.445 7.316 7.316 0 0 1 2.954 2.118 7.198 7.198 0 0 1 1.55 3.265 7.874 7.874 0 0 1 4.245 2.77 7.719 7.719 0 0 1 1.644 4.758z"
          opacity=".23"
        />
      </g>
    </chakra.svg>
  )
})
