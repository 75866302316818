const baseStyle = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: 'base',
  color: 'white',
  bg: 'gray.800',
  px: '2',
  py: '0'
}

export const Tooltip = {
  baseStyle
}
