const brand = {
  50: '#EAFBF7',
  100: '#D6F7F0',
  200: '#94E8D7',
  300: '#6AE1C7',
  400: '#41D8B7',
  500: '#26b999',
  600: '#1D947A',
  700: '#176A57',
  800: '#0C3F33',
  900: '#041611'
}

const red = {
  50: '#FEE7EB',
  100: '#FFCBD5',
  200: '#F9859C',
  300: '#F75475',
  400: '#F5244D',
  500: '#D30931',
  600: '#AB0927',
  700: '#7A051D',
  800: '#480212',
  900: '#180106'
}

const yellow = {
  50: '#FFF8E5',
  100: '#FFE9B3',
  200: '#FFDB80',
  300: '#FFCD4E',
  400: '#FFBE18',
  500: '#E6A501',
  600: '#B38000',
  700: '#805C01',
  800: '#4D3700',
  900: '#1A1200'
}

const blue = {
  50: '#E5F4FF',
  100: '#B4DFFE',
  200: '#81C9FD',
  300: '#4FB3FC',
  400: '#1D9DFC',
  500: '#0377CC',
  600: '#025A9A',
  700: '#023C67',
  800: '#011f35',
  900: '#000203'
}

const gray = {
  50: '#F9F9F9', // <- Muted
  100: '#F5F5F5',
  200: '#E9E9E9',
  300: '#DDDDDD', // <- Border Colors
  400: '#999999',
  500: '#666666', // <- Secondary Text
  600: '#444444',
  700: '#333333',
  800: '#222222', // <- Secondary Swatch - Default Text
  900: '#111111'
}

// Used in new conditional segmented control component of DS
// TODO: Use from WD theme once it is included.
const chilimango400 = '#ff671b'

const colors = {
  brand,
  red,
  yellow,
  blue,
  gray,
  chilimango400
}

export default colors
