import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  forwardRef,
  Heading,
  HeadingProps,
  IconButton,
  IconButtonProps,
  Link,
  LinkProps,
  Tooltip,
  Checkbox,
  StylesProvider,
  useMultiStyleConfig,
  useStyles
} from '../../chakra'

/**
 * @module Card
 *
 * This module contains `Card` and related components. These components can be
 * composed together to create flexible, reusable cards.
 */

/**
 * Card
 *
 * The main container for a card. Use the other components in this module
 * inside of `Card` to create a full card layout.
 *
 * It renders the card's border and shadow.
 *
 * NOTE: `Card` does not have any padding. If you'd like to use `Card` as an
 * empty container, please compose with `CardBody`. See the `WithoutHeader`
 * story for an example.
 *
 * The underlying component is Chakra's `Box`.
 */
export const Card = forwardRef<BoxProps, 'div'>(({ children, ...props }: BoxProps, ref) => {
  const styles = useMultiStyleConfig('Card', {})
  return (
    <Box
      __css={styles.container}
      role="region"
      aria-label="Card Container"
      data-card
      data-testid="Card"
      ref={ref}
      {...props}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
})

Card.displayName = 'Card'

/**
 * Card Header (Wrapper)
 *
 * The header for a `Card`. It renders a space at the top with a bottom border.
 *
 * The underlying component is Chakra's `Flex`.
 */
export const CardHeader = forwardRef((props: FlexProps, ref) => {
  const styles = useStyles()
  return <Flex as="header" __css={styles.header} ref={ref} {...props} />
})

CardHeader.displayName = 'CardHeader'

/**
 * Card Footer
 *
 * The footer for a `Card`. It renders a space at the bottom with a top border.
 *
 * The underlying component is Chakra's `Flex`.
 */
export const CardFooter = forwardRef((props: FlexProps, ref) => {
  const styles = useStyles()
  return <Flex as="footer" __css={styles.footer} ref={ref} {...props} />
})

CardFooter.displayName = 'CardFooter'

/**
 * Card Header Title
 *
 * The heading text for a `Card`. Use this inside of `CardHeader`.
 *
 * The underlying component is Chakra's `Heading`.
 */
export const CardHeading = forwardRef((props: HeadingProps, ref) => (
  <Heading textStyle="heading3Semi" flexGrow={1} ref={ref} {...props} />
))

CardHeading.displayName = 'CardHeading'

/**
 * Card Header with Link Button
 *
 * An action button for a `Card`. Use this inside of `CardHeader`. The action button will be a link
 * button with the ability to have a click event. It will automatically push itself
 * to the right side of the header.
 *
 * The underlying element is Chakra's `Button`.
 */
export const CardActionButton = forwardRef((props: ButtonProps, ref) => (
  <Button variant="inlineLink" ref={ref} {...props} />
))

CardActionButton.displayName = 'CardActionButton'

/**
 * Card Header with Link
 *
 * An action link for a `Card`. Use this inside of `CardHeader`. The action link will look exactly like
 * the action link button but give the ability to act as a link with a href. It will automatically push
 * itself to the right side of the header.
 *
 * The underlying element is Chakra's `Link`.
 */
export const CardActionLink = forwardRef((props: LinkProps, ref) => <Link variant="link" ref={ref} {...props} />)

CardActionLink.displayName = 'CardActionLink'

/**
 * Card Header with Icon
 *
 * An action icon for a `Card`. Use this inside of `CardHeader`. It will automatically push itself to the right
 * side of the header.
 *
 * The underlying element is Chakra's `IconButton`. Use the `icon` prop to set the
 * icon. You must provide an `aria-label`.
 *
 * Note: We are no longer using Material UI icons. Please refer to our custom icons:
 * https://storybook.beta.vndly.com/?path=/docs/foundations-icons-icons--page
 */
export const CardActionIcon = forwardRef(({ 'aria-label': ariaLabel, ...props }: IconButtonProps, ref) => (
  <Tooltip aria-label={ariaLabel} label={ariaLabel}>
    <IconButton
      aria-label={ariaLabel}
      variant="inlineGray"
      size="md"
      fontSize="1.5rem"
      color="gray.600"
      cursor="pointer"
      _hover={{ color: 'gray.900' }}
      my="-8px"
      ml={2}
      _last={{ mr: '-8px' }}
      ref={ref}
      {...props}
    />
  </Tooltip>
))

CardActionIcon.displayName = 'CardActionIcon'

/**
 * Card Header with Checkbox
 *
 * An action checkbox for a `Card`. Use this inside of `CardHeader`. The action checkbox will be our standard
 * checkbox with the ability to have an isChecked and onChange event. It will automatically push itself to the
 * right side of the header.
 *
 * The underlying element is Chakra's `Checkbox`.
 */
export const CardActionCheckbox = forwardRef((props, ref) => <Checkbox ref={ref} {...props} />)

CardActionCheckbox.displayName = 'CardActionCheckbox'

/**
 * Card Body
 *
 * The body of a `Card`.
 *
 * It renders with a default padding. For use with lists, accordions, and other
 * components, pass `p={0}` in its props.
 *
 * The underlying element is Chakra's `Box`.
 */
export const CardBody = forwardRef<BoxProps, 'div'>((props: BoxProps, ref) => {
  const styles = useStyles()
  return <Box ref={ref} __css={styles.body} {...props} />
})

CardBody.displayName = 'CardBody'
