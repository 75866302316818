/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useRef } from 'react'
import type { GroupBase, Props } from 'react-select'
import Select, { ClearIndicatorProps, components, DropdownIndicatorProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { InputProps } from 'react-select/dist/declarations/src/components/Input'
import { useMsg } from '../../../common/localizationUtils'
import { isClient } from '../../../common/nextJsUtils'
import { TOP_NAV_HEIGHT } from '../../../common/scrollUtils'
import { CaretDown, Close } from '../../../icons'
import { useSelectStyle, Variants } from '../../../theme/components/ReactSelect'
import { forwardRef, useFormControlContext } from '../../chakra'
import { useFormControlExtendedContext } from './FormControlExtendedContext'

type ReactSelectProps = {
  variant?: Variants
  isCreatable?: boolean
  creatable?: boolean
  id?: string
  menuPortal?: boolean
}

/**
 * ReactSelect
 *
 * Select component using the React-Select library.
 *
 * Note: This component will eventually replace the VndlySelect component.
 */
export const ReactSelect = forwardRef(
  <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    {
      variant = 'base',
      creatable = false,
      isCreatable = creatable,
      placeholder,
      menuPortal = true,
      ...props
    }: Props<Option, IsMulti, Group> & ReactSelectProps,
    ref: any
  ) => {
    const msg = useMsg()
    const { isDisabled, isReadOnly, isInvalid, id: formControlId } = useFormControlContext() ?? {}
    const id = props.id ?? formControlId
    const closeMenuProps = useCloseMenu(id, props)

    const selectProps: Props<Option, IsMulti, Group> = {
      placeholder: placeholder === undefined ? msg('common.ui.select_placeholder') : placeholder,
      menuPlacement: 'auto',
      ...(menuPortal && isClient() && { menuPortalTarget: document.body }),
      isClearable: !!props.isMulti,
      'aria-errormessage': `${id}-feedback`,
      isDisabled: isDisabled || props.isDisabled,
      ...(isInvalid && { 'aria-invalid': isInvalid }),
      menuIsOpen: isReadOnly ? false : undefined,
      ...props,
      styles: useSelectStyle(variant),
      components: { Input, DropdownIndicator, ClearIndicator },
      isSearchable: isReadOnly ? false : props.isSearchable,
      inputId: id,
      instanceId: id,
      ...closeMenuProps
    }

    return (
      <div data-react-select>
        {isCreatable || creatable ? (
          <CreatableSelect ref={ref} {...selectProps} />
        ) : (
          <Select ref={ref} {...selectProps} />
        )}
      </div>
    )
  }
)

ReactSelect.displayName = 'ReactSelect'

const Input = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: InputProps<Option, IsMulti, Group>
) => {
  const { getInputProps } = useFormControlExtendedContext()
  return <components.Input {...props} {...getInputProps?.(props)} />
}

const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => (
  <components.DropdownIndicator {...props}>
    <CaretDown />
  </components.DropdownIndicator>
)

const ClearIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
) => (
  <components.ClearIndicator {...props}>
    <Close />
  </components.ClearIndicator>
)

function useCloseMenu<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  id: string,
  props: Props<Option, IsMulti, Group>
) {
  const isMenuOpenRef = useRef(false)
  return {
    closeMenuOnScroll: (event: any) => {
      // If closeMenuOnScroll is true then close the menu when onScroll is triggered.
      // Don't close if scrolling occurs within the select box (horizontal scrolling)
      // or in the menu list (while scrolling through items).
      const select = event.target.closest?.('[data-react-select]')
      const menuList = event.target.closest?.(`[react-select-${id}-listbox]`)
      if (props.closeMenuOnScroll && !select && !menuList) return true

      // If the menu isn't open, don't perform any calculations
      if (!isMenuOpenRef.current) return false

      // Grab the menu list element and calculate it's top position
      if (!menuList) return false

      // If top of menu list is above the bottom of the header, then close the menu
      const top = menuList.getBoundingClientRect().top
      if (top < TOP_NAV_HEIGHT) return true
      return false
    },
    // These two functions keep track of when the menu is open or not
    onMenuOpen: useCallback((...args: []) => {
      isMenuOpenRef.current = true
      return props.onMenuOpen?.(...args)
    }, []),
    onMenuClose: useCallback((...args: []) => {
      isMenuOpenRef.current = false
      return props.onMenuClose?.(...args)
    }, [])
  }
}
