import { colors } from '@workday/canvas-colors-web'
import { textStyles } from '../text-styles'

const expandedButtonStyles = {
  color: 'var(--vndly-color-container-dark) !important',
  bg: 'soap300',
  '.menu-button__icon': {
    color: 'licorice500'
  }
}
const variantMainMenu = {
  button: {
    ...textStyles.bodySmallSemi,
    display: 'flex',
    alignItems: 'center',
    color: 'blackPepper300',
    py: 3,
    px: 3,
    rounded: 4,
    _hover: {
      ...expandedButtonStyles
    },
    _focus: {
      boxShadow: 'none'
    },
    _focusVisible: {
      boxShadow: 'none',
      '&.--is-keyboard-usage': {
        ...expandedButtonStyles,
        boxShadow: 'outline'
      }
    },
    _expanded: {
      // Only give buttons a flat bottom, because they open a menu
      // Top level menu buttons that do not open a menu use the `<a>` tag
      'button&': {
        roundedBottom: 0
      },
      ...expandedButtonStyles,
      '.chevron-icon': {
        color: 'licorice500',
        transform: 'rotate(-180deg)'
      }
    },
    transition: 'background .1s ease-out',
    // Menu Button Left Icon
    '.menu-button__icon': {
      color: 'licorice200',
      w: 5,
      h: 5,
      mr: { base: 2, lg: 1 },
      position: 'relative',
      top: '-1px'
    },
    // Menu Button Right Chevron Icon
    '.chevron-icon': {
      w: 4,
      h: 4,
      ml: 1,
      transition: 'transform .3s'
    }
  },
  list: {
    bg: 'frenchVanilla100',
    borderColor: 'soap500',
    roundedTopLeft: 0,
    boxShadow: 'xl'
  },
  item: {
    color: 'blackPepper300',
    fontWeight: 'normal',
    fontSize: 'sm',
    // Menu Item Left Icon
    '.chakra-menu__icon': { color: 'licorice200', w: 5, h: 5 },
    _hover: {
      textDecoration: 'none',
      boxShadow: 'none',
      bg: 'soap300',
      color: 'var(--vndly-color-container-dark)  !important',
      // Menu Item Left Icon
      '.chakra-menu__icon': { color: 'licorice500' }
    },
    _focusVisible: {
      '.--is-keyboard-usage &': {
        boxShadow: 'outline'
      }
    },
    _focus: {
      color: 'blackPepper300'
    }
  }
}

const variantProfileMenu = {
  button: {
    '&[data-is-impersonated="true"]': {
      background: {
        base: 'cinnamon600',
        sm: `repeating-linear-gradient(120deg, ${colors.blackPepper400}, ${colors.blackPepper400} 10px, ${colors.cinnamon600} 10px, ${colors.cinnamon600} 20px)`
      },
      color: 'frenchVanilla100',
      '.profile-button__label': {
        color: 'frenchVanilla100',
        // Creates a soft red outline around the text to make it more readable
        textShadow:
          '-2px -2px 1px #a31c12, 2px -2px 1px #a31c12, -2px 2px 1px #a31c12, 2px 2px 1px #a31c12, 0px -2px 2px #a31c12, 0px 2px 2px #a31c12, -2px 0px 2px #a31c12, 2px 0px 2px #a31c12;'
      },
      '.chevron-icon': {
        color: 'frenchVanilla100'
      },
      '.profile-avatar-container': {
        borderColor: 'blackPepper400',
        boxShadow: { base: 'none', lg: `0 0 0 3px ${colors.cinnamon600}` }
      }
    },
    rounded: 4,
    px: 0,
    display: 'flex',
    alignItems: 'center',
    color: 'blackPepper300',
    borderWidth: '6px 0 6px 0',
    borderColor: 'transparent',
    boxSizing: 'content-box',
    gridGap: 2,
    '.chakra-button__icon': { m: 0 },
    '.chevron-icon': {
      color: 'licorice200',
      w: 4,
      h: 4,
      display: { base: 'none', sm: 'block' },
      transition: 'transform .3s'
    },
    _focus: {
      boxShadow: 'none',
      '.--is-keyboard-usage &': {
        boxShadow: 'outline'
      }
    },
    _expanded: {
      '.chevron-icon': {
        transform: 'rotate(-180deg)'
      }
    },
    '.profile-button__label': {
      ...textStyles.bodySmallSemi,
      color: 'blackPepper300',
      maxW: '17ch',
      m: 0
    },
    '.profile-avatar-container': {
      bg: 'soap200',
      border: '4px solid',
      borderColor: 'licorice200',
      transform: 'scale(.8)',
      position: 'relative',
      zIndex: 1
    },
    '.profile-avatar': {
      color: 'licorice200'
    },
    '@media (min-width: 0px) and (max-width: 600px)': { '.profile-button__label': { display: 'none' } },
    '@media (min-width: 1350px) and (max-width: 1450px)': { '.profile-button__label': { display: 'none' } }
  },
  list: {
    ...variantMainMenu.list,
    rounded: 4,
    roundedTopLeft: 4
  },
  item: {
    ...variantMainMenu.item
  }
}

const variantNavigationSubMenu = {
  button: variantProfileMenu.item,
  list: {
    boxShadow: '2xl'
  },
  item: variantProfileMenu.item
}

const variantOverflowMenu = {
  button: {
    color: 'gray.500',
    mb: '0',
    py: '4',
    px: '2',
    _hover: {
      color: 'gray.800'
    }
  },
  list: {
    borderRadius: 'base',
    zIndex: '10'
  },
  item: {
    p: 0,
    'a, button': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-start',
      p: '2',
      _selected: {
        bgColor: 'gray.200'
      },
      _hover: {
        border: 'none'
      }
    },
    _hover: {
      bgColor: 'gray.100'
    }
  }
}

export const Menu = {
  baseStyle: {
    item: {
      py: 2,
      px: 4,
      '&.delete-menu-item': {
        color: 'var(--vndly-color-link-delete)',
        justifyContent: 'flex-start',
        fontSize: 'var(--vndly-font-size-md)',
        fontWeight: 'var(--vndly-font-weight-semibold)',
        lineHeight: 'base'
      },
      _active: {
        borderColor: 'var(--vndly-color-container-border-focused)',
        borderWidth: '1px'
      }
    }
  },
  variants: {
    mainMenu: variantMainMenu,
    profileMenu: variantProfileMenu,
    navigationSubMenu: variantNavigationSubMenu,
    overflowMenu: variantOverflowMenu
  }
}
