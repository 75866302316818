import { lazyMsg } from '~/common/localizationUtils'

export const SOW_WORKERS_PER_PAGE = 10

export const TEAM_HOME_PATH = '/team'
export const TEAM_WORKERS_PATH = '/team/workers'
export const TEAM_WORKERS_CHECKLISTS_PATH = '/team/workers/:candidateId/job/:jobId'

export const TEAM_HOME_DEFAULT_STATUS_FILTERS = '?status=1%2C4'
export const TEAM_WORKERS_DEFAULT_STATUS_FILTERS = '?status=7%2C8%2C25%2C29%2C6%2C23'
export const TEAM_WORKERS_CHECKLISTS_DEFAULT_STATUS_FILTERS = '?search=&status=1%2C2%2C3%2C4%2C5%2Cexpire_soon'

export const useSharedFilters = (sowId: number, sowVersionId: number, refreshKey: string | undefined = '') => [
  {
    id: 'job_template',
    title: lazyMsg('term.role'),
    type: 'select',
    listType: 'number',
    defaultIsOpen: true,
    config: { containerMaxHeight: 600 },
    optionsFromApi: {
      preload: true,
      pageSize: 100,
      searchParamKey: 'title',
      url: `/api/v2/sow-module/sow/${sowId}/version/${sowVersionId}/job-templates/`,
      queries: ['title={{value}}', `filterRefreshKey=${refreshKey}`],
      transform: '[$.results.{"value": id, "label": title, "id": id.$string()}]'
    }
  }
]

export const getJobDetailsOrDraftHref = (job_id: number, sow_version_id: string): string => {
  return `/jobs/job_details/${job_id}?sow_version=${sow_version_id}`
}
