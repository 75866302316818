import PropTypes from 'prop-types'
import { isValidElement } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useDisclosure
} from '~/design_system'
import { isDevelopment } from '~/components/jsonschema/VndlyJsonSchemaFormBase/actions/utils'
import { SadRefresh, SadSearch, LockedGhost, HappyGhost } from '~/vnmoji'

/**
 * Example Usage:
 * Each state has a `when` predicate and a `render` method.
 *
 * <SadStates
    states={[
      {
        when: () => !selection,
        render: () => <p>😞 Please make a selection</p>
      },
      { when: () => !data,
        render: () => <p>😞 There is no data for this selection</p>
      }
    ]}>
      <p>😄 There is data for this selection!</p>
    </SadStates>
    @param {{
      states: { when: boolean | () => boolean, render: () => any }[]
      children: React.ReactNode | (a: any) => React.ReactNode
    }} props
 */
export const SadStates = ({ states = [], children = null, ...props }) => {
  const sadState = states.find(c => (typeof c.when === 'function' ? c.when() : c.when))

  if (sadState) {
    return sadState.render()
  }

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <PageErrorStateWithRefresh error={error} onRefresh={resetErrorBoundary} />
      )}
      {...props}>
      {typeof children === 'function' ? children() : children}
    </ErrorBoundary>
  )
}

SadStates.propTypes = {
  states: PropTypes.arrayOf(
    PropTypes.shape({
      when: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      render: PropTypes.func.isRequired
    })
  )
}

SadStates.defaultProps = {
  states: []
}

const spinnerConfig = {
  small: {
    size: 'md',
    thickness: 2
  },
  large: {
    size: 'xl',
    thickness: 4
  },
  default: {
    size: 'lg',
    thickness: 3
  }
}

export const LoadingState = ({ size: spinnerSize }) => {
  const { size, thickness } = spinnerConfig[spinnerSize] || spinnerConfig.default
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Spinner color="brand" thickness={thickness} speed="0.5s" size={size} />
    </Flex>
  )
}

export const SectionLoadingState = () => <LoadingState size="small" />

export const PageSadStateBox = props => (
  <Stack textAlign="center" bg="var(--vndly-color-container-background-subdued)" padding={6} mt={4} {...props} />
)
export const PageSadStateDefaultIcon = props => (
  <Box w="75px" h="75px" mx="auto" color="var(--vndly-color-container-border)" {...props} />
)
export const PageSadStateHeading = props => <Heading as="h3" textStyle="heading3Semi" {...props} />

/**
 * @deprecated This component has been replaced by our new NoSearchResults component as part of the DS,
 * please reference https://storybook.beta.vndly.com/?path=/docs/components-indicator-empty-states--docs#no-results
 *
 * @param {{
 *    heading: React.ReactNode,
 *    content?: React.ReactNode | null,
 *    icon?: any,
 *    happy?: boolean,
 * } | import('@chakra-ui/react').StackProps} props
 */
export const PageNoResultsState = ({ heading, content = null, icon = null, happy = false, ...rest }) => (
  <PageSadStateBox {...rest}>
    {(() => {
      if (isValidElement(icon)) return icon
      if (icon) return <PageSadStateDefaultIcon as={icon} />
      if (happy) return <Box as={HappyGhost} mx="auto" w="50%" h="50%" />
      return <Box as={SadSearch} mx="auto" w="50%" h="50%" />
    })()}
    <PageSadStateHeading>{heading}</PageSadStateHeading>
    <Text as="div">{content}</Text>
  </PageSadStateBox>
)

/**
 * @deprecated This component has been replaced by our new RefreshError component as part of the DS,
 * please reference https://storybook.beta.vndly.com/?path=/docs/components-indicator-empty-states--docs#refresh-or-error
 */
export const PageErrorState = ({ message, icon = null, iconSize = 'lg', raw = null, ...rest }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isDevelopment() })
  return (
    <>
      <Alert status="error" justifyContent="center" {...rest}>
        {icon ? (
          <Box as={icon} fontSize={iconSize} color="var(--vndly-color-container-icon-danger)" mr={3} />
        ) : (
          <AlertIcon onClick={onToggle} />
        )}
        {message}
      </Alert>
      {raw && (
        // If additional `raw` error details are provided they will be shown
        // in development, or can be viewed in production by clicking the AlertIcon
        <Collapse in={isOpen}>
          <Alert status="error">
            <Box
              as="pre"
              display="block"
              whiteSpace="pre-wrap"
              w="full"
              bg="gray.100"
              p={3}
              rounded={2}
              border="1px"
              borderColor="red.200"
              fontSize="sm">
              {raw}
            </Box>
          </Alert>
        </Collapse>
      )}
    </>
  )
}

/**
 *
 * @deprecated This component has been replaced by our new RefreshError component as part of the DS,
 * please reference https://storybook.beta.vndly.com/?path=/docs/components-indicator-empty-states--docs#refresh-or-error
 *
 * @param {Object} props
 * @param {false | () => void} [props.onRefresh] callback function to refetch/refresh the response
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {Error | string | null} [props.error]
 */
export const PageErrorStateWithRefresh = ({ onRefresh, error, width = '50%', height = '50%' }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isDevelopment() && error })
  return (
    <Stack p={4} role="alert">
      <Box as={SadRefresh} width={width} height={height} mx="auto" />
      <Stack spacing={4} textAlign="center">
        <Text w={300} mx="auto" textStyle="bodySemi">
          Content failed to load. If this problem persists contact support.
        </Text>
        <Stack isInline justifyContent="center">
          {onRefresh && (
            <Button variant="link" onClick={onRefresh}>
              Refresh
            </Button>
          )}
          {isDevelopment() && error && (
            <Button variant="link" onClick={onToggle}>
              Details
            </Button>
          )}
        </Stack>
      </Stack>
      <Collapse in={isOpen}>
        <Stack as="pre" whiteSpace="pre-wrap" w="full" bg="gray.100" p={3} fontSize="sm" mt={4}>
          {typeof error === 'string' ? (
            error
          ) : (
            <>
              <Box>{error?.message}</Box>
              <Box>{error?.stack}</Box>
            </>
          )}
        </Stack>
      </Collapse>
    </Stack>
  )
}

/**
 * @deprecated This component has been replaced by our new LockedOut component as part of the DS,
 * please reference https://storybook.beta.vndly.com/?path=/docs/components-indicator-empty-states--docs#locked-out
 */
export const PageNoAccessState = ({ heading, content = '', icon = null, ...rest }) => (
  <PageSadStateBox {...rest}>
    {icon ? <PageSadStateDefaultIcon as={icon} /> : <Box as={LockedGhost} mx="auto" w="50%" h="50%" />}
    <PageSadStateHeading as="h3" fontSize="xl">
      {heading}
    </PageSadStateHeading>
    <Text>{content}</Text>
  </PageSadStateBox>
)
