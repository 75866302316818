import { useEffect, useState } from 'react'
import { isClient, isServer } from './nextJsUtils'

/**
 * Will auto focus the element the first time it renders.
 * The element must be focusable, such as an input or have tabIndex="0".
 *
 * @example
 * // Here the error message will become focused when it's shown
 * <label ref={ autoFocusRef() } tabIndex="0">
 *   Error
 * </label>
 *
 * @param [ref] {Function} You can pass your own ref as a param if you need to do additional things with the ref element, e.g.
 *         const divRef = useRef()
 *         <div ref={autoFocusRef(divRef)} />
 *
 * @returns {Function} A ref function that receives the ref element
 */
export const autoFocusRef = ref => el => {
  if (el && el.dataset && !el.dataset.autoFocused) {
    // eslint-disable-next-line no-param-reassign
    el.dataset.autoFocused = true
    el.focus()
  }
  if (typeof ref === 'function') {
    ref(el)
  }
}

export const isInViewport = elem => {
  if (!elem) return false
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const getElIndexInParent = el => (el ? Array.from(el?.parentElement?.children ?? []).indexOf(el) : undefined)

export const dataAttr = condition => (condition ? '' : undefined)

export const ariaAttr = condition => (condition ? true : undefined)

/**
 * Get an element's content's width (just contents, no padding, border, etc)
 * @param element
 * @returns {number}
 */
export function getContentWidth(element, fallback = 0) {
  if (isServer() || !element) return fallback
  const styles = window.getComputedStyle(element)
  return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)
}

export function preloadImage(src) {
  if (src && isClient()) new window.Image().src = src
}

/**
 * A Hook that tells you if the user is using a mouse or a keyboard (their "modality"). Great for changing style
 * or behavior based on the user's modality. For example, you might only have a focus outline while using keyboard.
 *
 * @returns {{modality: 'mouse' | 'keyboard', isUsingKeyboard: boolean, isUsingMouse: boolean}}
 */
export function useInputModality() {
  const [modality, setModality] = useState('mouse')

  useEffect(() => {
    function onMouseMove() {
      setModality('mouse')
      clearAll()
      document.addEventListener('keydown', onKeyDown)
    }

    function onKeyDown(e) {
      const NAVIGATION_KEYS = ['Tab', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown']
      if (!NAVIGATION_KEYS.includes(e.key)) return
      setModality('keyboard')
      clearAll()
      document.addEventListener('mousemove', onMouseMove)
    }

    function clearAll() {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('keydown', onKeyDown)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('keydown', onKeyDown)

    return clearAll
  }, [])

  return { modality, isUsingMouse: modality === 'mouse', isUsingKeyboard: modality === 'keyboard' }
}

/**
 * A util that, provided a dom element, an attr name, and a callback, will call the callback everytime that
 * attribute on the element changes. The callback receives the new value.
 *
 * You can also pass a parent element and listen for that attribute to change on all nested nodes within, just pass
 * { subtree: true } as the fourth param. In this case you'll receive the target element, who's attribute changed
 * as the second param of your callback.
 *
 * @param el {HTMLElement}
 * @param attrName {string}
 * @param cb {(value: unknown, targetEl: HTMLElement, mutation: MutationRecord) => void}
 * @param opts {Object}
 * @param opts.subtree {true}
 *
 * @returns {function(): void}
 */
export function onAttributeChange(el, attrName, cb, opts = {}) {
  const mutationCallback = mutationsList => {
    mutationsList.forEach(m => {
      if (m.type === 'attributes' && m.attributeName === attrName) {
        cb(m.target.getAttribute(attrName), m.target, m)
      }
    })
  }
  const observer = new window.MutationObserver(mutationCallback)
  observer.observe(el, { attributes: true, subtree: opts.subtree })
  return () => observer.disconnect()
}
