import React, { useEffect, useState } from 'react'
import {
  Divider,
  Flex,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  DotIndicator,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FilterTab,
  FilterTabs,
  HStack,
  IconWithNotification,
  Link,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure
} from '~/design_system'
import asJqueryPlugin from '../common/jqueryUtils'
import { ChakraWrapper } from '~/components/ChakraWrapper'
import { Pin1, Tasks } from '~/icons'
import { PageViewType, useMyTasksQuery } from '~/api/myTasks'
import { useCategorizedTasks } from '~/user_tasks/components/utils'
import { PageErrorState, PageNoResultsState, SadStates } from '~/components/SadStates'
import { LoadingSkeletonState } from '~/components/v2/ResultsLayout/LoadingSkeleton'
import { Msg, useMsg } from '~/common/localizationUtils'
import { UserTask, UserTaskStatus } from '~/user_tasks/types'
import { CATEGORIES_ICON_MAP, UserTaskPageTab } from './constants'
import { TaskDrawerList } from './components/TasksDrawerList'

const clearBGStyle = { boxShadow: 'none !important', background: 'none !important' }

const UserTasksDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [pageView, setPageView] = useState<PageViewType>(UserTaskPageTab.MY_TASKS)
  const [showTaskNotiBubble, setShowTaskNotiBubble] = useState<boolean>(false)
  const msg = useMsg()

  const queryParams = {
    offset: 0,
    view: pageView,
    status: [UserTaskStatus.Pending, UserTaskStatus.Overdue]
  }

  const { data = { results: [], count: 0 }, isLoading, isError } = useMyTasksQuery(queryParams)
  const { results: tasks } = data

  useEffect(() => {
    if (pageView === UserTaskPageTab.MY_TASKS && data?.count > 0) {
      setShowTaskNotiBubble(true)
    } else if (pageView === UserTaskPageTab.MY_TASKS && data?.count === 0) {
      setShowTaskNotiBubble(false)
    }
  }, [data])

  const categorizedTasks = useCategorizedTasks(tasks)

  const handleOpen = () => {
    // @ts-ignore
    window.setHeaderIFrameFullHeight?.()
    onOpen()
  }
  const handleClose = () => {
    // @ts-ignore
    window.setHeaderIFrameHeight?.(60, 100)()
    onClose()
  }

  const taskListURL = `/tasks/list?status=${UserTaskStatus.Pending},${UserTaskStatus.Overdue}`

  const renderTabBody = () => {
    return (
      <SadStates
        states={[
          { when: isLoading, render: () => <LoadingSkeletonState /> },
          {
            when: !isLoading && isError,
            render: () => (
              // @ts-ignore
              <PageErrorState message="There was an error processing your request. Please try again later." />
            )
          },
          {
            when: !isLoading && (data == null || data?.results?.length === 0),
            render: () => (
              <PageNoResultsState
                background="white"
                heading={msg('common.ui.results', { count: 0 })}
                content={msg('no_results_card.check_your_categories')}
              />
            )
          }
        ]}>
        <Accordion allowToggle allowMultiple defaultIndex={[0]}>
          {Object.entries(categorizedTasks).map(([categoryName, tasks]) => (
            <UserTaskAccordionItem categoryName={categoryName} tasks={tasks} key={categoryName} />
          ))}
        </Accordion>
      </SadStates>
    )
  }

  return (
    <>
      <Button size="md" onClick={handleOpen} variant="mainMenu" data-testid="global-nav-tasks-btn">
        <IconWithNotification>
          <Tasks w={5} h={5} />
          {showTaskNotiBubble && <DotIndicator />}
        </IconWithNotification>
        <Text
          pl={2}
          textStyle="bodySmallSemi"
          color="inherit"
          id="task-header-icon-label"
          sx={{
            '@media (min-width: 1350px) and (max-width: 1530px)': { display: 'none' },
            '@media (min-width: 0px) and (max-width: 600px)': { display: 'none' }
          }}>
          {msg('term.task', { count: 99 })}
        </Text>
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton {...clearBGStyle} />
          <DrawerHeader>{msg('term.task', { count: 99 })}</DrawerHeader>
          <DrawerBody pb="0">
            <Stack>
              <Flex position="relative" justifyContent="center">
                <FilterTabs value={pageView} onChange={(value: PageViewType) => setPageView(value)}>
                  <FilterTab value={UserTaskPageTab.MY_TASKS} data-testid="tasks-settings-mini-tab-my-tasks-btn">
                    <Msg id="tasks.drawer.my_tasks.tab" />
                  </FilterTab>
                  <FilterTab value={UserTaskPageTab.ALL} data-testid="tasks-settings-mini-tab-all-btn">
                    <Msg id="common.ui.all" />
                  </FilterTab>
                </FilterTabs>
                <Link
                  variant="link"
                  href={taskListURL}
                  _focus={{ boxShadow: 'none' }}
                  target="_top"
                  position="absolute"
                  right="0"
                  display="inline-grid"
                  py="2"
                  data-testid="global-task-drawer-close-link">
                  {msg('tasks.drawer.task_list.link')}
                </Link>
              </Flex>
              <Divider />
              <Tabs index={pageView === UserTaskPageTab.MY_TASKS ? 0 : 1} defaultIndex={0}>
                <TabPanels>
                  <TabPanel data-testid="taskdrawer-tabpanel-mytasks" pt="4" px="0">
                    {renderTabBody()}
                  </TabPanel>
                  <TabPanel data-testid="taskdrawer-tabpanel-alltasks" pt="4" px="0">
                    {renderTabBody()}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="secondary" onClick={handleClose} data-testid="global-task-drawer-close-btn">
              {msg('common.ui.close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const UserTaskAccordionItem = ({ categoryName, tasks }: { categoryName: string; tasks: UserTask[] }) => {
  const Icon = CATEGORIES_ICON_MAP[categoryName] ?? Pin1
  return (
    <AccordionItem _first={{ borderTopWidth: 0 }} _last={{ borderBottomWidth: 0 }}>
      <AccordionButton justifyContent="space-between" _focus={{ outline: 0 }} py={4}>
        <HStack>
          <Icon />
          <Text textStyle="bodySemi">{categoryName}</Text>
        </HStack>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={0} _last={{ borderBottomWidth: 0 }}>
        <TaskDrawerList categoryName={categoryName} taskItems={tasks} />
      </AccordionPanel>
    </AccordionItem>
  )
}

export default UserTasksDrawer

asJqueryPlugin('UserTasksDrawer', () => (
  <ChakraWrapper>
    <UserTasksDrawer />
  </ChakraWrapper>
))
