import { layoutPropNames } from '@chakra-ui/system'
import { callAll, split } from '@chakra-ui/utils'
import cx from 'classnames'
import { pick } from 'lodash'
import { ChangeEvent, ForwardedRef, ReactNode, useState } from 'react'
import { Msg } from '../../../common/localizationUtils'
import useUuid from '../../../hooks/useUuid'
import { Warning } from '../../../icons'
import {
  Box,
  BoxProps,
  chakra,
  Checkbox,
  CheckboxProps,
  FormErrorMessageProps,
  forwardRef,
  HStack,
  omitThemingProps,
  Radio,
  RadioProps,
  Switch,
  SwitchProps,
  Text,
  TextProps,
  Tooltip,
  useMultiStyleConfig,
  useRadio,
  useRadioGroupContext
} from '../../chakra'
import { useFormControlExtendedContext } from './FormControlExtendedContext'

/**
 * Form Checkbox
 */
export const FormCheckbox = forwardRef(({ children, ...props }: CheckboxProps, ref) => (
  <Checkbox alignItems="flex-start" ref={ref} {...props}>
    {children}
  </Checkbox>
))

FormCheckbox.displayName = 'FormCheckbox'

/**
 * Form Radio
 */
export const FormRadio = forwardRef(({ children, ...props }: RadioProps, ref) => (
  <Radio alignItems="flex-start" ref={ref} {...props}>
    {children}
  </Radio>
))

FormRadio.displayName = 'FormRadio'

/**
 * Form Switch
 *
 * This switch includes an On/Off label.
 */
export const FormSwitch = forwardRef(
  ({ isChecked: isCheckedProp, defaultChecked, onChange, ...props }: SwitchProps, ref) => {
    const isControlled = isCheckedProp !== undefined
    const [isCheckedState, setIsCheckedState] = useState(defaultChecked)
    const isChecked = isControlled ? isCheckedProp : isCheckedState
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!isControlled) setIsCheckedState(e.target.checked)
      onChange?.(e)
    }
    return (
      <HStack alignItems="center">
        <Switch ref={ref} isChecked={isChecked} onChange={handleChange} {...props} />
        <Text textStyle="subtextSmall">{isChecked ? <Msg id="common.ui.on" /> : <Msg id="common.ui.off" />}</Text>
      </HStack>
    )
  }
)

FormSwitch.displayName = 'FormSwitch'

/**
 * Grouping Element: Form Section
 *
 * Forms often work best when broken into sections, grouping form elements according to their purpose –
 * if it were an order page you might put items in one group, customization in another, and delivery
 * in a third.
 */
export const FormSection = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Box p={4} textStyle="sectionHeading" bg="gray.300" ref={ref} {...props}>
    {children}
  </Box>
))

FormSection.displayName = 'FormSection'

/**
 * Grouping Element: Fieldset
 *
 * A fieldset is a tag you can use to semantically group fields in a form. You can then label that fieldset
 * with a legend. Depending on how the browser accessing your content works, this could increase accessibility
 * (for instance, a screen reader could indicate that the following section of the form is for alternate
 * shipping addresses, and that would let the visitor know they could possibly skip that section).
 * https://www.w3.org/TR/WCAG10-HTML-TECHS/#forms
 *
 */
export const Fieldset = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Box as="fieldset" border="1px solid" p={4} pt={2} borderRadius="md" borderColor="gray.300" ref={ref} {...props}>
    {children}
  </Box>
))

Fieldset.displayName = 'Fieldset'

export const Legend = forwardRef((props: TextProps, ref) => (
  <Text as="legend" px={1} mx={-1} textStyle="bodySmall" bg="white" w="auto" border="none" ref={ref} {...props} />
))

Legend.displayName = 'Legend'

/**
 * Form Warning Message
 *
 * Warning messages help users diagnose and fix non-blocking issues with a users input.  Warning states should not
 * be activated until after a user has interacted with the form control.
 *
 * Meant to be used in conjunction with the `isWarned` prop on the `FormControl` component.
 *
 * This is a component VNDLY created that expands FormControl's capabilities. Core Chakra UI does not provide this
 * functionality it is added by us.
 *
 * Code copied and modified from the FormErrorMessage component code in the chakra-ui source.
 *
 * @example
 * <FormControl isWarned>
 *   <FormLabel>Label</FormLabel>
 *   <Input />
 *   <FormWarningMessage>Warning message here</FormWarningMessage>
 * </FormControl>
 */
export const FormWarningMessage = forwardRef<Omit<FormErrorMessageProps, 'textStyle'>, 'div'>(
  ({ children, ...props }, ref) => {
    const styles = useMultiStyleConfig('FormError', props)
    const ownProps = omitThemingProps(props)
    const warningContext = useFormControlExtendedContext()

    if (!warningContext?.isWarned) return null

    return (
      <chakra.div
        {...warningContext.getWarningMessageProps?.(ownProps, ref)}
        className={cx('chakra-form__warning-message', props.className)}
        __css={styles.warningText}>
        <Warning __css={styles.warningIcon} aria-label="Form warning message icon" />
        <Msg id="common.ui.warning_with_message" message={children} />
      </chakra.div>
    )
  }
)

FormWarningMessage.displayName = 'FormWarningMessage'

/**
 * Use this hook to get the props for a custom styled Radio component using a variant added to the theme/Radio.js
 *
 * When defining your custom radio use the `customLabel` and `customControl` keys in the theme/Radio.js file to
 * define the styles for the radio button. These keys won't inherit all the default styles for the Radio component.
 *
 * @example
 * export const CustomRadio = forwardRef<RadioProps, 'input'>((props, ref) => {
 *   const { rootProps, inputProps, checkboxProps } = useCustomRadio({
 *     ...props,
 *     variant: 'myCustomVariant' // Point this to your custom variant in the theme/Radio.js file
 *   }, ref)
 *
 *   // This is likely all the html you'll need unless you are adding extra dom elements
 *   return (
 *     <chakra.label {...rootProps}>
 *       <chakra.input {...inputProps} />
 *       <chakra.div {...checkboxProps} />
 *     </chakra.label>
 *   )
 * })
 */
export function useCustomRadio(props: RadioProps, ref: ForwardedRef<'input'>) {
  const group = useRadioGroupContext()
  const checkboxId = useUuid()

  const { onChange: onChangeProp, value: valueProp } = props

  const styles = useMultiStyleConfig('Radio', { variant: props.variant, ...props })

  const ownProps = omitThemingProps(props)

  const {
    children,
    isFullWidth,
    isDisabled = group?.isDisabled,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
    ...rest
  } = ownProps

  let isChecked = props.isChecked
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp
  }

  let onChange = onChangeProp
  if (group?.onChange && valueProp != null) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const name = props?.name ?? group?.name

  const { state, getInputProps, getCheckboxProps, getRootProps, htmlProps } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name
  })

  const [layoutProps, otherProps] = split(htmlProps, layoutPropNames as any)
  const cbProps = getCheckboxProps(otherProps)
  const cbDataAttrs = pick(cbProps, dataKeys(cbProps))

  const rootProps = {
    ...layoutProps,
    ...getRootProps(),
    // Putting all the data-* props on the root (in addition to the checkbox)
    // To make theme styling easier with _checked, _active, etc
    ...cbDataAttrs,
    __css: styles.customLabel
  }
  const inputProps = { 'aria-labelledby': checkboxId, ...getInputProps(htmlInputProps, ref) }
  const checkboxProps = { id: checkboxId, ...cbProps, __css: styles.customControl, children }
  return { state, checkboxProps, inputProps, rootProps }
}

const dataKeys = (obj: Record<string, any>) => Object.keys(obj).filter(key => key.startsWith('data-'))

export type CustomRadioProps = Omit<RadioProps, 'variant'> &
  Required<Pick<RadioProps, 'variant'>> & { tooltip?: string }

/**
 * Use this component to render a custom radio button from a variant within theme/Radio.js
 *
 * @example
 * export const FilterTab = forwardRef<RadioProps, 'input'>((props, ref) => {
 *   return <CustomRadio ref={ref} variant="filterTab" {...props} />
 * })
 * FilterTab.displayName = "FilterTab"
 */
export const CustomRadio = forwardRef<CustomRadioProps, 'input'>(({ sx, tooltip, ...props }, ref) => {
  const { rootProps, inputProps: _inputProps, checkboxProps } = useCustomRadio(props, ref)

  const inputProps = { ..._inputProps }
  if (tooltip) {
    inputProps['aria-labelledby'] = ''
    inputProps['aria-label'] = tooltip
  }

  return (
    <TooltipOrNoop label={tooltip}>
      <chakra.label {...rootProps} sx={sx}>
        <chakra.input {...inputProps} />
        <chakra.div {...checkboxProps} data-custom-control />
      </chakra.label>
    </TooltipOrNoop>
  )
})

CustomRadio.displayName = 'CustomRadio'

function TooltipOrNoop({ label, children }: { label?: string; children: ReactNode }) {
  if (label) {
    return <Tooltip label={label}>{children}</Tooltip>
  }
  return <>{children}</>
}
