import { extractRefSchema } from 'json-rules-engine-simplified/lib/utils'

export const isDevelopment = () => process.env.NODE_ENV !== 'production'

export const toArray = field => {
  if (Array.isArray(field)) {
    return field
  }
  return [field]
}

export const toError = message => {
  if (isDevelopment()) {
    throw new ReferenceError(message)
  } else {
    // eslint-disable-next-line no-console
    console.error(message)
  }
}

/**
 * Find relevant schema for the field
 * @returns { field: "string", schema: "object" } relevant field and schema
 */
export const findRelSchemaAndField = (field, schema) => {
  const separator = field.indexOf('.')
  if (separator === -1) {
    return { field, schema }
  }

  const parentField = field.substr(0, separator)
  const refSchema = extractRefSchema(parentField, schema)
  if (refSchema) {
    return findRelSchemaAndField(field.substr(separator + 1), refSchema)
  }

  toError(`Failed to retrieve ${refSchema} from schema`)
  return { field, schema }
}

export function findRelUiSchema(field, uiSchema) {
  const separator = field.indexOf('.')
  if (separator === -1) {
    return uiSchema
  }

  const parentField = field.substr(0, separator)
  const refUiSchema = uiSchema[parentField]
  if (!refUiSchema) {
    return uiSchema
  }
  return findRelUiSchema(field.substr(separator + 1), refUiSchema)
}
