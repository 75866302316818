const sizes = {
  sm: {
    container: {
      borderRadius: 'base'
    }
  },
  md: {
    container: {
      borderRadius: 'base'
    }
  },
  lg: {
    container: {
      borderRadius: 'base'
    }
  }
}

export const Tag = {
  sizes
}
