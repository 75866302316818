import { Input } from './Input'

const sizes = {
  lg: Input.sizes.lg.field,
  md: Input.sizes.md.field,
  sm: Input.sizes.sm.field,
  xs: Input.sizes.xs.field
}

export const Textarea = {
  sizes,
  variants: {
    outline: props => Input.variants.outline(props).field
  },
  defaultProps: Input.defaultProps
}
