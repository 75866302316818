import { forwardRef, SimpleGrid, SimpleGridProps, GridItem, GridItemProps } from '../../chakra'
import { Container } from '../..'

/**
 * Page Grid Layout
 *
 * A responsive column layout with a 24px gutter between columns. Smaller screens will use
 * 6 columns, and larger screens will use the standard 12 columns.
 */
export const PageGrid = forwardRef(({ children, ...props }: SimpleGridProps, ref) => (
  <Container>
    <SimpleGrid columns={{ base: 6, md: 12 }} gap={6} ref={ref} {...props}>
      {children}
    </SimpleGrid>
  </Container>
))

PageGrid.displayName = 'PageGrid'

type PageGridItem = {
  align?: ['left' | 'center' | 'right', 'top' | 'center' | 'bottom']
} & GridItemProps

/**
 * Page Grid Item
 *
 * Page items inside a page grid with the ability to easily align content horizontally and vertically.
 * @param {[ string, string ]} align sets the alignment of grid item content horizonally (first value)
 * and vertically (second value)
 */
export const PageGridItem = forwardRef(({ align = ['left', 'top'], children, ...props }: PageGridItem, ref) => {
  let hAlign = 'flex-start'
  let vAlign = 'flex-start'

  if (align[0] === 'right') hAlign = 'flex-end'
  if (align[0] === 'center') hAlign = align[0]

  if (align[1] === 'bottom') vAlign = 'flex-end'
  if (align[1] === 'center') vAlign = align[1]

  return (
    <GridItem display="flex" alignItems={vAlign} justifyContent={hAlign} ref={ref} {...props}>
      {children}
    </GridItem>
  )
})

PageGridItem.displayName = 'PageGridItem'
