/**
 * Retrieve the trace id associated with an http response.
 * @param response an http response
 */
export function getTraceId(response: Response): string | undefined {
  if (response === undefined || response === null) return undefined
  return getSpanContext(response)?.traceId
}

/**
 * Retrieve the span id associated with an http response.
 * @param response an http response
 */
export function getSpanId(response: Response): string | undefined {
  return getSpanContext(response)?.spanId
}

// The following code has been copied from https://github.com/open-telemetry/opentelemetry-js/blob/main/packages/opentelemetry-core/src/trace/HttpTraceContextPropagator.ts

/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const TRACE_PARENT_HEADER = 'traceparent'
export const TRACE_STATE_HEADER = 'tracestate'

const VERSION = '00'
const VERSION_PART = '(?!ff)[\\da-f]{2}'
const TRACE_ID_PART = '(?![0]{32})[\\da-f]{32}'
const PARENT_ID_PART = '(?![0]{16})[\\da-f]{16}'
const FLAGS_PART = '[\\da-f]{2}'
const TRACE_PARENT_REGEX = new RegExp(
  `^\\s?(${VERSION_PART})-(${TRACE_ID_PART})-(${PARENT_ID_PART})-(${FLAGS_PART})(-.*)?\\s?$`
)

/**
 * Parses information from the [traceparent] span tag and converts it into {@link SpanContext}
 * @param traceParent - A meta property that comes from server.
 *     It should be dynamically generated server side to have the server's request trace Id,
 *     a parent span Id that was set on the server's request span,
 *     and the trace flags to indicate the server's sampling decision
 *     (01 = sampled, 00 = not sampled).
 *     for example: '{version}-{traceId}-{spanId}-{sampleDecision}'
 *     For more information see {@link https://www.w3.org/TR/trace-context/}
 */
export function parseTraceParent(traceParent: string): SpanContext | null {
  const match = TRACE_PARENT_REGEX.exec(traceParent)
  if (!match) return null

  // According to the specification the implementation should be compatible
  // with future versions. If there are more parts, we only reject it if it's using version 00
  // See https://www.w3.org/TR/trace-context/#versioning-of-traceparent
  if (match[1] === VERSION && match[5]) return null

  return {
    traceId: match[2],
    spanId: match[3],
    traceFlags: parseInt(match[4], 16)
  }
}

// End of copying

interface SpanContext {
  traceId: string
  spanId: string
  traceFlags: number
}

function getSpanContext(response: Response): SpanContext | undefined {
  const traceParent = response.headers.get(TRACE_PARENT_HEADER)
  if (traceParent) {
    return parseTraceParent(traceParent) || undefined
  }
  return undefined
}
