import { chakra, forwardRef } from '../design_system'
import { IconProps } from '../icons'

export const SadBrowser = forwardRef<IconProps, 'svg'>((props, ref) => (
  // @ts-expect-error
  <chakra.svg ref={ref} viewBox="0 0 360 180" {...props}>
    <g className="wd-icon-container">
      <path
        fill="#B9C0C7"
        d="M277 135c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zM97 53a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM231.105 47.808c-.017-.32-.025-.64-.025-.964C231.08 36.99 238.924 29 248.6 29c6.502 0 12.177 3.608 15.201 8.965a14.601 14.601 0 0 1 5.679-1.143c6.879 0 12.668 4.755 14.372 11.214C289.127 49.52 293 54.444 293 60.289 293 67.309 287.413 73 280.52 73h-47.04C226.587 73 221 67.309 221 60.289c0-6.193 4.348-11.352 10.105-12.481z"
        opacity=".23"
      />
      <path
        fill="#E04B00"
        d="M243 82c0 34.794-28.206 63-63 63-4.17 0-8.245-.405-12.189-1.178-.514-4.146-2.07-8.095-4.531-11.431-3.061-4.148-7.349-7.115-12.192-8.433a22.504 22.504 0 0 0-4.452-9.945c-2.262-2.867-5.18-5.087-8.482-6.452a20.115 20.115 0 0 0-15.699.122C118.949 99.839 117 91.147 117 82c0-34.794 28.205-63 63-63 34.794 0 63 28.206 63 63z"
      />
      <path
        fill="#B9C0C7"
        d="M168 146.881c.004 5.526-1.845 10.877-5.223 15.119H56.244a29.233 29.233 0 0 1 2.252-15.599c2.16-4.866 5.608-8.98 9.935-11.852a30 30 0 0 1-.134-21.315c2.566-6.86 7.55-12.424 13.92-15.54 6.371-3.116 13.64-3.546 20.305-1.201 6.665 2.344 12.215 7.284 15.502 13.798a20.465 20.465 0 0 1 9.73-4.083 20.115 20.115 0 0 1 10.4 1.353c3.302 1.365 6.22 3.585 8.482 6.452a22.504 22.504 0 0 1 4.452 9.945c4.843 1.318 9.131 4.285 12.192 8.433 3.061 4.149 4.721 9.246 4.72 14.49z"
        opacity=".23"
      />
      <path
        fill="#fff"
        d="M199.554 112.962l-19.81-19.81-18.863 18.862-11.314-11.314 18.863-18.863L149 62.407 160.408 51l19.429 19.43 18.956-18.956 11.315 11.314-18.956 18.956 19.81 19.81-11.408 11.408z"
      />
    </g>
  </chakra.svg>
))
