import { getColor } from '@chakra-ui/theme-tools'

const size = {
  lg: {
    borderRadius: 'md'
  },

  md: {
    borderRadius: 'base'
  },

  sm: {
    borderRadius: 'sm'
  },

  xs: {
    borderRadius: 'sm'
  }
}

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg
  },
  md: {
    field: size.md,
    addon: size.md
  },
  sm: {
    field: size.sm,
    addon: size.sm
  },
  xs: {
    field: size.xs,
    addon: size.xs
  }
}

const variantOutline = props => {
  const { theme, focusBorderColor } = props
  return {
    field: {
      bg: 'white',
      borderWidth: '1px',
      borderColor: 'gray.300',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base',
      '&::-webkit-input-placeholder, &::placeholder': {
        color: 'gray.400'
      },
      '&[data-warned]:not([aria-invalid=true], [data-invalid])': {
        borderColor: 'var(--vndly-color-input-border-warned)',
        boxShadow: `0 0 0 1px var(--vndly-color-input-shadow-warned)`,
        _focus: {
          borderColor: 'var(--vndly-color-input-border-warned)',
          boxShadow: `0 0 0 2px var(--vndly-color-input-shadow-warned-focused)`,
          outline: 'none'
        }
      },
      _hover: {
        borderColor: 'gray.400'
      },
      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, focusBorderColor),
        boxShadow: `0 0 0 1px ${getColor(theme, focusBorderColor)}`
      },
      _disabled: {
        bg: 'gray.300'
      },
      _invalid: {
        borderColor: 'var(--vndly-color-input-border-invalid)',
        boxShadow: `0 0 0 1px var(--vndly-color-input-shadow-invalid)`,
        _focus: {
          borderColor: 'var(--vndly-color-input-border-invalid)',
          boxShadow: `0 0 0 2px var(--vndly-color-input-shadow-invalid-focused)`,
          outline: 'none'
        }
      }
    },
    addon: {
      border: '1px solid',
      borderColor: 'gray.300',
      bg: 'var(--vndly-color-container-background-subdued)',
      color: 'var(--vndly-color-container-subdued)'
    }
  }
}

export const Input = {
  sizes,
  variants: {
    outline: variantOutline
  },
  defaultProps: {
    size: 'md',
    focusBorderColor: 'brand.500',
    variant: 'outline'
  }
}
