import { createIntl as createFormatJsIntl, createIntlCache, IntlShape } from '@formatjs/intl'
import invariant from 'invariant'
import React from 'react'
import { RawIntlProvider } from 'react-intl'

export interface ValeyardIntlContextValue {
  intl: IntlShape
  messages: Record<string, any[]>
  allLayersLoaded: boolean
}

export const ValeyardIntlContext = React.createContext<ValeyardIntlContextValue | undefined>(undefined)

export const ValeyardIntlProvider = ({
  intl,
  messages,
  children,
  allLayersLoaded
}: ValeyardIntlContextValue & {
  children: React.ReactNode
}) => {
  return (
    // This ValeyardIntlContext give the intl and messages context to <Msg/> and useMsg().
    <ValeyardIntlContext.Provider value={{ intl, messages, allLayersLoaded }}>
      {/* This RawIntlProvider gives the intl context to format.js components like <FormattedDate/> */}
      <RawIntlProvider value={intl as IntlShape<any>}>{children}</RawIntlProvider>
    </ValeyardIntlContext.Provider>
  )
}

export function useIntlContext() {
  const ctx = React.useContext(ValeyardIntlContext)
  invariant(ctx, 'useValeyardIntl must be used within a ValeyardIntlContext')
  return ctx
}

export function useIntl() {
  return useIntlContext().intl
}

const intlCache = createIntlCache()

export function createIntl({ locale_tag, timezone }: { locale_tag: string | undefined; timezone: string | undefined }) {
  return createFormatJsIntl(
    {
      locale: locale_tag ?? 'en',
      timeZone: timezone,
      messages: {}
    },
    intlCache
  )
}
