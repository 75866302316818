import { Msg } from '~/common/localizationUtils'
import { Timestamp } from '~/components/Timestamp'
import { AlertBanner, AlertDescription, Box } from '~/design_system'
import { useBannersQuery } from '~/settings/entities/api'

const INFO = 'info'
const WARNING = 'warning'
const ERROR = 'error'

export type BannerApiResponse = {
  [key: string]: Omit<BannerProps, 'key'>
}

export type BannerProps = {
  key: string
  enabled: boolean
  message: string
  messageKey: string
  messageContext: {
    datetime: string
  }
  messageType: typeof INFO | typeof WARNING | typeof ERROR
  userAgents: string[]
}

export type BannersProps = {
  banners?: BannerProps[]
}

const isUserAgentValid = (userAgents: string[]) => {
  if (!userAgents || !userAgents.length) {
    return true
  }

  return userAgents.some(userAgent => RegExp(userAgent).test(window.navigator.userAgent))
}

const mapBannerResponseToBannersArray = (banners?: BannerApiResponse) => {
  if (!banners) return null
  return Object.entries(banners)
    .map(([key, value]) => {
      return { key, ...value }
    })
    .filter(banner => banner.enabled)
}

export const SystemAlertBanners = () => {
  const { data } = useBannersQuery()
  const banners = mapBannerResponseToBannersArray(data) ?? []
  const visibleBanners = banners.filter(({ enabled, userAgents }) => enabled && isUserAgentValid(userAgents))
  if (!banners || !visibleBanners.length) return null

  return (
    <Box boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)">
      {visibleBanners.map(({ key, messageType, message, messageKey, messageContext }) => {
        const status = messageType ?? 'info'
        return (
          <AlertBanner key={key} status={status} variant="system">
            <AlertDescription>
              {messageKey ? (
                <Msg id={messageKey} datetime={<Timestamp datetime={messageContext.datetime} />} />
              ) : (
                <span>{message}</span>
              )}
            </AlertDescription>
          </AlertBanner>
        )
      })}
    </Box>
  )
}
