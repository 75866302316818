export const toHex = (x: number) => {
  const h = x.toString(16)
  return h.length === 1 ? `0${h}` : h
}

export const percentToHex = (x: number) => toHex(Math.round((x * 255) / 100))

export const calculateHexAtGradientPercentage = (color1: string, color2: string, ratio: number) => {
  /**
   * Given two colors, returns the hex color at the provided point in the continuum expressed
   * as a ratio (percentage).
   */
  const c1 = color1.replace('#', '')
  const c2 = color2.replace('#', '')

  const r = Math.ceil(parseInt(c1.substring(0, 2), 16) * ratio + parseInt(c2.substring(0, 2), 16) * (1 - ratio))
  const g = Math.ceil(parseInt(c1.substring(2, 4), 16) * ratio + parseInt(c2.substring(2, 4), 16) * (1 - ratio))
  const b = Math.ceil(parseInt(c1.substring(4, 6), 16) * ratio + parseInt(c2.substring(4, 6), 16) * (1 - ratio))

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}
