import workdayColors from '@workday/canvas-colors-web'
import { StylesConfig, GroupBase } from 'react-select'
import { useTheme } from '../../design_system'
import colors from '../colors'

/**
 * Default style for the `react-select` component.
 */
export const useSelectStyle = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
  variant: Variants
): StylesConfig<Option, IsMulti, Group> => {
  const style = VARIANTS[variant]
  const { zIndices } = useTheme()

  return {
    control: (provided, state) => ({
      ...provided,
      borderColor: style.control.borderColor,
      ':has(input[aria-invalid=true])': {
        borderColor: style.control.errorBorderColor,
        borderWidth: style.control.errorBorderWidth,
        ...(state.isFocused && { boxShadow: style.control.errorBoxShadow, outline: 'none' })
      },
      ':has(input[data-warned])': {
        borderColor: style.control.warningBorderColor,
        borderWidth: style.control.warningBorderWidth,
        ...(state.isFocused && { boxShadow: style.control.warningBoxShadow, outline: 'none' })
      },
      '&:hover': {
        borderColor: style.control.borderColorHover,
        boxShadow: 'none'
      },
      ...(state.isFocused && {
        borderColor: style.control.borderColorFocus,
        boxShadow: style.control.focusBoxShadow
      }),
      ...(state.isDisabled && {
        backgroundColor: style.control.backgroundColorDisabled,
        color: style.control.disabledColor
      }),
      ...(state.selectProps.isLoading && {
        borderColor: style.control.borderColorFocus,
        boxShadow: style.control.focusBoxShadow
      })
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: style.placeholder.color,
      ...(state.isDisabled && { color: style.placeholder.disabledColor })
    }),

    input: provided => ({
      ...provided,
      color: style.input.color
    }),

    singleValue: provided => ({
      ...provided,
      color: style.singleValue.color,
      fontSize: style.singleValue.fontSize
    }),

    option: (provided, state) => ({
      ...provided,
      color: style.option.color,
      backgroundColor: style.option.backgroundColor,
      fontSize: style.option.fontSize,
      '&:active': {
        backgroundColor: style.option.backgroundColorSelected,
        color: 'white'
      },
      ...(state.type === 'option' && state.isFocused && { backgroundColor: style.option.backgroundColorHover }),
      ...(state.type === 'option' &&
        state.isSelected && {
          color: 'white',
          backgroundColor: style.option.backgroundColorSelected
        })
    }),

    menu: provided => ({
      ...provided,
      zIndex: zIndices.docked
    }),

    menuPortal: provided => ({
      ...provided,
      zIndex: zIndices.popover
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: state.selectProps.isClearable && state.hasValue ? 'block' : 'none'
    }),

    loadingIndicator: provided => ({
      ...provided,
      color: colors.gray[500]
    })
  }
}

// Keeping track of all our variants using a string literal type.
export type Variants = 'base'

export type VariantStyle = {
  // Those top-level properties comes from the "Style Object" API of React-Select
  // Read more about it: https://react-select.com/styles#style-object
  control: {
    borderColor: string
    borderColorHover: string
    borderColorFocus: string
    focusBoxShadow: string
    errorBoxShadow: string
    errorBorderColor: string
    errorBorderWidth: string
    warningBoxShadow: string
    warningBorderColor: string
    warningBorderWidth: string
    backgroundColorDisabled: string
    disabledColor: string
  }

  placeholder: {
    color: string
    disabledColor: string
  }

  input: {
    color: string
    borderColor: string
  }

  singleValue: {
    color: string
    fontSize: string
  }

  option: {
    backgroundColor: string
    backgroundColorHover: string
    backgroundColorSelected: string
    color: string
    fontSize: string
  }
}

// Make sure our VARIANTS object is properly typed from the `Variants`
// string literal type and using a value of type `VariantStyle`.
const VARIANTS: { [key in Variants]: VariantStyle } = {
  base: {
    control: {
      borderColor: colors.gray[300],
      borderColorHover: colors.gray[400],
      borderColorFocus: colors.brand[500],
      focusBoxShadow: `0 0 0 1px ${colors.brand[500]}`,
      errorBoxShadow: `0 0 0 1px ${colors.red[600]}`,
      errorBorderColor: colors.red[500],
      errorBorderWidth: '2px',
      warningBoxShadow: `0 0 0 1px ${workdayColors.cantaloupe500}`,
      warningBorderColor: workdayColors.cantaloupe400,
      warningBorderWidth: '2px',
      backgroundColorDisabled: 'var(--vndly-color-input-background-disabled)',
      disabledColor: colors.gray[400]
    },

    placeholder: {
      color: 'var(--vndly-color-container-subdued)',
      disabledColor: colors.gray[400]
    },

    input: {
      color: 'var(--vndly-color-container-dark)',
      borderColor: 'var(--vndly-color-input-border)'
    },

    singleValue: {
      color: 'var(--vndly-color-container-dark)',
      fontSize: 'var(--vndly-font-size-md)'
    },

    option: {
      backgroundColor: 'transparent',
      backgroundColorHover: colors.gray[100],
      backgroundColorSelected: colors.blue[500],
      color: 'var(--vndly-color-container-dark)',
      fontSize: 'var(--vndly-font-size-md)'
    }
  }
}
