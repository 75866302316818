import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // Had to increment this to at least 1 to avoid a JS infinite loop that
      // occurs when the user is unauthenticated and visits application pages.
      // @see https://confluence.workday.com/display/VNDLYIMPL/2023-07-14%3A+Unauthenticated+Redirect+Issues+-+Draft
      // todo(tim): attempt to put back to false when we upgrade 'react-query'
      retry: process.env.NODE_ENV === 'test' ? false : 1
    }
  }
})
