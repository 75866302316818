import React, { cloneElement } from 'react'
import { useInputModality } from '~/common/domUtils'
import { Msg } from '~/common/localizationUtils'
import { ToggledNextLink } from '~/components/v2/next/ToggledNextLink'
import { Box, Button, ButtonProps, Flex, forwardRef, MenuList, useMenuButton, VisuallyHidden } from '~/design_system'
import { ChevronDown } from '~/icons'
import { useMenuItemProps } from '~/navigation_menu/constants'
import { HoverMenu, MenuItemLink, MenuItemLinkProps } from '~/navigation_menu/SubMenu'

export function MainMenusDesktop() {
  const menuItems = useMenuItemProps()
  return (
    <Flex
      as="nav"
      aria-labelledby="main-menu-label"
      display="none"
      sx={{ '@media(min-width: 1350px)': { display: 'flex' } }}>
      <VisuallyHidden as="h2" id="main-menu-label">
        <Msg id="nav.main_menu" description="Main Menu (for Screen Reader)" />
      </VisuallyHidden>
      <MenuButton {...menuItems.home.top} />
      {!menuItems.sow_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.sow_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.sow_menu.sow} />
            <DesktopMenuItemLink {...menuItems.sow_menu.rfp} />
          </MenuList>
        </HoverMenu>
      )}
      {!menuItems.jobs_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.jobs_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.jobs_menu.jobs} />
            <DesktopMenuItemLink {...menuItems.jobs_menu.interview_schedules} />
            <DesktopMenuItemLink {...menuItems.jobs_menu.interview_management} />
          </MenuList>
        </HoverMenu>
      )}
      <MenuButton {...menuItems.candidate_profile.top} />
      <MenuButton {...menuItems.candidates.top} />
      {!menuItems.contractors_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.contractors_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.contractors_menu.contractors_summary} />
            <DesktopMenuItemLink {...menuItems.contractors_menu.preidentified_candidates} />
            <DesktopMenuItemLink {...menuItems.contractors_menu.workers_summary} />
          </MenuList>
        </HoverMenu>
      )}
      {!menuItems.invoices_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.invoices_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.invoices_menu.invoices_summary} />
            <DesktopMenuItemLink {...menuItems.invoices_menu.invoice_payments} />
            <DesktopMenuItemLink {...menuItems.invoices_menu.misc_adjustments} />
            <DesktopMenuItemLink {...menuItems.invoices_menu.invoice_files} />
            <DesktopMenuItemLink {...menuItems.invoices_menu.invoice_job_status} />
          </MenuList>
        </HoverMenu>
      )}
      {!menuItems.timesheets_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.timesheets_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.timesheets_menu.timesheet_summary} />
            <DesktopMenuItemLink {...menuItems.timesheets_menu.timesheet_archives} />
          </MenuList>
        </HoverMenu>
      )}
      <MenuButton {...menuItems.dashboards.top} />
      {!menuItems.reports_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.reports_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.reports_menu.reports} />
            <DesktopMenuItemLink {...menuItems.reports_menu.file_transfer_reports} />
          </MenuList>
        </HoverMenu>
      )}
      {!menuItems.more_menu.top.isHidden && (
        <HoverMenu variant="mainMenu">
          <HoverMenuButton {...menuItems.more_menu.top} />
          <MenuList>
            <DesktopMenuItemLink {...menuItems.more_menu.approvals} />
            <DesktopMenuItemLink {...menuItems.more_menu.documents} />
            <DesktopMenuItemLink {...menuItems.more_menu.vendors} />
            <DesktopMenuItemLink {...menuItems.more_menu.users} />
            <DesktopMenuItemLink {...menuItems.more_menu.company_profile} />
            <DesktopMenuItemLink {...menuItems.more_menu.work_orders} />
            <DesktopMenuItemLink {...menuItems.more_menu.worker_tracking} />
            <DesktopMenuItemLink {...menuItems.more_menu.expenses} />
            <DesktopMenuItemLink {...menuItems.more_menu.bulk_updates} />
            <DesktopMenuItemLink {...menuItems.more_menu.checklists} />
            <DesktopMenuItemLink {...menuItems.more_menu.api_docs} />
            <DesktopMenuItemLink {...menuItems.more_menu.tenant_metadata} />
            <DesktopMenuItemLink {...menuItems.more_menu.company_settings} />
          </MenuList>
        </HoverMenu>
      )}
      <MenuButton {...menuItems.candidate_timesheets.top} />
      <MenuButton {...menuItems.candidate_expenses.top} />
    </Flex>
  )
}

type MenuButtonProps = ButtonProps & { href?: string; isHidden?: boolean }

const MenuButton = forwardRef<MenuButtonProps, 'button'>(
  ({ isHidden, leftIcon, rightIcon, children, href, ...props }, ref) => {
    const { isUsingKeyboard } = useInputModality()

    if (isHidden) return null

    const button = (
      <Button
        variant="mainMenu"
        ref={ref}
        as={href ? 'a' : undefined}
        href={href || undefined}
        // Typically this button is within a <HoverMenu> which adds it's own `--is-keyboard-usage` class, but we
        // have to add the util class here as well because this button isn't always within a HoverMenu.
        className={isUsingKeyboard ? '--is-keyboard-usage' : undefined}
        {...props}>
        {/* German hide icons at 1720px, for English we'd hide icons at 1550px, but we have to hide at widest language */}
        <Box sx={{ '@media(max-width: 1710px)': { display: 'none' } }}>
          {leftIcon && cloneElement(leftIcon, { className: 'menu-button__icon' })}
        </Box>
        {children}
        {rightIcon}
      </Button>
    )

    if (href)
      return (
        <ToggledNextLink href={href} passHref={false}>
          {button}
        </ToggledNextLink>
      )

    return button
  }
)

MenuButton.displayName = 'MenuButton'

function HoverMenuButton(props: MenuButtonProps) {
  const menuButtonProps = useMenuButton(props)
  if (props.isHidden) return null
  return <MenuButton rightIcon={<ChevronDown className="chevron-icon" />} {...props} {...menuButtonProps} />
}

const DesktopMenuItemLink = forwardRef<MenuItemLinkProps & { leftIcon?: MenuItemLinkProps['icon'] }, 'a'>(
  ({ leftIcon, ...props }, ref) => {
    return <MenuItemLink ref={ref} icon={leftIcon} {...props} />
  }
)

DesktopMenuItemLink.displayName = 'DesktopMenuItemLink'
