import React, { ReactNode, useEffect, useState } from 'react'
import { TOP_NAV_HEIGHT_PX } from '../../../common/scrollUtils'
import { useJumpToMenuContext } from '../..'
import {
  Box,
  BoxProps,
  Button,
  forwardRef,
  RadioGroup,
  Stack,
  StackProps,
  Heading,
  useRadio,
  useRadioGroupContext
} from '../../chakra'

type SidebarMenuListProps = {
  onChange?: (value: string | number) => void
} & StackProps

/**
 * SidebarMenuList
 *
 * The sidebar list wrapper for navigational sidebars such as jump-to menus or filters.
 *
 * This component accepts StackProps as input params.
 */
export const SidebarMenuList = forwardRef(({ children, onChange, ...props }: SidebarMenuListProps, ref) => (
  <RadioGroup onChange={onChange}>
    <Stack w="200px" ref={ref} {...props}>
      {children}
    </Stack>
  </RadioGroup>
))

SidebarMenuList.displayName = 'SidebarInteractiveList'
/**
 * SidebarMenuListItem
 *
 * Single list item for SidebarMenuList and also used within SidebarJumpToList.
 */
export const SidebarMenuListItem = forwardRef((props: any, ref) => {
  const group = useRadioGroupContext()
  const isChecked = group.value === props.value
  const { getCheckboxProps } = useRadio({ isChecked, ...props })
  const checkbox = getCheckboxProps()

  useEffect(() => {
    /* isActive prop is true if the scroll position is within the boundaries of the section
	  area. When isActive is true, the list item button will be highlighted. */
    if (props.isActive) {
      group.onChange(props.value)
    }
  }, [props.isActive])

  const handleClick = () => {
    if (props.value != null && group.onChange != null) group.onChange(props.value)
  }

  return (
    <Button
      as={Box}
      variant="list"
      size={props.size}
      onClick={handleClick}
      {...checkbox}
      ref={ref}
      whiteSpace="normal"
      py={2}
      height="auto"
      {...props.styleProps}>
      <Box as="a" href={`#${props.value}`} w="full">
        {props.children}
      </Box>
    </Button>
  )
})

SidebarMenuListItem.displayName = 'SidebarInteractiveItem'

export interface JumpToListItem {
  id: string
  name: string | JSX.Element
}

type SidebarJumpListProps = {
  title?: ReactNode
  size?: 'sm' | 'md' | 'lg'
  sidebarWidth?: string
  boxProps?: BoxProps
  stackProps?: StackProps
  topOffset?: string
}

/**
 * SidebarJumpToList
 *
 * A sidebar Jump-to list is used alongside long content for jump to menus. References can be passed to keep track of
 * scroll position where it will automatically highlight the list item it passes. This component is only used
 * for jump-to menus.
 */
export const SidebarJumpToList = forwardRef(
  (
    {
      title,
      size = 'md',
      sidebarWidth = '200px',
      boxProps = {
        borderRight: '1px solid'
      },
      stackProps = {
        spacing: 0,
        paddingY: 6,
        borderRight: '1px solid'
      },
      topOffset = TOP_NAV_HEIGHT_PX
    }: SidebarJumpListProps,
    ref
  ) => {
    const { targets } = useJumpToMenuContext()
    const [activeSectionId, setActiveSectionId] = useState(targets[0]?.id)

    useEffect(() => {
      const handleScroll = () => {
        // Figure out active section
        const targetVisibilities = targets.map(item => {
          const foundEl = item.el?.closest(`[data-section-header]`)
            ? item.el?.closest(`[data-section-container]`)
            : item.el
          if (!foundEl) return null // { percentageInView: 0, pixelsOnScreen: 0 }
          const rect = foundEl?.getBoundingClientRect()
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight
          const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0)
          if (visibleHeight <= 0) return null // { percentageInView: 0, pixelsOnScreen: 0 }
          const inViewPercentage = (visibleHeight / rect.height) * 100
          const elementTop = Math.max(rect.top, 0)
          const elementBottom = Math.min(rect.bottom, viewportHeight)
          const pixelsOnScreen = elementBottom - elementTop
          return { id: item.id, percentageInView: Math.round(inViewPercentage), pixelsOnScreen }
        })

        // If the last target is fully in view, make it active
        const lastTargetVisibility = targetVisibilities[targetVisibilities.length - 1]
        if (lastTargetVisibility?.percentageInView === 100) {
          setActiveSectionId(lastTargetVisibility.id)
          return
        }

        // Otherwise set the most active section to the first one that is fully on screen
        const firstFullyOnScreen = targetVisibilities.find(tv => tv?.percentageInView === 100)
        if (firstFullyOnScreen) {
          setActiveSectionId(firstFullyOnScreen.id)
          return
        }

        // Lastly, if not section is fully on screen, set the active section to the one with the most pixels on screen
        const mostPixelsOnScreen = targetVisibilities.reduce((champ, contender) => {
          if ((contender?.pixelsOnScreen ?? 0) > (champ?.pixelsOnScreen ?? 0)) return contender
          return champ
        })
        if (mostPixelsOnScreen) {
          setActiveSectionId(mostPixelsOnScreen.id)
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [targets])

    return (
      <Box w={sidebarWidth} borderRight={boxProps?.borderRight} borderRightColor="gray.300" ref={ref}>
        <Stack
          py={stackProps?.paddingY ?? stackProps?.py}
          spacing={stackProps?.spacing}
          w="inherit"
          position="sticky"
          top={topOffset}
          backgroundColor="white"
          borderRight={stackProps?.borderRight}
          borderRightColor="gray.300">
          {title && (
            <Heading py={2} pl={4} textStyle="sectionHeading">
              {title}
            </Heading>
          )}
          <RadioGroup>
            <Stack>
              {targets.map(item => (
                <SidebarMenuListItem key={item.id} value={item.id} size={size} isActive={item.id === activeSectionId}>
                  {item.name}
                </SidebarMenuListItem>
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
      </Box>
    )
  }
)

SidebarJumpToList.displayName = 'SidebarJumpToList'
