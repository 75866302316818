import { useQuery } from '@tanstack/react-query'
import fetch from '~/common/fetch'

export const api = {
  get: () => fetch.get('/api/v2/infrastructure/config')
}

export const useTenantMetadata = () => {
  return useQuery({ queryKey: ['tenant_metadata', 'get'], queryFn: () => api.get() })
}
