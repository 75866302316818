import { useDisclosure, MenuItem, useSuccessToast, useErrorModal } from '~/design_system'
import { Check, UserAdd, Close, Pin } from '~/icons'
import { useCloseTaskMutation, useTogglePinTaskMutation, useToggleDismissTaskMutation } from '~/api/myTasks'
import { useHomeConfigQuery } from '~/api/home'
import { useMsg } from '~/common/localizationUtils'

interface TaskActionButtonProps {
  taskID: number
  subType: string
  isPinned: boolean
  showPinAction?: boolean
}

export const TaskActionButtons = ({ taskID, subType, isPinned, showPinAction = true }: TaskActionButtonProps) => {
  const { data: homeConfig, isLoading } = useHomeConfigQuery()
  const { onClose } = useDisclosure()
  const supportAssign = false
  const successToast = useSuccessToast()
  const { showModal: showErrorModal } = useErrorModal()
  const enableDismissAll = !isLoading && homeConfig?.tasks?.can_dismiss_all
  const msg = useMsg()

  function onDismissSuccess() {
    successToast(
      `${subType} ${msg('term.task', { count: 1 })} ${
        isPinned ? msg('tasks.actions.undismissed') : msg('tasks.actions.dismissed')
      }`
    )
    onClose()
  }

  function onDismissError() {
    showErrorModal({
      message: `${subType} ${
        isPinned
          ? msg('user_tasks.tasks_failed_to_undismiss', { count: 1 })
          : msg('user_tasks.tasks_failed_to_dismiss', { count: 1 })
      }`
    })
  }

  function onPinSuccess() {
    successToast(`${subType} ${isPinned ? msg('tasks.actions.unmarked') : msg('tasks.actions.marked')}`)
    onClose()
  }

  function onPinError() {
    showErrorModal({
      message: `${subType} ${isPinned ? msg('tasks.actions.unmarked_failed') : msg('tasks.actions.marked_failed')}`
    })
  }

  function onCompleteSuccess() {
    successToast(`${subType} ${msg('term.task', { count: 1 })} ${msg('common.ui.completed')}`)
    onClose()
  }

  function onCompleteError() {
    showErrorModal({ message: `${subType} ${(msg('user_tasks.tasks_failed_to_mark_complete'), { count: 1 })}` })
  }

  const { mutateAsync: closeTask } = useCloseTaskMutation({ onSuccess: onCompleteSuccess, onError: onCompleteError })
  const { mutateAsync: togglePinTask } = useTogglePinTaskMutation({ onSuccess: onPinSuccess, onError: onPinError })
  const { mutateAsync: toggleDismissTask } = useToggleDismissTaskMutation({
    onSuccess: onDismissSuccess,
    onError: onDismissError
  })

  return (
    <>
      {!isLoading && (
        <>
          {showPinAction && (
            <MenuItem
              data-testid="pin-to-top-menu-item"
              onClick={() => {
                togglePinTask(taskID)
              }}>
              <Pin mr={2} /> {isPinned ? msg('tasks.actions.unpin') : msg('tasks.actions.pin')}
            </MenuItem>
          )}
          <MenuItem
            data-testid="dismiss-for-me-menu-item"
            onClick={() => {
              toggleDismissTask(taskID)
            }}>
            <Close mr={2} /> {msg('tasks.actions.dismiss_for_me')}
          </MenuItem>
          {enableDismissAll && (
            <MenuItem
              data-testid="dismiss-for-all-menu-item"
              onClick={() => {
                closeTask(taskID)
              }}>
              <Check mr={2} /> {msg('tasks.actions.dismiss_for_all')}
            </MenuItem>
          )}
          {supportAssign && (
            <MenuItem data-testid="assign-to-menu-item">
              <UserAdd mr={2} /> {msg('tasks.actions.assign')}
            </MenuItem>
          )}
        </>
      )}
    </>
  )
}
