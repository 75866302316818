import { ReactNode } from 'react'
import { Badge, forwardRef, HStack, Link, LinkProps, Stack, StackProps, Text, TextProps } from '../../chakra'
import { BadgeVariant } from '../../types'

/**
 * Title Block Inline
 *
 * Used for any inline title blocks. More commonly used inline text blocks are available below.
 */
export const TitleBlockInline = forwardRef(({ children, ...props }: StackProps, ref) => (
  <HStack alignItems="center" ref={ref} {...props}>
    {children}
  </HStack>
))

/**
 * Title Block Two Line
 *
 * Used for any two line title blocks.
 */
export const TitleBlockTwoLine = forwardRef(({ children, ...props }: StackProps, ref) => (
  <Stack spacing={0} ref={ref} {...props}>
    {children}
  </Stack>
))

/**
 * Title Block Text Types
 *
 * Primary, subtext, secondary or link texts with their text styles based on sizes: 'sm', 'md', 'lg'.
 */
type TitleBlockTextProps = {
  textSize: 'sm' | 'md' | 'lg'
} & TextProps

export const TitleBlockPrimaryText = forwardRef(({ textSize = 'lg', children, ...props }: TitleBlockTextProps, ref) => {
  let primaryTextStyle = 'bodySemi'
  if (textSize === 'lg') primaryTextStyle = 'heading1'
  if (textSize === 'md') primaryTextStyle = 'heading3Semi'

  if (textSize === 'sm') {
    return (
      <Text m={0} textStyle={primaryTextStyle} ref={ref} {...props}>
        {children}
      </Text>
    )
  }

  return (
    <Text as={textSize === 'lg' ? 'h1' : 'h3'} m={0} textStyle={primaryTextStyle} ref={ref} {...props}>
      {children}
    </Text>
  )
})

export const TitleBlockSubtext = forwardRef(({ textSize, children, ...props }: TitleBlockTextProps, ref) => (
  <Text m={0} textStyle={textSize === 'lg' ? 'subtext' : 'subtextSmall'} ref={ref} {...props}>
    {children}
  </Text>
))

export const TitleBlockSecondaryText = forwardRef(({ textSize, children, ...props }: TitleBlockTextProps, ref) => (
  <Text m={0} textStyle={textSize === 'sm' ? 'bodySmall' : 'body'} ref={ref} {...props}>
    {children}
  </Text>
))

export const TitleBlockLink = forwardRef(({ children, ...props }: LinkProps, ref) => (
  // @ts-ignore
  <Link variant="inlineGray" size="sm" ref={ref} {...props}>
    {children}
  </Link>
))

/**
 * Commonly Used Title Block Components
 */
type TitleBlockProps = {
  primaryText: ReactNode
  subtext: ReactNode
  badgeStatus?: BadgeVariant
  badgeText: ReactNode
  size: 'sm' | 'md' | 'lg'
} & StackProps

export const TitleBlockInlineDefault = forwardRef(
  ({ primaryText, subtext, badgeStatus = 'incomplete', badgeText, size, ...props }: TitleBlockProps, ref) => (
    <TitleBlockInline ref={ref} {...props}>
      <TitleBlockPrimaryText textSize={size}>{primaryText}</TitleBlockPrimaryText>
      <Badge variant={badgeStatus || 'incomplete'} size={size === 'lg' ? 'lg' : 'sm'} data-testid="status-badge">
        {badgeText}
      </Badge>
      <TitleBlockSubtext whiteSpace="nowrap" textSize={size}>
        {subtext}
      </TitleBlockSubtext>
    </TitleBlockInline>
  )
)

TitleBlockInlineDefault.displayName = 'TitleBlockInlineDefault'

type TitleBlockWithLinkProps = {
  primaryText: ReactNode
  subtext: ReactNode
  badgeStatus?: BadgeVariant
  badgeText: ReactNode
  size: 'sm' | 'md' | 'lg'
  linkProps: LinkProps
} & StackProps

export const TitleBlockInlineWithLink = forwardRef(
  (
    { primaryText, subtext, badgeStatus = 'incomplete', badgeText, size, linkProps, ...props }: TitleBlockWithLinkProps,
    ref
  ) => (
    <TitleBlockInline ref={ref} {...props}>
      <TitleBlockPrimaryText textSize={size}>{primaryText}</TitleBlockPrimaryText>
      <Badge variant={badgeStatus || 'incomplete'} size={size === 'lg' ? 'lg' : 'sm'} data-testid="status-badge">
        {badgeText}
      </Badge>
      <TitleBlockLink {...linkProps}>{subtext}</TitleBlockLink>
    </TitleBlockInline>
  )
)

TitleBlockInlineWithLink.displayName = 'TitleBlockInlineWithLink'

export const TitleBlockTwoLineDefault = forwardRef(
  ({ primaryText, subtext, badgeStatus = 'incomplete', badgeText, size, ...props }: TitleBlockProps, ref) => (
    <TitleBlockTwoLine ref={ref} {...props}>
      <TitleBlockPrimaryText textSize={size}>{primaryText}</TitleBlockPrimaryText>
      <TitleBlockInline>
        <Badge
          m={0}
          variant={badgeStatus || 'incomplete'}
          size={size === 'lg' ? 'lg' : 'sm'}
          data-testid="status-badge">
          {badgeText}
        </Badge>
        <TitleBlockSubtext textSize={size}>{subtext}</TitleBlockSubtext>
      </TitleBlockInline>
    </TitleBlockTwoLine>
  )
)

TitleBlockTwoLineDefault.displayName = 'TitleBlockTwoLineDefault'

type TitleBlockWithSecondaryProps = {
  primaryText: ReactNode
  subtext: ReactNode
  secondaryText: ReactNode
  badgeStatus?: BadgeVariant
  badgeText: ReactNode
  size: 'sm' | 'md' | 'lg'
} & StackProps

export const TitleBlockTwoLineWithSecondary = forwardRef(
  (
    {
      primaryText,
      subtext,
      secondaryText,
      badgeStatus = 'incomplete',
      badgeText,
      size,
      ...props
    }: TitleBlockWithSecondaryProps,
    ref
  ) => (
    <TitleBlockTwoLine ref={ref} {...props}>
      <TitleBlockInline>
        <TitleBlockPrimaryText textSize={size}>{primaryText}</TitleBlockPrimaryText>
        <Badge
          m={0}
          variant={badgeStatus || 'incomplete'}
          size={size === 'lg' ? 'lg' : 'sm'}
          data-testid="status-badge">
          {badgeText}
        </Badge>
        <TitleBlockSubtext textSize={size}>{subtext}</TitleBlockSubtext>
      </TitleBlockInline>
      <TitleBlockSecondaryText textSize={size}>{secondaryText}</TitleBlockSecondaryText>
    </TitleBlockTwoLine>
  )
)

TitleBlockTwoLineWithSecondary.displayName = 'TitleBlockTwoLineWithSecondary'
