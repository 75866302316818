import qs from 'qs'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { queryClient } from '~/common/react-query/queryClient'
import fetch from '../common/fetch'

export enum UserPreferenceType {
  SAVED_FILTERS = 'saved_filters',
  VISIBLE_FIELDS = 'visible_fields',
  VIEW_ACCESS = 'view_access'
}

export enum UserPreferenceNamespace {
  JOB = 'job',
  WORKFLOW_APPROVALS = 'workflow_approvals',
  JOB_APPLICATION = 'job_application',
  WORK_ORDER = 'work_order',
  DATA_PRIVACY = 'data_privacy',
  WORKER_TRACKER_WORK_ORDER = 'worker_tracker_work_order',
  VENDOR_DISTRIBUTION_GROUPS = 'vendor_distribution_groups',
  VENDOR_DISTRIBUTION_RULES = 'vendor_distribution_rules',
  RATE_CARDS = 'rate_cards',
  SOW_LIST = 'sow_list',
  SOW_CHANGE_ORDERS = 'sow_change_orders',
  SOW_CHANGE_ORDER_LIST_INNER = 'sow_change_order_list_inner',
  SOW_UNITS = 'sow_units',
  SOW_MILESTONES = 'sow_milestones',
  SOW_FIXED_PRICE_PAYMENTS = 'sow_fixed_price_payments',
  RFP_LIST = 'rfp_list',
  RFP_PROPOSALS = 'rfp_proposals',
  SOW_ROLE_LIST = 'sow_role_list',
  SOW_CHECKLIST_LIST = 'sow_checklist_list',
  SOW_WORKER_LIST = 'sow_worker_list',
  VENDOR_LIST = 'vendor_list',
  INDEPENDENT_CONTRACTOR_LIST = 'independent_contractor_list',
  TASK_LIST = 'task_list',
  INTERVIEW = 'interview',
  INTERVIEW_TWP = 'interview_twp',
  SETTINGS_CUSTOMIZATION = 'settings_customization',
  CONTRACTORS = 'contractors',
  REPORTING = 'reporting',
  MANAGE_CHECKLIST_ACTIONS = 'manage_checklist_actions',
  VENDOR_CHECKLIST_ACTIONS = 'vendor_checklist_actions',
  WORKER_TRACKER_WORK_ORDER_CHECKLIST_ACTIONS = 'worker_tracker_work_order_checklist_actions',
  WORKER_TRACKER_CHECKLIST_ACTIONS = 'worker_tracker_checklist_actions',
  CONTRACTORS_CHECKLIST_ACTIONS = 'contractors_checklist_actions',
  WORK_ORDER_CHECKLIST_ACTIONS = 'work_order_checklist_actions',
  JOB_APPLIANT_CHECKLIST_ACTIONS = 'job_applicant_checklist_actions',
  VENDOR_CANDIDATE_CHECKLIST_ACTIONS = 'vendor_candidate_checklist_actions',
  EVENTS = 'events'
}

export enum UserRoles {
  EMPLOYER = '2',
  VENDOR = '3'
}

export interface UserPreferenceListParams {
  preference_type: UserPreferenceType
  namespace: UserPreferenceNamespace
  obj_id?: number
}

interface UserPreference<PreferenceType, Data> {
  id: number
  namespace: UserPreferenceNamespace
  preference_type: PreferenceType
  data: Data
  uuid?: string
  user_id?: number
}

export interface UserPreferenceRequest {
  namespace: UserPreferenceNamespace
  preference_type: UserPreferenceType
  data: SavedFilterData
}

export interface SavedFilterData {
  name: string
  filterValues: Record<string, any>
  search: string | undefined
}

export type SavedFilterUserPreference = UserPreference<UserPreferenceType.SAVED_FILTERS, SavedFilterData>

export enum VisibleFieldPosition {
  ATTRIBUTES_COLUMN = 'attributes_column',
  TITLE_ADDITIONAL_INFO = 'title_additional_info',
  META = 'meta'
}

export type VisibleFieldOption = {
  label: string
  value: string
}

export type VisibleFieldData = {
  icon: string
  label?: string
  display_order: number
  key: string
  blocked: boolean
  value: string | boolean
  position: VisibleFieldPosition
  options: VisibleFieldOption[] | null
}

type VisibleFieldsUserPreference = UserPreference<UserPreferenceType.VISIBLE_FIELDS, VisibleFieldData[]>

type ViewAccessDataUserPreference = UserPreference<UserPreferenceType.VIEW_ACCESS, any>

export type ObjId = {
  job_id?: number
  wo_id?: number
}

/**
 * User Preference API
 */
const USER_PREF_BASE_URL = '/api/v2/user_preferences'
const SAVED_FILTER_QUERY_KEY = 'saved_filters'
const VISIBLE_FIELDS_QUERY_KEY = 'visible_fields'
const VIEW_ACCESS_QUERY_KEY = 'view_access'
const VIEW_ACCESS_NO_CACHE_QUERY_KEY = 'view_access-no-cache'
const THIRTY_MINUTES = 1000 * 60 * 30
const responseCache = { ttl: THIRTY_MINUTES }
export const userPrefsApi = {
  get<PreferenceType extends UserPreference<string, Record<string, any>>>(
    params: UserPreferenceListParams
  ): Promise<PreferenceType> {
    return fetch.get(`${USER_PREF_BASE_URL}?${qs.stringify(params)}`)
  },
  list<PreferenceType extends UserPreference<string, Record<string, any>>>(
    params: UserPreferenceListParams
  ): Promise<PreferenceType[]> {
    return fetch.get(`${USER_PREF_BASE_URL}?${qs.stringify(params)}`)
  },
  create: (data: UserPreferenceRequest, objId?: ObjId) => fetch.post(USER_PREF_BASE_URL, { ...data, ...objId }),
  update: (dataId: number | undefined, data: UserPreferenceRequest, objId?: ObjId) =>
    fetch.put(`${USER_PREF_BASE_URL}/${dataId}/`, { ...data, ...objId }),
  delete: ({ userPreferenceId }: { userPreferenceId: number }) =>
    fetch.delete(`${USER_PREF_BASE_URL}/${userPreferenceId}`)
}

export const useSavedFiltersListQuery = (namespace: UserPreferenceNamespace) =>
  useQuery({
    queryKey: [SAVED_FILTER_QUERY_KEY, namespace],
    queryFn: () =>
      userPrefsApi.list<SavedFilterUserPreference>({
        preference_type: UserPreferenceType.SAVED_FILTERS,
        namespace
      })
  })

export const useSavedFilterCreateMutation = (
  opts?: UseMutationOptions<UserPreferenceRequest, unknown, UserPreferenceRequest>
) =>
  useMutation({
    mutationFn: (data: UserPreferenceRequest) => userPrefsApi.create(data),
    ...opts,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([SAVED_FILTER_QUERY_KEY])
      if (typeof opts?.onSuccess === 'function') opts.onSuccess(data, variables, context)
    }
  })

export const useSavedFilterUpdateMutation = (
  id: number,
  opts?: UseMutationOptions<UserPreferenceRequest, unknown, UserPreferenceRequest>
) =>
  useMutation({
    mutationFn: (data: UserPreferenceRequest) => {
      return userPrefsApi.update(id, data)
    },
    ...opts,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([SAVED_FILTER_QUERY_KEY])
      if (typeof opts?.onSuccess === 'function') opts.onSuccess(data, variables, context)
    }
  })

export const useSavedFiltersDeleteMutation = () =>
  useMutation({
    mutationFn: userPrefsApi.delete,
    onSuccess: async () => {
      await queryClient.invalidateQueries([SAVED_FILTER_QUERY_KEY])
    }
  })

export const useVisibleFieldsQuery = (
  preference_type: UserPreferenceType,
  namespace: UserPreferenceNamespace,
  objId?: ObjId
) => {
  const result = useQuery({
    queryKey: [VISIBLE_FIELDS_QUERY_KEY, namespace],
    queryFn: () => userPrefsApi.get<VisibleFieldsUserPreference>({ preference_type, namespace, ...objId }),
    staleTime: Infinity
  })

  return Object.assign(result, {
    visible_fields_user_data: result.data?.data,
    visible_fields_id: result.data?.id
  })
}

export const useVisibleFieldsMutation = (
  id: number | undefined,
  objId?: ObjId,
  opts?: UseMutationOptions<UserPreferenceRequest, unknown, UserPreferenceRequest>
) =>
  useMutation({
    mutationFn: (data: UserPreferenceRequest) => {
      if (id) {
        return userPrefsApi.update(id, data, objId)
      }
      return userPrefsApi.create(data, objId)
    },
    ...opts,
    onSuccess: async (...args) => {
      if (typeof opts?.onSuccess === 'function') opts?.onSuccess(...args)
      await queryClient.invalidateQueries([VISIBLE_FIELDS_QUERY_KEY])
    }
  })

export type BaseVisibleFieldsSettingsResponse = {
  title: string
  fields: VisibleFieldData[]
  default_fields: VisibleFieldData[]
}

type VisibleFieldsSettingsResponse = {
  client_data: BaseVisibleFieldsSettingsResponse
  vendor_data: BaseVisibleFieldsSettingsResponse
}

export type VisibleFieldsSettingsSaveParams = {
  namespace?: UserPreferenceNamespace
  userRole?: UserRoles
  visibleFields: VisibleFieldData[]
}

type VisibleFieldsSettingsConfig = {
  can_update_visible_fields_config: boolean
}

export interface VisibleFieldsSettingsParams {
  namespace: UserPreferenceNamespace
  job_id?: number
  wo_id?: number
}

const VISIBLE_FIELDS_SETTINGS_URL = '/api/v2/settings/visible_fields_defaults'
const VISIBLE_FIELDS_SETTINGS_CONFIG_URL = '/api/v2/settings/visible_fields_defaults_config'
const VISIBLE_FIELDS_SETTINGS_KEY = 'settings/visible_fields_defaults'
const VISIBLE_FIELDS_SETTINGS_CONFIG_KEY = 'settings/visible_fields_defaults/config'
const visibleFieldSettingsApi = {
  get: (params: VisibleFieldsSettingsParams): Promise<VisibleFieldsSettingsResponse> =>
    fetch.get(`${VISIBLE_FIELDS_SETTINGS_URL}/?${qs.stringify(params)}`, { responseCache }),
  put: ({ userRole, visibleFields, namespace }: VisibleFieldsSettingsSaveParams): Promise<any> =>
    fetch.put(`${VISIBLE_FIELDS_SETTINGS_URL}/?user_role=${userRole}&namespace=${namespace}`, visibleFields),
  config: (): Promise<VisibleFieldsSettingsConfig> =>
    fetch.get(`${VISIBLE_FIELDS_SETTINGS_CONFIG_URL}/`, { responseCache })
}

export const useVisibleFieldsSettingsQuery = (
  namespace: UserPreferenceNamespace,
  objId?: ObjId,
  opts?: UseQueryOptions<VisibleFieldsSettingsResponse>
) => {
  const result = useQuery({
    queryKey: [VISIBLE_FIELDS_SETTINGS_KEY, namespace],
    queryFn: () => visibleFieldSettingsApi.get({ namespace, ...objId }),
    staleTime: Infinity,
    ...opts
  })

  return Object.assign(result, {
    visible_fields_client_data: result.data?.client_data,
    visible_fields_vendor_data: result.data?.vendor_data
  })
}

export const useVisibleFieldsSettingsMutation = (
  opts?: UseMutationOptions<any, unknown, VisibleFieldsSettingsSaveParams>
) =>
  useMutation({
    mutationFn: visibleFieldSettingsApi.put,
    ...opts,
    onSuccess: async (...args) => {
      if (typeof opts?.onSuccess === 'function') opts?.onSuccess(...args)
      await queryClient.invalidateQueries([VISIBLE_FIELDS_SETTINGS_KEY])
    }
  })

export const useVisibleFieldsSettingsConfig = () => {
  return useQuery({
    queryKey: [VISIBLE_FIELDS_SETTINGS_CONFIG_KEY],
    queryFn: () => visibleFieldSettingsApi.config(),
    staleTime: Infinity
  })
}

export const useViewAccessQuery = (preference_type: UserPreferenceType, namespace: UserPreferenceNamespace) => {
  const result = useQuery({
    queryKey: [VIEW_ACCESS_QUERY_KEY, namespace],
    queryFn: () => userPrefsApi.get<ViewAccessDataUserPreference>({ preference_type, namespace }),
    staleTime: Infinity
  })
  return Object.assign(result, {
    viewAccessUserData: result.data?.data,
    viewAccessId: result.data?.id
  })
}

export const useViewAccessMutation = (
  id: number | undefined,
  opts?: UseMutationOptions<UserPreferenceRequest, unknown, UserPreferenceRequest>
) =>
  useMutation({
    mutationFn: (data: UserPreferenceRequest) => {
      if (id) {
        return userPrefsApi.update(id, data)
      }
      return userPrefsApi.create(data)
    },
    ...opts,
    onSuccess: async (...args) => {
      if (typeof opts?.onSuccess === 'function') opts?.onSuccess(...args)
      await queryClient.invalidateQueries([VIEW_ACCESS_QUERY_KEY])
      await queryClient.invalidateQueries([VIEW_ACCESS_NO_CACHE_QUERY_KEY])
    }
  })
