import { useCallback, useLayoutEffect, useRef } from 'react'

export function useLatestRef<T>(value: T): { current: T } {
  // Store the value in a ref
  const valueRef = useRef(value)

  // Update the ref to point to the new value on each render
  useUniversalLayoutEffect(() => {
    valueRef.current = value
  }, [value])

  // Return a stable ref
  return valueRef
}

export function useLatestCallbackRef<T extends (...args: any[]) => any>(handler: T): T {
  // Store the handler in a ref
  const handlerRef = useLatestRef(handler)

  // Return a stable function
  return useCallback((...args: Parameters<T>) => {
    // Call the current handler with all arguments
    return handlerRef.current?.(...args)
  }, []) as T
}

// Prevents Server side error
export const useUniversalLayoutEffect = typeof document === 'undefined' ? () => {} : useLayoutEffect
