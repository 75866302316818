export const woStatus = {
  DRAFT: 1,
  APPLIED: 16,
  INTERVIEWING: 17,
  CLIENT_REJECTED: 18,
  PENDING_OFFER_APPROVALS: 19,
  CLIENT_OFFER_RELEASED: 20,
  VENDOR_OFFER_DECLINED: 21,
  VENDOR_WITHDRAWN: 22,
  VERIFICATION_IN_PROGRESS: 23,
  VERIFICATION_FAILED: 24,
  PENDING_ONBOARD_APPROVALS: 25,
  PENDING_OFFER_ACCEPTANCE: 29,
  APPROVAL_PENDING: 2,
  SUBMITTED: 3,
  ACCEPTED: 4,
  DECLINED: 5,
  READY_TO_ONBOARD: 6,
  ACTIVE: 7,
  ENDED: 8,
  CANCELLED: 9,
  ENDED_APPROVAL_PENDING: 10,
  INTERNAL_APPROVALS_REJECTED: 11,
  JOB_CLOSED: 26,
  DRAFT_DELETED: 28,
  CONNECTOR_PENDING: 14,
  CONNECTOR_FAILED: 15
}

export type StatusEnum = {
  l10n_key: string
  variant: string
}

type WOStatusConfig = {
  [key: number]: StatusEnum
}

// TODO (vic) - maybe WO config should be providing these instead of duplicating the list from DateStatusEnum.WO_CHOICES
export const FILTER_STATUSES = [
  woStatus.ACTIVE,
  woStatus.APPLIED,
  woStatus.CANCELLED,
  woStatus.DRAFT,
  woStatus.DRAFT_DELETED,
  woStatus.ENDED,
  woStatus.JOB_CLOSED,
  woStatus.INTERNAL_APPROVALS_REJECTED,
  woStatus.INTERVIEWING,
  woStatus.PENDING_OFFER_APPROVALS,
  woStatus.VENDOR_OFFER_DECLINED,
  woStatus.CLIENT_OFFER_RELEASED,
  woStatus.PENDING_ONBOARD_APPROVALS,
  woStatus.PENDING_OFFER_ACCEPTANCE,
  woStatus.READY_TO_ONBOARD,
  woStatus.CLIENT_REJECTED,
  woStatus.VERIFICATION_FAILED,
  woStatus.VERIFICATION_IN_PROGRESS,
  woStatus.VENDOR_WITHDRAWN
]
// [val] & l10n_key must match WorkOrderWorkflowState enum
export const woStatusConfig: WOStatusConfig = {
  [woStatus.ACTIVE]: {
    l10n_key: 'work_order.workflow_state.active',
    variant: 'success'
  },
  [woStatus.APPLIED]: {
    l10n_key: 'work_order.workflow_state.applied',
    variant: 'incomplete'
  },
  [woStatus.CANCELLED]: {
    l10n_key: 'work_order.workflow_state.cancelled',
    variant: 'complete'
  },
  [woStatus.CLIENT_REJECTED]: {
    l10n_key: 'work_order.workflow_state.client_rejected',
    variant: 'danger'
  },
  [woStatus.CONNECTOR_FAILED]: {
    l10n_key: 'work_order.workflow_state.failed_connector',
    variant: 'danger'
  },
  [woStatus.CONNECTOR_PENDING]: {
    l10n_key: 'work_order.workflow_state.pending_connector',
    variant: 'pending'
  },
  [woStatus.DRAFT]: {
    l10n_key: 'work_order.workflow_state.draft',
    variant: 'incomplete'
  },
  [woStatus.DRAFT_DELETED]: {
    l10n_key: 'work_order.workflow_state.draft_deleted',
    variant: 'complete'
  },
  [woStatus.ENDED]: {
    l10n_key: 'work_order.workflow_state.ended',
    variant: 'complete'
  },
  [woStatus.ENDED_APPROVAL_PENDING]: {
    l10n_key: 'work_order.workflow_state.ended_approval_pending',
    variant: 'pending'
  },
  [woStatus.JOB_CLOSED]: {
    l10n_key: 'work_order.workflow_state.job_closed',
    variant: 'complete'
  },
  [woStatus.INTERNAL_APPROVALS_REJECTED]: {
    l10n_key: 'work_order.workflow_state.internal_approvals_rejected',
    variant: 'danger'
  },
  [woStatus.INTERVIEWING]: {
    l10n_key: 'work_order.workflow_state.interviewing',
    variant: 'pending'
  },
  [woStatus.PENDING_OFFER_APPROVALS]: {
    l10n_key: 'work_order.workflow_state.pending_offer_approvals',
    variant: 'pending'
  },
  [woStatus.CLIENT_OFFER_RELEASED]: {
    l10n_key: 'work_order.workflow_state.offer_released',
    variant: 'pending'
  },
  [woStatus.VENDOR_OFFER_DECLINED]: {
    l10n_key: 'work_order.workflow_state.vendor_offer_declined',
    variant: 'danger'
  },
  [woStatus.PENDING_ONBOARD_APPROVALS]: {
    l10n_key: 'work_order.workflow_state.pending_onboard_approvals',
    variant: 'pending'
  },
  [woStatus.PENDING_OFFER_ACCEPTANCE]: {
    l10n_key: 'term.pending_offer_acceptance',
    variant: 'pending'
  },
  [woStatus.APPROVAL_PENDING]: {
    l10n_key: 'work_order.workflow_state.approval_pending',
    variant: 'pending'
  },
  [woStatus.SUBMITTED]: {
    l10n_key: 'work_order.workflow_state.submitted',
    variant: 'pending'
  },
  [woStatus.ACCEPTED]: {
    l10n_key: 'work_order.workflow_state.accepted',
    variant: 'complete'
  },
  [woStatus.DECLINED]: {
    l10n_key: 'work_order.workflow_state.declined',
    variant: 'danger'
  },
  [woStatus.READY_TO_ONBOARD]: {
    l10n_key: 'work_order.workflow_state.ready_to_onboard',
    variant: 'pending'
  },
  [woStatus.VENDOR_WITHDRAWN]: {
    l10n_key: 'work_order.workflow_state.vendor_withdrawn',
    variant: 'complete'
  },
  [woStatus.VERIFICATION_IN_PROGRESS]: {
    l10n_key: 'work_order.workflow_state.verification_in_progress',
    variant: 'pending'
  },
  [woStatus.VERIFICATION_FAILED]: {
    l10n_key: 'work_order.workflow_state.verification_failed',
    variant: 'danger'
  }
}
export const woHireType = {
  DIRECT_HIRE: 1,
  CONTRACTOR: 2
}
export const sourceType = {
  SUPPLIER_SOURCED: 1,
  PAYROLLED: 2,
  DIRECTED: 3,
  DIRECT_HIRE_SOURCE: 5,
  SOW: 6,
  WP: 7
}
export const sourceTypeConfig = {
  [sourceType.SUPPLIER_SOURCED]: {
    label: 'Supplier Sourced'
  },
  [sourceType.PAYROLLED]: {
    label: 'Payrolled'
  },
  [sourceType.DIRECTED]: {
    label: 'Directed'
  },
  [sourceType.DIRECT_HIRE_SOURCE]: {
    label: 'Direct Hire'
  },
  [sourceType.SOW]: {
    label: 'Statement of Work'
  },
  [sourceType.WP]: {
    label: 'Worker Profile'
  }
}
export const userRoles = {
  Employer: 'employer',
  Vendor: 'vendor',
  All: 'ALL'
}
